import { createSlice } from "@reduxjs/toolkit";

const defaultPageSize = localStorage.getItem("invoices_pageSize_default")
	? localStorage.getItem("invoices_pageSize_default")
	: process.env.REACT_APP_DEFAULT_PAGE_SIZE_LIST_INVOICE;

export const initialFilter = {
	invoiceNoOrSupplierCode: "",
	status: [],
	startDate: null,
	endDate: null,
	pageNo: 0,
	pageSize: defaultPageSize,
	selectAll: false,
	sortBy: "",
	period: "",
};
const filterListInvoices = createSlice({
	name: "filter",
	initialState: initialFilter,
	reducers: {
		updateFilter: (state, action) => action.payload,
		initFilter: () => initialFilter,
	},
});

export const { updateFilter, initFilter } = filterListInvoices.actions;

export default filterListInvoices.reducer;
