import { format, parse } from "date-fns";
import { FETCH_ORDER_TO_APPROVE, CREATE_ORDER_APPROVAL_REJECTION } from "../../constants";
import { URL_BASE_ORDER } from "../../constants/endpoint";

export const fetchOrderProtected = (id, token) => ({
	type: FETCH_ORDER_TO_APPROVE,
	request: { url: `${URL_BASE_ORDER}/protected/${id}`, params: { token } },
	meta: {
		onSuccess: (response) => {
			if (response.status === 200) {
				let { data } = response;
				const issueDate = response.data.issueDate
					? parse(response.data.issueDate, "dd/MM/yyyy", new Date())
					: null;
				const dueDate = response.data.dueDate
					? parse(response.data.dueDate, "dd/MM/yyyy", new Date())
					: null;
				data = {
					...data,
					issueDate: issueDate ? format(issueDate, "yyyy-MM-dd") : null,
					dueDate: dueDate ? format(dueDate, "yyyy-MM-dd") : null,
				};
				response = { ...response, data };
			}
			return response;
		},
		onError: (error) => {
			if ([401, 403].includes(error.response.status)) {
				window.location.replace("/login");
			}
		},
	},
});

export const createOrderApprovalRejection = (id, orderRejection, token, onSuccess, onError) => ({
	type: CREATE_ORDER_APPROVAL_REJECTION,
	request: {
		url: `${URL_BASE_ORDER}/protected/${id}/reject`,
		params: { token },
		method: "post",
		data: orderRejection,
		headers: { "Content-Type": "application/json" },
	},
	meta: {
		onSuccess: (response, requestAction, store) => {
			if (onSuccess) onSuccess();
			return store.dispatch(fetchOrderProtected(id, token));
		},
		onError: (error, requestAction, store) => {
			if ([401, 403].includes(error.response.status)) {
				window.location.replace("/login");
			}
			if (onError) onError();
			return store.dispatch(fetchOrderProtected(id, token));
		},
	},
});

export const createOrderApprovalConfirmation = (id, token, approvalData, onSuccess, onError) => ({
	type: CREATE_ORDER_APPROVAL_REJECTION,
	request: {
		url: `${URL_BASE_ORDER}/protected/${id}/approve`,
		params: { token },
		method: "put",
		data: approvalData,
		headers: { "Content-Type": "application/json" },
	},
	meta: {
		onSuccess: (response, requestAction, store) => {
			if (onSuccess) onSuccess();
			return store.dispatch(fetchOrderProtected(id, token));
		},
		onError: (error, requestAction, store) => {
			if ([401, 403].includes(error.response.status)) {
				window.location.replace("/login");
			}
			if (onError) onError();
			return store.dispatch(fetchOrderProtected(id, token));
		},
	},
});
