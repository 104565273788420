import React from "react";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import { Tab, Tabs } from "@material-ui/core";
import useStyles from "./style";
import SubPageContainer from "../../components/SubPageContainer";
import SubPagePanel from "../../components/SubPagePanel";
// import { HotfolderConfig } from "./hotfolderConfig";

import { EmailConfig } from "./emailConfig";

export const ImportConfig = () => {
	const classes = useStyles();

	const { t } = useTranslation();

	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<Grid item xs={12} sm={12}>
			<SubPageContainer>
				<Grid container xs={12} justify="center" alignContent="center" alignItems="center">
					<Grid item xs={10}>
						<Tabs
							value={value}
							onChange={handleChange}
							aria-label="Assets"
							className={classes.tabs}
							scrollButtons="auto"
							variant="scrollable"
						>
							<Tab
								label={t("admin.email-config")}
								classes={{ root: classes.tab, selected: classes.selectedTab }}
							/>
							{/* <Tab
								label={t("admin.ftp-config")}
								classes={{ root: classes.tab, selected: classes.selectedTab }}
							/> */}
						</Tabs>
					</Grid>
				</Grid>
				<SubPagePanel value={value} index={0}>
					<Grid container xs={12} justify="center" alignContent="center" alignItems="center">
						<Grid item xs={10}>
							<EmailConfig />
						</Grid>
					</Grid>
				</SubPagePanel>

				{/* <SubPagePanel value={value} index={1}>
					<Grid container xs={12} justify="center" alignContent="center" alignItems="center">
						<Grid item xs={10}>
							<HotfolderConfig />
						</Grid>
					</Grid>
				</SubPagePanel> */}
			</SubPageContainer>
		</Grid>
	);
};

export default ImportConfig;
