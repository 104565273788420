export default {
	orders: "Orders",
	clients: "Clients",
	client: "Client",
	"client.delete.msg": "Are you sure you want to delete this client?",
	"client.delete.success": "Client deleted successfully",
	"client.delete.error": "Error occured while deleting client",
	clientCount: "Total number of clients",
	searchClient: "Find a client",
	countOrders: "Total number of orders",
	"Total amount of orders": "Total amount of orders",
	searchOrders: "Find an order...",
	"Add an order": "Add Order",
	"order.duplicated": "Duplicated",
	"extraction.label.not_order": "Other than order",
	"Order Details": "Order Details",
	"order Number": "Order number",
	"Order overview": "Order overview",
	"order.detail.reject.success": "Order was rejected  successfully",
	"order.detail.reject.failed": "Order wasn't rejected",
	"order.status.underProcess": "Being processed",
	"order.status.processed": "Processed",
	clientEmptyMessageError: "The client of the order is not filled",
	"Orders to verify": "To verify",
	"Orders to be checked": "To control",
	"order.exported": "Exported orders",
	"order.status.exported": "Exported",
	"Add your Order": "Add your order or ",
	"order.unexported": "Non-exported orders",
	"upload.orders": "Upload Orders",
	"My Orders": "My Orders",
	"Total of order expenses": "Orders Total",
	"order.total_invoice": "Total orders",
	"New clients": "New clients",
	"order.status.Processed": "Processing",
	"Total VAT amount": "Total VAT amount",
	"order.status.UnderProcess": "Being processed",
	"order.status.integration": "Integration in progress",
	ClientCannotBeDeleted: "Client cannot be deleted",
	"order.status.toverify": "To Verify",
	"order.status.tocontrol": "To Control",
	"order.status.topay": "To Pay",
	"order.status.payed": "Payed",
	"order.status.rejected": "Rejected",
	"order.status.canceled": "Cancelled",
	"order.status.exception": "Exception",
	duplicated_order: "Duplicated order",
	"order.selected.title0": "All orders are selected",
	"order.selected.title1": "The ",
	"order.selected.title2": " orders on this page are all selected.",
	"order.selected.title3": "Select all orders",
	OrdersNotCreatedMessage: "The following orders cannot be created :",
	"order.total_amoutn_to_verify": "Total amount of orders to verify",
	"client.add.button": "Add a client",
	"client.import.button": "Import clients",
	"clients.export.excel": "Export clients list",
	"client.add2.button": "Add a client",
	"client.add.step1": "General Informations",
	"client.add.step2": "Primary Contact",
	"client.add.step3": "Financial Informations",
	"client.add.next": "Next",
	"client.add.previous": "Previous",
	"client.add.finish": "Finish",
	"client.add.confirm": "Are you sure you want to save the changes?",
	"clients.empty.field.name.row.excel": "There is an line with empty name !!",
	"client.name.exist.already": "The customer is not unique",
	"clients.names.exist.already": "The clients {{clients}} already exist on the system! ",
	"clients.error.sample": "The file sent does not comply with the exemplary file.",
	"clients.add.multiple": "Add clients or ",
	"upload.client": "Upload clients",
	"upload.client.instructions1": "Fill the excel sheet with the clients' information",
	"upload.client.instructions2":
		"Fill the column “Logo” with the logo file name. (Example: logofilename.jpg)",
	"upload.client.instructions3": "Make sure all the fields are of type TEXT",
	"upload.client.instructions4":
		"Place the filled excel sheet and the clients' logos in the same folder",
	"upload.client.instructions5": "Compress that folder : ",
	"upload.client.instructions51":
		'Windows: Right Click on the Folder then choose  "Send to"  then click on "Compressed Folder"',
	"upload.client.instructions52":
		"MAC: Control-click it or tap it using two fingers, then choose Compress from the shortcut menu.",
	"upload.client.instructions6":
		"Upload the compressed folder to the portal or drag and drop it to the dedicated zone",
	"upload.client.instructions7": "Click on Upload clients",
	"add.client.name": "Client's Name",
	"client.created": "Client created successfully!",
	"client.updated": "Client updated successfully!",
	"client.error": "Error occurred while saving client",
	"client.delete.confirm": "Are you sure you want to delete the client?",
	"client.modify": " Modify client",
	"order.detail.company_siret": "Company id",
	orderRejectMessage: "Are you sure you want to reject this order?",
	orderControlMessage: "Are you sure you want to put this order in control?",
	"order.upload": "IMPORT ORDERS",
	"order.detail.message.rectify": "Are you sur you want to save these changes?",
	"order.file.not.created.message": "Error when creating the file",
	order_uploaded_success:
		"The order has been added successfully.It will appear in the list if you are the issuer of this order.",
};
