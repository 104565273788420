import React from "react";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import { Divider, Typography } from "@material-ui/core";
import get from "lodash/get";

export const DetailsLegalInformation = ({ supplier }) => {
	const { t } = useTranslation();
	return (
		<Grid container xs={12} spacing={4}>
			<Grid container item xs={12} spacing={4} alignItems="flex-end">
				<Grid item xs={4} spacing={4}>
					<Typography variant="subtitle2" color="primary">
						{t("Company Siret")}
						<br />
						<Typography variant="body2" align="left">
							{get(supplier, "companySiret")}

							<Divider />
						</Typography>
					</Typography>
				</Grid>
				<Grid item xs={4} spacing={4}>
					<Typography variant="subtitle2" color="primary">
						{t("Company Siren")}
						<br />
						<Typography variant="body2" align="left">
							{get(supplier, "companySiren")}

							<Divider />
						</Typography>
					</Typography>
				</Grid>

				<Grid item xs={4} spacing={4}>
					<Typography variant="subtitle2" color="primary">
						{t("codeNaf")}
						<br />
						<Typography variant="body2" align="left">
							{get(supplier, "codeNaf")}

							<Divider />
						</Typography>
					</Typography>
				</Grid>
			</Grid>
			<Grid container item xs={12} spacing={4} alignItems="flex-end">
				<Grid item xs={4} spacing={4}>
					<Typography variant="subtitle2" color="primary">
						{t("vatNumber")}
						<br />
						<Typography variant="body2" align="left">
							{get(supplier, "vatNumber")}

							<Divider />
						</Typography>
					</Typography>
				</Grid>
				<Grid item xs={4} spacing={4}>
					<Typography variant="subtitle2" color="primary">
						{t("socialCapital")}
						<br />
						<Typography variant="body2" align="left">
							{get(supplier, "capital")}

							<Divider />
						</Typography>
					</Typography>
				</Grid>
				<Grid item xs={4} spacing={4}>
					<Typography variant="subtitle2" color="primary">
						{t("email")}
						<br />
						<Typography variant="body2" align="left">
							{get(supplier, "email")}

							<Divider />
						</Typography>
					</Typography>
				</Grid>
			</Grid>
			<Grid container item xs={12} spacing={4} alignItems="flex-end">
				<Grid item xs={4} spacing={4}>
					<Typography variant="subtitle2" color="primary">
						{t("Street")}
						<br />
						<Typography variant="body2" align="left">
							{get(supplier, "street")}

							<Divider />
						</Typography>
					</Typography>
				</Grid>
				<Grid item xs={4} spacing={4}>
					<Typography variant="subtitle2" color="primary">
						{t("Zip Code")}
						<br />
						<Typography variant="body2" align="left">
							{get(supplier, "zipCode")}

							<Divider />
						</Typography>
					</Typography>
				</Grid>
				<Grid item xs={4} spacing={4}>
					<Typography variant="subtitle2" color="primary">
						{t("City")}
						<br />
						<Typography variant="body2" align="left">
							{get(supplier, "city")}

							<Divider />
						</Typography>
					</Typography>
				</Grid>
			</Grid>
			<Grid container item xs={12} spacing={4} alignItems="flex-end">
				<Grid item xs={4} spacing={4}>
					<Typography variant="subtitle2" color="primary">
						{t("Phone")}
						<br />
						<Typography variant="body2" align="left">
							{get(supplier, "phone")}

							<Divider />
						</Typography>
					</Typography>
				</Grid>
				<Grid item xs={4} spacing={4}>
					<Typography variant="subtitle2" color="primary">
						{t("Fax")}
						<br />
						<Typography variant="body2" align="left">
							{t(get(supplier, "fax"))}

							<Divider />
						</Typography>
					</Typography>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default DetailsLegalInformation;
