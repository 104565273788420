import React, { useState } from "react";
import { TextField, Chip } from "@material-ui/core";
import { REGEX } from "utils";

const MultiEmailInput = ({
	id,
	name,
	inputRef,
	label,
	required,
	fullWidth,
	limit,
	setEmails,
	emails,
}) => {
	const [value, setValue] = useState("");

	const addEmail = (input) => {
		if (
			new RegExp(REGEX.EMAIL).test(input) &&
			emails.findIndex((email) => input === email) === -1
		) {
			setEmails([...emails, input]);
		}
	};

	const handleOnBlur = (e) => {
		addEmail(e.target.value);
		setValue("");
	};

	const handleDelete = (e) => {
		const index = emails.indexOf(e.target.value);
		const tempEmails = [...emails];
		tempEmails.splice(index, 1);
		setEmails(tempEmails);
	};

	return (
		<>
			<TextField
				value={value}
				onChange={(e) => setValue(e.target.value)}
				onBlur={handleOnBlur}
				inputRef={inputRef}
				name={name}
				id={id}
				label={label}
				required={emails.length === 0 ? required : false}
				fullWidth={fullWidth}
				disabled={limit ? emails.length >= limit : false}
			/>
			{emails.map((email) => (
				<Chip label={email} onDelete={handleDelete} />
			))}
		</>
	);
};

export default MultiEmailInput;
