import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import CardActions from "@material-ui/core/CardActions";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import get from "lodash/get";
import { useTranslation } from "react-i18next";
import { toast } from "utils";
import { getApi } from "redux/store";
import UserProfile from "../../App/user-profile.png";
import useStyles from "./style";
import InfoItem from "./components/InfoItem";
import MultiLines from "./components/MultiLines";
import MultiLinesWithTitle from "./components/MultiLinesWithTitle";
import { deleteSupplier } from "../../redux/actions/suppliers/index";
import ConfirmDialog from "../ConfirmDialog";

function TableCae({
	index,
	onClickRow,
	row,
	infoItems,
	onDeleteItem,
	withActions,
	withLogo,
	admin,
	refreshSuppliers,
	isSupplierTraining,
	isSupplier,
	isClient,
	isClientTraining,
	supplierTrainingItem,
	showActionBtns,
	...props
}) {
	const classes = useStyles();
	const history = useHistory();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const getImageURL = (path, module) => `${getApi()}/${module}${path}`;
	const [confirmObject, setConfirmObject] = React.useState({
		message: "",
		openConfirm: false,
		cancel: false,
		isLoading: false,
	});

	const onSuccessDelete = () => {
		toast.success(t("supplier.delete.success"));
		refreshSuppliers();
	};

	const onErrorDelete = (errorMsg) => {
		toast.error(t(errorMsg));
	};

	const confirmDeleteSupplier = () => {
		setConfirmObject({ message: t("supplier.delete.confirm"), openConfirm: true });
	};

	const handleDeleteSupplier = () => {
		dispatch(deleteSupplier(row.id)).then((response) => {
			if (response.status === 200) {
				onSuccessDelete();
			} else {
				onErrorDelete(response.error.response.data.detail);
			}
		});
	};

	const onCancel = () => {
		setConfirmObject({ message: "", openConfirm: false });
	};

	const onConfirm = () => {
		setConfirmObject({ message: "", openConfirm: false, isLoading: true });
		handleDeleteSupplier();
	};

	const updateSupplier = () => {
		history.push(`/supplier/${row?.id}`);
	};

	if (!onClickRow) {
		onClickRow = () => undefined;
	}

	return (
		<Card key={index} className={classes.root} elevation={1}>
			<CardContent className={classes.content}>
				<ButtonBase focusRipple onClick={() => onClickRow(row.id)}>
					<Grid container spacing={6}>
						{withLogo && (
							<Grid item xs={2} align="center">
								<Grid container item direction="column">
									<Grid item className={classes.avatar}>
										<Avatar
											alt="Supplier logo"
											src={
												get(row, "logo")
													? getImageURL(row.logo, isSupplier ? "invoice" : "order")
													: UserProfile
											}
										/>
									</Grid>
									<Grid item>
										<Typography variant="subtitle2" align="center">
											{row.code}
										</Typography>
									</Grid>
								</Grid>
							</Grid>
						)}
						<Grid item xs align="left">
							<Grid container>
								{infoItems &&
									infoItems.map((item) => (
										<>
											{item.multiLines ? (
												<>
													{item.withTitle ? (
														<Grid item xs>
															<MultiLinesWithTitle rows={item.rows} />
														</Grid>
													) : (
														<Grid item xs>
															<MultiLines rows={item.rows} />
														</Grid>
													)}
												</>
											) : (
												<Grid item xs>
													<InfoItem label={item.label} value={item.value} />
												</Grid>
											)}
										</>
									))}
							</Grid>
						</Grid>
						{supplierTrainingItem}
					</Grid>
				</ButtonBase>
			</CardContent>
			{withActions && (
				<CardActions>
					<Grid container xs={12} justify="flex-end">
						{admin ? (
							props.children
						) : (
							<>
								<>
									<Grid item>
										<IconButton aria-label="download">
											<EditIcon />
										</IconButton>
									</Grid>
									{showActionBtns && (
										<Grid item>
											<IconButton aria-label="delete" onClick={() => onDeleteItem(row.id)}>
												<DeleteIcon />
											</IconButton>
										</Grid>
									)}
									<Grid item>
										<Checkbox
											//   value={parseInt(row.id, 10)}
											//   checked={selected.includes(parseInt(row.id, 10))}
											onChange={(e) => {
												const list = [];
												if (e.target.checked) {
													list.push(parseInt(e.target.value, 10));
												} else {
													list.splice(list.indexOf(parseInt(e.target.value, 10)), 1);
												}
												// setSelected(list);
											}}
										/>
									</Grid>
								</>
							</>
						)}
					</Grid>
				</CardActions>
			)}
			{!isSupplierTraining && !isClientTraining && (
				<>
					<CardActions>
						{!props.isOrderList && (
							<Grid container justify="flex-end">
								<Tooltip placement="top" title={t("tootlip.action.edit")}>
									<IconButton aria-label="editSupplier" onClick={() => updateSupplier(row?.id)}>
										<EditIcon />
									</IconButton>
								</Tooltip>
							</Grid>
						)}
						{showActionBtns && (
							<Grid container justify="flex-end">
								<Tooltip placement="top" title={t("tooltip.action.delete")}>
									<IconButton
										aria-label="deleteSupplier"
										onClick={() => confirmDeleteSupplier(row?.id)}
									>
										<DeleteIcon />
									</IconButton>
								</Tooltip>
							</Grid>
						)}
					</CardActions>
					<ConfirmDialog
						message={confirmObject.message}
						open={confirmObject.openConfirm}
						isLoading={confirmObject.isLoading}
						onConfirm={onConfirm}
						onCancel={onCancel}
					/>
				</>
			)}
		</Card>
	);
}

export default TableCae;
