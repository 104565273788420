import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Grid, TablePagination, Typography } from "@material-ui/core";
import get from "lodash/get";
import { Query } from "@redux-requests/react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import { currencyFormat, toast } from "utils";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { exportClientsExcel, fetchCountClients } from "redux/actions/orders";
import DropzoneArea from "components/DropZoneArea";
import roles from "redux/constants/roles";
import {
	fetchClients,
	createMultiClients,
	downloadImportClientSample,
} from "../../redux/actions/clients";
import TableCardItem from "./components/TableCardItem";
import useStyles from "./style";
import CircularLoader from "../../components/Loaders/CircularLoader";
import { initFilter, updateFilter } from "../../redux/clientFilterReducer";
import ConfirmationDialog from "./components/ConfirmationDialog";
import Filter from "./components/Filter";
import { FETCH_COUNT_CLIENTS, ORDER_TOTAL_AMOUNT } from "../../redux/constants/index";
import Instructions from "../Suppliers/Component/ImportInstructions";

const ClientList = () => {
	const { t } = useTranslation();
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const [selected, setSelected] = React.useState([]);
	const [open, setOpen] = React.useState(false);
	const [scroll, setScroll] = React.useState("paper");
	const [files, setFiles] = React.useState([]);
	const [openDeletePopup, setOpenDeletePopup] = React.useState(false);
	const [selectedId, setSelectedId] = React.useState(0);
	const filterClients = useSelector(({ filterClients: i }) => i);
	const idClients = "";
	const [expanded, setExpanded] = React.useState(false);
	const [exportLoading, setExportLoading] = React.useState({ export: false, example: false });

	const currentUser = useSelector(({ requests }) =>
		get(requests, "queries.FETCH_CURRENT_USER.data")
	);
	const clients = useSelector(({ requests }) => get(requests, "queries.FETCH_CLIENTS.data"));

	const showActionBtns = currentUser && get(currentUser, "roles", []).includes(roles.manager);

	const reloadClients = () => {
		dispatch(fetchClients(filterClients));
		dispatch(fetchCountClients());
	};

	const handleChangePage = (event, newPage) => {
		dispatch(updateFilter({ ...filterClients, pageNo: newPage }));
	};

	const onloadFiles = (f) => {
		setFiles(f);
	};

	const onDeleteFile = (file) => {
		files.splice(files.indexOf(file), 1);
		toast.info(t("fileRemovedMsg", { filename: file.name }), { autoClose: 3000 });
	};

	const handleChangeRowsPerPage = (event) => {
		dispatch(
			updateFilter({
				...filterClients,
				pageSize: +event.target.value,
				pageNo: 0,
			})
		);
	};

	const acceptedOrders = [".zip"];

	const onClickRow = (id) => {
		dispatch(initFilter());
		history.push(`/client/details/${id}`);
	};

	const onDeleteItem = (id) => {
		setOpenDeletePopup(true);
		setSelectedId(id);
	};

	const handleSelectedAll = (checked) => {
		setSelected(checked ? idClients : []);
	};

	const openInstructions = () => {
		setOpen(true);
		setScroll("paper");
	};

	useEffect(() => {
		reloadClients();
	}, [filterClients, dispatch]);

	const handleClose = () => {
		setOpen(false);
	};

	const descriptionElementRef = React.useRef(null);
	React.useEffect(() => {
		if (open) {
			const { current: descriptionElement } = descriptionElementRef;
			if (descriptionElement !== null) {
				descriptionElement.focus();
			}
		}
	}, [open]);

	const onclickUploadClients = () => {
		files.forEach((element) => {
			dispatch(createMultiClients(element)).then((res) => {
				const response = res.data;
				if (response.emptyLine) {
					toast.error(t("clients.empty.field.name.row.excel"));
					return;
				}
				if (response?.notConform) {
					toast.error(t("clients.error.sample"));
				} else if (response?.unsavedClients?.length !== 0) {
					toast.error(
						`${t("clients.names.exist.already", {
							clients: response.unsavedClients.join(", "),
						})}`
					);
				}
				reloadClients();
			});
		});
	};

	const exportClients = () => {
		setExportLoading({ ...exportLoading, export: true });
		dispatch(exportClientsExcel()).then(() => {
			setExportLoading({ ...exportLoading, export: false });
		});
	};

	const onClickDownloadClientsTemplate = () => {
		setExportLoading({ ...exportLoading, example: true });
		dispatch(downloadImportClientSample()).then((res) => {
			if (res.status === 200) openInstructions();
			setExportLoading({ ...exportLoading, example: false });
		});
	};

	const onDrop = () => {
		// toast.success(t("fileAddedMsg"), { autoClose: 3000 });
	};

	return (
		<>
			<Grid spacing={2} direction="column" xs={12} container className={classes.firstGrid}>
				{expanded && (
					<Grid container justify="flex-end">
						<IconButton aria-label="help" onClick={() => openInstructions()}>
							<HelpOutlineIcon />
						</IconButton>
					</Grid>
				)}
				<Grid
					spacing={2}
					container
					item
					xs={12}
					className={classes.headerZone}
					justify="center"
					alignItems="stretch"
				>
					<Grid item xs={6}>
						<DropzoneArea
							fileLimit={30}
							expanded={expanded}
							showFileNames
							useChipsForPreview
							dropzoneClass={classes.dropzone}
							dropzoneParagraphClass={classes.text}
							onChange={onloadFiles}
							onDelete={onDeleteFile}
							onDrop={onDrop}
							zoneMessage="clients.add.multiple"
							acceptedFiles={acceptedOrders}
						/>
					</Grid>
					<Grid container item xs={2}>
						{expanded && (
							<>
								<Button
									fullWidth
									variant="contained"
									color="primary"
									onClick={(e) => {
										onclickUploadClients(e);
										setExpanded(!expanded);
									}}
									className={classes.actionButton}
								>
									{t("upload.client")}
								</Button>
								<Button
									fullWidth
									variant="outlined"
									color="secondary"
									onClick={() => {
										// TO DO
										onClickDownloadClientsTemplate();
									}}
									className={classes.actionButton}
								>
									{exportLoading.example && <CircularLoader size={12} />}&nbsp;
									{t("downloqd.template")}
								</Button>
							</>
						)}
						<Dialog
							open={open}
							onClose={handleClose}
							scroll={scroll}
							aria-labelledby="scroll-dialog-title"
							aria-describedby="scroll-dialog-description"
						>
							<DialogTitle id="scroll-dialog-title">
								{t("upload.instructions")}
								<IconButton
									aria-label="close"
									className={classes.closeButton}
									onClick={handleClose}
								>
									<CloseIcon />
								</IconButton>
							</DialogTitle>

							<DialogContent dividers={scroll === "paper"}>
								<DialogContentText
									id="scroll-dialog-description"
									ref={descriptionElementRef}
									tabIndex={-1}
								>
									<Instructions isClient />
								</DialogContentText>
							</DialogContent>
						</Dialog>
					</Grid>
				</Grid>
				<Grid container item xs={12} direction="row-reverse">
					<Grid container item xs={2} justify="flex-end">
						{!expanded && showActionBtns && (
							<Button
								fullWidth
								variant="contained"
								color="secondary"
								m={2}
								style={{ marginBottom: "5px" }}
								onClick={() => {
									history.push("/addClient");
								}}
								className={classes.actionButton}
							>
								{t("client.add.button")}
							</Button>
						)}
						{!expanded && showActionBtns && (
							<Button
								fullWidth
								variant="contained"
								color="primary"
								style={{ marginBottom: "5px" }}
								onClick={() => {
									setExpanded(!expanded);
								}}
								className={classes.actionButton}
							>
								{t("client.import.button")}
							</Button>
						)}
						{!expanded && (
							<Button
								fullWidth
								variant="outlined"
								color="primary"
								className={classes.actionButton}
								onClick={() => exportClients()}
								disabled={exportLoading.export}
							>
								{exportLoading.export && <CircularLoader size={12} />}&nbsp;
								{t("clients.export.excel")}
							</Button>
						)}
					</Grid>
					<Grid container item direction="column" xs={10}>
						<Grid item>
							<Typography variant="h6" color="secondary">
								{t("Total amount of orders")} :
								<Query type={ORDER_TOTAL_AMOUNT}>
									{({ data }) => (data ? currencyFormat(data) : currencyFormat(0))}
								</Query>
								€
							</Typography>
						</Grid>
						<Typography variant="subtitle2" color="textSecondary">
							{t("clientCount")} :
							<Query type={FETCH_COUNT_CLIENTS}>{({ data }) => ` ${data}`}</Query>
						</Typography>
					</Grid>
				</Grid>
				<Grid item container>
					<Filter
						selected={selected}
						setSelected={setSelected}
						handleSelectedAll={handleSelectedAll}
					/>
				</Grid>
				<Grid
					item
					// todo this is a temp fix
					style={{
						minHeight: "1200px",
						margin: "30px 0px",
						paddingBottom: "50px",
					}}
				>
					{get(clients, "content") ? (
						clients.content.map((row, i) => {
							const infoItems = [
								{
									multiLines: true,
									rows: [
										{ variant: "body1", value: row.email, align: "left" },
										{
											variant: "body2",
											color: "secondary",
											value: row.phone,
											align: "left",
										},
										{
											variant: "subtitle2",
											color: "textSecondary",
											value: row.address,
											align: "left",
										},
									],
								},
								{ label: t("VAT Number"), value: row.vatNumber },
								{ label: t("Company Siret"), value: row.companySiret },
							];
							return (
								<TableCardItem
									index={i}
									t={t}
									row={row}
									contactDetails
									infoItems={infoItems}
									onClickRow={onClickRow}
									onDeleteItem={onDeleteItem}
									refreshClients={() => reloadClients()}
									withLogo
									showActionBtns={showActionBtns}
								/>
							);
						})
					) : (
						<CircularLoader />
					)}
					<TablePagination
						rowsPerPageOptions={[10, 25, 50]}
						component="div"
						count={get(clients, "totalElements")}
						rowsPerPage={filterClients.pageSize}
						page={filterClients.pageNo}
						onChangePage={handleChangePage}
						onChangeRowsPerPage={handleChangeRowsPerPage}
						labelRowsPerPage={t("pagination.rows.per.page")}
					/>
				</Grid>
			</Grid>
			<ConfirmationDialog
				open={openDeletePopup}
				setOpen={setOpenDeletePopup}
				id={selectedId}
				filterSuppliers={filterClients}
			/>
		</>
	);
};

export default ClientList;
