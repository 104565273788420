import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import InsertDriveFileRounded from "@material-ui/icons/InsertDriveFileOutlined";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ordersTotalAmountsOverDue } from "redux/actions/clients";
import DashboardCard from "../../../../components/DashboardCard/index";
import { currencyFormat } from "../../../../utils/index";
import { ordersTotalAmounts } from "../../../../redux/actions/orders/index";

function UnpaidOrder(props) {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(ordersTotalAmountsOverDue());
	}, []);
	const ordersTotalAmountsUnPaid = useSelector(
		({ requests }) =>
			requests.queries.ORDER_TOTAL_AMOUNT_UNPAID && requests.queries.ORDER_TOTAL_AMOUNT_UNPAID.data
	);

	const ordersTotalAmountsOverdue = useSelector(
		({ requests }) =>
			requests.queries.ORDER_TOTAL_OVERDUE_AMOUNT &&
			requests.queries.ORDER_TOTAL_OVERDUE_AMOUNT.data
	);

	useEffect(() => {
		dispatch(
			ordersTotalAmounts(
				[
					"INTEGRATION",
					"TO_VERIFY",
					"TO_CONTROL",
					"TO_PAY",
					"PAYED",
					"REJECTED",
					"CANCELED",
					"EXCEPTION",
					"TO_VALIDATE_EXTERNAL",
				],
				"_UNPAID"
			)
		);
	}, []);

	return (
		<DashboardCard
			title={t("order.unexported")}
			icon={InsertDriveFileRounded}
			{...props} // eslint-disable-line
		>
			<Grid container spacing={2} justify="center">
				<Grid item xs={6}>
					<Typography variant="h5" color="primary" gutterBottom align="center">
						{ordersTotalAmountsUnPaid && ordersTotalAmountsUnPaid !== ""
							? `${currencyFormat(ordersTotalAmountsUnPaid)} €`
							: `${currencyFormat(0)} €`}
					</Typography>
					<Typography variant="subtitle2" color="textSecondary" gutterBottom align="center">
						{t("Total amount of orders")}
					</Typography>
				</Grid>
				<Grid item xs={6} className="p-relative">
					<Divider orientation="vertical" absolute />
					<Typography variant="h5" color="error" gutterBottom align="center">
						{ordersTotalAmountsUnPaid && ordersTotalAmountsOverdue !== ""
							? `${currencyFormat(ordersTotalAmountsOverdue)} €`
							: `${currencyFormat(0)} €`}
					</Typography>
					<Typography variant="subtitle2" color="textSecondary" gutterBottom align="center">
						{t("Late")}
					</Typography>
				</Grid>
			</Grid>
		</DashboardCard>
	);
}

export default UnpaidOrder;
