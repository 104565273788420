import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";

import get from "lodash/get";
import { useDispatch, useSelector } from "react-redux";
import { isSupplierOnly } from "utils";
import InvoicesTable from "../../components/InvoicesToValidate";
import MyTasks from "./components/MyTasks";
import PaidInvoice from "./components/PaidInvoice";
import UnpaidInvoice from "./components/UnpaidInvoice";
import LastActions from "./components/LastActions";
import AddInvoice from "./components/AddInvoice";
import { fetchSettingsByModuleName } from "../../redux/actions/users";

import useStyles from "./style";

const formatSettings = (data) =>
	data.reduce((acc, { code, value }) => ({ ...acc, [code]: parseInt(value, 10) }), {});

const SettingsDisplay = ({ value, children }) => (value ? children : <></>);

function Home() {
	const classes = useStyles();
	const currentUser = useSelector(({ requests }) =>
		get(requests, "queries.FETCH_CURRENT_USER.data")
	);
	const dispatch = useDispatch();
	const [settings, setSettings] = React.useState({});

	useEffect(() => {
		if (currentUser && !isSupplierOnly(get(currentUser, "roles", [])))
			dispatch(fetchSettingsByModuleName("invoice")).then((res) => {
				if (get(res, "data")) {
					setSettings(formatSettings(res.data));
				}
			});
	}, [dispatch, currentUser]);

	const col1 = settings.INDICATOR_MY_TASKS;
	const col2 = +(settings.INDICATOR_INVOICES_EXPORTED + settings.INDICATOR_LOAD_INVOICES) > 0;
	const col3 = +(settings.INDICATOR_INVOICES_NOT_EXPORTED + settings.INDICATOR_LAST_ACTIONS) > 0;
	const col = 12 / (col1 + col2 + col3);

	return get(currentUser, "roles") &&
		(currentUser.roles.includes("invoice_user") || currentUser.roles.includes("manager")) ? (
		<>
			<Grid container alignItems="stretch" className={classes.firstGrid}>
				<SettingsDisplay value={settings.INDICATOR_MY_TASKS}>
					<Grid item xs={12} sm={col}>
						<MyTasks elevation={26} square />
					</Grid>
				</SettingsDisplay>
				<SettingsDisplay
					value={settings.INDICATOR_INVOICES_EXPORTED || settings.INDICATOR_LOAD_INVOICES}
				>
					<Grid container item xs={12} sm={col}>
						<SettingsDisplay value={settings.INDICATOR_INVOICES_EXPORTED}>
							<Grid item xs={12}>
								<PaidInvoice elevation={26} square />
							</Grid>
						</SettingsDisplay>
						<SettingsDisplay value={settings.INDICATOR_LOAD_INVOICES}>
							<Grid item xs={12}>
								<AddInvoice settings={settings} elevation={26} square />
							</Grid>
						</SettingsDisplay>
					</Grid>
				</SettingsDisplay>
				<Grid container item xs={12} sm={col}>
					<SettingsDisplay value={settings.INDICATOR_INVOICES_NOT_EXPORTED}>
						<Grid item xs={col === 12 ? 6 : 12}>
							<UnpaidInvoice elevation={26} square style={{ background: "#f8f9fa" }} />
						</Grid>
					</SettingsDisplay>
					<SettingsDisplay value={settings.INDICATOR_LAST_ACTIONS}>
						<Grid item xs={col === 12 ? 6 : 12}>
							<LastActions elevation={26} square />
						</Grid>
					</SettingsDisplay>
				</Grid>
			</Grid>
			<Grid container spacing={4}>
				<Grid container spacing={4} item xs={12}>
					<Grid item xs={12} sm={12}>
						<SettingsDisplay value={settings.INDICATOR_LAST_5_INVOICES}>
							<InvoicesTable settings={settings} isDashboard />
						</SettingsDisplay>
					</Grid>
				</Grid>
			</Grid>
		</>
	) : (
		<></>
	);
}

export default Home;
