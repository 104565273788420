/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { connect } from "react-redux";
import "fabric-webpack";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import CardActionArea from "@material-ui/core/CardActionArea";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import isEmpty from "lodash/isEmpty";
import { fetchOcrData, fetchOcrImage } from "../../../../../redux/actions/orders";
import { updateFormulaire, initFormulaire } from "../../../../../redux/formulaireReducer";
import { adaptData, highlightColor, rectColor, reducedData } from "../utils";
import { getApi } from "redux/store";

const { fabric } = window;
class DesignCanvas extends Component {
	constructor(props) {
		super(props);
		this.state = {
			canvas: null,
			dimensions: {},
			updated: false,
			imageLoading: true,
			emptyPagesData: false,
			disablePan: true,
			LoadPreviewFile: false,
			imgHeight: null,
			imgWidth: null,
			currentImageId: null,
		};
	}

	static propTypes = {
		width: PropTypes.number.isRequired,
		height: PropTypes.number.isRequired,
	};

	static defaultProps = {
		width: 720,
		height: 867,
	};

	componentWillMount() {
		const { orderId } = this.props;
		this.props.fetchOcrData(orderId, (data) => {
			if (isEmpty(data.pagesDataDto)) {
				this.setState({ emptyPagesData: true });
			} else {
				this.initItem(data.orderDto);
			}
		});
	}

	componentDidMount() {
		document.addEventListener("keydown", this.handleKeyDown);
		document.addEventListener("keyup", this.handleKeyUp);
	}

	componentWillUnmount() {
		document.removeEventListener("keyup", this.handleKeyDown);
		document.removeEventListener("keyup", this.handleKeyUp);
		this.props.initFormulaire();
		window.location.reload();
	}

	initItem = (value) => {
		const formulaire = JSON.parse(JSON.stringify(this.props.formulaire));
		formulaire.orderDto = value;
		this.props.updateFormulaire(formulaire);
	};

	componentWillReceiveProps(nextProps) {
		const that = this;
		if (nextProps.formulaire) {
			this.setState({ formulaire: nextProps.formulaire });
		}
		if (nextProps.ocrData) {
			const pagesDataDto = nextProps.ocrData.pagesDataDto[0];
			if (pagesDataDto && !this.state.updated) {
				this.setState({ currentImageId: pagesDataDto.pageId });
				const img = new Image();
				img.onload = function () {
					that.setState({ imageLoading: false });
					that.createCanvas(pagesDataDto, img);
				};
				img.src = `${getApi()}/order/image/${pagesDataDto.pageImageId}`;

				this.setState({ updated: true });
			}
		}
		if (nextProps.location) {
			this.findItems(nextProps.location);
		}

		if (nextProps.previewFile) {
			this.setState({ LoadPreviewFile: true });
		}
	}

	updateCanvas = (page, withLocation) => {
		const that = this;
		const img = new Image();
		img.onload = function () {
			that.createCanvas(page, img, withLocation);
		};
		img.src = `${getApi()}/order/image/${page.pageImageId}`;

		this.setState({ updated: true });
	};

	findSelectedPage = (location) => {
		const { ocrData } = this.props;
		if (location && location.pageId) {
			const selectedPage = ocrData.pagesDataDto.find((page) => (page.pageId = location.pageId));
			this.setState({ currentImageId: selectedPage.pageId }, () => {
				this.updateCanvas(selectedPage, location);
			});
		}
	};

	updateItem = (value, item) => {
		const formulaire = JSON.parse(JSON.stringify(this.state.formulaire));
		const _item = item || formulaire.selectedItem;
		if (!_item) {
		} else if (_item === "orderDto") {
			formulaire[_item] = value;
		} else if (_item.startsWith("order.withLabel")) {
			const selectedOrder = _item.split(".")[2];
			formulaire.orderDto.order[selectedOrder].label.value = value.value;
			formulaire.orderDto.order[selectedOrder].label.location = value.location;
		} else if (_item.startsWith("order")) {
			const selectedOrder = _item.split(".")[1];
			formulaire.orderDto.order[selectedOrder].value = value.value;
			formulaire.orderDto.order[selectedOrder].location = value.location;
		} else if (_item.startsWith("withLabel")) {
			const selectedOrder = _item.split(".")[1];
			formulaire.orderDto[selectedOrder].label.value = value.value;
			formulaire.orderDto[selectedOrder].label.location = value.location;
		} else if (_item.startsWith("additionalAmountsht")) {
			const index = _item.split(".")[1];
			formulaire.orderDto.additionalAmountsHT[index].amount.value = value.value;
			formulaire.orderDto.additionalAmountsHT[index].amount.location = value.location;
		} else if (_item.startsWith("additionalAmountstva")) {
			const index = _item.split(".")[1];
			formulaire.orderDto.additionalAmountsTVA[index].amount.value = value.value;
			formulaire.orderDto.additionalAmountsTVA[index].amount.location = value.location;
		} else if (_item.startsWith("additionalAmountscharge")) {
			const index = _item.split(".")[1];
			formulaire.orderDto.additionalAmountsCharge[index].amount.value = value.value;
			formulaire.orderDto.additionalAmountsCharge[index].amount.location = value.location;
		} else if (_item.startsWith("table")) {
			const index = _item.split(".")[1];
			const selectedOrder = _item.split(".")[2];
			formulaire.orderDto.billingLinesDtos[index][selectedOrder].value = value.value;
			formulaire.orderDto.billingLinesDtos[index][selectedOrder].location = value.location;
			// temp work
		} else if (_item === "vatNumber") {
			formulaire.orderDto.client.vatNumber.value = value.value;
			formulaire.orderDto.client.vatNumber.location = value.location;
		} else if (_item === "companySiret") {
			formulaire.orderDto.client.companySiret.value = value.value;
			formulaire.orderDto.client.companySiret.location = value.location;
		} else {
			formulaire.orderDto[_item].value = value.value;
			formulaire.orderDto[_item].location = value.location;
		}
		Object.preventExtensions(formulaire);
		this.props.updateFormulaire(formulaire);
	};

	createCanvas = (pagesDataDto, _image, withLocation) => {
		const oldCanvas = this.state.canvas;
		const canvas = oldCanvas || new fabric.Canvas(this.c);
		const texts = new fabric.Canvas();
		const that = this;
		fabric.Image.fromURL(_image.src, (img) => {
			const initZoom = this.props.width / img.width;
			this.setState({
				imgHeight: img.height,
				imgWidth: img.width,
				initZoom,
			});
			img.hasControls = false;
			img.lockMovementX = true;
			img.lockMovementY = true;
			img.lockRotation = true;
			img.lockScalingY = false;
			img.lockScalingX = false;
			img.lockSkewingX = true;
			img.lockSkewingY = true;
			img.hasRotatingPoint = false;
			img.hoverCursor = "auto";
			canvas.setBackgroundImage(img);
			canvas.setWidth(this.props.width);
			canvas.setHeight(img.height * initZoom);
			canvas.setZoom(initZoom);
			const _data = reducedData(1, 1, pagesDataDto);
			texts.loadFromJSON(JSON.stringify(adaptData(_data)), () => {
				texts.getObjects().map((o) => {
					o.hasControls = false;
					o.lockMovementX = true;
					o.lockMovementY = true;
					o.lockRotation = true;
					o.lockScalingY = true;
					o.lockScalingX = true;
					o.lockSkewingX = true;
					o.lockSkewingY = true;
					o.hasRotatingPoint = false;
					o.setControlsVisibility = {
						bl: false,
						br: false,
						mb: false,
						ml: false,
						mr: false,
						mt: false,
					};
				});
				texts.renderAll();
			});
			canvas.remove(...canvas.getObjects());
			texts.getObjects().map((o) => {
				canvas.add(o);
			});
			if (withLocation) {
				canvas.getObjects().map((o) => {
					if (
						o.text.location.top >= withLocation.top &&
						o.text.location.bottom <= withLocation.bottom &&
						o.text.location.left >= withLocation.left &&
						o.text.location.right <= withLocation.right &&
						o.text.location.pageId === withLocation.pageId &&
						// for incorect location
						withLocation.top !== 0 &&
						withLocation.left !== 0
					) {
						o.fill = highlightColor;
					} else {
						o.fill = rectColor;
					}
				});
				canvas.renderAll();
			}
		});

		canvas.on("mouse:over", (options) => {
			if (options.target && !(options.target.selectable === false)) {
				options.target.set("fill", highlightColor);
				canvas.renderAll();
			}
		});

		canvas.on("mouse:out", (options) => {
			options.target && !options.target.active && options.target.set("fill", rectColor);
			canvas.renderAll();
		});

		canvas.on("object:selected", (options) => {
			const objs = get(options, "target._objects", []).filter((e) => e.type === "rect");
			if (objs.length) {
				const item = {
					value: "",
					location: {},
				};
				options.target._objects.map((e) => {
					e.set("fill", highlightColor);
					item.value += ` ${e.text.value}`;
					item.location.top =
						item.location.top <= e.text.location.top ? item.location.top : e.text.location.top;
					item.location.left =
						item.location.left <= e.text.location.left ? item.location.left : e.text.location.left;
					item.location.right =
						item.location.right >= e.text.location.right
							? item.location.right
							: e.text.location.right;
					item.location.bottom =
						item.location.bottom >= e.text.location.bottom
							? item.location.bottom
							: e.text.location.bottom;
					item.location.pageId = e.text.location.pageId;
				});
				item.value.trim();
				that.updateItem(item);
			} else {
				options.target.set("fill", highlightColor);
				that.updateItem(options.target.text);
			}
		});

		canvas.on("selection:cleared", (options) => {
			canvas.getObjects().map((o) => {
				o.fill = rectColor;
			});
			canvas.renderAll();
			that.setState({ items: null });
		});
		this.setState({ canvas });
		canvas.renderAll();
	};

	findElementsInCanvas = (location, canvas) => {
		const { currentImageId } = this.state;
		if (location.pageId !== currentImageId) {
			this.findSelectedPage(location);
		} else {
			canvas.getObjects().map((o) => {
				if (
					o.text.location.top >= location.top &&
					o.text.location.bottom <= location.bottom &&
					o.text.location.left >= location.left &&
					o.text.location.right <= location.right &&
					o.text.location.pageId === location.pageId &&
					// for incorect location
					location.top !== 0 &&
					location.left !== 0
				) {
					o.fill = highlightColor;
				} else {
					o.fill = rectColor;
				}
			});
			canvas.renderAll();
		}
	};

	findItems = (location) => {
		const { canvas } = this.state;
		this.findElementsInCanvas(location, canvas);
	};

	handleKeyDown = (e) => {
		const { disablePan } = this.state;
		if (disablePan && e.key === "Alt") {
			this.setState({ disablePan: false });
		}
	};

	handleKeyUp = (e) => {
		if (e.key === "Alt") {
			this.setState({ disablePan: true });
		}
	};

	zoomIn = () => {
		const { canvas, imgHeight, imgWidth } = this.state;
		const zoom = canvas.getZoom() + 0.1;
		canvas.setHeight(imgHeight * zoom);
		canvas.setWidth(imgWidth * zoom);
		canvas.setZoom(zoom);
		this.setState({ canvas });
	};

	zoomOut = () => {
		const { canvas, imgHeight, imgWidth } = this.state;
		const zoom = canvas.getZoom() - 0.1 > 0 ? canvas.getZoom() - 0.1 : canvas.getZoom();
		canvas.setHeight(imgHeight * zoom);
		canvas.setWidth(imgWidth * zoom);
		canvas.setZoom(zoom);
		this.setState({ canvas });
	};

	resetZoom = () => {
		const { initZoom, canvas } = this.state;
		const { width, height } = this.props;
		canvas.setHeight(height);
		canvas.setWidth(width);
		canvas.setZoom(initZoom);
		this.setState({ canvas });
	};

	render() {
		const { width, height, ocrData, previewFile } = this.props;
		const { imageLoading, emptyPagesData, disablePan, LoadPreviewFile } = this.state;
		return (
			<>
				{imageLoading && !emptyPagesData && LoadPreviewFile ? (
					<div
						style={{
							height: 800,
							width: 800,
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<CircularProgress />
					</div>
				) : (
					<>
						{emptyPagesData ? (
							<iframe
								src={previewFile}
								type="application/pdf"
								width="100%"
								height="776px"
								title="order"
							/>
						) : (
							<Grid container xs={12} style={{ marginTop: "-45px" }}>
								<Grid item xs={10}>
									<div className="tools">
										<IconButton onClick={this.zoomIn}>
											<ZoomInIcon />
										</IconButton>
										<IconButton onClick={this.zoomOut}>
											<ZoomOutIcon />
										</IconButton>
										<IconButton onClick={this.resetZoom}>
											<RotateLeftIcon />
										</IconButton>
									</div>
									<div style={{ width, height, overflow: "auto" }}>
										<canvas
											ref={(c) => (this.c = c)}
											width={width}
											height={height}
											style={{ border: "2px solid black" }}
										/>
									</div>
								</Grid>
								<Grid
									item
									xs={2}
									style={{
										marginTop: "45px",
										maxHeight: "776px",
										overflow: "auto",
									}}
								>
									{ocrData &&
										ocrData.pagesDataDto.map((page, index) => (
											<CardActionArea
												style={{ marginBottom: "5px" }}
												onClick={() => {
													this.setState({ currentImageId: page.pageId });
													this.updateCanvas(page);
												}}
											>
												<CardMedia
													image={`${getApi()}/order/image/${page.pageImageId}`}
													title={`ocr page ${index}`}
													style={{ height: "170px", border: "2px solid black" }}
												/>
												<CardContent>
													<Typography align="center" variant="subtitle2">
														page: {index + 1} / {ocrData.pagesDataDto.length}
													</Typography>
												</CardContent>
											</CardActionArea>
										))}
								</Grid>
							</Grid>
						)}
					</>
				)}
			</>
		);
	}
}

const mapStateToProps = (state) => {
	const { formulaire } = state;
	const { requests } = state;
	return {
		formulaire,
		selectedItem: formulaire.selectedItem,
		ocrData: get(requests, "queries.FETCH_OCR_DATA.data"),
		ocrImage: get(requests, "queries.FETCH_OCR_IMAGE.data"),
	};
};

const mapDispatchToProps = {
	initFormulaire,
	fetchOcrData,
	fetchOcrImage,
	updateFormulaire,
};
export default connect(mapStateToProps, mapDispatchToProps)(DesignCanvas);
