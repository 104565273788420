import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	firstGrid: {
		paddingTop: "1%",
		width: "calc(100% + 48px)",
	},
	root: {
		backgroundColor: theme.palette.background.paper,
	},
	input: {
		Width: "224px",
	},
	space: {
		marginTop: "10px",
	},
	tab: {
		fontWeight: "300",
		height: "20px",
	},
	supplierDetails: {
		backgroundColor: theme.palette.background.paper,
	},
	supplierItem: {
		margin: "25px 0",
	},

	large: {
		width: theme.spacing(15),
		height: theme.spacing(15),
	},

	customBadge: {
		backgroundColor: theme.palette.background.paper,
		height: "24px",
	},
	invoicesTable: {
		marginTop: "25px",
	},
	rootContainer: {
		padding: theme.spacing(2, 0),
	},
	appBar: { backgroundColor: "transparent", boxShadow: "none" },
	invoicesDetails: {
		marginTop: theme.spacing(4),
	},
	selectedTab: {
		color: "#0da8ad",
	},
	fullwidth: {
		width: "100%",
	},
	expand: {
		transform: "rotate(0deg)",
		marginLeft: "10",
		float: "right",
		height: "100%",
		padding: "20px",
		transition: theme.transitions.create("transform", {
			duration: theme.transitions.duration.shortest,
		}),
	},
	dropzone: {
		background: "transparent",
		maxWidth: "100%",
		minHeight: "80%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
		"& .MuiDropzoneArea-icon": {
			color: theme.palette.primary.main,
			opacity: 0.8,
		},
	},
	actionButton: {
		margin: "2px",
	},
	closeButton: {
		position: "absolute",
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
}));
