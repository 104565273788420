import React from "react";
import { useTranslation } from "react-i18next";
import {
	Grid,
	Button,
	Typography,
	FormControl,
	InputLabel,
	Input,
	InputAdornment,
	IconButton,
	NativeSelect,
} from "@material-ui/core";
import AttachmentIcon from "@material-ui/icons/Attachment";
import { useForm } from "react-hook-form";
import { DropzoneArea } from "material-ui-dropzone";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "utils";
import { useParams } from "react-router-dom";
import { createOrderAttachment, fetchOrderAttachments } from "redux/actions/orders";
import { EXTENTIONS_ATTACHMENTS } from "utils/constants";
import get from "lodash/get";
import clsx from "clsx";
import useStyles from "./style";

const AttachmentForm = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { idOrder } = useParams();

	// eslint-disable-next-line no-unused-vars
	const { errors, handleSubmit, register, setError, clearErrors } = useForm();
	const [docType, setDocType] = React.useState("");
	const [showForm, setShowForm] = React.useState(false);
	const [file, setFile] = React.useState(null);
	const [filename, setFilename] = React.useState(null);

	const docTypes = useSelector(
		({ requests }) =>
			requests.queries.FETCH_ORDER_ATTACHMENTS_DOC_TYPES &&
			requests.queries.FETCH_ORDER_ATTACHMENTS_DOC_TYPES.data
	);

	const handleDocTypeChange = (event) => {
		const { target } = event;
		setDocType(target.value);
	};

	const onloadFiles = (filesParam) => {
		setFile(filesParam);
		if (filesParam && filesParam.length > 0) {
			clearErrors("files");
		}
	};

	const onDeleteFile = () => {
		setFile(null);
		toast.info(t("fileRemovedMsg", { filename: file.name }), { autoClose: 3000 });
	};

	const onSubmit = (data) => {
		if (file && file.length === 0) {
			setError("files", {
				type: "manual",
				message: t("field_required"),
			});
			return;
		}
		setFilename(null);
		setShowForm(false);
		setDocType(null);
		const selectedDocType = docTypes.find((e) => data.docType === e.docType);
		data = {
			...data,
			name: filename,
			orderId: idOrder,
			orderAttachmentTypeDto: selectedDocType,
		};
		dispatch(createOrderAttachment(data, file)).then((res) => {
			dispatch(fetchOrderAttachments(idOrder));
			if (get(res, "data")) {
				toast.success(t("attachment.upload.success"));
			} else {
				toast.error(t(get(res, "error.response.data.detail")));
			}
			setDocType(null);
		});
		setShowForm(false);
	};

	const onDrop = () => {
		// toast.success(t("fileAddedMsg"), { autoClose: 3000 });
	};

	return (
		<>
			{showForm ? (
				<form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
					<Grid container xs={12} justify="space-between">
						<Grid container xs={6} alignItems="flex-start">
							<FormControl fullWidth>
								<InputLabel id="search" />
								<Input
									id="standard-adornment-search"
									type="text"
									name="name"
									required
									value={filename}
									placeholder={t("invoice.attachment.label")}
									onChange={(e) => setFilename(e.target.value)}
									style={{ marginRight: "10px" }}
									inputRef={register({
										required: {
											value: true,
											message: t("field_required"),
										},
										maxLength: { value: 255, message: "Value is too Long" },
										pattern: {
											value: /.*\S.*/,
											message: t("name_notValid"),
										},
									})}
									startAdornment={
										<InputAdornment position="start">
											<IconButton color="primary" aria-label="Recherche">
												<AttachmentIcon />
											</IconButton>
										</InputAdornment>
									}
								/>
							</FormControl>
							<Typography className={classes.errorMessage}>{errors.name?.message}</Typography>
						</Grid>
						<Grid container xs={6} alignItems="flex-start">
							<NativeSelect
								value={docType}
								onChange={handleDocTypeChange}
								inputRef={register({
									required: { value: true, message: t("field_required") },
								})}
								name="type"
								fullWidth
								style={{ marginTop: "15px" }}
							>
								{/* eslint-disable-next-line */}
								<option value="" />
								{docTypes &&
									docTypes.map((elt, i) => (
										<option key={i} value={t(elt.label)}>
											{t(elt.label)}
										</option>
									))}
							</NativeSelect>
							<Typography className={classes.errorMessage}>{errors.type?.message}</Typography>
						</Grid>
					</Grid>
					<Grid container xs={12} justify="center">
						<DropzoneArea
							inputProps={{ id: "newFile" }}
							name="attachment"
							filesLimit={1}
							maxFileSize={9999999999999}
							className={clsx("classes.dropzone", errors.files?.message && classes.dropzoneError)}
							showFileNames
							useChipsForPreview
							dropzoneText={
								<>
									<Typography variant="subtitle1" color="textSecondary">
										{t("Add your Attachment")}&nbsp;
										<u>{`${t("browse")}`}</u>
									</Typography>
								</>
							}
							dropzoneClass={clsx(classes.dropzone, errors.files && classes.dropzoneError)}
							dropzoneParagraphClass={classes.text}
							onChange={onloadFiles}
							onDelete={onDeleteFile}
							acceptedFiles={EXTENTIONS_ATTACHMENTS}
							inputRef={register({
								required: {
									value: true,
									message: t("field_required"),
								},
							})}
							showAlerts={false}
							onDrop={onDrop}
						/>
					</Grid>
					<Grid container xs={12} justify="center">
						<Button
							type="submit"
							className={classes.uploadButton}
							fullWidth
							color="secondary"
							variant="contained"
						>
							{t("attachment.upload")}
						</Button>
						<Button
							className={classes.addButton}
							fullWidth
							color="secondary"
							variant="outlined"
							onClick={() => {
								setFilename(null);
								setShowForm(false);
								setDocType(null);
							}}
						>
							{t("attachment.cancel")}
						</Button>
					</Grid>
				</form>
			) : (
				<Grid container xs={12} justify="flex-end">
					<Button
						type="submit"
						className={classes.addButton}
						color="secondary"
						variant="contained"
						onClick={() => setShowForm(true)}
					>
						{t("add attach")}
					</Button>
				</Grid>
			)}
		</>
	);
};

export default AttachmentForm;
