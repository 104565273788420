import React, { useState } from "react";

import ReactFlow, {
	removeElements,
	addEdge,
	MiniMap,
	Controls,
	Background,
} from "react-flow-renderer";

import { useDispatch } from "react-redux";
import {
	fetchWorkflow,
	updateWorkflow,
	updateWorkflowInvoicesStatus,
	checkStatusToDelete,
} from "administration/redux/actions";

import {
	IconButton,
	Switch,
	Checkbox,
	FormGroup,
	FormControlLabel,
	FormControl,
	TextField,
	Button,
	Grid,
	Tooltip,
} from "@material-ui/core";
import { AddCircleOutline, SaveAltOutlined, Undo } from "@material-ui/icons";
// import FileCopy from "@material-ui/icons/FileCopy";
import PopupModal from "administration/component/PopupModal";
import { useTranslation } from "react-i18next";
import ConfirmDialog from "components/ConfirmDialog";
import { toast } from "utils";
import CustomEdge from "./components/CustomEdge";
import {
	permanentInvoiceStatus,
	initialTransitions,
	disableAutomaticTransitionStatus,
} from "./Utils";
import useStyles from "../style";
import ChangeStatusForm from "./components/ChangeInvoiceStatusForm";
import CloneWorkflowPopup from "./components/CloneWorkflowPopup";
import InvoiceStatusNode from "./components/InvoiceStatusNode";

const onLoad = (reactFlowInstance) => reactFlowInstance.fitView();

const Workflow = () => {
	const { t } = useTranslation();
	const classes = useStyles();
	const dispatch = useDispatch();

	const edgeTypes = {
		custom: CustomEdge,
	};

	const [elements, setElements] = useState([]);
	const [validationRules, setValidationRules] = useState([]);

	const [automatic, setAutomatic] = useState(false);

	const [openStatusModal, setOpenStatusModal] = useState(false);

	const [nodeName, setNodeName] = React.useState("");

	const [labelName, setLabelName] = React.useState("");

	const [nodeCode, setNodeCode] = React.useState("");

	const [updateStatusModalOpen, setUpdateStatusModalOpen] = React.useState(false);

	const [nodeId, setNodeId] = React.useState("");

	const [canRectify, setCanRectify] = React.useState(false);

	const [isTransition, setIsTransition] = React.useState(false);

	const [errorMessage, setErrorMessage] = React.useState("");

	const [targetStatusC, setTargetStatusC] = React.useState("");

	const [sourceStatusC, setSourceStatusC] = React.useState("");

	const [newInvoicesStatus, setNewInvoiceStatus] = React.useState(null);

	const [workflowStatuses, setWorkflowStatuses] = React.useState([]);

	const [isChangeStatusFormOpen, setIsChangeStatusFormOpen] = React.useState(false);

	const [oldStatus, setOldStatus] = React.useState(false);

	const [invoices, setInvoices] = React.useState([]);

	const [cloneWorkflowIsOpen, setCloneWorkflowIsOpen] = React.useState(false);

	const [workflowCode, setWorkflowCode] = React.useState("");

	// const [workflowDescription, setWorkflowDescription] = React.useState("");

	const [isLitigeStatus, setIsLitigeStatus] = React.useState(false);

	const [confirmObject, setConfirmObject] = React.useState({
		message: "",
		openConfirm: false,
		cancel: false,
		isLoading: false,
	});

	const onCancel = () => {
		setConfirmObject({ message: "", openConfirm: false });
	};

	const onElementClick = (event, e) => {
		const element = elements.find((el) => el.id === e.id);
		const isTrans = element.source && element.target;

		setUpdateStatusModalOpen(true);

		setNodeId(element.id);

		setNodeCode(element.id);

		setNodeName(isTrans ? element.label : element.data.label);

		setLabelName(element.data?.buttonLabel);

		setAutomatic(element.data?.automatic);

		setIsLitigeStatus(element.data?.isLitige);

		setCanRectify(element.data?.rectify);

		setIsTransition(element.source && element.target);

		setTargetStatusC(element.source && element.target ? element.target : null);

		setSourceStatusC(element.source && element.target ? element.source : null);

		const prevValidationRules = [...validationRules];
		if (prevValidationRules.length > 0) {
			prevValidationRules.forEach((valRule) => {
				if (element.data.rules) {
					element.data.rules.forEach((elRule) => {
						if (valRule.code === elRule.code) {
							valRule.active = elRule.active;
						}
					});
				}
			});
		}

		setValidationRules(prevValidationRules);
	};

	const onElementsRemove = (elementsToRemove) => {
		setElements((els) => removeElements(elementsToRemove, els));
	};
	const onConnect = (params) => {
		const sourceEl = elements.find((el) => el?.id === params?.source);
		if (sourceEl?.data?.isLitige) {
			toast.error(t("workflow.source.status.litige.message"));
			return;
		}
		setElements((els) =>
			addEdge(
				{
					...params,
					arrowHeadType: "arrow",
					description: params.target,
					label: params.target,
					type: "smoothstep",
					data: {
						label: t(params.description),
						buttonLabel: params.target,
						rules: validationRules,
						automatic: false,
						to_delete: false,
					},
				},
				els
			)
		);
	};

	const fetchCurrentWorkflow = () => {
		dispatch(fetchWorkflow()).then((res) => {
			if (res.status !== 200) {
				toast.error(t("workflow.load.error"));
				return;
			}
			setWorkflowStatuses(res.data.status);
			if (res.data.rules) setValidationRules(res.data.rules);
			const statusNodes = res.data.status?.map((nodeStatus) => {
				const coords = nodeStatus?.coordinates && nodeStatus?.coordinates.split(",");
				return {
					id: nodeStatus?.code,
					type: "status",
					sourcePosition: "right",
					targetPosition: "left",
					data: {
						label: nodeStatus?.displayName ? nodeStatus?.displayName : t(nodeStatus?.label),
						rules: nodeStatus?.rules,
						rectify: nodeStatus?.rectify,
						to_delete: false,
						isLitige: nodeStatus.isLitige,
					},
					position: { x: coords && Number(coords[0]), y: coords && Number(coords[1]) },
				};
			});
			const transitionNodes = res.data.transitions.map((nodeTransition) => ({
				id: `${nodeTransition?.sourceStatusCode}-${nodeTransition?.targetStatusCode}`,
				description: nodeTransition?.description
					? t(nodeTransition?.description)
					: t(nodeTransition?.targetStatusCode),
				source: nodeTransition?.sourceStatusCode,
				target: nodeTransition?.targetStatusCode,
				label: t(nodeTransition?.description),
				data: {
					label: t(nodeTransition?.description),
					buttonLabel: nodeTransition?.buttonLabel
						? t(nodeTransition?.buttonLabel)
						: t(nodeTransition?.description),
					rules: nodeTransition?.rules,
					automatic: nodeTransition?.automatic,
					to_delete: false,
				},
				type: "smoothstep",
				arrowHeadType: "arrow",
				animated: !!nodeTransition.automatic,
			}));
			const allNodes = [...statusNodes, ...transitionNodes, ...initialTransitions(t)];
			const uniqueNodes = Array.from(new Set(allNodes.map((a) => a.id))).map((id) =>
				allNodes.find((a) => a.id === id)
			);

			setWorkflowCode(res.data.code);
			// setWorkflowDescription(res.data.description);

			setElements(uniqueNodes);
		});
	};

	React.useEffect(() => {
		fetchCurrentWorkflow();
	}, [dispatch]);

	const handleSaveFail = (res) => {
		if (
			res.error?.response?.status === 400 &&
			res.error?.response?.data?.title === "workflow.invoice.exists"
		) {
			setIsChangeStatusFormOpen(true);
		} else {
			toast.error(t(res.error.response.data.detail));
		}
	};

	const generateWorkflowObject = (oldStatusCode) => {
		const statusList = [];
		const transitionsList = [];

		elements
			.filter((el) => el.code !== oldStatusCode)
			.forEach((el) => {
				if (el.source && el.target) {
					transitionsList.push({
						type: "custom",
						code: `${el.source}-${el.target}`,
						description: `${el.label ? el.label : el.target}`,
						buttonLabel: el.data && el.data.description,
						sourceStatusCode: el.source,
						targetStatusCode: el.target,
						rules: el.data && el.data.rules,
						automatic: el.data && el.data.automatic,
						to_delete: el.data ? el.data.to_delete : false,
					});
				} else {
					statusList.push({
						code: `${el.id}`,
						label: `${el.data && el.data.label}`,
						coordinates: `${el.position ? Number(el.position.x) : 0},${
							el.position ? Number(el.position.y) : 0
						}`,
						rules: el.data && el.data.rules,
						rectify: el.data && el.data.rectify,
						to_delete: el.data ? el.data.to_delete : false,
						isLitige: el.data ? el.data.isLitige : false,
					});
				}
			});

		return {
			code: workflowCode,
			status: statusList,
			transitions: transitionsList,
		};
	};

	const saveWorkflow = (oldStatusCode) => {
		setConfirmObject({ ...confirmObject, isLoading: true });
		const workflowObject = generateWorkflowObject(oldStatusCode);
		dispatch(updateWorkflow(workflowObject)).then((res) => {
			if (res.status === 200) {
				fetchCurrentWorkflow();
				toast.success(t("workflow.status.success"));
			} else {
				handleSaveFail(res);
			}
			setConfirmObject({ ...confirmObject, openConfirm: false, isLoading: false });
		});
	};

	const onNodeDragStop = (event, node) => {
		setElements(
			elements.map((item) => (item.id === node.id ? { ...item, position: node.position } : item))
		);
	};

	const addNewNode = (code) => {
		const newNode = {
			id: code,
			type: "status",
			sourcePosition: "right",
			targetPosition: "left",
			data: { label: code, rules: [], to_delete: false },
			position: { x: 50, y: 100 },
		};

		setElements((prevElements) => [...prevElements, newNode]);

		setNodeName("");
		setOpenStatusModal(false);
	};

	const checkAndSetAutomaticTransiton = (transitionNode) => {
		if (!automatic) return false;
		// click on transition and i will check its source status
		const sourceStatus = transitionNode.source;

		// check transitions with the same source status
		const transitionsForSourceStatus = elements.filter(
			(el) => el.source && el.target && el.source === sourceStatus && el.id !== transitionNode.id
		);

		// ensure that only no or one of them have the automatic field is on
		const automaticTransitionExist = transitionsForSourceStatus.filter((e) => e.data.automatic);

		return automaticTransitionExist.length >= 1;
	};

	const updateNode = (code) => {
		try {
			const el = elements.find((s) => s.id === code);
			const elRules = validationRules;

			const rules = isTransition
				? elRules.map((rule) => ({
						...rule,
						transitionRuleCode: `rule-${Math.random() * 100000000}`,
				  }))
				: elRules.map((rule) => ({
						...rule,
						statusRuleCode: `rule-${Math.random() * 100000000}`,
				  }));

			if (el.source && el.target) {
				if (checkAndSetAutomaticTransiton(el)) {
					throw new Error();
				} else {
					const e = elements.map((item) =>
						item.id === code
							? {
									...item,
									target: targetStatusC,
									label: nodeName,
									animated: !!(isTransition && automatic),
									data: {
										buttonLabel: labelName,
										description: labelName,
										rules,
										automatic,
										to_delete: false,
									},
							  }
							: item
					);
					setElements(e);
					setUpdateStatusModalOpen(false);
					setNodeName("");
					setNodeCode("");
					setErrorMessage("");
					setLabelName("");
				}
			}
			// status
			else {
				setElements(
					elements.map((item) =>
						item.id === code
							? {
									...item,
									data: {
										label: nodeName,
										rules,
										rectify: canRectify,
										to_delete: false,
										isLitige: isLitigeStatus,
									},
							  }
							: item
					)
				);
				setUpdateStatusModalOpen(false);
				setNodeName("");
				setNodeCode("");
				setErrorMessage("");
				setLabelName("");
			}

			const prevValidationRules = [...validationRules];
			prevValidationRules.forEach((valRule) => {
				valRule.active = false;
			});

			setValidationRules(prevValidationRules);
		} catch (e) {
			setErrorMessage(t("errorautotransition"));
		}
	};

	const validateStatusTransitions = (statusEl, code) => {
		if (!statusEl.source && !statusEl.target) {
			// verify if the element to be deleted is not a transmission
			let counter = 0;
			elements.forEach((e) => {
				if (e && e.data && !e.data.to_delete) {
					if ((e.target && e.target === code) || (e.source && e.source === code)) counter += 1;
				}
			});
			if (counter > 0) {
				toast.error(`${t("workflow.delete.error", { count: counter })}`);
				return false;
			}
		}
		return true;
	};

	const deleteStatus = (code) => {
		setElements(
			elements.map((item) =>
				item.id === code
					? { ...item, data: { ...item.data, to_delete: true, automatic: false } }
					: item
			)
		);

		setUpdateStatusModalOpen(false);
	};

	const handleDeleteStatus = (code) => {
		const elementToBeDeleted = elements.find((el) => el.id === code);
		if (!validateStatusTransitions(elementToBeDeleted, code)) {
			return;
		}
		if (!elementToBeDeleted.source && !elementToBeDeleted.target) {
			dispatch(checkStatusToDelete(workflowCode, code)).then((res) => {
				if (res.status === 200) {
					if (res.data?.length > 0) {
						setOldStatus(code);
						setUpdateStatusModalOpen(false);
						setIsChangeStatusFormOpen(true);
						setInvoices(res.data);
					} else {
						deleteStatus(code);
					}
				}
			});
		} else {
			deleteStatus(code);
		}
	};

	const updateInvoicesStatus = (oldStatusCode, newStatusCode, invoicesToUpdate) => {
		let workflowObject = generateWorkflowObject(null);
		workflowObject = { ...workflowObject, code: workflowCode };
		const objIndex = workflowObject.status.findIndex((st) => st.code === oldStatusCode);
		const st = workflowObject.status[objIndex];
		workflowObject.status[objIndex] = st;

		dispatch(updateWorkflow(workflowObject)).then((res) => {
			if (res.status === 200) {
				dispatch(
					updateWorkflowInvoicesStatus(
						oldStatusCode,
						newStatusCode,
						invoicesToUpdate,
						workflowObject
					)
				).then(() => {
					window.location.reload();
				});
			}
			setIsChangeStatusFormOpen(false);
		});
	};

	const nodesOfWorkflow = elements.filter((el) => (el.data ? !el.data.to_delete : el));

	const handleChangeIsLitigeStatus = () => setIsLitigeStatus(!isLitigeStatus);

	const handleChangeRectify = () => setCanRectify(!canRectify);

	return (
		<>
			<Grid xs={12} style={{ width: "100%" }}>
				<PopupModal
					openPopup={openStatusModal}
					setOpenPopup={() => setOpenStatusModal(false)}
					dialogTitle={t("addstatus")}
					dialogContents={
						<FormControl style={{ width: "100%" }} className={classes.formControl}>
							<TextField
								id="standard-basic"
								label={t("status")}
								value={nodeName}
								onChange={(event) => {
									setNodeName(event.target.value);
								}}
							/>
						</FormControl>
					}
					dialogActions={
						<>
							<Button onClick={() => setOpenStatusModal(false)} color="primary">
								{t("cancel")}
							</Button>
							<Button onClick={() => addNewNode(nodeName)} color="primary">
								{t("addstatus")}
							</Button>
						</>
					}
				/>

				<PopupModal
					openPopup={updateStatusModalOpen}
					classes={classes}
					setOpenPopup={() => {
						setUpdateStatusModalOpen(false);
						setNodeName("");
						setLabelName("");
						setNodeCode("");
						setErrorMessage("");
						const prevValidationRules = [...validationRules];
						prevValidationRules.forEach((valRule) => {
							valRule.active = false;
						});

						setValidationRules(prevValidationRules);
					}}
					dialogTitle={isTransition ? t("updatetransition") : t("updatestatus")}
					dialogContents={
						<>
							<FormControl style={{ width: "100%" }} className={classes.formControl}>
								<TextField
									label={isTransition ? t("transition") : t("status")}
									value={t(nodeName)}
									disabled={permanentInvoiceStatus.includes(nodeId)}
									onChange={(event) => {
										setNodeName(event.target.value);
									}}
								/>
							</FormControl>
							{isTransition && (
								<FormControl style={{ width: "100%" }} className={classes.formControl}>
									<TextField
										label={t("transitionbuttonlabel")}
										value={t(labelName)}
										onChange={(event) => {
											setLabelName(event.target.value);
										}}
									/>
								</FormControl>
							)}
							{isTransition && (
								<>
									<FormControlLabel
										control={
											<Checkbox
												disabled={
													disableAutomaticTransitionStatus.includes(sourceStatusC) ||
													disableAutomaticTransitionStatus.includes(targetStatusC)
												}
												checked={automatic}
												onChange={() => setAutomatic(!automatic)}
												name="checkedA"
											/>
										}
										label={t("automatic_transition")}
									/>
									<p style={{ color: "red" }}>{errorMessage}</p>
								</>
							)}
							{!isTransition && (
								<>
									<Grid item>
										<FormControlLabel
											disabled={
												nodeName === "Integration" ||
												nodeName === "Rejected" ||
												nodeName === "Exported"
											}
											control={
												<Checkbox
													checked={canRectify}
													onChange={handleChangeRectify}
													name="checkedA"
												/>
											}
											label={t("AbleCorrect")}
										/>
									</Grid>
									<Grid item>
										<FormControlLabel
											disabled={
												nodeName === "Integration" ||
												nodeName === "Rejected" ||
												nodeName === "Exported"
											}
											control={
												<Checkbox
													checked={isLitigeStatus}
													onChange={handleChangeIsLitigeStatus}
													name="isLitigeStatusProperty"
												/>
											}
											label={t("workflow.property.isLitige")}
										/>
									</Grid>
								</>
							)}
							<FormGroup>
								{isTransition ? <h2>{t("blockedby")}</h2> : <h2>{t("appliedrules")}</h2>}
								{validationRules.map((rule, index) => (
									<FormControlLabel
										key={index}
										control={
											<Switch
												checked={rule.active}
												onChange={() => {
													setValidationRules(
														validationRules.map((item) =>
															item.code === rule.code ? { ...item, active: !rule.active } : item
														)
													);
												}}
											/>
										}
										label={t(rule.label)}
									/>
								))}
							</FormGroup>
						</>
					}
					dialogActions={
						<>
							<Button
								onClick={() => {
									setUpdateStatusModalOpen(false);
									setNodeName("");
									setLabelName("");
									setNodeCode("");
									setErrorMessage("");
									const prevValidationRules = [...validationRules];
									prevValidationRules.forEach((valRule) => {
										valRule.active = false;
									});

									setValidationRules(prevValidationRules);
								}}
								variant="outlined"
								color="primary"
							>
								{t("cancel")}
							</Button>

							<Button
								variant="contained"
								disabled={permanentInvoiceStatus.includes(nodeId.trim().toUpperCase())}
								onClick={() => handleDeleteStatus(nodeCode)}
								color="primary"
							>
								{isTransition ? t("deletetransition") : t("deletestatus")}
							</Button>

							<Button onClick={() => updateNode(nodeCode)} variant="contained" color="secondary">
								{t("update")}
							</Button>
						</>
					}
				/>
				<Grid xs={12} style={{ minHeight: "500px" }}>
					<Grid container item xs={12} alignItems="center" justify="space-between">
						<Grid container item xs={6}>
							{/* {workflowDescription} */}
						</Grid>
						<Grid container item xs={6} justify="flex-end">
							<Grid container item className={classes.utilButtons}>
								<Tooltip placement="top" title={t("workflow.toolTip.addStatus")}>
									<IconButton
										color="primary"
										aria-label="Add status"
										component="span"
										onClick={() => {
											setNodeCode("");
											setNodeName("");
											setOpenStatusModal(true);
										}}
									>
										<AddCircleOutline />
									</IconButton>
								</Tooltip>
							</Grid>
							<Grid container item className={classes.utilButtons}>
								<Tooltip placement="top" title={t("workflow.toolTip.save")}>
									<IconButton
										color="primary"
										aria-label="Save workflow"
										component="span"
										onClick={() => {
											setConfirmObject({
												message: t("asksaveworkflow"),
												openConfirm: true,
												isLoading: false,
											});
										}}
									>
										<SaveAltOutlined />
									</IconButton>
								</Tooltip>
							</Grid>
							{/* TODO: add the cloning workflow functionality */}
							{/* <Grid container item className={classes.utilButtons}>
								<Tooltip placement="top" title={t("workflow.toolTip.clone")}>
									<IconButton
										color="primary"
										aria-label="Clone workflow"
										component="span"
										onClick={() => {
											setCloneWorkflowIsOpen(true);
										}}
									>
										<FileCopy />
									</IconButton>
								</Tooltip>
							</Grid> */}
							<Grid container item className={classes.utilButtons}>
								<Tooltip placement="top" title={t("workflow.toolTip.undoCHanges")}>
									<IconButton
										color="primary"
										aria-label="Delete Changes"
										component="span"
										onClick={() => {
											fetchCurrentWorkflow();
										}}
									>
										<Undo />
									</IconButton>
								</Tooltip>
							</Grid>
						</Grid>
					</Grid>

					<Grid container xs={12}>
						<ReactFlow
							className={classes.reactWorkflow}
							edgeTypes={edgeTypes}
							nodeTypes={{ status: InvoiceStatusNode }}
							elements={nodesOfWorkflow}
							onElementsRemove={onElementsRemove}
							onConnect={onConnect}
							onLoad={onLoad}
							selectNodesOnDrag={false}
							onNodeDoubleClick={onElementClick}
							onNodeDragStop={onNodeDragStop}
							onEdgeDoubleClick={onElementClick}
							onEdgeUpdate={onElementClick}
							deleteKeyCode={null}
							elementsSelectable={false}
						>
							<MiniMap />
							<Controls />
							<Background color="#aaa" gap={16} />
						</ReactFlow>
					</Grid>
				</Grid>
			</Grid>
			<ConfirmDialog
				message={confirmObject.message}
				open={confirmObject.openConfirm}
				isLoading={confirmObject.isLoading}
				onConfirm={saveWorkflow}
				onCancel={onCancel}
			/>
			<ChangeStatusForm
				t={t}
				isOpen={isChangeStatusFormOpen}
				setIsOpen={setIsChangeStatusFormOpen}
				statusList={workflowStatuses}
				setNewInvoiceStatus={setNewInvoiceStatus}
				newInvoicesStatus={newInvoicesStatus}
				updateInvoicesStatus={updateInvoicesStatus}
				oldStatus={oldStatus}
				invoices={invoices}
				classes={classes}
			/>
			<CloneWorkflowPopup
				t={t}
				classes={classes}
				isOpen={cloneWorkflowIsOpen}
				setIsOpen={setCloneWorkflowIsOpen}
				dispatch={dispatch}
				workflowCode={workflowCode}
				fetchCurrentWorkflow={fetchCurrentWorkflow}
			/>
		</>
	);
};

export default Workflow;
