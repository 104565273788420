import supllierAr from "../pages/Suppliers/i18n/ar";
import clientAr from "../pages/Clients/i18n/ar";
import administrationAr from "../administration/i18n/ar";

export default {
	...supllierAr,
	...administrationAr,
	...clientAr,
	ltl: "ltl",
	Copyright: "حقوق النشر",
	Dashboard: "لوحة القيادة",
	"Business management": "ادارة اعمال",
	Orders: " الطلب ",
	Invoices: "الفواتير",
	Regulations: "اللوائح",
	Suppliers: "الموردون",
	Rapports: "التقارير",
	Search: "بحث",
	"Add your Invoice": " أضف فاتورتك",
	browse: "تصفح",
	fileAddedMsg: "تم إضافة الملف (الملفات) بنجاح!",
	fileRemovedMsg: "ملف {{filename}} إزالته.",
	"Show all": "عرض الكل",
	id: "اسم المستخدم",
	supplier: "المورد",
	invoiceNo: "رقم الفاتورة",
	issueDate: "يوم الاصدار",
	dueDate: "تاريخ الاستحقاق",
	orderDeliveryDate: "تاريخ التسليم او الوصول",
	"Price WT": "السعر بالوزن",
	"Taxe Rate": "معدل الضريبة",
	Price: "السعر",
	"Purchase order": "أمر شراء",
	"Last actions": "الإجراءات الأخيرة",
	Today: "اليوم",
	Yesterday: "في الامس",
	"Last week": "الاسبوع الماضي",
	Status: "الحالة",
	Date: "تاريخ",
	"My tasks": "مهامي",
	"Invoices to verify": "فواتير للتحقق",
	"Invoices to be checked": "الفواتير المراد فحصها",
	"Invoices to Payers": "فواتير للدافعين",
	"Paid invoices": "الفواتير المدفوعة",
	"Unpaid invoices": "فواتير غير مدفوعة",
	"Total amount of invoices": "إجمالي مبلغ الفواتير",
	"Total amount of orders": "إجمالي مبلغ الطلبات",
	"Total VAT amount": "إجمالي مبلغ ضريبة القيمة المضافة",
	"Statistical overview of purchases": "نظرة إحصائية عامة على المشتريات",
	"Statistical overview of product sales": "نظرة عامة إحصائية على مبيعات المنتج",
	"Total Purchases": "إجمالي المشتريات",
	"Total of expenses": "اجمالي المصاريف",
	Late: "متأخر",
	Administrator: "مدير",
	"New suppliers": "الموردون الجدد",
	FOUNDED_RESULT: "نتيجة مؤسسية",
	EndDate: "تاريخ الانتهاء",
	StartDate: "تاريخ البدء",
	"Add an invoice": "إضافة فاتورة",
	Download: "تحميل",
	Show: "تبين",
	Delete: "حذف",
	All: "الكل",
	SearchInvoice: "ابحث على فاتورة",
	"Delivery address": "عنوان التسليم",
	"Billing address": "عنوان وصول الفواتير",
	"Payment terms": "شروط الدفع",
	"Order Details": "تفاصيل الطلب",
	"Invoice Details": "تفاصيل الفاتورة",
	"Reject Invoice": "رفض الفاتورة",
	"Reject Order": "رفض الطلب",
	"Correct Extraction": "تصحيح الفاتورة",
	Control: "مراقبة",
	ToPay: "دفع",
	"Total HT": "إجمالي HT",
	"VAT amount": "قيمة الضريبة",
	Discount: "خصم",
	"Total amount": "المبلغ الإجمالي",
	"Invoice overview": "نظرة عامة على الفاتورة",
	History: "التاريخ",
	verificationDate: "تاريخ التحقق",
	rejectionDate: "تاريخ الرفض",
	controlDate: "تاريخ التحكم",
	dateOfReceipt: "تاريخ استلام",
	rejectMessage: "رفض الرسالة",
	controlMessage: "هل أنت متأكد أنك تريد وضع هذه الفاتورة قيد التحكم؟ ",
	ErrorMessageToControl: "لا يمكنك التحكم في هذه الفاتورة:",
	deleteMessage: "هل أنت متأكد أنك تريد حذف هذه الفاتورة (الفواتير)",
	individualDeleteMessage: "هل أنت متأكد أنك تريد حذف هذه الفاتورة؟",
	orderDeleteMessage: "هل أنت متأكد من أنك تريد حذف هذا الطلب؟",
	rejectionReason: "سبب الرفض",
	rejectionDetails: "تفاصيل الرفض",
	cancel: "إلغاء",
	confirm: "تؤكد",
	editForm: "تحرير النموذج",
	countInvoices: "حساب الفواتير ",
	countOrders: "حساب الطلبات ",
	tobeprocessed: "لتتم معالجتها ",
	None: "------------------",
	save: "حفظ",
	edit: "تعديل",
	Actions: "أجراءات",
	itemCode: "الرمز",
	designation: "الصنف",
	totalHt: "المجموع بدون ضرائب",
	quantity: "الكمية",
	unitPrice: "سعر الوحدة",
	deliveryDate: "تاريخ التسليم او الوصول",
	"order Lines": "خطوط الطلب",
	Commands: " الفواتير",
	"order.lines": "خطوط القيادة",
	Total: "مجموع",
	VAT: "قيمة الضريبة",
	"Pre-Tax-Amount": "المبلغ قبل الضريبة",
	sortBy: "صنف حسب",
	createdAt: "أنشئت في",
	updatedAt: "تحديث في",
	Upload: "تحميل الفواتير",
	InvoicesCannotBeDeleted: "لا يمكن حذف بعض الفواتير",
	OrdersCannotBeDeleted: "لا يمكن حذف بعض الأوامر ",
	InvoicesCannotBeRelaunched: "لا يمكن مطالبة بعض الفواتير",
	"Integration in progress": "قيد التقدم",
	"To verify": "للتحقق",
	"To control": "للتحكم",
	"To pay": "للدفع",
	Payed: "المدفوع",
	Rejected: "مرفوض",
	Canceled: "ألغيت",
	Exception: "استثناء",
	codeArticle: "رمز الصنف",
	deliveryDelay: "أجل التسليم",
	vat: "ضريبة القيمة المضافة",
	orderNo: "رقم الطلب",
	numberOfArticle: "عدد الصنف",
	deliveryMethod: "طريقة التوصيل",
	InvoicesNotCreatedMessage: "لا يمكن إنشاء الفواتير التالية",
	invoice: "فاتورة",
	order: "طلب",
	training: "...في التدريب",
	trained: "متدرب",
	"VAT Number": "ضريبة الشراء",
	"invoice Number": "رقم الفاتورة",
	"Apply Changes": "تطبيق",
	"Company Siret": "Siret",
	"Invoice was already paid": "تم دفع الفاتورة بالفعل",
	"Order has been already delivered": "تم تسليم الطلب بالفعل",
	"Goods or Services were never received": " لم يتم استلام السلع أو الخدمات مطلقًا",
	"Goods or services are not available at the moment":
		"السلع أو الخدمات غير متوفرة في الوقت الحالي",
	"Supporting documents were not received with the invoice":
		"لم يتم استلام المستندات الداعمة مع الفاتورة",
	"The purchase order number is missing, is incorrect or has been canceled":
		"رقم طلب الشراء مفقود أو غير صحيح أو تم إلغاؤه",
	"The order number is missing, is incorrect or has been canceled":
		"رقم الطلب مفقود أو غير صحيح أو تم إلغاؤه",
	"The amount being billed does not match the agreed upon amount on the contract":
		"المبلغ الذي يتم إصدار فاتورة به لا يتطابق مع المبلغ المتفق عليه في العقد",
	"Supporting documents were not received with the order":
		"لم يتم استلام المستندات الداعمة مع الطلب",
	Other: "أخرى",
	CREATE_ACTION: "إنشاء الفاتورة",
	EDIT_ACTION: "تعديل الفاتورة",
	VERIFY_ACTION: "فحص الكلية",
	REJECT_ACTION: "رفض الفاتورة",
	CONTROL_ACTION: "السيطرة على الفاتورة",
	END_TRAINING_ACTION: "اكتمل التعلم",
	CORRECT_ACTION: "إطلاق التعلم",
	TO_PAY_ACTION: "دفع الفاتورة",
	DELET_ACTION: "شطب الفاتورة",
	RELAUNCH_ACTION: "إعادة إنشاء الفاتورة",
	EXPORT_ACTION: "تصدير الفاتورة",
	ORDER_CREATE_ACTION: "الطلب خلق",
	ORDER_VERIFY_ACTION: "الطلب قيد التحقق",
	ORDER_REJECT_ACTION: "رفض الطلب",
	ORDER_CONTROL_ACTION: "الطلب المرسلة للسيطرة",
	ORDER_TO_VALIDATE_EXTERNAL_ACTION: "طلب التحقق من الصحة",
	ORDER_END_TRAINING_ACTION: "الانتهاء من التعلم",
	ORDER_CORRECT_ACTION: "إطلاق التعلم",
	ORDER_TO_PAY_ACTION: "دفع الطلب",
	ORDER_DELETE_ACTION: "حذف الطلب",
	ORDER_EDIT_ACTION: "تعديل الطلب",
	ORDER_RELAUNCH_ACTION: "استخراج صدر",
	ORDER_EXPORT_ACTION: "تصدير الطلب",
	INVOICE_CHANGE_STATUS: "وفاتورة مرت إلى الوضع {{status}}",
	INVOICE_CHANGE_STATUS_AUTOMATIC: "تم تمرير الفاتورة تلقائيًا إلى الحالة {{status}}",
	action: "عمل",
	details: "تفاصيل",
	totalEmptyMessageError: "لم يتم ملء المبلغ الإجمالي للفاتورة",
	supplierEmptyMessageError: "لم يتم ملء مورد الفاتورة",
	incohenrenceAmountsMessageError:
		"حساب المبالغ غير صحيح ؛ يختلف مبلغ ضريبة القيمة المضافة والمبلغ باستثناء ضريبة القيمة المضافة عن المبلغ الإجمالي",
	invoice_charge: "التكاليف",
	order_charge: "التكاليف",
	total_extract: "فاتورة مستخرجة بالكامل",
	partial_extract: "فاتورة مستخرجة جزئياً",
	not_extract: "فاتورة غير مستخرجة",
	country: "بلد",
	duplicated_invoice: "فاتورة مكررة",
	"invoice.total_invoice": "إجمالي الفواتير",
	currency: "عملة",
	DeliverySlip: "وثيقة التوصيل",
	"invoice.detail.company_siret": "هوية الشركة",
	reason: "السبب",
	"invoice.exported": "الفواتير المصدرة",
	"invoice.unexported": "الفواتير غير المصدرة",
	"invoice.status.underProcess": "يتم معالجتها",
	"invoice.status.processed": "معالجة",
	"invoice.status.InoviceProcessed": "الفواتير المعالجة",
	"invoice.status.InoviceUnderProcess": "الفواتير قيد المعالجة",
	"invoice.status.integration": "Integration",
	"invoice.status.toverify": "To Verify",
	"invoice.status.tocontrol": "To Control",
	"invoice.status.topay": "To Pay",
	"invoice.status.payed": "Payed",
	"invoice.status.rejected": "Rejected",
	"invoice.status.canceled": "Canceled",
	"invoice.status.exception": "Exception",
	"invoice.status.external_validation": "في انتظار التأكيد",
	"invoice.status.open": "فتح",
	"invoice.status.reintegrated": "إعادة دمجها",
	"order.status.external_validation": "في انتظار التأكيد",
	comment: "تعليق",
	"invoice.status.exported": "مصدر",
	"Invoice available": "الفاتورة متاحة",
	"invoice.duplicated": "مكرر",
	"extraction.label.fully_extracted": "مستخرجة بالكامل",
	"extraction.label.partially_extracted": "مستخرجة جزئياً",
	"extraction.label.not_extracted": "غير مستخرجة",
	"extraction.label.not_invoice": "ليست فاتورة",
	"tootlip.action.edit": "تعديل",
	"tooltip.action.delete": "حذف",
	"tooltip.action.edit": "المعدل",
	"tooltip.action.relaunch": "إعادة التشغيل",
	"tooltip.action.download": "تحميل",
	"tooltip.action.relaunch_extraction": "أعد تشغيل الاستخراج",
	"tooltip.action.export_excel": "excel تصدير",
	"tooltip.action.export_entry_account_excel": "قيد محاسبة تصدير Excel",
	"tooltip.action.export_xml": "xml تصدير",
	"supplier.add.button": "موردي الاستيراد",
	"supplier.export.suppliers.excel": "تصدير قائمة الموردين",
	"supplier.add2.button": "إضافة مورد",
	"suppliers.add.multiple": "أضف الموردين أو ",
	"upload.supplier": "تحميل الموردين",
	"downloqd.template": "تحميل النموذج",
	"invoice.total_amoutn_to_verify": "إجمالي مبلغ الفواتير للتحقق",
	Administration: "الادارة",
	Logout: "تسجيل خروج",
	Profile: "الملف الشخصي",
	"invoice.selected.title0": "تم تحديد جميع الفواتير",
	"invoice.selected.title1": " تم تحديد جميع الفواتير",
	"invoice.selected.title2": " الموجودة في هذه الصفحة",
	"invoice.selected.title3": "حدد جميع الفواتير",
	"supplier.add.step1": "المعلومات العامة",
	"supplier.add.step2": "اتصال رئيسي",
	"supplier.add.step3": "المعلومات المالية",
	"supplier.add.next": "التالي",
	"supplier.add.previous": "سابق",
	"supplier.add.finish": "ينهي",
	"supplier.add.country": "بلد القاعدة",
	"supplier.add.suppliername": "اسم المورد",
	"supplier.add.logo": "شعار",
	"supplier.add.capital": "رأس مال",
	"supplier.add.siret": "شركة SIRET",
	"supplier.add.siren": "شركة SIREN",
	"supplier.add.linkedin": "LinkedIn",
	"supplier.add.email": "بريد الالكتروني",
	"supplier.add.fax": "فاكس",
	"supplier.add.civility": "الكياسة",
	"supplier.add.phone": "هاتف",
	"supplier.add.vat": "ظريبه الشراء",
	"supplier.add.naf": "Code NAF",
	"supplier.add.bank": "مصرف",
	"supplier.add.bic": "BIC",
	"supplier.add.iban": "IBAN",
	"supplier.add.rib": "RIB",
	"supplier.add.acc": "حساب محاسبة",
	"supplier.add.curr": "العملة الافتراضية",
	"supplier.add.function": "وظيفة",
	"supplier.add.firstName": "الاسم الاول",
	"supplier.add.lastName": "الكنية",
	"supplier.add.city": "مدينة",
	"supplier.add.street": "شارع",
	"supplier.add.zipCode": "الرمز البريدي",
	"supplier.add.confirm": "هل أنت متأكد أنك تريد حفظ التغييرات؟",
	"supplier.delete.confirm": "هل أنت متأكد أنك تريد حذف المزود؟",
	"supplier.delete.success": "تم حذف المورد بنجاح",
	"supplier.have.attached.user.accounts.or.invoices.message": "حدث خطأ أثناء حذف المورد",
	"client.has.attached.user.accounts.message":
		"لا يمكن حذف العميل (العملاء).يتم إرفاق الطلب / الحساب!",
	"suplier.action.agree": "قبول",
	"suplier.action.disagree": "رفض",
	"supplier.add.aux": "حساب مساعد",
	"supplier.name.exist.already": "المورد موجود على النظام",
	"suppliers.names.exist.already": "الموردين {{fournisseurs}} موجود بالفعل على النظام!",
	"suppliers.empty.field.name.row.excel": "هناك صف بدون اسم !!",
	"suppliers.error.sample": "الملف المرسل لا يتوافق مع الملف المثالي.",
	ApplyForApproval: "طلب الموافقة",
	TO_VALIDATE_EXTERNAL_ACTION: "طلب الموافقة",
	EXPORT_EXCEL: "excel تصدير",
	EXPORT_EDI: "edi تصدير",
	EXPORT_XML: "xml تصدير",
	EXPORT_PDF: "pdf تصدير",
	EXPORT_EC: "JE تصدير",
	"order.absent": "طلب غائب",
	"amount.greate.thang.goods": "مبلغ الفاتورة أكبر من الطلب",
	"delivery.absent": "عدم التسليم",
	delivery: "تسليم",
	"user.roles.user": "مستخدم",
	"profile.update-password.new-password-required": "كلمة مرور جديدة مطلوبة",
	"profile.update-password.password-required": "كلمة المرور مطلوبة",
	"profile.update-password.password-not-matched": "كلمات المرور غير متطابقة",
	"profile.update-password.current-password": "كلمة المرور الحالية",
	"profile.update-password.new-password": "كلمة مرور جديدة",
	"profile.update-password.confirm-password": "تأكيد كلمة المرور",
	"profile.update-password.reset-password": "إعادة تعيين كلمة المرور",
	"profile.update-password.password-reset": "إعادة تعيين كلمة المرور",
	"profile.update-password.password-reset-success": "تم تغيير كلمة المرور الخاصة بك بنجاح",
	"profile.update-password.password-email-send": "تم إرسال بريد إلكتروني. يرجى التحقق من بريدك.",
	"profile.update-password.password-reset-done": "تم",
	"profile.update-password.update-password": "تغيير كلمة المرور",
	"profile.update-password.email": "بريدك الإلكتروني الحالي",
	"profile.update-password.tokenError": "رمز غير صالح ، تم تحديث كلمة المرور بالفعل!",
	"Zip Code": "الرمز البريدي",
	Street: "شارع",
	City: "مدينة",
	"supplier.delete.training.error.message": "لا يوجد تدريب لهذا المورد.",
	"invoice.detail.reject.success": "تم رفض الفاتورة بنجاح",
	"invoice.detail.reject.failed": "لم يتم رفض الفاتورة",
	"invoice.detail.approved.success": "تمت الموافقة على الفاتورة بنجاح",
	"invoice.detail.marked.selectedField": "وضع علامة على الحقل المحدد",
	"invoice.detail.approved.failed": "لم تتم الموافقة على الفاتورة",
	"invoice.detail.apply.validate": "تطبيق والتحقق",
	"invoice.detail.export.success": "تم تصدير الفاتورة بنجاح",
	"order.detail.export.success": "تم تصدير الطلب بنجاح",
	"invoice.detail.export.failed": "لم يتم تصدير الفاتورة",
	"order.detail.export.failed": "لم يتم تصدير الطلب",
	"invoice.detail.message.rectify": "هل أنت متأكد أنك تريد حفظ هذه التغييرات؟",
	"invoice.detail.message.confirmExport": "هل أنت متأكد أنك تريد تصدير هذه الفاتورة؟",
	"order.detail.message.confirmExport": "هل أنت متأكد أنك تريد تصدير هذا الطلب؟",
	"order.detail.approved.success": "تمت الموافقة على الطلب بنجاح",
	"order.detail.approved.failed": "لم تتم الموافقة على الطلب",
	"token.validation.noteExist": "تم إرسال طلب تغيير كلمة المرور بالفعل",
	"client.delete.msg": "هل أنت متأكد أنك تريد حذف هذا العميل؟",
	year: "هذه السنة",
	month: "هذا الشهر",
	week: "هذا الاسبوع",
	day: "اليوم",
	Exported: "المصدرة",
	Integrated: "المدمجة",
	"switch.orders": "الطلبات",
	"switch.invoices": "الفواتير",
	"Invoice attachements": "المرفقات",
	"invoice.attachment.contract": "اتفافية",
	"invoice.attachment.order": "ترتيب",
	"invoice.attachment.receipt": "إيصال",
	"invoice.attachment.oth": "آخر",
	"order.attachment.contract": "اتفافية",
	"order.attachment.order": "ترتيب",
	"order.attachment.receipt": "إيصال",
	"order.attachment.oth": "آخر",
	"attachment.name": "اسم",
	"attachment.upload": "تحميل",
	"attachment.cancel": "يلغي",
	completeerrors: "إكمال الأخطاء",
	completeerrorsphrase: "المهمة لا تزال تحتوي على أخطاء",
	warnings: "تحذيرات",
	ruleerrors: "أخطاء القواعد",
	checkerrors: "تحقق من الأخطاء",
	completetask: "أكمل المهمة",
	correcterrors: "تصحيح الأخطاء",
	orderDeleteMessageMultiple: "هل أنت متأكد من أنك تريد حذف النظام (الطلبات) المحدد؟",
	"messenger.addComment": "تعليق",
	"messenger.postComment": "ضف تعليق",
	EXPORT_IDOC: "idoc تصدير",
	lockTraining: "إلغاء التعلم",
	unlockTraining: "تنشيط التعلم",
	"accountingLines.costCenter": "مركز تقييم الكلفة",
	"accountingLines.paymentMethod": "طريقة السداد",
	"accountingLines.glAccounts": "حساب GL",
	"accountingLines.description": "وصف",
	"accountingLines.sign": "عملية",
	"accountingLines.amount": "كمية",
	"accountingLines.addButton": "اضافة سطر محاسبة",
	"accountingLines.summary": "خطوط المحاسبة غير متوازنة",
	"invoice.digital.extract": "مستخرج من الفاتورة الإلكترونية",
	"invoice.digital": "الفاتورة الإلكترونية",
	"invoice.digital.preview": "PDF العرض قير متاح : لا تحتوي هذه الفاتورة الإلكترونية على ملف",
	"tooltip.action.export": "تصدير الى",
	"invoice.digital.about": "حول الفاتورة",
	"invoice.digital.type": "نوع الفاتورة",
	"invoice.digital.signature.validity": "صلاحية التوقيع",
	"invoice.digital.format.compliance": "تنسيق التوافق",
	"invoice.digital.en16931.compliance": "الامتثال لمعيار EN 16931",
	"invoice.digital.en16931.rules.violated": "تم انتهاك عدد قواعد EN 16931",
	"invoice.digital.chorus.pro.compliance": "امتثال قواعد عمل Chorus Pro",
	"invoice.digital.chorus.pro.rules.violated": "تم انتهاك عدد قواعد الكورس الاحترافية",
	"invoice.digital.readmore": "قراءة المزيد حول الفاتورة",
	"invoice.go.to": "عرض الفاتورة",
	"invoiceNo.must.be.present": "يجب أن يكون رقم الفاتورة موجودا",
	"supplier.must.be.present": "يجب أن يكون المورد حاضرا",
	"issueDate.must.be.present": "يجب أن يكون تاريخ الإصدار موجودا",
	"total.must.be.present": "يجب أن يكون المبلغ الإجمالي موجودا",
	"currency.must.be.present": "يجب أن تكون العملة موجودة",
	"supplier.name.must.be.present": "يجب أن يكون لدى المورد اسم شركة",
	"supplier.siret.must.be.present": "يجب أن يكون معرّف شركة المورد موجودًا",
	"supplier.vatNumber.must.be.present": "يجب أن يكون رقم ضريبة القيمة المضافة للمورد موجودًا",
	"supplier.country.must.be.present": "يجب أن يكون لدى المورد رمز البلد",
	"designation.must.be.present": "يجب أن يتضمن سطر الفاتورة اسم المنتج",
	"quantity.must.be.present": "يجب أن يتضمن بند الفوترة كمية",
	"vatRate.must.be.present": "يجب أن يتضمن بند الفوترة معدل ضريبة القيمة المضافة",
	"price.must.be.present": "يجب أن يتضمن سطر الفاتورة سعر الوحدة باستثناء ضريبة القيمة المضافة",
	"Disputed Invoices": "الفواتير المتنازع عليها",
	"invoice.export.entry_account_excel": "القيد المحاسبي Excel",
	"invoice.export.error": "خطأ أثناء تصدير الفاتورة",
	"order.export.error": "خطأ أثناء تصدير الطلب",
	CREATE_ACTION_LITIGE: "إنشاء الفاتورة المتنازع عليها",
	REINTEGRATE_ACTION_LITIGE: "إعادة دمج الفاتورة المتنازع عليها",
	REINTEGRATE_ACTION: "إعادة دمج الفاتورة ",
	REJECT_ACTION_LITIGE: "رفض الفاتورة",

	"invoice.litige.already.in.open.status": "هذه الفاتورة متنازع عليها حاليا",
	"confirmation.reintegrate": "تريد حقًا إعادة دمج هذه الفاتورة ",
	"confirmation.nextStatus": "هل أنت متأكد أنك تريد تغيير حالة الفاتورة إلى",
	"confirmation.relance": "هل أنت متأكد أنك تريد إعادة تشغيل الاستخراج؟",
	"confirmation.ExportStatus": '  "هل أنت متأكد أنك تريد تغيير حالة الفاتورة إلى "يصدر ',
	"file.scan.failed": "فشل فحص مكافحة الفيروسات",
	"file.infected": "ملف مصاب",
	logoScanFailed: "فشل فحص مكافحة الفيروسات الشعار",
	logoInfected: "الشعار المصاب بالعدوى",
};
