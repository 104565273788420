import Grid from "@material-ui/core/Grid";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSettingsWithCode } from "../../../redux/actions/users";
import FilterLitige from "./Components/FilterLitige";
import InvoicesLitigeTable from "./Components/invoicesLitigeTable";
import useStyles from "./style";

function InvoicesLitigeList() {
	const classes = useStyles();
	const dispatch = useDispatch();

	const [selected, setSelected] = React.useState([]);

	const [filterLitigeData, setFilterLitigeData] = useState({
		invoiceNoOrSupplierCode: "",
		status: [],
		pageNo: 0,
		pageSize: 5,
		sortBy: "",
	});

	const idInvoicesLitige = useSelector(
		({ requests }) =>
			requests.queries.LIST_FETCH_INVOICE_LITIGE &&
			requests.queries.LIST_FETCH_INVOICE_LITIGE.data &&
			requests.queries.LIST_FETCH_INVOICE_LITIGE.data.content.map(
				(invoicelitige) => invoicelitige.id
			)
	);

	const formatSettings = (data) =>
		data && data.reduce((acc, { code, value }) => ({ ...acc, [code]: parseInt(value, 10) }), {});

	const settings = useSelector(({ requests }) =>
		get(requests, "queries.FETCH_SETTINGS_WITH_CODE_INVOICE.data")
	);
	const handleSelectedAll = (checked) => {
		setSelected(checked ? idInvoicesLitige : []);
	};

	useEffect(() => {
		if (isEmpty(settings)) {
			dispatch(fetchSettingsWithCode("INVOICE"));
		}
	}, [filterLitigeData]);

	return (
		<>
			<Grid spacing={2} xs={12} container className={classes.firstGrid}>
				<Grid item container xs={12}>
					<FilterLitige
						settings={settings}
						selected={selected}
						setSelected={setSelected}
						handleSelectedAll={handleSelectedAll}
						filterLitigeData={filterLitigeData}
						setFilterLitigeData={setFilterLitigeData}
					/>
				</Grid>
				<Grid item xs={12}>
					<InvoicesLitigeTable
						settings={formatSettings(settings)}
						selected={selected}
						setSelected={setSelected}
						filterLitigeData={filterLitigeData}
						setFilterLitigeData={setFilterLitigeData}
					/>
				</Grid>
			</Grid>
		</>
	);
}

export default InvoicesLitigeList;
