import React, { useState } from "react";
import { Button, FormControl, TextField, Grid } from "@material-ui/core";
import PopupModal from "administration/component/PopupModal";
import { toast } from "utils";
import { cloneWorkflow } from "administration/redux/actions";

const CloneWorkflowPopup = ({
	isOpen,
	setIsOpen,
	t,
	classes,
	dispatch,
	workflowCode,
	fetchCurrentWorkflow,
}) => {
	const [labelName, setLabelName] = useState("");
	const [description, setDescription] = useState("");

	const handleCloneWorkflow = () => {
		dispatch(cloneWorkflow(workflowCode, labelName, description)).then((res) => {
			if (res.status === 200) {
				fetchCurrentWorkflow();
				toast.success(t("workflow.clone.success"));
			} else {
				toast.error(t("workflow.clone.error"));
			}
			setIsOpen(false);
		});
	};
	return (
		<>
			<PopupModal
				openPopup={isOpen}
				setOpenPopup={() => setIsOpen(false)}
				dialogTitle={t("workflow.clone.newName")}
				dialogContents={
					<>
						<FormControl style={{ width: "100%" }} className={classes.formControl}>
							<TextField
								label={t("workflow.clone.code")}
								value={t(labelName)}
								onChange={(event) => {
									setLabelName(event.target.value);
								}}
							/>
						</FormControl>
						<FormControl style={{ width: "100%" }} className={classes.formControl}>
							<TextField
								multiline
								rows={3}
								label={t("workflow.clone.description")}
								value={t(description)}
								onChange={(event) => {
									setDescription(event.target.value);
								}}
							/>
						</FormControl>
					</>
				}
				dialogActions={
					<Grid container item xs={12} justify="flex-end">
						<Button color="primary" onClick={handleCloneWorkflow}>
							{t("workflow.clone.save")}
						</Button>
					</Grid>
				}
			/>
		</>
	);
};

export default CloneWorkflowPopup;
