import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  firstGrid: {
    paddingTop: "1%",
    width: "calc(100% + 48px)",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  tableContainer: {
    margin: 0,
    width: "900px",
    minHeight: "350px",
    maxHeight: "350px",
    overflow: "auto",
  },
  rejectButton: {
    backgroundColor: "#d63031",
    color: "white",
  },
  space: {
    marginTop: "10px",
  },
  mandatory: {
    color: "#D11",
  },
  dropzone: {
    background: "transparent",
    maxWidth: "100%",
    minHeight: "80%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    "& .MuiDropzoneArea-icon": {
      color: theme.palette.primary.main,
      opacity: 0.8,
    },
  },
  headerZone: {
    width: "100%",
  },
  container: {
    padding: "25px 0 0 0",
  },
  blackColor: {
    color: "black",
  },
  resize: {
    fontSize: "0.875rem",
  },
  header: {
    margin: "0 0 0 100px",
  },
  headerAction: {
    marginTop: theme.spacing(4)
  },
  tabsContainer: {
    height: "800px",
    maxHeight: "800px",
  },
  invoiceOrderTabs: {
    minWidth: "500px",
  },
  tabInvoiceContainer: {
    height: "600px",
    minWidth: "500px",
  },
  tabInvoice: {
    margin: 0,
    maxHeight: "360px",
    overflow: "auto",
    minHeight: "360px",
    boxShadow: "0 1px 15px 1px rgba(69,65,78,.08)",
    backgroundColor: theme.palette.background.paper,
  },
  tabCommand: {
    margin: 0,
    maxHeight: "200px",
    minHeight: "200px",
    boxShadow: "0 1px 15px 1px rgba(69,65,78,.08)",
    backgroundColor: theme.palette.background.paper,
  },
  orderTab: {
    margin: 0,
    maxHeight: "200px",
    boxShadow: "0 1px 15px 1px rgba(69,65,78,.08)",
    backgroundColor: theme.palette.background.paper,
    padding: "10px 0",
  },
  tableCell1: {
    width: "60px",
    minWidth: "60px",
    fontSize: "0.8rem",
  },
  tableCell2: {
    width: "40px",
    minWidth: "40px",
    fontSize: "0.8rem",
  },
  tableCell3: {
    width: "220px",
    minWidth: "220px",
    fontSize: "0.8rem",
  },
  tableCell4: {
    width: "100px",
    minWidth: "100px",
    fontSize: "0.8rem",
  },
  cardBilling: {
    padding: "-16px",
    maxHeight: "416px",
    minHeight: "416px",
    minWidth: "500px",
    boxShadow: "0 1px 15px 1px rgba(69,65,78,.08)",
    backgroundColor: theme.palette.background.paper,
    margin: "24px 0 0 0",
  },
  headerBillingCard: {
    height: "50px",
    paddingLeft: "16px",
  },
  previewImg: {
    width: "100%",
    maxWidth: "100%",
    maxHeight: "800px",
    height: "800px",
    boxShadow: "0 1px 15px 1px rgba(69,65,78,.08)",
  },
  detailsContent: {
    height: "870px",
    minWidth: "800px",
  },
  rectifyHover: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  emptyInput: {
    // backgroundColor: "#C0C0C0",
    backgroundImage: `url(
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAIAAAACUFjqAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjQ3MTY4MTFCNUE3MTExRTdBMjNFRDU2NzRENUYwRDg1IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjQ3MTY4MTFDNUE3MTExRTdBMjNFRDU2NzRENUYwRDg1Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6NDcxNjgxMTk1QTcxMTFFN0EyM0VENTY3NEQ1RjBEODUiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NDcxNjgxMUE1QTcxMTFFN0EyM0VENTY3NEQ1RjBEODUiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz419SIpAAAAKUlEQVR42mJ8/fo1AxIQERFB5jIx4AU0lWb8//8/Mv/NmzeDxWkAAQYA6M4I0ebkMicAAAAASUVORK5CYII="
    )`,
  },
  rectifyHeader: {
    margin: "15px 0 0 58px",
  },
  invoiceInput: {
    margin: "15px 0 15px -20px",
    // backgroundColor: "white",
  },
  alignStatus: {
    marginRight: "30px",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },

  alert: {
    padding: "5px 15px",
    marginBottom: "5px",
  },

  approveContainer: {
    padding: "16px",
  },
  historyContainer: {
    margin: 0,
    maxWidth: "100%",
    minWidth: "100%",
    minHeight: "800px",
    maxHeight: "800px",
    overflow: "auto",
  },
  selectAll: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    marginTop: "24px",
  },
  marginTop: "24px",
  linePadding: {
    padding: "0px 16px",
  },
  title: {
    textDecoration: 'underline',
  },
  backBtn: {
    margin: theme.spacing(2),
  },

  cardActions: {
    justifyContent: "center",
    margin: 0,
  },
  button: {
    "&:hover": {
      backgroundColor: "#D5D5D5",
      color: "white",
    }
  },
  toolbar: {
    paddingRight: 24,
    justifyContent: "space-between",
  },
  appBar: {
    borderTopLeftRadius: 5,
    width: "100%",
    zIndex: theme.zIndex.drawer + 1,
  },

  menuButton: {
    marginRight: 36,
  },

  rootAppBar: {
    flexGrow: 1,
  },
}));
