import React from "react";
import PopupModal from "administration/component/PopupModal";
import {
	Button,
	Grid,
	FormControl,
	InputLabel,
	Select,
	Input,
	MenuItem,
	ListItemText,
	List,
	ListSubheader,
	ListItem,
	Divider,
	Typography,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";

import ConfirmDialog from "components/ConfirmDialog";

const ChangeStatusForm = ({
	t,
	isOpen,
	setIsOpen,
	updateInvoicesStatus,
	oldStatus,
	statusList,
	setNewInvoiceStatus,
	newInvoicesStatus,
	invoices,
	classes,
}) => {
	const { errors, handleSubmit, register, control } = useForm();
	const [confirmObject, setConfirmObject] = React.useState({
		message: "",
		openConfirm: false,
		cancel: false,
		isLoading: false,
	});

	const handleNewStatusChange = (event) => {
		setNewInvoiceStatus(event.target.value);
	};

	const handleConfirm = () => {
		setConfirmObject({ ...confirmObject, isLoading: true });
		updateInvoicesStatus(oldStatus, newInvoicesStatus, invoices).then(() => {
			setConfirmObject({ ...confirmObject, isLoading: false });
		});
	};

	const handleCancel = () => {
		setConfirmObject({ ...confirmObject, openConfirm: false });
	};

	const onSubmit = (data) => {
		setNewInvoiceStatus(data?.newInvoiceStatus);
		setConfirmObject({
			message: t("workflow.updateStatus.warning"),
			openConfirm: true,
		});
	};

	return (
		<>
			<PopupModal
				openPopup={isOpen}
				setOpenPopup={() => setIsOpen(false)}
				dialogTitle={t("workflow.changeInvoicesStatus")}
				dialogContents={
					<form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
						<Grid container xs={12} direction="row">
							<Grid container item xs={5}>
								<List
									style={{
										width: "100%",
										maxWidth: 360,
										bgcolor: "background.paper",
										position: "relative",
										overflow: "auto",
										maxHeight: 400,
										"& ul": { padding: 0 },
									}}
									subheader={<li />}
								>
									<ListSubheader>{t("Invoices in old status")}</ListSubheader>
									{invoices &&
										invoices.map((invoice, index) => (
											<li key={`section-${index}`}>
												<ul>
													<ListItem key={`item-${index}-${invoice.id}`}>
														<ListItemText primary={`ID ${invoice.id}`} />
													</ListItem>
												</ul>
											</li>
										))}
								</List>
							</Grid>
							<Grid container xs={1}>
								<Divider orientation="horizontal" style={{ width: "2px", height: "100%" }} />
							</Grid>

							<Grid container item xs={5}>
								<Grid item xs={12} justify="center">
									<FormControl fullWidth>
										<InputLabel id="integrated">{t("New Status")}</InputLabel>
										<Controller
											as={
												<Select
													labelId="newInvoiceStatus"
													id="newInvoiceStatus"
													name="newInvoiceStatus"
													value={newInvoicesStatus}
													input={<Input />}
													onChange={handleNewStatusChange}
													renderValue={(selected) => t(selected)}
													{...register("newInvoiceStatus", {
														required: t("field_required"),
													})}
												>
													{statusList &&
														statusList
															.filter((status) => status.code !== oldStatus)
															.map((status, index) => (
																<MenuItem key={index} value={status?.code}>
																	<ListItemText primary={t(status?.label)} />
																</MenuItem>
															))}
												</Select>
											}
											name="newInvoiceStatus"
											rules={{ required: t("field_required") }}
											control={control}
											value={newInvoicesStatus}
											defaultValue=""
										/>
									</FormControl>
									<Typography className={classes.validation_error}>
										{errors.newInvoiceStatus?.message}
									</Typography>
								</Grid>
							</Grid>
							<Grid container item xs={12} justify="flex-end">
								<Button
									onClick={() => setIsOpen(false)}
									variant="outlined"
									color="primary"
									className={classes.actionButton}
								>
									{t("cancel")}
								</Button>
								<Button
									variant="contained"
									type="submit"
									className={classes.actionButton}
									color="secondary"
								>
									{t("update all")}
								</Button>
							</Grid>
						</Grid>
					</form>
				}
			/>
			<ConfirmDialog
				message={confirmObject.message}
				open={confirmObject.openConfirm}
				isLoading={confirmObject.isLoading}
				onConfirm={handleConfirm}
				onCancel={handleCancel}
			/>
		</>
	);
};

export default ChangeStatusForm;
