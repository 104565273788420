import React from "react";
import DashboardIcon from "@material-ui/icons/DashboardOutlined";
import PeopleIcon from "@material-ui/icons/PeopleOutline";
import LocalShippingOutlined from "@material-ui/icons/LocalShippingOutlined";
import InsertDriveFileOutlined from "@material-ui/icons/InsertDriveFileOutlined";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import ShoppingCartOutlined from "@material-ui/icons/ShoppingCartOutlined";
import InvoicesLitigeList from "pages/InvoicesLitige/InvoicesLitigeList";
import GavelOutlinedIcon from "@material-ui/icons/GavelOutlined";

import TuneIcon from "@material-ui/icons/Tune";
import Orders from "pages/Orders/List";
import Home from "../pages/Home";
import ClientsDashboard from "../pages/Home2";
import List from "../pages/Invoices/List";
import { List as SuppliersList } from "../pages/Suppliers/List";
import ClientList from "../pages/Clients/List";
import { UsersHome } from "../administration/users";
import Roles from "../redux/constants/roles";
// import Messages from "../pages/Messages";

export default {
	invoices: [
		{
			index: 1,
			name: "Dashboard",
			path: "/",
			component: () => <Home />,
			icon: <DashboardIcon />,
			hasAccess: [Roles.user, Roles.manager],
		},
		{
			index: 2,
			name: "Administration",
			path: "/administration/invoice/commercial-organization",
			component: () => <UsersHome />,
			icon: <TuneIcon />,
			hasAccess: [Roles.admin],
		},
		{
			index: 3,
			name: "Invoices",
			path: "/invoices",
			component: () => <List />,
			icon: <InsertDriveFileOutlined />,
			hasAccess: [Roles.user, Roles.manager],
		},
		{
			index: 4,
			name: "Disputed Invoices",
			path: "/InvoicesLitige",
			component: () => <InvoicesLitigeList />,
			icon: <GavelOutlinedIcon />,
			hasAccess: [Roles.user, Roles.manager],
		},
		{
			index: 5,
			name: "Suppliers",
			path: "/supplier",
			component: () => <SuppliersList />,
			icon: <PeopleIcon />,
			hasAccess: [Roles.user, Roles.manager],
		},
		{
			index: 6,
			name: "supplier",
			path: "/",
			component: () => <SuppliersList />,
			icon: <LocalShippingOutlined />,
			hasAccess: [Roles.supplier],
		},
	],
	orders: [
		{
			index: 1,
			name: "Dashboard",
			path: "/home",
			component: () => <ClientsDashboard />,
			icon: <DashboardIcon />,
			hasAccess: [Roles.purchase_user, Roles.manager],
		},
		{
			index: 2,
			name: "Administration",
			path: "/administration/order/commercial-organization",
			component: () => <UsersHome />,
			icon: <TuneIcon />,
			hasAccess: [Roles.admin],
		},
		{
			index: 3,
			name: "orders",
			path: "/orders",
			component: () => <Orders />,
			icon: <ShoppingCartOutlined />,
			hasAccess: [Roles.purchase_user, Roles.manager],
		},
		{
			index: 4,
			name: "clients",
			path: "/client",
			component: () => <ClientList />,
			icon: <PeopleAltOutlinedIcon />,
			hasAccess: [Roles.purchase_user, Roles.manager],
		},
		{
			index: 4,
			name: "client",
			path: "/",
			component: () => <ClientList />,
			icon: <PeopleAltOutlinedIcon />,
			hasAccess: [Roles.client],
		},
	],
};
