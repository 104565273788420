export default {
	countSuppliers: "Número total de proveedores",
	searchSupplier: "Encuentra un proveedor",
	name: "Nombre",
	contact: "",
	principalContact: "Contacto principal",
	outstandingInvoices: "Facturas pendientes",
	addSupplier: "Agregar proveedor",
	"": "",
	associatedContact: "Contactos asociados",
	financialInformation: "Información financiera",
	legalForm: "Forma jurídica",
	return: "Atras",
	account_journal_auxiliary: "Auxiliar",
	account_journal_number: "Cuenta contable",
	invoice_uploaded_success:
		"La factura se ha agregado correctamente. Aparecerá en la lista si eres el emisor de esta factura.",
	"limited.liability.company": "SARL",
	"single.person.limited.liability": "EURL",
	"public.limited.company": "SA",
	"general.partnership": "SNC",
	"simplified.joint.stock.company": "SAS",
	"single.person.simplified.joint.stock.company": "SASU",
	other: "Otra",
	companySiret: "Número SIRET",
	vatNumber: "Número de valor agregado",
	codeNaf: "Código NAF",
	email: "Correo electrónico",
	address: "Dirección",
	fax: "Fax",
	socialCapital: "Capital social",
	function: "Posición",
	phone: "Teléfono",
	bank: "Banco",
	iban: "IBAN",
	bic: "BIC",
	rib: "RIB",
	noPrincipalContact: "No hay contacto principal disponible",
	details: "Detalles",
	"upload.instructions": "Instrucciones",
	"upload.instructions1": "Complete la hoja de Excel con la información del proveedor",
	"upload.instructions2":
		'Complete la columna "Logotipo" con el nombre del archivo del logotipo. (Ejemplo: logofilename.jpg)',
	"upload.instructions3": "Asegúrese de que todos los campos sean de tipo TEXTO",
	"upload.instructions4":
		"Coloque la hoja de Excel completa y los logotipos de proveedores en la misma carpeta",
	"upload.instructions5": "Comprime esta carpeta:",
	"upload.instructions51":
		'Windows: haga clic con el botón derecho en la carpeta, luego elija "Enviar a " y luego haga clic en "Carpeta comprimida "',
	"upload.instructions52":
		"MAC: haz Control-clic en él o tócalo con dos dedos, luego elige Comprimir en el menú emergente.",
	"upload.instructions6":
		"Cargue la carpeta comprimida en el portal o arrástrela y suéltela en el área dedicada",
	"upload.instructions7": 'Haga clic en "Descargar proveedores"',
	"supplier.modify": " Modificar proveedor",
	"invoice.attachment.label": "Fraseología",
	"invoice.attachment.extension": "Tipo de archivo",
	"invoice.attachment.type": "Tipo de Documento",
	"invoice.attachment.creationDate": "Fecha Agregada",
	"invoice.attachment.size": "Tamaño",
	field_required: "Requerida",
	"add attach": "Añadir un adjunto",
	"attachment.upload": "Añadir el archivo",
	"attachment.upload.success": "Archivo subido correctamente",
	"attachment.upload.error": "No se pudo cargar el archivo",
	"Add your Attachment": "Agregue su archivo adjunto",
	"invoice.attachment.delete.confirm": "¿Está seguro de que desea eliminar este archivo adjunto?",
	"attachment.delete.success": "Archivo adjunto eliminado correctamente",
	"workflow.invoice.exists": "Eliminación de estado de la factura",
	"workflow.invoice.exists.message":
		"No se puede eliminar el estado de la factura.Una o más factura están en este estado.",
	"workflow.changeInvoicesStatus": "Cambiar de estado de facturas",
	"workflow.updateStatus.warning":
		"¿Está seguro de que desea actualizar estos estatus de facturas? Esta acción también le ahorrará el flujo de trabajo y no se puede deshacer.",
	"supplier.lockedTraining": "¿Bloqueada?",
	"workflow.load.error": "Error al recuperar el flujo de trabajo",
	"workflow.toolTip.addStatus": "Añadir estado",
	"workflow.toolTip.save": "Ahorrar",
	"workflow.toolTip.clone": "Clon",
	"workflow.toolTip.undoCHanges": "Cancelar cambios",
	"workflow.clone.newName": "Nuevo flujo de trabajo",
	"workflow.clone.code": "Apellido",
	"workflow.clone.description": "Descripción",
	"workflow.clone.save": "Ahorrar",
	"workflow.clone.success": "Flujo de trabajo clonado con éxito!",
	"workflow.clone.error": "Se produjo un error al clonar el flujo de trabajo.",
	searchClient: "Encontrar un cliente",
	"invoice.transition.reintegrate": "Reintegrar factura",
};
