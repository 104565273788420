import React, { Fragment } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import { useTranslation } from "react-i18next";
import get from "lodash/get";

export const DetailsClientContact = ({ contact }) => {
	const { t } = useTranslation();
	return (
		<Grid container xs={12} direction="column" spacing={4}>
			{contact ? (
				<>
					<Grid container item xs={12} spacing={4}>
						<Grid item xs={6}>
							<Typography variant="subtitle1" color="primary">
								{t("name")}
								<br />
								<Typography variant="body2" color="primary">
									{"".concat(
										t(get(contact, "civility")),
										" ",
										get(contact, "firstName") ?? "",
										" ",
										get(contact, "lastName") ?? ""
									)}
								</Typography>
								<Divider />
							</Typography>
						</Grid>
						<Grid item xs={6}>
							<Typography variant="subtitle1" color="primary">
								{t("function")}
								<br />
								<Typography variant="body2" color="primary">
									{get(contact, "function")}
								</Typography>
								<Divider />
							</Typography>
						</Grid>
					</Grid>
					<Grid container item xs={12} spacing={4}>
						<Grid item xs={6}>
							<Typography variant="subtitle1" color="primary">
								{t("email")}
								<br />
								<Typography variant="body2" color="primary">
									{get(contact, "email")}
								</Typography>
								<Divider />
							</Typography>
						</Grid>
						<Grid item xs={6}>
							<Typography variant="subtitle1" color="primary">
								{t("phone")}
								<br />
								<Typography variant="body2" color="primary">
									{get(contact, "phone")}
								</Typography>
								<Divider />
							</Typography>
						</Grid>
					</Grid>
				</>
			) : (
				<Typography variant="body2" color="primary">
					{t("noPrincipalContact")}
				</Typography>
			)}
		</Grid>
	);
};

export default DetailsClientContact;
