import React from "react";
import { Divider, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import get from "lodash/get";

export const DetailsFinancialInformations = ({ supplier }) => {
    const { t } = useTranslation();
    return (
        <Grid container xs={12} spacing={4}   >
            <Grid container item xs={12} spacing={4} >
                <Grid item xs={6}>
                    <Typography variant="subtitle1" color="primary"  >
                        {t("bank")}
                        <br />
                        <Typography variant="body2" color="primary"  >
                            {get(supplier, "bank")}
                        </Typography>
                        <Divider />
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="subtitle1" color="primary"  >
                        {t("iban")}
                        <br />
                        <Typography variant="body2" color="primary"  >
                            {get(supplier, "iban")}
                        </Typography>
                        <Divider />
                    </Typography>
                </Grid>
            </Grid>

            <Grid container item xs={12} spacing={4}   >
                <Grid item xs={6}>
                    <Typography variant="subtitle1" color="primary"  >
                        {t("bic")}
                        <br />
                        <Typography variant="body2" color="primary"  >
                            {get(supplier, "bic")}
                        </Typography>
                        <Divider />
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="subtitle1" color="primary"  >
                        {t("rib")}
                        <br />
                        <Typography variant="body2" color="primary"  >
                            {get(supplier, "rib")}
                        </Typography>
                        <Divider />
                    </Typography>
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={4}   >
                <Grid item xs={6}>
                    <Typography variant="subtitle1" color="primary"  >
                        {t("account_journal_auxiliary")}
                        <br />
                        <Typography variant="body2" color="primary"  >
                            {get(supplier, "accountJournalAuxiliary")}
                        </Typography>
                        <Divider />
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="subtitle1" color="primary"  >
                        {t("account_journal_number")}
                        <br />
                        <Typography variant="body2" color="primary"  >
                            {get(supplier, "accountJournalNumber")}
                        </Typography>
                        <Divider />
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default DetailsFinancialInformations;
