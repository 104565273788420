import React, { Fragment } from "react";
import { connect, useSelector } from "react-redux";
import { DropzoneArea } from "material-ui-dropzone";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { get } from "lodash";
import clsx from "clsx";
import { useHistory } from "react-router";
import { toast } from "utils";
import useStyles from "./style";
import DashboardCard from "../../../../components/DashboardCard/index";
import { createInvoice, fetchInvoices } from "../../../../redux/actions/invoices/index";

const acceptedInvoices = ["image/*", "application/pdf", "application/xml", ".xml"];

function AddInvoice(props) {
	const classes = useStyles();
	const { t } = useTranslation();
	const history = useHistory();

	const [files, setFiles] = React.useState([]);

	const onloadFiles = (filesParams) => {
		setFiles(filesParams);
		if (typeof props.changeFile !== "undefined") {
			props.changeFile(filesParams);
		}
	};
	const currentUser = useSelector(({ requests }) =>
		get(requests, "queries.FETCH_CURRENT_USER.data")
	);
	const onDeleteFile = (file) => {
		files.splice(files.indexOf(file), 1);
		toast.info(t("fileRemovedMsg", { filename: file.name }), { autoClose: 3000 });
	};
	const onDrop = () => {
		// toast.success(t("fileAddedMsg"), { autoClose: 3000 });
	};
	const onclick = () => {
		const filesNotPersisted = [];
		files.forEach((element, i) => {
			props.createInvoice(element, false).then((response) => {
				props.fetchInvoices();
				if (response != null) {
					if (response.status !== 201) {
						filesNotPersisted.push(element.name);
					} else if (
						response.status === 201 &&
						currentUser &&
						get(currentUser, "commercialOrganizationId")
					) {
						toast.success(t("invoice_uploaded_success"));
					}
					if (i === files.length - 1) {
						if (filesNotPersisted.length === files.length) {
							toast.error(`${t("InvoicesNotCreatedMessage")} : ${filesNotPersisted.join(", ")}`, {
								autoClose: 3000,
							});
						} else {
							history.push({ pathname: "/invoices", state: { files: filesNotPersisted } });
						}
					}
				}
			});
		});
	};
	return (
		<DashboardCard
			{...props} // eslint-disable-line
		>
			<Grid
				container
				direction="row"
				justify="center"
				alignItems="center"
				xs={12}
				spacing={2}
				className={clsx("h-100", "w-100")}
			>
				{props.settings.INVOICE_MAXIMUM_SIZE && (
					<DropzoneArea
						filesLimit={30}
						maxFileSize={50000000}
						showFileNames
						useChipsForPreview
						dropzoneText={
							<>
								{t("Add your Invoice")}
								<u>{` ${t("browse")}`}</u>
							</>
						}
						dropzoneClass={classes.dropzone}
						dropzoneParagraphClass={classes.text}
						onChange={onloadFiles}
						onDelete={onDeleteFile}
						onDrop={onDrop}
						acceptedFiles={acceptedInvoices}
						showAlerts={false}
					/>
				)}
				<Button
					variant="contained"
					color="secondary"
					size="medium"
					className={classes.dropzoneActions}
					onClick={onclick}
				>
					{t("Upload")}
				</Button>
			</Grid>
		</DashboardCard>
	);
}
const mapDispatchToProps = {
	createInvoice,
	fetchInvoices,
};
export default connect(null, mapDispatchToProps)(AddInvoice);
