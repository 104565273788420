import React, { useEffect } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Chip from "@material-ui/core/Chip";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@material-ui/core";
import useStyles from "./style";
import { fetchLastThreeOrders } from "../../../../redux/actions/orders/index";
import { initFilterOrder, updateFilterOrder } from "../../../../redux/orderFilterReducer";
import { invoiceStatusColors } from "../../../../utils/index";
import CircularLoader from "../../../../components/Loaders/CircularLoader";

function LastActions(props) {
	const classes = useStyles();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();

	const lastOrders = useSelector(
		({ requests }) =>
			requests.queries.FETCH_LAST_ACTIONS_ORDER && requests.queries.FETCH_LAST_ACTIONS_ORDER.data
	);

	useEffect(() => {
		dispatch(fetchLastThreeOrders());
	}, []);
	const onClickRow = (id) => {
		dispatch(initFilterOrder());
		history.push(`orders/details/${id}`);
	};
	const onClickShowAll = () => {
		dispatch(
			updateFilterOrder({
				orderNoOrClientLabel: "",
				status: [],
				startDate: null,
				endDate: null,
				sortBy: "updatedAt",
				pageNo: 0,
				pageSize: 10,
			})
		);
		history.push("/orders", { fromDashboard: true });
	};
	return (
		<Card
			className="h-100"
			{...props} // eslint-disable-line
		>
			<CardHeader
				title={t("Last actions")}
				titleTypographyProps={{
					variant: "subtitle1",
					color: "primary",
				}}
				classes={{
					action: classes.select,
				}}
				action={
					<Button
						align="right"
						color="secondary"
						className={classes.underlineText}
						onClick={() => onClickShowAll()}
					>
						{t("Show all")}
					</Button>
				}
			/>
			<CardContent className="p-0">
				<Table aria-label="Actions Table">
					<TableHead>
						<TableRow>
							<TableCell>{t("orderNo")}.</TableCell>
							<TableCell align="center">{t("Status")}</TableCell>
							<TableCell align="center">{t("updatedAt")}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{lastOrders ? (
							lastOrders.map((row, i) => (
								<TableRow
									hover
									key={i}
									onClick={() => onClickRow(row.id)}
									className={classes.hover}
								>
									<TableCell component="th" scope="row">
										{row.orderNo}
									</TableCell>
									<TableCell align="center">
										<Chip
											label={t(row.status.label)}
											size="small"
											style={{
												backgroundColor: invoiceStatusColors[row.status.code],
												color: "white",
											}}
										/>
									</TableCell>
									<TableCell align="center">{row.updatedAt}</TableCell>
								</TableRow>
							))
						) : (
							<CircularLoader />
						)}
					</TableBody>
				</Table>
			</CardContent>
		</Card>
	);
}

export default LastActions;
