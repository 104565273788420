import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import { useTranslation } from "react-i18next";

import { invoiceStatusColors } from "../../../../../utils";

function MultiLines({ rows }) {
	const { t } = useTranslation();

	return (
		<Grid container direction="column">
			{rows &&
				rows.map((row) => (
					<Grid item xs align="center">
						{row.status ? (
							<Chip
								label={t(row.value)}
								size="small"
								style={{
									backgroundColor: invoiceStatusColors[row.value],
									color: "white",
								}}
							/>
						) : (
							<Typography variant={row.variant} color={row.color} align={row.align}>
								{row.value}
							</Typography>
						)}
					</Grid>
				))}
		</Grid>
	);
}

export default MultiLines;
