import React from "react";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useForm } from "react-hook-form";
import { toast } from "utils";
import { CircularProgress } from "@material-ui/core";

const motifs = [
	{
		code: "ABSENTE",
		label: "order.absent",
	},
	{
		code: "AMOUNT_GREATER_THAN_GOODS",
		label: "amount.greate.thang.goods",
	},
	{
		code: "DELIVERY_ABSENT",
		label: "delivery.absent",
	},
	{ code: "OTHER", label: "Other" },
];

const ApprovalDialog = ({ open, setOpen, ...props }) => {
	const { t } = useTranslation();

	const { errors, handleSubmit, register } = useForm();
	const [confirmObject, setConfirmObject] = React.useState({
		message: t("ApplyForApproval"),
		cancel: false,
		isLoading: false,
	});

	const handleCloseApprovalPopup = () => {
		setConfirmObject({ ...confirmObject, isLoading: false });
	};

	const onSubmit = (data) => {
		setConfirmObject({ ...confirmObject, isLoading: true });
		const motif = motifs.find((motifParam) => t(motifParam.label) === data.motif);
		const id = props.orderPersisted && props.orderPersisted.id;
		props.createOrderApproval(
			id,
			{
				email: data.email,
				reason: t(motif.label),
				comment: data.comment,
			},
			() => {
				handleCloseApprovalPopup();
			},
			() => {
				handleCloseApprovalPopup();
				toast.error(t("this operation is failed"));
			}
		);
	};

	return (
		<Dialog
			open={open}
			onClose={handleCloseApprovalPopup}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			fullWidth
		>
			<DialogTitle id="alert-dialog-title">{t(confirmObject.message)}</DialogTitle>
			<form onSubmit={handleSubmit(onSubmit)}>
				<DialogContent>
					<TextField
						inputRef={register}
						name="email"
						id="contact_approval_email"
						label={t("email")}
						fullWidth
						required
						type="email"
					/>
					<Autocomplete
						options={motifs}
						getOptionLabel={({ label = "" }) => t(label)}
						id="morif"
						renderInput={(params) => (
							<div>
								<TextField
									{...params} // eslint-disable-line
									inputRef={register({ required: true })}
									variant="standard"
									label={t("reason")}
									margin="normal"
									name="motif"
								/>
								{errors.motif && (
									<Typography variant="subtitle2" align="left" color="error">
										{t("this field is mandatory")}
									</Typography>
								)}
							</div>
						)}
					/>
					<TextField
						inputRef={register}
						name="comment"
						id="outlined-multiline-static"
						label={t("comment")}
						multiline
						fullWidth
						rows={4}
						defaultValue=""
						variant="outlined"
					/>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleCloseApprovalPopup}
						color="secondary"
						disabled={confirmObject.isLoading}
						autoFocus
					>
						{t("cancel")}
					</Button>
					<Button type="submit" color="secondary" disabled={confirmObject.isLoading}>
						{confirmObject.isLoading && <CircularProgress size={14} />}&nbsp; {t("confirm")}
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
};

export default ApprovalDialog;
