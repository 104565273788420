import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import FileCopyRounded from "@material-ui/icons/FileCopyOutlined";
import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";
import DashboardCard from "../../../../components/DashboardCard/index";
import { currencyFormat } from "../../../../utils/index";
import { ordersTotalVATAmounts } from "../../../../redux/actions/orders/index";

function PaidOrder(props) {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const ordersTotalAmountsPaid = useSelector(
		({ requests }) =>
			requests.queries.ORDER_TOTAL_AMOUNT_PAID && requests.queries.ORDER_TOTAL_AMOUNT_PAID.data
	);
	const ordersTotalVatAmountsPaid = useSelector(
		({ requests }) =>
			requests.queries.ORDER_TOTAL_VAT_AMOUNT_PAID &&
			requests.queries.ORDER_TOTAL_VAT_AMOUNT_PAID.data
	);
	useEffect(() => {
		dispatch(ordersTotalVATAmounts(["EXPORTED"], "_PAID"));
	}, []);
	return (
		<DashboardCard
			title={t("order.exported")}
			icon={FileCopyRounded}
			{...props} // eslint-disable-line
		>
			<Grid container spacing={2} justify="center">
				<Grid item xs={6}>
					<Typography variant="h5" color="primary" gutterBottom align="center">
						{`${
							ordersTotalAmountsPaid ? currencyFormat(ordersTotalAmountsPaid) : currencyFormat(0)
						} €`}
					</Typography>
					<Typography variant="subtitle2" color="textSecondary" gutterBottom align="center">
						{t("Total amount of orders")}
					</Typography>
				</Grid>
				<Grid item xs={6} className="p-relative">
					<Divider orientation="vertical" absolute />

					<Typography variant="h5" color="primary" gutterBottom align="center">
						{`${
							ordersTotalVatAmountsPaid
								? currencyFormat(ordersTotalVatAmountsPaid)
								: currencyFormat(0)
						} €`}
					</Typography>
					<Typography variant="subtitle2" color="textSecondary" gutterBottom align="center">
						{t("Total VAT amount")}
					</Typography>
				</Grid>
			</Grid>
		</DashboardCard>
	);
}

export default PaidOrder;
