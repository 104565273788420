import { createSlice } from "@reduxjs/toolkit";

export const initialFilter = {
	orderNumberOrClientCode: "",
	status: [],
	startDate: null,
	endDate: null,
	pageNo: 0,
	pageSize: 10,
	selectAll: false,
	sortBy: "",
};

const filterListClients = createSlice({
	name: "filterClient",
	initialState: initialFilter,
	reducers: {
		updateFilter: (state, action) => action.payload,
		initFilter: () => initialFilter,
	},
});

export const { updateFilter, initFilter } = filterListClients.actions;

export default filterListClients.reducer;
