import React from "react";
import { useDispatch } from "react-redux";
import { Grid } from "@material-ui/core";
import { useParams } from "react-router-dom";
import CircularLoader from "components/Loaders/CircularLoader";
import InvoiceAttachmentsTable from "./Table/index";
import AttachmentForm from "./Form/index";
import {
	fetchOrderAttachmentsDocTypes,
	fetchOrderAttachments,
} from "../../../../../redux/actions/orders";

export default () => {
	const dispatch = useDispatch();
	const [loaded, setLoaded] = React.useState(false);
	const { idOrder } = useParams();

	React.useEffect(() => {
		dispatch(
			fetchOrderAttachmentsDocTypes(() => {
				setLoaded(true);
			})
		);
		dispatch(fetchOrderAttachments(idOrder));
	}, []);

	return (
		<>
			{loaded ? (
				<Grid container xs={12}>
					<AttachmentForm />
					<InvoiceAttachmentsTable />
				</Grid>
			) : (
				<CircularLoader />
			)}
		</>
	);
};
