import React, { useState, useEffect } from "react";
import { useSelector, connect, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import {
	Grid,
	Button,
	Chip,
	Typography,
	Select,
	MenuItem,
	ListItemText,
	Input,
	TextField,
	Divider,
	Tabs,
	Tab,
	Table,
	TableHead,
	TableRow,
	TableCell,
	Tooltip,
	Paper,
	Dialog,
	DialogTitle,
	DialogActions,
	List,
	ListItem,
	AppBar,
	TableBody,
	useTheme,
	TableContainer,
	IconButton,
	CircularProgress,
} from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import AddIcon from "@material-ui/icons/Add";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import MuiAlert from "@material-ui/lab/Alert";
import PropTypes from "prop-types";
import { isClientOnly, toast } from "utils/index";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import clsx from "clsx";
import { fetchSettingsByModuleName } from "redux/actions/users";
import useStyles from "./style";
import CircularLoader from "../../../components/Loaders/CircularLoader";
import {
	fetchOrder,
	rectifyOrder,
	fetchCountOrders,
	fetchDeliveryMethods,
	fetchDocumentOrder,
	fetchPaymentTerms,
	fetchRejectionMotifsOrder,
	verifyOrder,
	createOrderApproval,
	exportXMLFileToServer,
	downloadOrderExcel,
	downloadXMLOrder,
	downloadOrder,
	createOrderRejection,
	downloadEdifactOrder,
	downloadIdocOrder,
	// exportOrderSingleAccountingEntryExcel,
} from "../../../redux/actions/orders";
import { updateFormulaire } from "../../../redux/orderFormulaireReducer";
import { fetchClients } from "../../../redux/actions/clients";
import {
	TO_VERIFY,
	TO_CONTROL,
	INTEGRATION,
	TO_VERIFY_LIST,
	TREATED,
	REJECTED,
} from "../../../redux/constants";
import DesignCanvas from "./components/DesignCanvas";
import ConfirmDialog from "../../../components/ConfirmDialog";
import ApprovalDialog from "./components/ApprovalDialog";
import DialogReject from "./components/DialogReject";
import History from "./components/History";
import AmountsComponent from "./components/AmountsComponent";
import DeliveryItemsSection from "./components/DeliveryItemsSection";
import Attachements from "./components/Attachements";
import PDFViewer from "./components/PDFViewer";
import PDFJSBackend from "./components/pdfjs";

const ConfirmationDialog = ({ open, setOpen, ...props }) => {
	const { t } = useTranslation();

	const handleCloseControlPopup = () => {
		setOpen(!open);
	};

	const toControl = () => {
		props.verifyOrder(
			props.orderPersisted && props.orderPersisted.id,
			handleCloseControlPopup,
			() => toast.error(t("this operation is failed"))
		);
	};

	return (
		<Dialog
			open={open}
			onClose={handleCloseControlPopup}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			{isEmpty(props.dataErrors) ? (
				<>
					<DialogTitle id="alert-dialog-title">{t("orderControlMessage")}</DialogTitle>
					<DialogActions>
						<Button onClick={handleCloseControlPopup} color="secondary" autoFocus>
							{t("cancel")}
						</Button>
						<Button onClick={toControl} type="submit" color="secondary">
							{t("confirm")}
						</Button>
					</DialogActions>
				</>
			) : (
				<>
					<DialogTitle id="alert-dialog-title">{t("ErrorMessageToControl")}</DialogTitle>
					<List>
						{props.dataErrors.map((message) => (
							<ListItem>
								<ListItemText
									primary={
										<>
											<WarningIcon color="error" /> {t(message)}
										</>
									}
								/>
							</ListItem>
						))}
					</List>
					<DialogActions>
						<Button onClick={handleCloseControlPopup} color="secondary" autoFocus>
							{t("close")}
						</Button>
					</DialogActions>
				</>
			)}
		</Dialog>
	);
};

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />; // eslint-disable-line
}

const TabPanel = (props) => {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`scrollable-auto-tabpanel-${index}`}
			aria-labelledby={`scrollable-auto-tab-${index}`}
			{...other} // eslint-disable-line
		>
			{value === index && (
				<Grid container xs={12} style={{ margin: 0 }}>
					{children}
				</Grid>
			)}
		</div>
	);
};

TabPanel.propTypes = {
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `scrollable-auto-tab-${index}`,
		"aria-controls": `scrollable-auto-tabpanel-${index}`,
	};
}

const OrderDetails = (props) => {
	const classes = useStyles();
	const history = useHistory();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const theme = useTheme();
	// eslint-disable-next-line no-shadow
	const orderFormulaire = useSelector(({ orderFormulaire }) => orderFormulaire);
	const currentUser = useSelector(({ requests }) =>
		get(requests, "queries.FETCH_CURRENT_USER.data")
	);

	const [order, setOrder] = useState({});
	const [client, setClient] = useState("");
	const [dataErrors, setDataErrors] = useState([]);
	const [value, setValue] = useState(0);
	const [rectify, setRectify] = useState(false);
	const [mimeType, setMimeType] = useState(null);
	const [location, setLocation] = useState(null);
	const [previewFile, setPreviewFile] = useState(null);
	const [openRejectPopup, setOpenRejectPopup] = useState(false);
	const [openControlPopup, setOpenControlPopup] = useState(false);
	const [openApplyForApprovalPopup, setOpenApplyForApprovalPopup] = useState(false);
	const [isEditFacture] = useState(false);
	const [settings, setSettings] = useState({ loaded: false, data: [] });
	const [billingLines, setBillingLines] = useState([]);
	const [scValue, setScValue] = useState(0);
	const [exportIsLoading, setExportIsLoading] = useState({
		excel: false,
		xml: false,
		pdf: false,
		edi: false,
		idoc: false,
	});
	const [confirmObject, setConfirmObject] = useState({
		message: "",
		openConfirm: false,
		cancel: false,
		rectify: false,
		control: false,
		export: false,
		training: false,
		isLoading: false,
	});

	const initConfirmObject = () => {
		setConfirmObject({
			message: "",
			openConfirm: false,
			cancel: false,
			rectify: false,
			control: false,
			training: false,
			isLoading: false,
		});
	};

	const getDataFromSettings = (code) =>
		get(
			settings.data.find((e) => e.code === code),
			"value"
		) === "1";

	const onCloseDialogReject = (error) => {
		if (error) {
			toast.error(t("order.detail.reject.failed"));
		} else {
			toast.success(t("order.detail.reject.success"));
		}
	};

	const isClient = isClientOnly(get(currentUser, "roles", []));
	const status = get(props.orderPersisted, "status");
	const statusCode = get(status, "code");

	const getOrderStatusLabel = (arg) => {
		if (isClient) {
			if (TO_VERIFY_LIST.includes(arg.code)) {
				return t("order.status.underProcess");
			}
			if (TREATED.includes(arg.code)) {
				return t("order.status.processed");
			}
			if (REJECTED.includes(arg.code)) {
				return t("Rejected");
			}
		}
		return t(status.label);
	};

	const initErrors = (orderParam) => {
		const errors = [];
		if (orderParam) {
			if ((!orderParam.supplier || !orderParam.client.label) && isEmpty(orderParam.client)) {
				errors.push("clientEmptyMessageError");
			}
			if (!orderParam.supplier && !isEmpty(orderParam.suppliers)) {
				errors.push({
					withParam: true,
					message: "error.duplicateSuppliers",
					param: orderParam.suppliers[0].vatNumber,
				});
			}
			setDataErrors(errors);
		}
	};

	const handleChangeFirstTab = (event, newValue) => {
		setValue(newValue);
	};

	const handleChangeSecondTab = (event, newScValue) => {
		setScValue(newScValue);
	};

	const exportEdi = () => {
		// TODO
		dispatch(
			exportXMLFileToServer(
				props.match.params.idOrder,
				() => {
					toast.success(t("order.detail.export.success"));
					dispatch(fetchOrder(props.match.params.idOrder));
					initConfirmObject();
				},
				() => {
					toast.error(t("order.detail.export.failed"));
					initConfirmObject();
				}
			)
		);
	};

	const onExportError = () => {
		toast.error(t("order.export.error"));
	};

	const exportEXCEl = () => {
		setExportIsLoading({ ...exportIsLoading, excel: true });
		dispatch(
			downloadOrderExcel(
				props.match.params.idOrder,
				() => {
					onExportError();
				},
				() => {
					setExportIsLoading({ ...exportIsLoading, excel: false });
				}
			)
		);
	};

	const exportXML = () => {
		setExportIsLoading({ ...exportIsLoading, xml: true });
		dispatch(
			downloadXMLOrder(
				props.match.params.idOrder,
				() => {
					onExportError();
				},
				() => {
					setExportIsLoading({ ...exportIsLoading, xml: false });
				}
			)
		);
	};

	const exportPDF = () => {
		setExportIsLoading({ ...exportIsLoading, pdf: true });
		dispatch(
			downloadOrder(
				props.match.params.idOrder,
				"ord",
				() => {
					onExportError();
				},
				() => {
					setExportIsLoading({ ...exportIsLoading, pdf: false });
				}
			)
		);
	};

	const exportEdifact = () => {
		setExportIsLoading({ ...exportIsLoading, edi: true });
		dispatch(
			downloadEdifactOrder(
				props.match.params.idOrder,
				() => {
					onExportError();
				},
				() => {
					setExportIsLoading({ ...exportIsLoading, edi: false });
				}
			)
		);
	};
	const exportIdoc = () => {
		setExportIsLoading({ ...exportIsLoading, idoc: true });
		dispatch(
			downloadIdocOrder(
				props.match.params.idOrder,
				() => {
					onExportError();
				},
				() => {
					setExportIsLoading({ ...exportIsLoading, idoc: false });
				}
			)
		);
	};

	// const exportEC = () => {
	// 	// TODO
	// 	dispatch(exportOrderSingleAccountingEntryExcel(props.match.params.idOrder));
	// };

	const onConfirm = () => {
		if (confirmObject.cancel) {
			initConfirmObject();
			window.location.reload(false);
		}

		if (confirmObject.rectify) {
			setConfirmObject({ ...confirmObject, isLoading: true });
			props
				.rectifyOrder(props.match.params.idOrder, orderFormulaire.orderDto, confirmObject.training)
				.then(() => {
					initConfirmObject();
				});
		}
		if (confirmObject.control) {
			setConfirmObject({ ...confirmObject, isLoading: true });
			if (isEmpty(dataErrors)) {
				props.verifyOrder(
					props.orderPersisted && props.orderPersisted.id,
					() => {
						initConfirmObject();
					},
					() => {
						initConfirmObject();
					}
				);
				initConfirmObject();
			} else {
				initConfirmObject();
			}
		}
		if (confirmObject.export) {
			setConfirmObject({ ...confirmObject, isLoading: true });
			exportEdi();
		}
	};
	const onCancel = () => {
		setConfirmObject({ message: "", openConfirm: false, isLoading: false });
	};

	const updateSelectedItem = (v) => {
		dispatch(updateFormulaire({ ...orderFormulaire, selectedItem: v }));
	};

	const handleSupplierFieldChange = (event) => {
		const { target } = event;
		setClient(target.value);
		const selectedClient = props.clients.content.find((s) => event.target.value === s.label);
		setOrder({
			...order,
			supplier: selectedClient,
		});
		const _formulaire = JSON.parse(JSON.stringify(orderFormulaire));
		_formulaire.orderDto.client.label.value = event.target.value;
		if (selectedClient) {
			_formulaire.orderDto.client.id.value = selectedClient.id;
			_formulaire.orderDto.client.vatNumber.value = selectedClient.vatNumber;
			_formulaire.orderDto.client.companySiret.value = selectedClient.companySiret;
		} else {
			_formulaire.orderDto.client.vatNumber.value = "";
			_formulaire.orderDto.client.companySiret.value = "";
		}
		if (isEmpty(event.target.value)) {
			// eslint-disable-line
			_formulaire.orderDto.client.label.location = {
				top: null,
				right: null,
				left: null,
				bottom: null,
			};
		}
		props.updateFormulaire(_formulaire);
	};

	const getShowApproval = () => {
		if (!props.orderPersisted.purchaseOrderNo) {
			return true;
		}
		if (get(order, "alertes.alertes") && !isEmpty(order.alertes.alertes)) {
			return true;
		}

		return false;
	};

	const getClientsOptions = () => {
		if (!isEmpty(order.clients)) {
			return order.clients;
		}
		return get(props, "clients.content", []);
	};

	const showExportBtn = () => {
		if (
			get(props.orderPersisted, "isApproved", false) === null ||
			get(props.orderPersisted, "isApproved", false)
		) {
			return true;
		}
		return false;
	};

	useEffect(() => {
		// dispatch(fetchCountClients());
		dispatch(fetchCountOrders());
		dispatch(fetchDeliveryMethods());
		if (!isClient)
			dispatch(fetchSettingsByModuleName("order")).then((res) => {
				if (res.data) setSettings({ loaded: true, data: res.data });
			});
		fetchOrder(props.match.params.idOrder ? parseFloat(props.match.params.idOrder) : 0);
		setOrder(props.orderPersisted);
		// if (props.orderPersisted) setOrder(props.orderPersisted.order);
		if (props.orderPersisted) setBillingLines(props.orderPersisted?.billingLinesDtos);
		setClient(
			props.orderPersisted && props.orderPersisted.client ? props.orderPersisted.client.label : ""
		);

		initErrors(props.orderPersisted, props.billingLines);
	}, [props.orderPersisted]);

	useEffect(() => {
		if (props.document) {
			const blob = new Blob([props.document], { type: mimeType });
			const fileURL = URL.createObjectURL(blob);
			setPreviewFile(fileURL);
		}
	}, [props.document]);

	useEffect(() => {
		props.fetchOrder(props.match.params.idOrder ? parseFloat(props.match.params.idOrder) : 0);
		props.fetchDocumentOrder(
			props.match.params.idOrder ? parseFloat(props.match.params.idOrder) : 0,
			"ord",
			null,
			setMimeType
		);
		props.fetchClients({
			nameOrContactLastNameOrContactFirstName: "",
			pageNo: 0,
			pageSize: null,
			sortBy: "label",
		});
		props.fetchPaymentTerms();
		props.fetchRejectionMotifsOrder({ token: null });
	}, []);

	const onChangeBilling = (event, i, cellule) => {
		const newRows = billingLines.slice();
		newRows[i][cellule] = event.target.value;
		setBillingLines(newRows);
	};

	// temp fix
	useEffect(
		() => () => {
			window.location.reload(false);
		},
		[]
	);

	const minimizeValueDesignation = (v) => (v && v.length > 45 ? `${v.substr(0, 44)}...` : v);
	const minimizeValueCode = (v) => (v && v.length > 9 ? `${v.substr(0, 8)}...` : v);

	const addBillingLine = (index) => {
		if (rectify) {
			const _formulaire = JSON.parse(JSON.stringify(orderFormulaire));
			const item = {
				id: {},
				codeArticle: {},
				designation: {},
				quantity: {},
				vat: {},
				price: {},
				totalHt: {},
				deliveryDelay: {},
			};
			_formulaire.orderDto.billingLinesDtos.splice(index, 0, item);
			_formulaire.orderDto.billingLinesDtos[index].designation.value = "";
			_formulaire.orderDto.billingLinesDtos[index].codeArticle.value = "";
			_formulaire.orderDto.billingLinesDtos[index].quantity.value = "";
			_formulaire.orderDto.billingLinesDtos[index].vat.value = "";
			_formulaire.orderDto.billingLinesDtos[index].price.value = "";
			_formulaire.orderDto.billingLinesDtos[index].price.value = "";
			_formulaire.orderDto.billingLinesDtos[index].totalHt.value = "";
			props.updateFormulaire(_formulaire);
		} else {
			const newRows = billingLines ? billingLines.slice() : [];
			newRows.push({});
			setBillingLines(newRows);
		}
	};

	return (
		<Grid container xs={12} className={classes.root}>
			<Grid container xs={isClient ? 7 : 6} item alignItems="left">
				<Grid item xs={3}>
					<Button
						variant="contained"
						fullWidth
						color="secondary"
						className={classes.backBtn}
						onClick={() => history.goBack()}
					>
						{t("return")}
					</Button>
				</Grid>
				<Grid container xs={9} item alignItems="center" justify="flex-end">
					<Grid item>
						{props.orderPersisted && props.orderPersisted.status ? (
							<Chip
								align="left"
								className={classes.alignStatus}
								label={
									props.orderPersisted &&
									props.orderPersisted.status &&
									getOrderStatusLabel(props.orderPersisted.status)
								}
								color="secondary"
							/>
						) : (
							<CircularLoader />
						)}
					</Grid>
				</Grid>
			</Grid>
			<Grid container item xs={12} className={classes.detailsContent} justify="space-evenly">
				{(!isClient ||
					!isEmpty(get(order, "purchaseOrder.deliveryItems", [])) ||
					TREATED.includes(statusCode)) && (
					<Grid container item xs={5} className={classes.invoiceOrderTabs}>
						<Grid item xs={12}>
							<AppBar position="static" color="white" elevation={0}>
								<Tabs
									value={value}
									onChange={handleChangeFirstTab}
									indicatorColor="secondary"
									textColor="secondary"
									variant="fullWidth"
									aria-label="tab facture"
								>
									{(!isClient || TREATED.includes(statusCode)) && (
										<Tab label={t("Order Details")} {...a11yProps(0)} />
									)}
									{!isEmpty(get(order, "purchaseOrder.deliveryItems", [])) &&
										get(props.orderPersisted, "status.code") !== INTEGRATION && (
											// eslint-disable-next-line
											<Tab label={t("Delivery Details")} {...a11yProps(1)} />
										)}
								</Tabs>
							</AppBar>
							{(!isClient || TREATED.includes(statusCode)) && (
								<TabPanel
									section={1}
									value={value}
									index={0}
									dir={theme.direction}
									className={classes.tabInvoiceContainer}
								>
									{order ? (
										<Grid container item xs={12}>
											<Grid container item xs={12} className={classes.tabInvoice} spacing={2}>
												<Grid
													container
													item
													xs={12}
													justify="center"
													alignItems="flex-end"
													spacing={1}
													className={classes.noPaddingY}
												>
													<Grid item xs={6}>
														<Typography variant="subtitle2" align="left" color="primary">
															{t("client")}
															<br />
															<>
																{rectify ? (
																	<>
																		<Select
																			labelId="client"
																			id="client"
																			className={classes.resize}
																			value={client}
																			onChange={handleSupplierFieldChange}
																			fullWidth
																			disabled={
																				order &&
																				get(order, "digitalOrder") &&
																				isEmpty(order.clients)
																			}
																			displayEmpty
																			InputProps={{
																				classes: {
																					input: classes.resize,
																				},
																			}}
																			input={<Input name="client" id="client" />}
																		>
																			<MenuItem value="">
																				<ListItemText primary="" />
																			</MenuItem>
																			{getClientsOptions().map((s, i) => (
																				<MenuItem key={i} value={s.label}>
																					<ListItemText primary={s.label} />
																				</MenuItem>
																			))}
																		</Select>
																	</>
																) : (
																	<Typography
																		variant="body2"
																		align="left"
																		className={classes.blackColor}
																	>
																		{get(order, "client.label")}
																		<Divider />
																	</Typography>
																)}
															</>
														</Typography>
													</Grid>
													<Grid item xs={6}>
														<Typography variant="subtitle2" align="left" color="primary">
															{t("order Number")}

															<br />
															{isEditFacture || rectify ? (
																<TextField
																	fullWidth
																	id="purchaseOrderNo"
																	onClick={() => {
																		updateSelectedItem("purchaseOrderNo");
																		setLocation(orderFormulaire.orderDto.purchaseOrderNo.location);
																	}}
																	onChange={(e) => {
																		const _formulaire = JSON.parse(JSON.stringify(orderFormulaire));
																		_formulaire.orderDto.purchaseOrderNo.value = e.target.value;
																		if (isEmpty(e.target.value)) {
																			_formulaire.orderDto.purchaseOrderNo.location = {
																				top: null,
																				right: null,
																				left: null,
																				bottom: null,
																			};
																		}
																		props.updateFormulaire(_formulaire);
																	}}
																	value={orderFormulaire.orderDto.purchaseOrderNo.value}
																	className={clsx(
																		"classes.resize",
																		rectify &&
																			isEmpty(orderFormulaire.orderDto.purchaseOrderNo.value) &&
																			classes.emptyInput
																	)}
																	InputProps={{
																		classes: {
																			input: classes.resize,
																		},
																	}}
																	InputLabelProps={{
																		shrink: true,
																	}}
																/>
															) : (
																<Typography
																	variant="body2"
																	align="left"
																	className={classes.blackColor}
																>
																	{props.orderPersisted && props.orderPersisted.orderNo}

																	<Divider />
																</Typography>
															)}
														</Typography>
													</Grid>
												</Grid>

												<Grid
													container
													item
													xs={12}
													justify="center"
													alignItems="flex-end"
													spacing={2}
												>
													<Grid item xs={6}>
														<Typography variant="subtitle2" align="left" color="primary">
															{t("VAT Number")}

															<>
																{rectify ? (
																	<Input
																		disabled
																		className={classes.resize}
																		fullWidth
																		value={get(orderFormulaire.orderDto, "client.vatNumber.value")}
																	/>
																) : (
																	<Typography
																		variant="body2"
																		align="left"
																		className={classes.blackColor}
																	>
																		{get(order, "client.vatNumber")}
																		<Divider />
																	</Typography>
																)}
															</>
														</Typography>
													</Grid>
													<Grid item xs={6}>
														<Typography variant="subtitle2" align="left" color="primary">
															{t("order.detail.company_siret")}
															<br />
															{isEditFacture || rectify ? (
																<TextField
																	fullWidth
																	id="companySiret"
																	// type="date"
																	onClick={() => {
																		updateSelectedItem("companySiret");
																		setLocation(
																			orderFormulaire.orderDto.client.companySiret.location
																		);
																	}}
																	onChange={(e) => {
																		if (rectify) {
																			const _formulaire = JSON.parse(
																				JSON.stringify(orderFormulaire)
																			);
																			_formulaire.orderDto.client.companySiret.value =
																				e.target.value;
																			if (isEmpty(e.target.value)) {
																				_formulaire.orderDto.client.companySiret.location = {
																					top: null,
																					right: null,
																					left: null,
																					bottom: null,
																				};
																			}
																			props.updateFormulaire(_formulaire);
																		} else {
																			setOrder({
																				...order,
																				dueDate: e.target.value,
																			});
																		}
																	}}
																	value={get(orderFormulaire.orderDto, "client.companySiret.value")}
																	className={classes.textField}
																	disabled
																	InputProps={{
																		classes: {
																			input: classes.resize,
																		},
																	}}
																	InputLabelProps={{
																		shrink: true,
																	}}
																/>
															) : (
																<Typography
																	variant="body2"
																	align="left"
																	className={classes.blackColor}
																>
																	{get(order, "client.companySiret")}
																	<Divider />
																</Typography>
															)}
														</Typography>
													</Grid>
												</Grid>

												<Grid
													container
													item
													xs={12}
													justify="center"
													alignItems="flex-end"
													spacing={1}
												>
													<Grid item xs={6}>
														<Typography variant="subtitle2" align="left" color="primary">
															{t("issueDate")}
															<br />
															{isEditFacture || rectify ? (
																<TextField
																	fullWidth
																	id="issueDate"
																	// type="date"
																	onClick={() => {
																		updateSelectedItem("issueDate");
																		setLocation(orderFormulaire.orderDto.issueDate.location);
																	}}
																	onChange={(e) => {
																		if (rectify) {
																			const _formulaire = JSON.parse(
																				JSON.stringify(orderFormulaire)
																			);
																			_formulaire.orderDto.issueDate.value = e.target.value;
																			if (isEmpty(e.target.value)) {
																				_formulaire.orderDto.issueDate.location = {
																					top: null,
																					right: null,
																					left: null,
																					bottom: null,
																				};
																			}
																			props.updateFormulaire(_formulaire);
																		} else {
																			setOrder({
																				...order,
																				issueDate: e.target.value,
																			});
																		}
																	}}
																	value={
																		rectify
																			? orderFormulaire.orderDto.issueDate.value
																			: get(order, "issueDate")
																	}
																	className={classes.textField}
																	InputProps={{
																		classes: {
																			input: clsx(
																				"classes.resize",
																				rectify &&
																					isEmpty(orderFormulaire.orderDto.issueDate.value) &&
																					classes.emptyInput
																			),
																		},
																	}}
																	InputLabelProps={{
																		shrink: true,
																	}}
																/>
															) : (
																<Typography
																	variant="body2"
																	align="left"
																	className={classes.blackColor}
																>
																	{get(order, "issueDate")}

																	<Divider />
																</Typography>
															)}
														</Typography>
													</Grid>

													<Grid item xs={6}>
														<Typography variant="subtitle2" align="left" color="primary">
															{t("orderDeliveryDate")}
															<br />
															{isEditFacture || rectify ? (
																<TextField
																	fullWidth
																	id="dueDate"
																	// type="date"
																	onClick={() => {
																		updateSelectedItem("dueDate");
																		setLocation(orderFormulaire.orderDto.dueDate.location);
																	}}
																	onChange={(e) => {
																		if (rectify) {
																			const _formulaire = JSON.parse(
																				JSON.stringify(orderFormulaire)
																			);
																			_formulaire.orderDto.dueDate.value = e.target.value;
																			if (isEmpty(e.target.value)) {
																				_formulaire.orderDto.dueDate.location = {
																					top: null,
																					right: null,
																					left: null,
																					bottom: null,
																				};
																			}
																			props.updateFormulaire(_formulaire);
																		} else {
																			setOrder({
																				...order,
																				dueDate: e.target.value,
																			});
																		}
																	}}
																	value={
																		rectify
																			? orderFormulaire.orderDto.dueDate.value
																			: get(order, "dueDate")
																	}
																	className={classes.textField}
																	InputProps={{
																		classes: {
																			input: clsx(
																				"classes.resize",
																				rectify &&
																					isEmpty(orderFormulaire.orderDto.dueDate.value) &&
																					classes.emptyInput
																			),
																		},
																	}}
																	InputLabelProps={{
																		shrink: true,
																	}}
																/>
															) : (
																<Typography
																	variant="body2"
																	align="left"
																	className={classes.blackColor}
																>
																	{get(order, "dueDate")}

																	<Divider />
																</Typography>
															)}
														</Typography>
													</Grid>
												</Grid>
												<Grid
													container
													item
													xs={12}
													justify="center"
													alignItems="flex-end"
													spacing={1}
												>
													<Grid item xs={6}>
														<Typography variant="subtitle2" align="left" color="primary">
															{t("Delivery address")}

															<br />
															{isEditFacture || rectify ? (
																<Input
																	className={clsx(
																		"classes.resize",
																		rectify &&
																			isEmpty(orderFormulaire.orderDto.deliveryAddress.value) &&
																			classes.emptyInput
																	)}
																	fullWidth
																	rows={3}
																	multiline
																	onChange={(e) => {
																		if (rectify) {
																			const _formulaire = JSON.parse(
																				JSON.stringify(orderFormulaire)
																			);
																			_formulaire.orderDto.deliveryAddress.value = e.target.value;
																			if (isEmpty(e.target.value)) {
																				_formulaire.orderDto.deliveryAddress.location = {
																					top: null,
																					right: null,
																					left: null,
																					bottom: null,
																				};
																			}
																			props.updateFormulaire(_formulaire);
																		} else {
																			setOrder({
																				...order,
																				deliveryAddress: e.target.value,
																			});
																		}
																	}}
																	onClick={() => {
																		updateSelectedItem("deliveryAddress");
																		setLocation(orderFormulaire.orderDto.deliveryAddress.location);
																	}}
																	value={
																		rectify
																			? orderFormulaire.orderDto.deliveryAddress.value
																			: get(order, "deliveryAddress")
																	}
																/>
															) : (
																<Typography
																	variant="body2"
																	align="left"
																	className={classes.blackColor}
																>
																	{get(order, "deliveryAddress")}
																	<Divider />
																</Typography>
															)}
														</Typography>
													</Grid>
													<Grid item xs={6}>
														<Typography variant="subtitle2" align="left" color="primary">
															{t("Billing address")}
															{isEditFacture || rectify ? (
																<Input
																	className={clsx(
																		"classes.resize",
																		rectify &&
																			isEmpty(orderFormulaire.orderDto.billingAddress.value) &&
																			classes.emptyInput
																	)}
																	fullWidth
																	rows={3}
																	multiline
																	onClick={() => {
																		updateSelectedItem("billingAddress");
																		setLocation(orderFormulaire.orderDto.billingAddress.location);
																	}}
																	onChange={(e) => {
																		if (rectify) {
																			const _formulaire = JSON.parse(
																				JSON.stringify(orderFormulaire)
																			);
																			_formulaire.orderDto.billingAddress.value = e.target.value;
																			if (isEmpty(e.target.value)) {
																				_formulaire.orderDto.billingAddress.location = {
																					top: null,
																					right: null,
																					left: null,
																					bottom: null,
																				};
																			}
																			props.updateFormulaire(_formulaire);
																		} else {
																			setOrder({
																				...order,
																				billingAddress: e.target.value,
																			});
																		}
																	}}
																	value={
																		rectify
																			? orderFormulaire.orderDto.billingAddress.value
																			: get(order, "billingAddress")
																	}
																/>
															) : (
																<Typography
																	variant="body2"
																	align="left"
																	className={classes.blackColor}
																>
																	{rectify
																		? orderFormulaire.orderDto.billingAddress.value
																		: order && order.billingAddress}
																	{/* {invoice && invoice.billingAddress} */}
																	<Divider />
																</Typography>
															)}
														</Typography>
													</Grid>
												</Grid>
												<Grid container item xs={12} alignItems="flex-end" spacing={1}>
													<Grid item xs={6}>
														<Typography variant="subtitle2" align="left" color="primary">
															{t("country")}

															<br />
															{isEditFacture || rectify ? (
																<Input
																	className={clsx(
																		"classes.resize",
																		rectify &&
																			isEmpty(get(orderFormulaire, "orderDto.country.value")) &&
																			classes.emptyInput
																	)}
																	fullWidth
																	onChange={(e) => {
																		if (rectify) {
																			const _formulaire = JSON.parse(
																				JSON.stringify(orderFormulaire)
																			);
																			_formulaire.orderDto.country.value = e.target.value;
																			if (isEmpty(e.target.value)) {
																				_formulaire.orderDto.country.location = {
																					top: null,
																					right: null,
																					left: null,
																					bottom: null,
																				};
																			}
																			props.updateFormulaire(_formulaire);
																		} else {
																			setOrder({
																				...order,
																				country: e.target.value,
																			});
																		}
																	}}
																	onClick={() => {
																		updateSelectedItem("country");
																		setLocation(get(orderFormulaire, "orderDto.country.location"));
																	}}
																	value={
																		rectify
																			? get(orderFormulaire, "orderDto.country.value")
																			: get(order, "country")
																	}
																/>
															) : (
																<Typography
																	variant="body2"
																	align="left"
																	className={classes.blackColor}
																>
																	{get(order, "country")}
																	<Divider />
																</Typography>
															)}
														</Typography>
													</Grid>
													<Grid item xs={6}>
														<Typography variant="subtitle2" align="left" color="primary">
															{t("currency")}

															<br />
															{isEditFacture || rectify ? (
																<Input
																	className={clsx(
																		"classes.resize",
																		rectify &&
																			isEmpty(orderFormulaire.orderDto.currency.value) &&
																			classes.emptyInput
																	)}
																	fullWidth
																	onChange={(e) => {
																		if (rectify) {
																			const _formulaire = JSON.parse(
																				JSON.stringify(orderFormulaire)
																			);
																			_formulaire.orderDto.currency.value = e.target.value;
																			if (isEmpty(e.target.value)) {
																				_formulaire.orderDto.currency.location = {
																					top: null,
																					right: null,
																					left: null,
																					bottom: null,
																				};
																			}
																			props.updateFormulaire(_formulaire);
																		} else {
																			setOrder({
																				...order,
																				currency: e.target.value,
																			});
																		}
																	}}
																	onClick={() => {
																		updateSelectedItem("currency");
																		setLocation(orderFormulaire.orderDto.currency.location);
																	}}
																	value={
																		rectify
																			? orderFormulaire.orderDto.currency.value
																			: get(order, "currency")
																	}
																/>
															) : (
																<Typography
																	variant="body2"
																	align="left"
																	className={classes.blackColor}
																>
																	{get(order, "currency")}
																	<Divider />
																</Typography>
															)}
														</Typography>
													</Grid>
												</Grid>
											</Grid>

											{(!isClient || TREATED.includes(statusCode)) && (
												<Grid container item xs={12} className={classes.cardBilling}>
													<Grid
														container
														item
														alignItems="center"
														xs={12}
														className={classes.headerBillingCard}
													>
														<Grid item alignContent="center">
															<Typography variant="subtitle2" align="left" color="primary">
																{t("order.lines")}
															</Typography>
														</Grid>
													</Grid>
													<Grid container item alignItems="center" justify="center" xs={12}>
														<TableContainer
															component={Paper}
															elevation={0}
															className={classes.tableContainer}
														>
															<Table aria-label="spanning table">
																<TableHead>
																	<TableRow>
																		<TableCell>
																			<Typography
																				className={classes.tableCell1}
																				variant="subtitle2"
																				align="left"
																			>
																				{t("itemCode")}
																			</Typography>
																		</TableCell>
																		<TableCell>
																			<Typography
																				className={classes.tableCell3}
																				variant="subtitle2"
																				align="left"
																			>
																				{t("designation")}
																			</Typography>
																		</TableCell>
																		<TableCell>
																			<Typography
																				className={classes.tableCell2}
																				variant="subtitle2"
																				align="left"
																			>
																				{t("quantity")}
																			</Typography>
																		</TableCell>
																		<TableCell>
																			<Typography
																				className={classes.tableCell1}
																				variant="subtitle2"
																				align="left"
																			>
																				{t("unitPrice")}
																			</Typography>
																		</TableCell>
																		<TableCell>
																			<Typography
																				className={classes.tableCell2}
																				variant="subtitle2"
																				align="left"
																			>
																				{t("vat")}
																			</Typography>
																		</TableCell>
																		<TableCell>
																			<Typography
																				className={classes.tableCell4}
																				variant="subtitle2"
																				align="left"
																			>
																				{t("totalHt")}
																			</Typography>
																		</TableCell>
																		<TableCell>
																			{rectify &&
																				orderFormulaire.orderDto.billingLinesDtos &&
																				isEmpty(orderFormulaire.orderDto.billingLinesDtos) && (
																					<IconButton
																						aria-label="add"
																						onClick={() => addBillingLine(0)}
																					>
																						<AddIcon />
																					</IconButton>
																				)}
																		</TableCell>
																	</TableRow>
																</TableHead>
																<TableBody>
																	{rectify ? (
																		<>
																			{orderFormulaire.orderDto.billingLinesDtos &&
																				!isEmpty(orderFormulaire.orderDto.billingLinesDtos) &&
																				orderFormulaire.orderDto.billingLinesDtos.map((row, i) => (
																					<TableRow key={row.id}>
																						<>
																							<TableCell>
																								<Input
																									className={clsx(
																										"classes.tableCell1",
																										isEmpty(
																											orderFormulaire.orderDto.billingLinesDtos[i]
																												.codeArticle.value
																										) && classes.emptyInput
																									)}
																									fullWidth
																									value={row.codeArticle.value}
																									onClick={() => {
																										updateSelectedItem(`table.${i}.codeArticle`);
																										setLocation(
																											orderFormulaire.orderDto.billingLinesDtos[i]
																												.codeArticle.location
																										);
																									}}
																									onChange={(event) => {
																										const _formulaire = JSON.parse(
																											JSON.stringify(orderFormulaire)
																										);
																										_formulaire.orderDto.billingLinesDtos[
																											i
																										].codeArticle.value = event.target.value;

																										props.updateFormulaire(_formulaire);
																									}}
																								/>
																							</TableCell>
																							<TableCell>
																								<Input
																									className={clsx(
																										"classes.tableCell3",
																										rectify &&
																											isEmpty(
																												orderFormulaire.orderDto.billingLinesDtos[i]
																													.designation.value
																											) &&
																											classes.emptyInput
																									)}
																									fullWidth
																									multiline
																									value={row.designation.value}
																									onClick={() => {
																										updateSelectedItem(`table.${i}.designation`);
																										setLocation(
																											orderFormulaire.orderDto.billingLinesDtos[i]
																												.designation.location
																										);
																									}}
																									onChange={(event) => {
																										const _formulaire = JSON.parse(
																											JSON.stringify(orderFormulaire)
																										);
																										_formulaire.orderDto.billingLinesDtos[
																											i
																										].designation.value = event.target.value;

																										props.updateFormulaire(_formulaire);
																									}}
																								/>
																							</TableCell>
																							<TableCell>
																								{" "}
																								<Input
																									className={clsx(
																										"classes.tableCell2",
																										isEmpty(
																											orderFormulaire.orderDto.billingLinesDtos[i]
																												.quantity.value
																										) && classes.emptyInput
																									)}
																									fullWidth
																									value={row.quantity.value}
																									onClick={() => {
																										updateSelectedItem(`table.${i}.quantity`);
																										setLocation(
																											orderFormulaire.orderDto.billingLinesDtos[i]
																												.quantity.location
																										);
																									}}
																									onChange={(event) => {
																										const _formulaire = JSON.parse(
																											JSON.stringify(orderFormulaire)
																										);
																										_formulaire.orderDto.billingLinesDtos[
																											i
																										].quantity.value = event.target.value;

																										props.updateFormulaire(_formulaire);
																									}}
																								/>
																							</TableCell>
																							<TableCell>
																								<Input
																									className={clsx(
																										"classes.tableCell1",
																										isEmpty(
																											orderFormulaire.orderDto.billingLinesDtos[i]
																												.price.value
																										) && classes.emptyInput
																									)}
																									fullWidth
																									value={row.price.value}
																									onClick={() => {
																										updateSelectedItem(`table.${i}.price`);
																										setLocation(
																											orderFormulaire.orderDto.billingLinesDtos[i]
																												.price.location
																										);
																									}}
																									onChange={(event) => {
																										const _formulaire = JSON.parse(
																											JSON.stringify(orderFormulaire)
																										);
																										_formulaire.orderDto.billingLinesDtos[
																											i
																										].price.value = event.target.value;

																										props.updateFormulaire(_formulaire);
																									}}
																								/>
																							</TableCell>
																							<TableCell>
																								{" "}
																								<Input
																									className={clsx(
																										"classes.tableCell2",
																										isEmpty(
																											orderFormulaire.orderDto.billingLinesDtos[i]
																												.vat.value
																										) && classes.emptyInput
																									)}
																									fullWidth
																									value={row.vat.value}
																									onClick={() => {
																										updateSelectedItem(`table.${i}.vat`);
																										setLocation(
																											orderFormulaire.orderDto.billingLinesDtos[i]
																												.vat.location
																										);
																									}}
																									onChange={(event) => {
																										const _formulaire = JSON.parse(
																											JSON.stringify(orderFormulaire)
																										);
																										_formulaire.orderDto.billingLinesDtos[
																											i
																										].vat.value = event.target.value;

																										props.updateFormulaire(_formulaire);
																									}}
																								/>
																							</TableCell>
																							<TableCell>
																								{" "}
																								<Input
																									className={clsx(
																										"classes.tableCell4",
																										rectify &&
																											isEmpty(
																												orderFormulaire.orderDto.billingLinesDtos[i]
																													.totalHt.value
																											) &&
																											classes.emptyInput
																									)}
																									fullWidth
																									value={row.totalHt.value}
																									onClick={() => {
																										updateSelectedItem(`table.${i}.totalHt`);
																										setLocation(
																											orderFormulaire.orderDto.billingLinesDtos[i]
																												.totalHt.location
																										);
																									}}
																									onChange={(event) => {
																										const _formulaire = JSON.parse(
																											JSON.stringify(orderFormulaire)
																										);
																										_formulaire.orderDto.billingLinesDtos[
																											i
																										].totalHt.value = event.target.value;

																										props.updateFormulaire(_formulaire);
																									}}
																								/>
																							</TableCell>
																							<TableCell>
																								<IconButton
																									aria-label="add"
																									onClick={() => {
																										const _formulaire = JSON.parse(
																											JSON.stringify(orderFormulaire)
																										);
																										_formulaire.orderDto.billingLinesDtos.splice(
																											i,
																											1
																										);
																										props.updateFormulaire(_formulaire);
																									}}
																								>
																									<DeleteOutline />
																								</IconButton>
																							</TableCell>
																							<TableCell>
																								<IconButton
																									aria-label="add"
																									onClick={() => addBillingLine(i + 1)}
																								>
																									<AddIcon />
																								</IconButton>
																							</TableCell>
																						</>
																					</TableRow>
																				))}
																		</>
																	) : (
																		<>
																			{billingLines &&
																				!isEmpty(billingLines) &&
																				billingLines.map((row, i) => (
																					<TableRow key={row.id}>
																						{isEditFacture ? (
																							<>
																								<TableCell>
																									{" "}
																									<Input
																										className={classes.tableCell1}
																										fullWidth
																										value={row.codeArticle}
																										onChange={(event) =>
																											onChangeBilling(event, i, "codeArticle")
																										}
																									/>
																								</TableCell>
																								<TableCell>
																									{" "}
																									<Input
																										className={classes.tableCell3}
																										fullWidth
																										value={row.designation}
																										onChange={(event) =>
																											onChangeBilling(event, i, "designation")
																										}
																									/>
																								</TableCell>
																								<TableCell>
																									{" "}
																									<Input
																										className={classes.tableCell2}
																										fullWidth
																										value={row.quantity}
																										onChange={(event) =>
																											onChangeBilling(event, i, "quantity")
																										}
																									/>
																								</TableCell>
																								<TableCell>
																									{" "}
																									<Input
																										className={classes.tableCell1}
																										fullWidth
																										value={row.price}
																										onChange={(event) =>
																											onChangeBilling(event, i, "price")
																										}
																									/>
																								</TableCell>
																								<TableCell>
																									{" "}
																									<Input
																										className={classes.tableCell2}
																										fullWidth
																										value={row.vat}
																										onChange={(event) =>
																											onChangeBilling(event, i, "vat")
																										}
																									/>
																								</TableCell>
																								<TableCell>
																									{" "}
																									<Input
																										className={classes.tableCell1}
																										fullWidth
																										value={row.totalHt}
																										onChange={(event) =>
																											onChangeBilling(event, i, "totalHt")
																										}
																									/>
																								</TableCell>
																								<TableCell>
																									<IconButton
																										aria-label="add"
																										onClick={() => {
																											const newRows = billingLines.slice();
																											newRows.splice(i, 1);
																											setBillingLines(newRows);
																										}}
																									>
																										<DeleteOutline />
																									</IconButton>
																								</TableCell>
																							</>
																						) : (
																							<>
																								<Tooltip title={row.codeArticle} arrow>
																									<TableCell>
																										<Typography
																											className={classes.tableCell1}
																											variant="subtitle"
																											align="left"
																										>
																											{minimizeValueCode(row.codeArticle)}
																										</Typography>
																									</TableCell>
																								</Tooltip>
																								<Tooltip title={row.designation} arrow>
																									<TableCell>
																										<Typography
																											className={classes.tableCell3}
																											variant="subtitle"
																											align="left"
																										>
																											{minimizeValueDesignation(row.designation)}
																										</Typography>
																									</TableCell>
																								</Tooltip>
																								<TableCell>
																									<Typography
																										className={classes.tableCell2}
																										variant="subtitle"
																										align="left"
																									>
																										{row.quantity}
																									</Typography>
																								</TableCell>
																								<TableCell>
																									<Typography
																										className={classes.tableCell1}
																										variant="subtitle"
																										align="left"
																									>
																										{row.price}
																									</Typography>
																								</TableCell>
																								<TableCell>
																									<Typography
																										className={classes.tableCell2}
																										variant="subtitle"
																										align="left"
																									>
																										{row.vat}
																									</Typography>
																								</TableCell>
																								<TableCell>
																									<Typography
																										className={classes.tableCell1}
																										variant="subtitle"
																										align="left"
																									>
																										{row.totalHt}
																									</Typography>
																								</TableCell>
																							</>
																						)}
																					</TableRow>
																				))}
																		</>
																	)}
																	{rectify ? (
																		<AmountsComponent
																			formulaire={orderFormulaire}
																			rectify={rectify}
																			location={location}
																			setLocation={setLocation}
																		/>
																	) : (
																		<>
																			<TableRow>
																				<TableCell rowSpan={100} />
																				<TableCell rowSpan={100} />
																				<TableCell rowSpan={100} />
																				<TableCell rowSpan={100} />
																			</TableRow>

																			{order &&
																				order.additionalAmountsHT &&
																				order.additionalAmountsHT.map((row) => (
																					<TableRow>
																						<TableCell align="right">{t("Total HT")}</TableCell>

																						<TableCell align="right">
																							{get(row, "amount")} {get(order, "currencySymbol")}
																						</TableCell>
																					</TableRow>
																				))}
																			{order &&
																				order.additionalAmountsTVA &&
																				order.additionalAmountsTVA.map((row) => (
																					<TableRow>
																						<TableCell align="right"> {t("VAT amount")}</TableCell>

																						<TableCell align="right">
																							{get(row, "amount")} {get(order, "currencySymbol")}
																						</TableCell>
																					</TableRow>
																				))}

																			<TableRow>
																				<TableCell align="right"> {t("Discount")}</TableCell>

																				<TableCell align="right">
																					{get(order, "discount")} {get(order, "currencySymbol")}
																				</TableCell>
																			</TableRow>
																			{order &&
																				order.additionalAmountsCharge &&
																				order.additionalAmountsCharge.map((row) => (
																					<TableRow>
																						<TableCell align="right">
																							{" "}
																							{t("order_charge")}
																						</TableCell>

																						<TableCell align="right">
																							{get(row, "amount")} {get(order, "currencySymbol")}
																						</TableCell>
																					</TableRow>
																				))}
																			<TableRow>
																				<TableCell align="right">{t("Total amount")}</TableCell>
																				<TableCell align="right">
																					{get(order, "total")} {order && order.currencySymbol}
																				</TableCell>
																			</TableRow>
																		</>
																	)}
																</TableBody>
															</Table>
															<>
																{get(order, "status.code") !== "TO_VALIDATE_EXTERNAL" && (
																	<Grid container xs={12} className={classes.approveContainer}>
																		<>
																			{get(order, "approvedBy") && (
																				<Grid item xs={6}>
																					<Typography
																						variant="subtitle2"
																						align="left"
																						color="primary"
																					>
																						{get(order, "isApproved") === false
																							? t("rejectedBy")
																							: t("approvedBy")}

																						<Typography variant="body2" align="left">
																							{get(order, "approvedBy")}
																						</Typography>
																					</Typography>
																				</Grid>
																			)}
																			{get(order, "approvedComment") && (
																				<Grid item xs={6}>
																					<Typography
																						variant="subtitle2"
																						align="left"
																						color="primary"
																					>
																						{t("comment")}

																						<Typography
																							variant="body2"
																							align="left"
																							className={classes.blackColor}
																						>
																							{get(order, "approvedComment")}
																							<Divider />
																						</Typography>
																					</Typography>
																				</Grid>
																			)}
																		</>
																	</Grid>
																)}
																<Divider />
																<>
																	{dataErrors &&
																		!isEmpty(dataErrors) &&
																		get(props.orderPersisted, "status.code") !== INTEGRATION &&
																		dataErrors.map((row) => (
																			<Grid item className={classes.alert}>
																				<Alert severity="error" fullWidth>
																					{row.withParam
																						? t(row.message, { VatNumber: row.param })
																						: t(row)}
																				</Alert>
																			</Grid>
																		))}
																</>
																<>
																	{get(order, "alertes.alertes") &&
																		!isEmpty(order.alertes.alertes) &&
																		order.alertes.alertes.map((row) => (
																			<Grid item className={classes.alert}>
																				<Alert severity="warning" fullWidth>
																					{row.message}
																				</Alert>
																			</Grid>
																		))}
																</>
															</>
														</TableContainer>
														<Grid container item xs={12} direction="column" />
													</Grid>
												</Grid>
											)}
										</Grid>
									) : (
										<CircularLoader />
									)}
								</TabPanel>
							)}
							{!isEmpty(get(order, "purchaseOrder.deliveryItems", [])) &&
								get(props.orderPersisted, "status.code") !== INTEGRATION && (
									<TabPanel section={2} value={value} index={1} dir={theme.direction}>
										<DeliveryItemsSection
											deliveryItems={get(order, "purchaseOrder.deliveryItems")}
											orderNo={get(order, "purchaseOrder.orderNo")}
										/>
									</TabPanel>
								)}
						</Grid>
					</Grid>
				)}

				<Grid container item xs={rectify ? 6 : 5}>
					<Grid item xs={12}>
						{rectify ? (
							<DesignCanvas
								orderId={props.match.params.idOrder}
								location={location}
								previewFile={previewFile}
							/>
						) : (
							<>
								<AppBar position="relative" color="default" elevation={0}>
									<Tabs
										className={classes.root}
										value={scValue}
										onChange={handleChangeSecondTab}
										indicatorColor="secondary"
										textColor="secondary"
										variant="fullWidth"
										aria-label="full width tabs example2"
									>
										{/* eslint-disable-next-line */}
										<Tab label={t("Order overview")} {...a11yProps(0)} />
										{/* eslint-disable-next-line */}
										{!isClient && <Tab label={t("History")} {...a11yProps(1)} />}
										<Tab label={t("Invoice attachements")} {...a11yProps(isClient ? 1 : 2)} />
									</Tabs>
								</AppBar>
								<TabPanel
									style={{
										minHeight: "800px",
									}}
									section={2}
									value={scValue}
									index={0}
									dir={theme.direction}
								>
									{previewFile ? (
										mimeType === "application/pdf" && previewFile ? (
											<>
												<PDFViewer
													backend={PDFJSBackend}
													src={previewFile}
													style={{
														height: "800px",
													}}
												/>
											</>
										) : (
											<img src={previewFile} className={classes.previewImg} alt="preview" />
										)
									) : (
										<CircularLoader />
									)}
								</TabPanel>
								{!isClient && (
									<TabPanel section={2} value={scValue} index={1} dir={theme.direction}>
										<History
											idOrder={props.match.params.idOrder}
											{...props} //eslint-disable-line
										/>
									</TabPanel>
								)}
								<TabPanel
									section={3}
									value={scValue}
									index={isClient ? 1 : 2}
									dir={theme.direction}
								>
									<Attachements />
								</TabPanel>
							</>
						)}
					</Grid>
				</Grid>
			</Grid>
			<Grid container item xs={12} direction="column" />
			<Grid container item xs={12} direction="column">
				{rectify ? (
					<Grid
						container
						item
						xs={5}
						className={classes.rectifyHeader}
						spacing={1}
						direction="row-reverse"
						justify="flex-end"
					>
						<Grid item xs={4}>
							<Button
								variant="outlined"
								fullWidth
								color="secondary"
								disabled={
									props.orderPersisted?.client && props.orderPersisted?.client?.lockedTraining
								}
								onClick={() => {
									setConfirmObject({
										message: t("invoice.detail.message.rectify"),
										openConfirm: true,
										rectify: true,
										training: true,
										isLoading: false,
									});
								}}
							>
								{t("invoice.detail.apply.validate")}
							</Button>
						</Grid>
						<Grid item xs={4}>
							<Button
								variant="outlined"
								fullWidth
								color="secondary"
								onClick={() => {
									setConfirmObject({
										message: t("order.detail.message.rectify"),
										openConfirm: true,
										rectify: true,
										training: false,
										isLoading: false,
									});
								}}
							>
								{t("Apply Changes")}
							</Button>
						</Grid>
						<Grid item xs={4}>
							<Button
								variant="outlined"
								fullWidth
								className={classes.rejectButton}
								onClick={() => {
									setConfirmObject({
										message: t("CancelActionMessage"),
										openConfirm: true,
										cancel: true,
										isLoading: false,
									});
								}}
							>
								{t("cancel")}
							</Button>
						</Grid>
					</Grid>
				) : (
					<>
						{get(props, "orderPersisted.status.code") === "EXPORTED" && settings.loaded ? (
							<Grid container item xs={5} className={classes.header} spacing={1}>
								{getDataFromSettings("EXPORT_EXCEL") && (
									<Grid item xs>
										<Button
											variant="contained"
											fullWidth
											color="secondary"
											onClick={() => {
												exportEXCEl();
											}}
											disabled={exportIsLoading?.excel}
										>
											{exportIsLoading?.excel && <CircularProgress size={14} />}&nbsp;
											{t("EXPORT_EXCEL")}
										</Button>
									</Grid>
								)}

								{getDataFromSettings("EXPORT_XML") && (
									<Grid item xs>
										<Button
											variant="contained"
											fullWidth
											color="secondary"
											onClick={() => {
												exportXML();
											}}
											disabled={exportIsLoading?.xml}
										>
											{exportIsLoading?.xml && <CircularProgress size={14} />}&nbsp;
											{t("EXPORT_XML")}
										</Button>
									</Grid>
								)}
								{getDataFromSettings("EXPORT_PDF") && (
									<Grid item xs>
										<Button
											variant="contained"
											fullWidth
											color="secondary"
											onClick={() => {
												exportPDF();
											}}
											disabled={exportIsLoading?.pdf}
										>
											{exportIsLoading?.pdf && <CircularProgress size={14} />}&nbsp;
											{t("EXPORT_PDF")}
										</Button>
									</Grid>
								)}
								{getDataFromSettings("EXPORT_EDI") && (
									<Grid item xs>
										<Button
											variant="contained"
											fullWidth
											color="secondary"
											onClick={() => {
												exportEdifact();
											}}
											disabled={exportIsLoading?.edi}
										>
											{exportIsLoading?.edi && <CircularProgress size={14} />}&nbsp;
											{t("EXPORT_EDI")}
										</Button>
									</Grid>
								)}
								{getDataFromSettings("EXPORT_IDOC") && (
									<Grid item xs>
										<Button
											variant="contained"
											fullWidth
											color="secondary"
											onClick={() => {
												exportIdoc();
											}}
											disabled={exportIsLoading?.idoc}
										>
											{exportIsLoading?.idoc && <CircularProgress size={14} />}&nbsp;
											{t("EXPORT_IDOC")}
										</Button>
									</Grid>
								)}
							</Grid>
						) : (
							<Grid
								container
								item
								xs={5}
								justify="flex-start"
								className={classes.header}
								spacing={1}
							>
								<Grid item xs={4} style={{ padding: "0 4px 0 0" }}>
									{!isClient && (
										<Button
											variant="contained"
											fullWidth
											className={classes.rejectButton}
											onClick={() => {
												setOpenRejectPopup(true);
											}}
											disabled={
												props.orderPersisted &&
												props.orderPersisted.status.code !== TO_VERIFY &&
												props.orderPersisted.status.code !== TO_CONTROL
											}
										>
											{t("Reject Order")}
										</Button>
									)}
									<DialogReject
										open={openRejectPopup}
										setOpen={setOpenRejectPopup}
										onClose={onCloseDialogReject}
										// eslint-disable-next-line react/jsx-props-no-spreading
										{...props}
									/>
								</Grid>
								{props.orderPersisted &&
								(props.orderPersisted.status.code === "TO_CONTROL" ||
									props.orderPersisted.status.code === "TO_PAY") ? (
									// eslint-disable-next-line react/jsx-no-undef
									<>
										<Grid item xs={4} style={{ padding: "0 0 0 4px" }}>
											{props.orderPersisted && props.orderPersisted.status.code === TO_CONTROL && (
												// eslint-disable-next-line react/jsx-no-undef
												<>
													{showExportBtn() && (
														<Button
															variant="contained"
															fullWidth
															color="secondary"
															onClick={() => {
																setConfirmObject({
																	message: t("order.detail.message.confirmExport"),
																	openConfirm: true,
																	export: true,
																	isLoading: false,
																});
															}}
														>
															{t("export")}
														</Button>
													)}
												</>
											)}
										</Grid>
										<Grid item xs={4} style={{ padding: "0 0 0 4px" }}>
											{props.orderPersisted && props.orderPersisted.status.code === TO_CONTROL && (
												// eslint-disable-next-line react/jsx-no-undef
												<>
													{getShowApproval() && (
														<Button
															variant="outlined"
															fullWidth
															color="secondary"
															onClick={() => {
																setOpenApplyForApprovalPopup(true);
															}}
														>
															{t("Apply for approval")}
														</Button>
													)}
												</>
											)}

											<ApprovalDialog
												open={openApplyForApprovalPopup}
												setOpen={setOpenApplyForApprovalPopup}
												// eslint-disable-next-line react/jsx-props-no-spreading
												{...props}
											/>
										</Grid>
									</>
								) : (
									props.orderPersisted &&
									props.orderPersisted.treatable &&
									!isClient && (
										// eslint-disable-next-line react/jsx-no-undef
										<>
											<Grid item xs={4} style={{ padding: "0 2px 2px 0" }}>
												<Button
													variant="outlined"
													fullWidth
													color="secondary"
													disabled={
														props.orderPersisted && props.orderPersisted.status.code !== TO_VERIFY
													}
													onClick={() => setRectify(!rectify)}
												>
													{t("Correct Extraction")}
												</Button>
											</Grid>
											<Grid item xs={4} style={{ padding: "0 0 0 4px" }}>
												<Button
													variant="contained"
													fullWidth
													color="secondary"
													to="/"
													onClick={() => {
														setConfirmObject({
															message: isEmpty(dataErrors)
																? t("orderControlMessage")
																: t("ErrorMessageToControl"),
															openConfirm: true,
															control: true,
															isLoading: false,
														});
													}}
													disabled={
														!get(order, "client") ||
														(props.orderPersisted && props.orderPersisted.status.code !== TO_VERIFY)
													}
												>
													{t("Control")}
												</Button>
												<ConfirmationDialog
													dataErrors={dataErrors}
													open={openControlPopup}
													setOpen={setOpenControlPopup}
													// eslint-disable-next-line react/jsx-props-no-spreading
													{...props}
												/>
											</Grid>
										</>
									)
								)}
							</Grid>
						)}
					</>
				)}
			</Grid>
			<ConfirmDialog
				message={confirmObject.message}
				open={confirmObject.openConfirm}
				isLoading={confirmObject.isLoading}
				onConfirm={onConfirm}
				onCancel={onCancel}
			/>
			<Grid container item xs={12} direction="column" className={classes.headerAction} />
		</Grid>
	);
};

const mapStateToProps = (state) => {
	const dataQueris = state.requests.queries;
	const dataMutations = state && state.mutations;
	const { orderFormulaire } = state;
	return {
		statusList:
			dataQueris.LIST_FETCH_STATUS &&
			dataQueris.LIST_FETCH_STATUS.data &&
			dataQueris.LIST_FETCH_STATUS.data.orderStatusList,
		clients: dataQueris.FETCH_CLIENTS && dataQueris.FETCH_CLIENTS.data,
		client: dataQueris.FETCH_CLIENT && dataQueris.FETCH_CLIENT.data,
		status: dataQueris.FETCH_STATUS && dataQueris.FETCH_STATUS.data,
		paymentTerm: dataQueris.FETCH_PAYMENT_TERM && dataQueris.FETCH_PAYMENT_TERM.data,
		paymentTerms: dataQueris.FETCH_PAYMENT_TERMS && dataQueris.FETCH_PAYMENT_TERMS.data,
		orderPersisted: dataQueris.FETCH_ORDER && dataQueris.FETCH_ORDER.data,
		billingLines:
			dataQueris.LIST_FETCH_BILLING_LINES_ORDER && dataQueris.LIST_FETCH_BILLING_LINES_ORDER.data,
		document: dataQueris.FETCH_DOCUMENT_ORDER && dataQueris.FETCH_DOCUMENT_ORDER.data,
		response: dataQueris.RESPONSE && dataQueris.RESPONSE.data,
		rejectionMotifs:
			dataQueris.FETCH_REJECTION_MOTIFS_ORDER && dataQueris.FETCH_REJECTION_MOTIFS_ORDER.data,
		rejectionError:
			dataMutations &&
			dataMutations.CREATE_ORDER_REJECTION &&
			dataMutations.CREATE_ORDER_REJECTION.error,
		selectedItem: orderFormulaire.selectedItem,
	};
};

const mapDispatchToProps = {
	fetchOrder,
	fetchClients,
	fetchDocumentOrder,
	fetchPaymentTerms,
	fetchRejectionMotifsOrder,
	verifyOrder,
	createOrderApproval,
	createOrderRejection,
	updateFormulaire,
	rectifyOrder,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetails);
