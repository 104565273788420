import React from "react";
import { Handle } from "react-flow-renderer";

const customNodeStyles = {
	backgroundColor: "#d4d9ff",
	color: "#546aff",
	border: "2px solid #546aff",
	fontWeight: 400,
	borderRadius: 5,
	padding: 5,
};

const InvoiceStatusNode = ({ data, id }) => (
	<div style={customNodeStyles}>
		<Handle type="target" position="left" style={{ borderRadius: 0 }} />
		<div>{data.label}</div>
		{id !== "TO_VALIDATE_EXTERNAL" && (
			<Handle type="source" position="right" id="a" style={{ borderRadius: 0 }} />
		)}
	</div>
);

export default InvoiceStatusNode;
