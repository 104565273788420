import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { useDispatch, useSelector } from "react-redux";
import get from "lodash/get";
import { fetchSettingsByModuleName } from "../../redux/actions/users";
import MyTasks from "./components/MyTasks";
import LastActions from "./components/LastActions";
import OrdersList from "../Orders/OrdersList";
import AddOrder from "./components/AddOrder";
import PaidOrder from "./components/PaidOrder";
import UnpaidOrder from "./components/UnpaidOrder";
import useStyles from "./style";

const formatSettings = (data) =>
	data.reduce((acc, { code, value }) => ({ ...acc, [code]: parseInt(value, 10) }), {});

const SettingsDisplay = ({ value, children }) => (value ? children : <></>);

const ClientsDashboard = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const currentUser = useSelector(({ requests }) =>
		get(requests, "queries.FETCH_CURRENT_USER.data")
	);

	const [settings, setSettings] = useState({});

	useEffect(() => {
		dispatch(fetchSettingsByModuleName("order")).then((res) => {
			if (get(res, "data")) {
				setSettings(formatSettings(res.data));
			}
		});
	}, [dispatch]);

	const col1 = settings.INDICATOR_MY_TASKS;
	const col2 = +(settings.INDICATOR_ORDERS_EXPORTED + settings.INDICATOR_LOAD_ORDERS) > 0;
	const col3 = +(settings.INDICATOR_ORDERS_NOT_EXPORTED + settings.INDICATOR_LAST_ACTIONS) > 0;
	const col = 12 / (col1 + col2 + col3);

	return get(currentUser, "roles") &&
		(currentUser.roles.includes("order_user") || currentUser.roles.includes("manager")) ? (
		<>
			<Grid container alignItems="stretch" className={classes.firstGrid}>
				<SettingsDisplay value={settings.INDICATOR_MY_TASKS}>
					<Grid item xs={12} sm={col}>
						<MyTasks elevation={26} square />
					</Grid>
				</SettingsDisplay>
				<SettingsDisplay
					value={settings.INDICATOR_ORDERS_EXPORTED || settings.INDICATOR_LOAD_ORDERS}
				>
					<Grid container item xs={12} sm={col}>
						<SettingsDisplay value={settings.INDICATOR_ORDERS_EXPORTED}>
							<Grid item xs={12}>
								<PaidOrder elevation={26} square />
							</Grid>
						</SettingsDisplay>
						<SettingsDisplay value={settings.INDICATOR_LOAD_ORDERS}>
							<Grid item xs={12}>
								<AddOrder settings={settings} elevation={26} square />
							</Grid>
						</SettingsDisplay>
					</Grid>
				</SettingsDisplay>
				<Grid container item xs={12} sm={col}>
					<SettingsDisplay value={settings.INDICATOR_ORDERS_NOT_EXPORTED}>
						<Grid item xs={col === 12 ? 6 : 12}>
							<UnpaidOrder elevation={26} square style={{ background: "#f8f9fa" }} />
						</Grid>
					</SettingsDisplay>
					<SettingsDisplay value={settings.INDICATOR_LAST_ACTIONS}>
						<Grid item xs={col === 12 ? 6 : 12}>
							<LastActions elevation={26} square />
						</Grid>
					</SettingsDisplay>
				</Grid>
			</Grid>
			<Grid container spacing={4}>
				<Grid container spacing={4} item xs={12}>
					<Grid item xs={12} sm={12}>
						<SettingsDisplay value={settings.INDICATOR_LAST_5_ORDERS}>
							<OrdersList settings={settings} isDashboard />
						</SettingsDisplay>
					</Grid>
				</Grid>
			</Grid>
		</>
	) : (
		<></>
	);
};

export default ClientsDashboard;
