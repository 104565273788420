export default {
	lastName: "Last name",
	firstName: "First name",
	email: "Email",
	Username: "Username",
	userFunction: "Position",
	supplier_name: "Supplier",
	client_name: "Client name",
	job: "Function",
	manageUsers: " Manage users",
	manageSuppliers: "Manage suppliers",
	manageClients: "Manage clients",
	manageGroups: "Manage groups",
	manageRoles: "Manage roles",
	manageWorkflow: "Workflow management",
	manageLicenseAddOn: "Manage licenses & Add-ons",
	status: "Status",
	ACTIVE: "Active",
	WAITING: "Waiting",
	DISABLE: "Disable",
	add_user: "Add user",
	add_user_button: "ADD USER",
	add_group_button: "ADD COMMERCIAL ORGANIZATION",
	add_supplier_button: "ADD SUPPLIER",
	add_client_button: "ADD CLIENT",
	add_supplier: "Add supplier",
	add_client: "Add client",
	edit_supplier: "Edit supplier",
	sendEmailCreationUserMessage:
		"An email with the username and password was sent to the user to enable connection",
	CancelActionMessage: "Are you sure you want to cancel this action?",
	searchUser: "Search",
	userCivility: "Civility",
	administrator: "Administration",
	user: "Agent",
	supplier: "Supplier",
	Mr: "Sir",
	Ms: "Miss",
	Mrs: "Madam",
	enableDisableActionMessage: "Are you sure you want to enable / disable this user",
	edit_user: "Edit User",
	// validation messages
	lastName_required: "Last name is required",
	lastName_notValid: "Last name is not valid",
	supplierName_notValid: "Supplier name is not valid",
	firstName_required: "First name is required",
	firstName_notValid: "First name is not valid",
	phone_notValid: "Phone is not valid",
	supplier_required: "Supplier name is required",
	email_required: "Email is required",
	email_notValid: "Email is  not valid",
	siren_notValid: "The SIREN code is not valid",
	siret_notValid: "Company tax registration number is  not valid",
	url_notValid: "URL is  not valid",
	name_notValid: "Name is not valid",
	supplier_created:
		"Supplier added successfully.If the supplier is not displayed on your suppliers list please contact your administrator",
	supplier_updated: "Supplier updated successfully!",
	supplier_error: "Error occurred while saving supplier",
	supplier_update_error: "Failed to update supplier details!",
	job_required: "The function is required",
	civility_required: "Civility is required",
	doctype_required: "Document type is required",
	deleteUserActionMessage: "Are you sure you want to delete this user?",
	deleteUsersActionMessage: "Are you sure you want to delete these users?",
	"admin.settings": "Settings",
	"admin.commercialOrganization": "Commercial organization",
	"admin.settings.invoices": "Invoices Settings",
	"admin.settings.orders": "Orders Settings",
	"admin.config.update-settings": "Settings have been successfully updated",
	"admin.config.update-message": "Email configuration has been successfully updated",
	"admin.AUTO_EXPORT": "Automatic Export",
	"admin.AMOUNT_TOLERANCE": "Tolerance of totals",
	"admin.MAXIMUM_SIZE": "Maximum size of a loaded file",
	"admin.email-config": "Email configuration",
	"admin.ftp-config": "FTP configuration",
	"admin.import-config": "Import configuration",
	Host: "Host",
	Port: "Port",
	Email: "Email",
	Password: "Password",
	"Read folder": "Read folder",
	"Processing folder": "Processing folder",
	"Success folder": "Success folder",
	"Exception folder": "Exception folder",
	supplierTraining: "Supplier training",
	clientTraining: "Client training",
	deleteTraining: "Delete training",
	deleteTrainingMessage: "Are you sure you want to delete training from this provider",
	successdeleteTrainingMessage: "Learning has been deleted successfully",
	"admin.download-buttons": "Actions on invoices",
	"admin.order.download-buttons": "Actions on orders",
	"admin.download-buttons-description":
		"Choose which actions you would like to allow on your invoices in the portal.",
	"admin.order.download-buttons-description":
		"Choose which actions you would like to allow on your orders in the portal.",
	"admin.download-button.excel": "Download in Excel Format",
	"admin.download-button.xml": "Download in XML Format",
	"admin.download-button.pdf": "Download in PDF Format",
	"admin.download-button.edi": "Download in EDI Format",
	"admin.download-button.journal-entry": "Download Excel Journal Entry",
	"admin.invoice.AUTO_EXPORT-description":
		"This parameter activates the automatic export of invoices that are fully extracted and have no error messages.",
	"admin.order.AUTO_EXPORT-description":
		"This parameter activates the automatic export of order that are fully extracted and have no error messages.",
	"admin.invoice.AMOUNT_TOLERANCE-description":
		"This field allows you to set a tolerance in the calculation of the totals of an invoice. It is on the basis of this tolerance that error messages will be displayed.",
	"admin.order.AMOUNT_TOLERANCE-description":
		"This field allows you to set a tolerance in the calculation of the totals of an order. It is on the basis of this tolerance that error messages will be displayed.",
	"admin.MAXIMUM_SIZE-description": "Maximum size of a document to upload in MBs",
	"admin.display-indicators": "Indicators display in the dashboard",
	"admin.display-indicators-description":
		"Choose which indicators you want to visualize in your NovyPOM Dashboard.",
	"admin.setting.my_tasks": "My tasks",
	"My orders": "My orders",
	"admin.setting.invoices-exported": "Invoices exported",
	"admin.setting.invoices-not-exported": "Invoices not exported",
	"admin.setting.last-actions": "Last actions",
	"admin.setting.load-invoices": "Load invoices",
	"admin.setting.last-5-invoices": "Last 5 invoices",
	"admin.setting.last-5-orders": "Last 5 orders",
	"admin.setting.download-pdf": "Download PDF",
	"admin.setting.delete": "Delete",
	"admin.setting.delete-all": "Delete all invoices",
	"admin.order.setting.delete-all": "Delete all orders",
	"admin.setting.relaunch-extraction": "Relaunch the extraction",
	"admin.setting.AMOUNT_TOLERANCE.required": "Tolerance of totals is required",
	"admin.export-buttons": "Export download buttons",
	"admin.invoice.export-buttons-description":
		"Set up the display of possible downloads shortcuts after exporting an invoice. The buttons will be visible after the export in the invoice details.",
	"admin.order.export-buttons-description":
		"Set up the display of possible downloads shortcuts after exporting an order. The buttons will be visible after the export in the order details.",
	"admin.setting.export_entry_account_excel": "Export Excel Journal Entry",
	"admin.extraction-level-settings": "Importance of the extracted fields (Extraction level)",
	"admin.extraction-level-settings-desciption":
		"Set the importance of the fields which seem the most important to you and of those which you are essential. This will be taken into account in the calculation of the Extraction Level.",
	"admin.level-settings": "Extraction Level Settings",
	"admin.extraction-level.champs": "Fields",
	"admin.extraction-level.status": "Status",
	"admin.extraction-level.mandatory": "Essential",
	"admin.extraction-level.ignored": "Ignored",
	"admin.extraction-level.important": "Important",
	"admin.setting.message.confirm": "Are you sure you want to update these settings?",
	"admin.user.confirm.message.edit": "Are you sure you want to update this user?",
	"admin.user.confirm.message.add": "Are you sure you want to save this user?",
	"admin.supplier.confirm.message.add": "Are you sure you want to save this user?",
	"admin.supplier.confirm.message.edit": "Are you sure you want to update this user?",
	"admin.config.message.confirm": "Are you sure you want to update this email configuration?",
	"admin.extraction.message.confirm": "Are you sure you want to update these extractions levels?",
	"invoice.user.updated.success": "User was updated  successfully",
	"admin.config.ftp.message.confirm":
		"Are you sure you want to modify this configuration of the FTP server?",
	"admin.config.ftp.update-message":
		"The configuration of the FTP server has been updated successfully",
	agent_accounting: "Accounting Agent",
	agent_purchase: "Purchasing Agent",
	"admin.settings.invoice": "Invoice",
	"admin.settings.order": "Order",
	"admin.settings.order.upload": "Upload orders",
	"order.exported": "Exported orders",
	"order.unexported": "Non-exported orders",
	"admin.client.confirm.message.add": "Are you sure you want to save this user?",
	"pagination.rows.per.page": "Rows per page:",
	fix_errors: "Cannot proceed, please check the following errors",
	automatic_transition: "Allow Automatic Transition",
	"rule.orderMissing": "The order number does not exist",
	"rule.orderNumberAbsent": "The order number is not available",
	"rule.quantityInvalid": "The invoiced quantity is higher than the quantity in the order",
	"rule.unitPriceLineInvalid":
		"The unit price invoiced is different than the unit price of the order",
	"rule.orderLineMissing": "The order line does not exist",
	"rule.orderLineMissings": "The order lines do not exist",
	"rule.totalAmountInvalid":
		"The total amount of the invoice is higher than the total amount of the order",
	"rule.cumulativeOrderInvalid":
		"The total amount will be higher than the total in the order after export",
	"rule.accumulatedQuantityLineInvalid":
		"The calculated quantity will be higher than the quantity of the order after export",
	"rule.incoherenceAmounts":
		"The total amount tax included is different than tax free amount plus VAT",
	"rule.totalEmpty": "The total amount of the invoice is empty",
	"rule.DeliveryMissing": "The delivery slip does not exist",
	"rule.DeliveredQuantityInvalid":
		"The invoiced quantity is higher than delivered quantity as mentioned in the delivery slip",
	"rule.sumTotalHtInvalid":
		"The total tax free amount is different than the sum of the tax free lines",
	"rule.duplicatedInvoice": "The invoice already exists",
	transition: "Transition",
	transitionbuttonlabel: "Button Label",
	addstatus: "Add Status",
	saveworkflow: "Save Workflow",
	asksaveworkflow: "Are you sure you want to save the workflow ?",
	update: "Update",
	deletestatus: "Delete Status",
	deletetransition: "Delete Transition",
	updatestatus: "Update status",
	updatetransition: "Update Transition",
	blockedby: "Blocked By",
	appliedrules: "Applied Rules",
	errorautotransition: "You cannot have more than one automatic transition",
	AbleCorrect: "Eligible for manual correction",
	workflow: "Workflow",
	"workflow.delete.error":
		"This status has {{count}} transition, please delete the transition before deleting the status.",
	"workflow.status.success": "Workflow updated successfuly!",
	"workflow.source.status.litige.message":
		"The status in litigation must not have outgoing transitione",
	"workflow.property.isLitige": "Litigation status",
	manager: "Manager",
	"user.super_user": "Super user",
	"user.super_user.add": "Add super user",
	"user.super_user.edit": "Edit super user",
	managerSuperusers: "Manage Super Users",
	"admin.download-button.idoc": "Download in IDOC Format",
	"admin.setting.download-idoc": "Download IDOC",
	"admin.setting.download-edi": "Download EDI",
	"admin.workflow": "Workflow",
	"admin.setting.download-ubl": "Export to UBL",
	"admin.setting.download-cii": "Export to CII16B",
	"admin.setting.download-xcbl": "Export to XCBL",
	"admin.setting.download-facturx": "Export to Factur-X",
	"user.field.commercialOrganization": "Commercial organization",
	"admin.commercialOrganization.name": "Name",
	"admin.commercialOrganization.description": "Description",
	"admin.commercialOrganization.suppliers": "Suppliers",
	"admin.commercialOrganization.clients": "Clients",
	"admin.commercialOrganization.add": "Add",
	"admin.commercialOrganization.remove": "Remove",
	"admin.commercialOrganization.edit":
		"Are you sure you want to edit this commercial organization?",
	"admin.commercialOrganization.save":
		"Are you sure you want to save this commercial organization?",
	"admin.commercialOrganization.editRecord": "Add commercial organization",
	"admin.commercialOrganization.addRecord": "Edit commercial organization",
	"admin.commercialOrganization.success": "Commercial organization added successfully",
	"admin.commercialOrganizationEdit.success": "Commercial organization changed successfully",
	"admin.commercialOrganization.deleteSuccess": "Commercial organization delete successfully",
	"admin.commercialOrganization.deleteMsg":
		"Are you sure you want to delete this commercial organization?",
	"user.commercialOrganization.exists.message": "The commercial organization already exists.",
	"user.commercialOrganization.users.exists.message":
		"Unable to delete commercial organization. Some users are still assigned.",
	"user.commercial_organization_showAll": "Affect all",
	"user.commercial_organization_showAll.description":
		"By choosing this option, the director will have permission to see all the data related to invoices and orders",
};
