/* eslint-disable global-require */
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Input from "@material-ui/core/Input";
import CountrySelect from "components/CountryPicker";
import { useDispatch } from "react-redux";
import countries from "i18n-iso-countries";
import { fetchClients } from "redux/actions/clients";
import { REGEX } from "../../../utils";
import { fetchSuppliers } from "../../../redux/actions/suppliers";
import { ImageProfil } from "../../Suppliers/Component/ImageProfil";
import useStyles from "../style";

const GeneralInformation = (props) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const classes = useStyles();
	useEffect(() => {
		if (props.isClient) dispatch(fetchClients());
		else dispatch(fetchSuppliers());
	}, []);

	const lang = localStorage.getItem("lgn") ? localStorage.getItem("lgn") : "fr";
	const temp = countries.getNames(lang, { select: "official" });
	const countryList = [];
	Object.keys(temp).forEach((ctr) => countryList.push({ isoCode: ctr, displayName: temp[ctr] }));

	return (
		<Grid container xs={12} justify="center">
			<Grid container xs={2} alignItems="center" justify="flex-end">
				<Typography variant="subtitle2" align="left" color="primary">
					{t("supplier.add.logo")}
					<Grid justify="center" className={classes.ImageProfil}>
						<ImageProfil
							src={props.supplier?.logo}
							changeValue={props.setLogo}
							isClient={props.isClient}
						/>
					</Grid>
				</Typography>
			</Grid>
			<Grid container xs={10} justify="flex-end">
				<Grid container xs={12} justify="center" spacing={6} className={classes.gridRow}>
					<Grid item xs={4}>
						<Typography variant="subtitle2" align="left" color="primary">
							{t("supplier.add.country")}
							<CountrySelect
								onChange={(newValue) => {
									props.updateSupplier({
										target: {
											name: "countryCode",
											value: newValue,
										},
									});
								}}
								value={props.supplier?.countryCode}
								name="countryCode"
								countryList={countryList}
							/>
						</Typography>
					</Grid>
					<Grid item xs={4}>
						<Typography variant="subtitle2" align="left" color="primary">
							{props.isClient ? t("add.client.name") : t("supplier.add.suppliername")}
							<span style={{ color: "red" }}>*</span>
							<br />
							<Input
								value={props.supplier?.code}
								onChange={(event) => props.updateSupplier(event)}
								onBlur={(event) => {
									props.setSupplier({
										...props.supplier,
										[event.target.name]: event.target.value.trim().replace(/\s\s+/g, " "),
									});
								}}
								name="code"
								inputRef={props.register({
									required: { value: true, message: t("supplier_required") },
								})}
								disabled={!!props.edit}
								fullWidth
							/>
							<p className={classes.validation_error}>{props.errors.code?.message}</p>
						</Typography>
					</Grid>
				</Grid>
				<Grid container item xs={12} justify="center" spacing={6} className={classes.gridRow}>
					<Grid item xs={4}>
						<Typography variant="subtitle2" align="left" color="primary">
							{t("supplier.add.street")}
							<br />
							<Input
								value={props.supplier?.street}
								onChange={(event) => props.updateSupplier(event)}
								name="street"
								fullWidth
							/>
						</Typography>
					</Grid>
					<Grid item xs={4}>
						<Typography variant="subtitle2" align="left" color="primary">
							{t("supplier.add.city")}
							<br />
							<Input
								value={props.supplier?.city}
								onChange={(event) => props.updateSupplier(event)}
								name="city"
								fullWidth
							/>
						</Typography>
					</Grid>
				</Grid>
				<Grid container item xs={12} justify="center" spacing={6} className={classes.gridRow}>
					<Grid item xs={4}>
						<Typography variant="subtitle2" align="left" color="primary">
							{t("supplier.add.siret")}
							<br />
							<Input
								value={props.supplier?.companySiret}
								onChange={(event) => props.updateSupplier(event)}
								name="companySiret"
								fullWidth
							/>
							{/* <p className={classes.validation_error}>
								{props.errors.companySiret && t("siret_notValid")}
							</p> */}
						</Typography>
					</Grid>
					<Grid item xs={4}>
						<Typography variant="subtitle2" align="left" color="primary">
							{t("supplier.add.siren")}
							<br />
							<Input
								value={props.supplier?.companySiren}
								onChange={(event) => props.updateSupplier(event)}
								inputRef={props.register({
									pattern: { value: REGEX.SIREN, message: t("siren_notValid") },
								})}
								name="companySiren"
								fullWidth
							/>
							<p className={classes.validation_error}>{props?.errors?.companySiren?.message}</p>
						</Typography>
					</Grid>
				</Grid>
				<Grid container item xs={12} justify="center" spacing={6} className={classes.gridRow}>
					<Grid item xs={4}>
						<Typography variant="subtitle2" align="left" color="primary">
							{t("supplier.add.zipCode")}
							<br />
							<Input
								value={props.supplier?.zipCode}
								onChange={(event) => props.updateSupplier(event)}
								name="zipCode"
								fullWidth
							/>
							<p className={classes.validation_error}>{props.errors.zipCode?.message}</p>
						</Typography>
					</Grid>
					<Grid item xs={4}>
						<Typography variant="subtitle2" align="left" color="primary">
							{t("supplier.add.email")}
							<br />
							<Input
								value={props.supplier?.email}
								onChange={(event) => props.updateSupplier(event)}
								inputRef={props.register({
									pattern: { value: REGEX.EMAIL, message: t("email_notValid") },
								})}
								placeholder="supplychain@novelis.io"
								name="email"
								type="email"
								fullWidth
							/>
							<p className={classes.validation_error}>{props.errors.email?.message}</p>
						</Typography>
					</Grid>
				</Grid>
				<Grid container item xs={12} justify="center" spacing={6} className={classes.gridRow}>
					<Grid item xs={4}>
						<Typography variant="subtitle2" align="left" color="primary">
							{t("supplier.add.linkedin")}
							<Input
								value={props.supplier?.linkedIn}
								onChange={(event) => props.updateSupplier(event)}
								inputRef={props.register({
									pattern: { value: REGEX.LINKEDIN, message: t("url_notValid") },
								})}
								placeholder="https://www.linkedin.com/example"
								name="linkedIn"
								fullWidth
							/>
							<p className={classes.validation_error}>{props.errors.linkedIn?.message}</p>
						</Typography>
					</Grid>
					<Grid item xs={4}>
						<Typography variant="subtitle2" align="left" color="primary">
							{t("supplier.add.fax")}
							<Input
								value={props.supplier?.fax}
								onChange={(event) => props.updateSupplier(event)}
								inputRef={props.register({
									pattern: { value: REGEX.TEL, message: t("phone_notValid") },
								})}
								name="fax"
								placeholder="+33 123 456 7890"
								fullWidth
							/>
							<p className={classes.validation_error}>{props.errors.fax?.message}</p>
						</Typography>
					</Grid>
				</Grid>
				<Grid container item xs={12} justify="center" spacing={6} className={classes.gridRow}>
					<Grid item xs={4}>
						<Typography variant="subtitle2" align="left" color="primary">
							{t("supplier.add.phone")}
							<Input
								value={props.supplier?.phone}
								onChange={(event) => props.updateSupplier(event)}
								inputRef={props.register({
									pattern: { value: REGEX.TEL, message: t("phone_notValid") },
								})}
								name="phone"
								placeholder="+33 123 456 7890"
								fullWidth
							/>
							<p className={classes.validation_error}>{props.errors.phone?.message}</p>
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default GeneralInformation;
