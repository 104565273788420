import React from "react";
import Dialog from "@material-ui/core/Dialog";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";

const PopupModal = ({
	openPopup,
	setOpenPopup,
	dialogTitle,
	dialogContents,
	dialogActions,
	classes,
}) => (
	<Dialog
		open={openPopup}
		onClose={setOpenPopup}
		aria-labelledby="form-dialog-title"
		maxWidth="sm"
		fullWidth
	>
		<DialogTitle id="form-dialog-title">
			<Grid container xs={12} direction="row" justify="space-between" alignItems="center">
				{dialogTitle}
				<IconButton
					aria-label="close popup"
					onClick={() => {
						setOpenPopup(false);
					}}
				>
					<CloseIcon />
				</IconButton>
			</Grid>
		</DialogTitle>
		<Divider />
		<DialogContent>{dialogContents}</DialogContent>
		<DialogActions className={classes && classes.popupActions}>{dialogActions}</DialogActions>
	</Dialog>
);

export default PopupModal;
