import React, { useState, useEffect } from "react";
import { Query } from "@redux-requests/react";
import { Grid, TablePagination, Typography } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { FETCH_ALL_COMMERCIAL_ORGANIZATIONS } from "redux/constants";
import {
	fetchAllCommercialOrganizations,
	deleteCommercialOrganization,
} from "redux/actions/commercialOrganizations";
import CircularLoader from "components/Loaders/CircularLoader";
import { updateFilter } from "administration/redux/reducers/UserFilterReducer";
import ConfirmDialog from "components/ConfirmDialog";
import { toast } from "utils";
import { useQuery } from "components/Filter";

import CommercialOrganizationRow from "./Row";

// eslint-disable-next-line no-unused-vars
const CommercialOrganizationTable = ({ t, classes, module }) => {
	const dispatch = useDispatch();
	const query = useQuery();
	const [countUsers, setCountUsers] = useState(0);
	const filterUsers = useSelector(({ filterUsers: i }) => i);

	const isOrderEnv = useSelector(({ appState }) => appState.isOrderEnv);
	const env = isOrderEnv ? "ORDER" : "INVOICE";

	const [selectedId, setSelectedId] = useState(null);
	const [confirmObject, setConfirmObject] = useState({
		message: "",
		openConfirm: false,
		cancel: false,
		isLoading: false,
	});

	const handleChangePage = (event, newPage) => {
		dispatch(updateFilter({ ...filterUsers, pageNo: newPage }));
	};

	const handleChangeRowsPerPage = (event) => {
		dispatch(
			updateFilter({
				...filterUsers,
				pageSize: +event.target.value,
				pageNo: 0,
			})
		);
	};

	const onConfirm = () => {
		setConfirmObject({ ...confirmObject, isLoading: true });
		dispatch(deleteCommercialOrganization(selectedId, env)).then((res) => {
			if (res.status === 200) {
				toast.success(t("admin.commercialOrganization.deleteSuccess"));
			} else {
				toast.error(t(res.error.response.data.detail));
			}
			setConfirmObject({ ...confirmObject, openConfirm: false, isLoading: false });
			dispatch(fetchAllCommercialOrganizations(filterUsers));
		});
	};

	const onCancel = () => {
		setConfirmObject({ ...confirmObject, openConfirm: false, isLoading: false });
	};

	const handleDeleteCommercialOrganization = () => [
		setConfirmObject({
			...confirmObject,
			openConfirm: true,
			message: t("admin.commercialOrganization.deleteMsg"),
			isLoading: false,
		}),
	];

	const handleFilterValueChange = () => {
		dispatch(
			updateFilter({
				...filterUsers,
				userName: query.get("q"),
				pageNo: 0,
			})
		);
	};

	useEffect(() => {
		dispatch(fetchAllCommercialOrganizations(filterUsers));
	}, [filterUsers]);

	useEffect(() => {
		handleFilterValueChange();
	}, [query.get("q")]);

	return (
		<>
			<Grid container item style={{ width: "80%" }}>
				<Grid container justify="left" alignItems="center">
					<Grid item xs={3} align="left">
						<Grid item>
							<Typography variant="subtitle2" align="center" color="textSecondary">
								{t("admin.commercialOrganization.name")}
							</Typography>
						</Grid>
					</Grid>
					<Grid item xs={6} align="left">
						<Grid item xs={12}>
							<Typography variant="subtitle2" align="center" color="textSecondary">
								{t("admin.commercialOrganization.description")}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Query type={FETCH_ALL_COMMERCIAL_ORGANIZATIONS} loadingComponent={CircularLoader}>
				{({ data }) => {
					setCountUsers(data.totalElements);
					return data.content.map((row, index) => (
						<CommercialOrganizationRow
							key={`commercial_organization_row_${index}`}
							index={index}
							data={row}
							t={t}
							classes={classes}
							handleDeleteCommercialOrganization={handleDeleteCommercialOrganization}
							setSelectedId={setSelectedId}
						/>
					));
				}}
			</Query>
			<Grid container justify="flex-end">
				<TablePagination
					rowsPerPageOptions={[10, 25, 50]}
					component="div"
					count={countUsers}
					rowsPerPage={filterUsers.pageSize}
					page={filterUsers.pageNo}
					onChangePage={handleChangePage}
					onChangeRowsPerPage={handleChangeRowsPerPage}
					labelRowsPerPage={t("pagination.rows.per.page")}
				/>
			</Grid>
			<ConfirmDialog
				message={confirmObject.message}
				open={confirmObject.openConfirm}
				isLoading={confirmObject.isLoading}
				onConfirm={onConfirm}
				onCancel={onCancel}
			/>
		</>
	);
};

export default CommercialOrganizationTable;
