import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    display: "flex",
    marginBottom: "1%",
    cursor: "pointer",
    transition: theme.transitions.create("transform", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.short,
    }),
    "&:hover": {
      background: "white !important",
      boxShadow: theme.shadows[25],
      transform: "scale(1.01)",
    },
  },
  content: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  actions: {
    width: "21%",
  },
  avatar: {
    alignSelf: "center",
  },
  contentInfoGrid: {},
  deleteIcon: {
    color: theme.palette.error.main,
  },
  downloadIcon: {
    color: theme.palette.success.main,
  },
  cardTable: {
    background: theme.palette.grey[200],
  },
  tableCell: {
    borderBottom: "none",
    fontWeight: "300",
  },
  fullyExtracted: {
    borderRight: "7px solid #31d031",
  },
  partiallyExtracted: {
    borderRight: "7px solid #ff7600",
  },
  nonExtracted: {
    borderRight: "7px solid #ef2929",
  },
}));
