import React, { useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import get from "lodash/get";
import { Button, Input, NativeSelect, IconButton, Tooltip } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import GroupIcon from "@material-ui/icons/Group";
import EditIcon from "@material-ui/icons/Edit";
import { toast, REGEX } from "utils";
import clsx from "clsx";

import { fetchClients } from "redux/actions/clients";
import { createUser, fetchCivilities, fetchJobs, fetchUser, updateUser } from "../redux/actions";
import useStyles from "../style";
import ConfirmDialog from "../../components/ConfirmDialog";

export const AddClientAccount = (props) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [idUser, setIdUser] = React.useState();
	const isAddEditMode = ["edit", "add"].some(
		(i) => new URL(window.location).pathname.indexOf(i) !== -1
	);
	const isEditMode = ["edit"].some((i) => new URL(window.location).pathname.indexOf(i) !== -1);

	const clients = useSelector(
		({ requests }) =>
			requests.queries.FETCH_CLIENTS &&
			requests.queries.FETCH_CLIENTS.data &&
			requests.queries.FETCH_CLIENTS.data.content
	);
	const jobs = useSelector(
		({ requests }) => requests.queries.FETCH_USERS_JOBS && requests.queries.FETCH_USERS_JOBS.data
	);
	const civilities = useSelector(
		({ requests }) =>
			requests.queries.FETCH_USERS_CIVILITIES && requests.queries.FETCH_USERS_CIVILITIES.data
	)?.filter((civ) => civ.label !== "Ms");
	const userTomodify = useSelector(
		({ requests }) => requests.queries.FETCH_USER && requests.queries.FETCH_USER.data
	);
	const { t } = useTranslation();
	const classes = useStyles();
	const { errors, handleSubmit, register, setValue } = useForm();
	const [client, setClient] = React.useState("");
	const [job, setJob] = React.useState(t("client"));
	const [civility, setCivility] = React.useState("");

	const [confirmObject, setConfirmObject] = React.useState({
		message: "",
		openConfirm: false,
		cancel: false,
		isLoading: false,
	});
	const [userToSave, setUserToSave] = React.useState(null);
	const handleClientFieldChange = (event) => {
		const { target } = event;
		setClient(target.value);
	};
	const handleJobFieldChange = (event) => {
		const { target } = event;
		setJob(target.value);
	};
	const handleCivilityFieldChange = (event) => {
		const { target } = event;
		setCivility(target.value);
	};

	useEffect(() => {
		dispatch(fetchClients());
		dispatch(fetchJobs());
		dispatch(fetchCivilities());
	}, []);
	useEffect(() => {
		if (props.match.params.idUser) {
			setIdUser(props.match.params.idUser);
			dispatch(fetchUser(props.match.params.idUser));
		}
	}, [props.match.params.idUser]);
	useEffect(() => {
		if (props.match.params.idUser && userTomodify) {
			setValue("firstName", userTomodify.firstName);
			setValue("lastName", userTomodify.lastName);
			setValue("email", userTomodify.email);
			setClient(get(userTomodify, "client.label"));
			setValue("firstName", userTomodify.firstName);
			setValue("lastName", userTomodify.lastName);
			setJob(t(get(userTomodify, "jobTitle.label")));
			setCivility(t(get(userTomodify, "civility.label")));
		}
	}, [userTomodify]);
	const onSubmit = (data) => {
		data.jobTitle = t("client");
		const selectedClient = clients.find((s) => data.clientId === t(s.label));
		const tempValue = data.jobTitle === t("client") ? t("client") : t("user");
		const selectedJob = jobs.find((s) => tempValue === t(s.label));
		const selectedCivility = civilities.find((s) => data.civility === t(s.label));
		data = {
			...data,
			jobTitle: selectedJob,
			civility: selectedCivility,
			clientId: get(selectedClient, "id"),
		};
		setUserToSave(data);
		setConfirmObject({
			message: props.match.params.idUser
				? t("admin.client.confirm.message.edit")
				: t("admin.client.confirm.message.add"),
			openConfirm: true,
		});
	};
	const onConfirm = () => {
		if (!confirmObject.cancel) {
			let data = userToSave;
			setConfirmObject({ ...confirmObject, isLoading: true });
			if (props.match.params.idUser) {
				// update mode
				data = { ...data, id: props.match.params.idUser };
				dispatch(
					updateUser(
						props.match.params.idUser,
						data,
						() => {
							setConfirmObject({ message: "", openConfirm: false, isLoading: false });
							toast.success(t("invoice.user.updated.success"));
							setTimeout(() => {
								history.push("/administration/clients");
							}, 1000);
						},
						(error) => {
							setConfirmObject({ message: "", openConfirm: false, isLoading: false });
							toast.error(error);
						}
					)
				);
			} else {
				dispatch(
					createUser(
						data,
						() => {
							setConfirmObject({ message: "", openConfirm: false, isLoading: false });
							toast.success(t("sendEmailCreationUserMessage"));
							setTimeout(() => {
								history.push("/administration/clients");
							}, 1000);
						},
						(error) => {
							setConfirmObject({ message: "", openConfirm: false, isLoading: false });
							toast.error(error);
						}
					)
				);
			}
		} else {
			setConfirmObject({ message: "", openConfirm: false, cancel: false });
			history.push("/administration/clients");
		}
	};
	const onCancel = () => {
		setConfirmObject({ message: "", openConfirm: false });
	};

	return (
		<>
			<Grid container xs={12} justify="center" alignContent="center" alignItems="center">
				<Grid container item xs={12}>
					<Typography variant="caption" color="primary">
						<GroupIcon fontSize="small" /> {t("manageClients")} {t(" > ")}{" "}
						{props.match.params.idUser && isAddEditMode
							? t("edit_client")
							: !isAddEditMode && userTomodify
							? t("client")
							: t("add_client")}
					</Typography>
				</Grid>
				{!isAddEditMode && (
					<Grid container justify="flex-start" xs={12}>
						<Grid item xs={2}>
							<Button
								variant="contained"
								fullWidth
								color="secondary"
								className={clsx(classes.backBtn)}
								onClick={() => history.goBack()}
							>
								{t("return")}
							</Button>
						</Grid>
					</Grid>
				)}
				<Grid container item xs={12} justify="center" alignItems="center" alignContent="center">
					<form onSubmit={handleSubmit(onSubmit)}>
						<Grid
							container
							item
							xs={12}
							sm={12}
							className={classes.firstGrid}
							justify="center"
							alignItems="center"
							alignContent="center"
						>
							<Grid
								container
								item
								xs={12}
								className={classes.headerTitle}
								justify="center"
								alignItems="center"
								alignContent="center"
							>
								<Typography variant="h6" color="primary">
									{props.match.params.idUser && isAddEditMode
										? t("edit_client")
										: !isAddEditMode && userTomodify
										? t("client")
										: t("add_client")}
								</Typography>
							</Grid>
							<Grid
								container
								item
								xs={8}
								className={classes.loginContainer}
								spacing={6}
								justify="center"
								alignItems="center"
								alignContent="center"
							>
								{idUser ? (
									<Grid container justify="flex-end" xs={12}>
										{!isEditMode && (
											<Tooltip placement="top" title={t("tooltip.action.edit")}>
												<IconButton
													aria-label="edit"
													onClick={() =>
														history.push(
															`/administration/clients/edit/${props.match.params.idUser}`
														)
													}
												>
													<EditIcon />
												</IconButton>
											</Tooltip>
										)}
									</Grid>
								) : (
									<Grid />
								)}
								<Grid container item xs={12} spacing={6}>
									<Grid item xs={4}>
										<Typography variant="subtitle2" align="left" color="primary">
											{t("client_name")}
											<span style={{ color: "red" }}>*</span>

											<br />
											<NativeSelect
												value={client}
												onChange={handleClientFieldChange}
												inputRef={register({
													required: { value: true, message: t("client_required") },
												})}
												name="clientId"
												fullWidth
												disabled={!isAddEditMode}
											>
												{clients &&
													clients.map((elt, i) => (
														<option key={i} value={t(elt.label)}>
															{t(elt.label)}
														</option>
													))}
											</NativeSelect>
											<p>{errors.client?.message}</p>
										</Typography>
									</Grid>
									<Grid item xs={4}>
										<Typography variant="subtitle2" align="left" color="primary">
											{t("email")}
											<span style={{ color: "red" }}>*</span>

											<Input
												inputRef={register({
													required: { value: true, message: t("email_required") },
												})}
												name="email"
												type="email"
												disabled={props.match.params.idUser || !isAddEditMode}
												fullWidth
											/>
											<p>{errors.email?.message}</p>
										</Typography>
									</Grid>
									<Grid item xs={4}>
										<Typography variant="subtitle2" align="left" color="primary">
											{t("userFunction")}
											<span style={{ color: "red" }}>*</span>

											<br />

											<NativeSelect
												value={job}
												onChange={handleJobFieldChange}
												inputRef={register({
													required: { value: true, message: t("job_required") },
												})}
												disabled
												name="jobTitle"
												fullWidth
											>
												{jobs &&
													jobs.map((elt, i) => (
														<option
															key={i}
															selected={
																props.match.params.idUser &&
																userTomodify?.label &&
																elt.label === userTomodify.civility.label
															}
															value={t(elt.label)}
														>
															{t(elt.label)}
														</option>
													))}
											</NativeSelect>
											<p>{errors.jobTitle?.message}</p>
										</Typography>
									</Grid>
								</Grid>
								<Grid container item xs={12} spacing={6}>
									<Grid item xs={4}>
										<Typography variant="subtitle2" align="left" color="primary">
											{t("userCivility")}

											<br />
											<NativeSelect
												value={civility}
												onChange={handleCivilityFieldChange}
												inputRef={register()}
												name="civility"
												fullWidth
												disabled={!isAddEditMode}
											>
												{/* eslint-disable-next-line */}
												<option value="" />
												{civilities &&
													civilities.map((elt, i) => (
														<option key={i} value={t(elt.label)}>
															{t(elt.label)}
														</option>
													))}
											</NativeSelect>
											<p>{errors.civility?.message}</p>
										</Typography>
									</Grid>
									<Grid item xs={4}>
										<Typography variant="subtitle2" align="left" color="primary">
											{t("lastName")}

											<br />

											<Input
												inputRef={register({
													pattern: { value: REGEX.FORM_NAMES, message: t("lastName_notValid") },
												})}
												defaultValue={
													props.match.params.idUser && userTomodify && userTomodify.lastName
												}
												name="lastName"
												fullWidth
												disabled={!isAddEditMode}
											/>
											<p>{errors.lastName?.message}</p>
										</Typography>
									</Grid>
									<Grid item xs={4}>
										<Typography variant="subtitle2" align="left" color="primary">
											{t("firstName")}

											<br />
											<Input
												inputRef={register({
													pattern: { value: REGEX.FORM_NAMES, message: t("firstName_notValid") },
												})}
												name="firstName"
												fullWidth
												disabled={!isAddEditMode}
											/>
										</Typography>
										<p>{errors.firstName?.message}</p>
									</Grid>
								</Grid>
							</Grid>
							<Grid
								container
								item
								xs={12}
								spacing={2}
								className={classes.actions}
								justify="center"
								alignItems="center"
								alignContent="center"
							>
								{isAddEditMode && (
									<>
										<Grid item>
											<Button
												color="primary"
												autoFocus
												onClick={() =>
													setConfirmObject({
														message: t("CancelActionMessage"),
														openConfirm: true,
														cancel: true,
													})
												}
											>
												{t("cancel")}
											</Button>
										</Grid>
										<Grid item>
											<Button variant="contained" type="submit" color="secondary">
												{t("confirm")}
											</Button>
										</Grid>
									</>
								)}
							</Grid>

							<ConfirmDialog
								message={confirmObject.message}
								open={confirmObject.openConfirm}
								isLoading={confirmObject.isLoading}
								onConfirm={onConfirm}
								onCancel={onCancel}
							/>
						</Grid>
					</form>
				</Grid>
			</Grid>
		</>
	);
};

export default AddClientAccount;
