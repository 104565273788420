import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";

import { useTranslation } from "react-i18next";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Query } from "@redux-requests/react";
import { useDispatch } from "react-redux";
import { Typography } from "@material-ui/core";
import useStyles from "./style";
import { fetchInvoiceHistoryLitige } from "../../../../../redux/actions/invoices";
import CircularLoader from "../../../../../components/Loaders/CircularLoader";
import { FETCH_INVOICE_HISTORY_LITIGE_LIST } from "../../../../../redux/constants";

function HistoryLitige(props) {
	const classes = useStyles();
	const { t } = useTranslation();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchInvoiceHistoryLitige(props.idInvoiceLitige)).then((r) => console.log(r));
	}, [props]);
	return (
		<Grid xs={12} className={classes.historyContainer}>
			<List>
				<Query loadingComponent={CircularLoader} type={FETCH_INVOICE_HISTORY_LITIGE_LIST}>
					{({ data }) =>
						data.map((row) => (
							<ListItem>
								<ListItemText
									primary={
										<Typography>
											<strong>@{row.userFullName}</strong>
											{"  -  "}
											{row.date}
										</Typography>
									}
									secondary={
										<Grid container item xs={12} spacing={2} className={classes.spaceTop}>
											<Grid container item xs={12} spacing={2}>
												{row.statusCode && (
													<Grid item container xs={12}>
														<Grid item xs={6}>
															{t("action")}
														</Grid>
														<Grid item xs={6}>
															{t(`${row.statusCode}_LITIGE`)}
														</Grid>
													</Grid>
												)}
												{row.information && (
													<Grid item container xs={12}>
														<Grid item xs={6}>
															{t("details")}
														</Grid>
														<Grid container item xs={6}>
															<Grid item xs={12}>
																{t(row.information)}
															</Grid>
															<Grid item xs={12}>
																{row.comment}
															</Grid>
														</Grid>
													</Grid>
												)}
											</Grid>
											<Grid item xs={12} spacing={2}>
												<Divider />
											</Grid>
										</Grid>
									}
								/>
							</ListItem>
						))
					}
				</Query>
			</List>
		</Grid>
	);
}
export default HistoryLitige;
