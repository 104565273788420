import { format } from "date-fns";
import {
	FETCH_ORDERS,
	FETCH_ORDER,
	DELETE_ORDER,
	FETCH_ORDER_BY_SUPPLIER,
	ORDER_TOTAL_AMOUNT,
	FETCH_COUNT_CLIENTS,
	FETCH_COUNT_ORDERS,
	LIST_FETCH_DELIVERY_METHOD_ORDERS,
	LIST_FETCH_BILLING_LINES_ORDER,
	FETCH_DOCUMENT_ORDER,
	FETCH_PAYMENT_TERMS_ORDER,
	FETCH_REJECTION_MOTIFS_ORDER,
	FETCH_COUNT_ORDER_INDICATOR,
	FETCH_LAST_ACTIONS_ORDER,
	ORDER_TOTAL_VAT_AMOUNT,
	FETCH_ORDER_HISTORY_LIST,
	DELETE_LIST_ORDER,
	VERIFY_ORDER,
	CREATE_ORDER_REJECTION,
	FETCH_ORDER_STATUS,
	FETCH_ORDER_ATTACHMENTS_DOC_TYPES,
	FETCH_ORDER_ATTACHMENTS,
	DOWNLOAD_LIST_ORDER,
	EXPORT_ORDER_ENTRY_ACCOUNT_EXCEL,
	FETCH_DOCUMENT_ORDER_IDOC,
	FETCH_DOCUMENT_ORDER_EDI,
	EXPORT_CLIENTS_EXCEL,
} from "../../constants";
import {
	URL_BASE_ORDER,
	URL_BASE_INVOICE,
	URL_CREATE_ORDER,
	URL_ORDER_TOTAL_AMOUNT,
	URL_COUNT_CLIENTS,
	URL_COUNT_ORDERS,
	URL_DELIVERY_METHOD_ORDER,
	URL_BASE_EXPORT,
	URL_PAYMENT_TERM_ORDER,
	URL_MOTIF_ORDER,
	URL_ORDER_INDICATORS,
	URL_LAST_ACTION_ORDER,
	URL_ORDER_TOTAL_VAT_AMOUNT,
	URL_VERIFY_ORDER,
	URL_ORDER_STATUS,
	URL_FETCH_ORDER_DOC_TYPES,
	URL_CREATE_ORDER_ATTACHMENT,
	// URL_BASE_EXPORTS_ORDER_EDIFACT,
	// URL_BASE_EXPORTS_ORDER_IDOC,
	URL_EXPORT_ORDER_EDIFACT,
	URL_EXPORT_ORDER_IDOC,
	URL_RELAUNCH_EXTRACTION_ORDER,
	URL_CREATE_INVOICE_REJECTION,
} from "../../constants/endpoint";

export const fetchOrders = (filter = {}) => {
	const {
		orderNoOrClientLabel,
		endDate,
		startDate,
		extractionLvl,
		duplicated,
		pageNo,
		pageSize,
		sortBy,
		status,
	} = filter;
	return {
		type: FETCH_ORDERS,
		request: {
			url: `${URL_BASE_ORDER}/list`,
			params: {
				orderNoOrClientLabel,
				status: status ? status.map((s) => s.code).join(",") : null,
				startDate: startDate ? format(new Date(startDate), "yyyy/MM/dd") : null,
				endDate: endDate ? format(new Date(endDate), "yyyy/MM/dd") : null,
				pageNo,
				pageSize,
				sortBy,
				extractionLvl: extractionLvl ? extractionLvl.join(",") : null,
				duplicated,
			},
		},
	};
};

export const fetchOrder = (id) => ({
	type: FETCH_ORDER,
	request: { url: `${URL_BASE_ORDER}/${id}` },
});

export const deleteOrder = (id, onSuccess, onError) => ({
	type: DELETE_ORDER,
	request: {
		url: `${URL_BASE_ORDER}/${id}`,
		method: "delete",
	},
	meta: {
		onSuccess: () => {
			if (onSuccess) onSuccess();
		},
		onError: () => {
			if (onError) onError();
		},
	},
});

export const fetchOrdersBySupplier = (id) => ({
	type: FETCH_ORDER_BY_SUPPLIER,
	request: {
		url: `${URL_BASE_INVOICE}/po/supplier/${id}/orders`,
	},
});

export const createOrder = (file, withCutter) => {
	const data = new FormData();
	data.append("file", file);
	return {
		type: "CREATE_ORDER",
		request: {
			url: URL_CREATE_ORDER,
			method: "post",
			params: {
				withoutCutter: !withCutter,
			},
			data,
			headers: { "Content-Type": "multipart/form-data" },
		},
		meta: {
			onSuccess: (response) => response,
		},
	};
};

export const ordersTotalAmounts = (status, categorie) => {
	categorie = categorie || "";
	return {
		type: `${ORDER_TOTAL_AMOUNT}${categorie}`,
		request: {
			url: URL_ORDER_TOTAL_AMOUNT,
			params: { status: status && status.join(",") },
		},
	};
};

export const fetchCountClients = () => ({
	type: `${FETCH_COUNT_CLIENTS}`,
	request: { url: `${URL_COUNT_CLIENTS}` },
});

export const fetchCountOrders = () => ({
	type: `${FETCH_COUNT_ORDERS}`,
	request: { url: `${URL_COUNT_ORDERS}` },
});

export const fetchDeliveryMethods = () => ({
	type: LIST_FETCH_DELIVERY_METHOD_ORDERS,
	request: { url: URL_DELIVERY_METHOD_ORDER },
});

export const fetchBillingLines = (id) => ({
	type: LIST_FETCH_BILLING_LINES_ORDER,
	request: {
		url: `${URL_BASE_ORDER}/${id}/billing`,
	},
});

export const fetchDocumentOrder = (idOrder, type = "ord", token, onSuccess) => ({
	type: FETCH_DOCUMENT_ORDER,
	request: {
		url: `${URL_BASE_EXPORT}/${idOrder}/pdf`,
		params: { token: token != null ? token : "", type },
		responseType: "blob",
	},
	meta: {
		onSuccess: (response) => {
			const disposition = response.headers["content-disposition"];
			if (disposition && disposition.indexOf("attachment") !== -1) {
				const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
				const matches = filenameRegex.exec(disposition);
				if (matches !== null && matches[1]) {
					const filename = matches[1].replace(/['"]/g, "");
					const extension = filename.split(".").pop();
					if (extension === "pdf") {
						onSuccess("application/pdf");
					} else {
						onSuccess(`image/${extension}`);
					}
				}
			}
			return response;
		},
	},
});

export const fetchPaymentTerms = () => ({
	type: `${FETCH_PAYMENT_TERMS_ORDER}`,
	request: { url: `${URL_PAYMENT_TERM_ORDER}` },
});

export const fetchRejectionMotifsOrder = ({ token }) => ({
	type: `${FETCH_REJECTION_MOTIFS_ORDER}`,
	request: {
		url: `${URL_MOTIF_ORDER}`,
		params: { token: token != null ? token : "" },
	},
});

export const fetchOrderIndicators = () => ({
	type: FETCH_COUNT_ORDER_INDICATOR,
	request: {
		url: `${URL_ORDER_INDICATORS}`,
	},
});

export const fetchLastThreeOrders = () => ({
	type: FETCH_LAST_ACTIONS_ORDER,
	request: {
		url: `${URL_LAST_ACTION_ORDER}`,
	},
});

export const ordersTotalVATAmounts = (status, categorie) => {
	categorie = categorie || "";
	return {
		type: `${ORDER_TOTAL_VAT_AMOUNT}${categorie}`,
		request: {
			url: URL_ORDER_TOTAL_VAT_AMOUNT,
			params: { status: status && status.join(",") },
		},
	};
};

export const fetchOrderHistory = (id) => ({
	type: FETCH_ORDER_HISTORY_LIST,
	request: {
		url: `${URL_BASE_ORDER}/${id}/history`,
	},
});

export const ordersNotDeleted = (data) => ({
	type: "ORDERS_NOT_DELETED",
	payload: data,
});

export const deleteOrders = (idOrders, all, onSuccess) => ({
	type: DELETE_LIST_ORDER,
	request: {
		url: `${URL_BASE_ORDER}/`,
		method: "delete",
		params: { idOrders: idOrders.join(","), all },
	},
	meta: {
		onSuccess: (response, requestAction, store) => {
			if (onSuccess) {
				onSuccess();
			}
			store.dispatch(fetchOrders());
			return store.dispatch(ordersNotDeleted(response.data));
		},
	},
});

export const verifyOrder = (id, onSuccess, onError) => ({
	type: VERIFY_ORDER,
	request: {
		url: `${URL_BASE_ORDER}/${id}${URL_VERIFY_ORDER}`,
		method: "put",
	},
	meta: {
		onSuccess: (response, requestAction, store) => {
			if (onSuccess) onSuccess();
			return store.dispatch(fetchOrder(id));
		},
		onError: () => {
			if (onError) onError();
		},
	},
});

export const createOrderApproval = (id, orderApproval, onSuccess, onError) => ({
	type: CREATE_ORDER_REJECTION,
	request: {
		url: `${URL_BASE_ORDER}/protected/${id}/tovalidate`,
		method: "put",
		data: orderApproval,
		headers: { "Content-Type": "application/json" },
	},
	meta: {
		onSuccess: (response, requestAction, store) => {
			if (onSuccess) onSuccess();
			return store.dispatch(fetchOrder(id));
		},
		onError: (error, requestAction, store) => {
			if (onError) onError();
			return store.dispatch(fetchOrder(id));
		},
	},
});

export const createOrderRejection = (id, orderRejection, onSuccess, onError) => ({
	type: CREATE_ORDER_REJECTION,
	request: {
		url: `${URL_BASE_ORDER}/${id}${URL_CREATE_INVOICE_REJECTION}`,
		method: "post",
		data: orderRejection,
		headers: { "Content-Type": "application/json" },
	},
	meta: {
		onSuccess: (response, requestAction, store) => {
			if (onSuccess) onSuccess();
			return store.dispatch(fetchOrder(id));
		},
		onError: (error, requestAction, store) => {
			if (onError) onError();
			return store.dispatch(fetchOrder(id));
		},
	},
});

export const exportXMLFileToServer = (id, onSuccess, onError) => ({
	type: "EXPORT_XML_FILE",
	request: {
		url: `${URL_BASE_EXPORT}/${id}/xml?type=ord`,
		responseType: "blob",
	},
	meta: {
		onSuccess: (response) => {
			if (onSuccess) onSuccess();
			return response;
		},
		onError: () => {
			if (onError) onError();
		},
	},
});

export const downloadEdifactOrder = (idOrder, onError, disableLoading) => ({
	type: FETCH_DOCUMENT_ORDER_EDI,
	request: {
		url: `${URL_EXPORT_ORDER_EDIFACT}/${idOrder}`,
		responseType: "blob",
	},
	meta: {
		onSuccess: (response) => {
			const link = document.createElement("a");
			link.href = window.URL.createObjectURL(new Blob([response.data]));
			const disposition = response.headers["content-disposition"];
			if (disposition && disposition.indexOf("attachment") !== -1) {
				const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
				const matches = filenameRegex.exec(disposition);
				if (matches !== null && matches[1]) {
					const filename = matches[1].replace(/['"]/g, "");
					link.setAttribute("download", filename);
				} else {
					link.setAttribute("download", "export.edi");
				}
			}
			document.body.appendChild(link);
			link.click();
			link.remove();
			disableLoading();
			return response;
		},
		onError: () => {
			disableLoading();
			onError();
		},
	},
});

export const downloadIdocOrder = (idOrder, onError, disableLoading) => ({
	type: FETCH_DOCUMENT_ORDER_IDOC,
	request: {
		url: `${URL_EXPORT_ORDER_IDOC}/${idOrder}`,
		responseType: "blob",
	},
	meta: {
		onSuccess: (response) => {
			const link = document.createElement("a");
			link.href = window.URL.createObjectURL(new Blob([response.data]));
			const disposition = response.headers["content-disposition"];
			if (disposition && disposition.indexOf("attachment") !== -1) {
				const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
				const matches = filenameRegex.exec(disposition);
				if (matches !== null && matches[1]) {
					const filename = matches[1].replace(/['"]/g, "");
					link.setAttribute("download", filename);
				} else {
					link.setAttribute("download", "export.idoc");
				}
			}
			document.body.appendChild(link);
			link.click();
			link.remove();
			disableLoading();
			return response;
		},
		onError: () => {
			disableLoading();
			onError();
		},
	},
});

export const downloadOrderExcel = (idOrder, onError, disableLoading) => ({
	type: "FETCH_EXCEL_INVOICE",
	request: {
		url: `${URL_BASE_EXPORT}/order/${idOrder}/excel`,
		responseType: "blob",
	},
	meta: {
		onSuccess: (response) => {
			const link = document.createElement("a");
			link.href = window.URL.createObjectURL(new Blob([response.data]));
			const disposition = response.headers["content-disposition"];
			if (disposition && disposition.indexOf("attachment") !== -1) {
				const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
				const matches = filenameRegex.exec(disposition);
				if (matches !== null && matches[1]) {
					const filename = matches[1].replace(/['"]/g, "");
					link.setAttribute("download", filename);
				} else {
					link.setAttribute("download", "order.xlsx");
				}
			}
			document.body.appendChild(link);
			link.click();
			link.remove();
			disableLoading();
			return response;
		},
		onError: () => {
			disableLoading();
			onError();
		},
	},
});

export const downloadXMLOrder = (id, onError, disableLoading) => ({
	type: FETCH_DOCUMENT_ORDER,
	request: {
		url: `${URL_BASE_EXPORT}/order/${id}/xmlpivot`,
		responseType: "blob",
	},
	meta: {
		onSuccess: (response) => {
			const link = document.createElement("a");
			link.href = window.URL.createObjectURL(new Blob([response.data]));
			const disposition = response.headers["content-disposition"];
			if (disposition && disposition.indexOf("attachment") !== -1) {
				const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
				const matches = filenameRegex.exec(disposition);
				if (matches !== null && matches[1]) {
					const filename = matches[1].replace(/['"]/g, "");
					link.setAttribute("download", filename);
				} else {
					link.setAttribute("download", "order.xml");
				}
			}
			document.body.appendChild(link);
			link.click();
			link.remove();
			disableLoading();
			return response;
		},
		onError: () => {
			disableLoading();
			onError();
		},
	},
});

export const downloadOrder = (id, type = "ord", onError, disableLoading) => ({
	type: FETCH_DOCUMENT_ORDER,
	request: {
		url: `${URL_BASE_EXPORT}/${id}/pdf`,
		params: { type },
		responseType: "blob",
	},
	meta: {
		onSuccess: (response) => {
			const link = document.createElement("a");
			link.href = window.URL.createObjectURL(new Blob([response.data]));
			const disposition = response.headers["content-disposition"];
			if (disposition && disposition.indexOf("attachment") !== -1) {
				const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
				const matches = filenameRegex.exec(disposition);
				if (matches !== null && matches[1]) {
					const filename = matches[1].replace(/['"]/g, "");
					link.setAttribute("download", filename);
				} else {
					link.setAttribute("download", "order.pdf");
				}
			}
			document.body.appendChild(link);
			link.click();
			link.remove();
			disableLoading();
			return response;
		},
		onError: () => {
			disableLoading();
			onError();
		},
	},
});

export const fetchListStatus = () => ({
	type: `LIST_${FETCH_ORDER_STATUS}`,
	request: { url: `${URL_ORDER_STATUS}` },
});

export const fetchOrderAttachmentsDocTypes = (onSuccess) => ({
	type: FETCH_ORDER_ATTACHMENTS_DOC_TYPES,
	request: {
		url: `${URL_FETCH_ORDER_DOC_TYPES}`,
	},
	meta: {
		onSuccess: (response) => {
			if (onSuccess) onSuccess();
			return response;
		},
		onError: (response) => {
			if (onSuccess) onSuccess();
			return response;
		},
	},
});

export const fetchOrderAttachments = (orderId, onSuccess, onError) => ({
	type: FETCH_ORDER_ATTACHMENTS,
	request: {
		url: `${URL_BASE_ORDER}/${orderId}/attachment?type=ORD`,
	},
	meta: {
		onSuccess: (response) => {
			if (onSuccess) onSuccess();
			return response;
		},
		onError: (response) => {
			if (onError) onError();
			return response;
		},
	},
});

export const createOrderAttachment = (info, file) => {
	const data = new FormData();
	data.append("file", file[0]);
	data.append("info", JSON.stringify(info));
	return {
		type: "CREATE_ORDER_ATTACHMENT",
		request: {
			url: URL_CREATE_ORDER_ATTACHMENT,
			method: "post",
			data,
			headers: { "Content-Type": "multipart/form-data" },
		},
		meta: {
			onSuccess: (response) => response,
		},
	};
};

export const downloadOrdersXML = (idOrders, filter = {}) => {
	const { selectAll } = filter;
	return {
		type: "DOWNLOAD_LIST_ORDERS_EXCEL",
		request: {
			url: `${URL_BASE_EXPORT}/order/xmlpivot`,
			params: { selectAll, idOrders: idOrders.join(",") },
			responseType: "blob",
		},
		meta: {
			onSuccess: (response) => {
				const link = document.createElement("a");
				link.href = window.URL.createObjectURL(new Blob([response.data]));
				link.setAttribute("download", "ordersXML.zip");
				document.body.appendChild(link);
				link.click();
				link.remove();
				return response;
			},
		},
	};
};

export const downloadOrderAttachment = (id) => ({
	type: "FETCH_ORDER_ATTACHMENT_DUCUMENT",
	request: {
		url: `${URL_BASE_EXPORT}/attachment/${id}?type=ORD`,
		responseType: "blob",
	},
	meta: {
		onSuccess: (response) => {
			const link = document.createElement("a");
			link.href = window.URL.createObjectURL(new Blob([response.data]));
			const disposition = response.headers["content-disposition"];
			if (disposition && disposition.indexOf("attachment") !== -1) {
				const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
				const matches = filenameRegex.exec(disposition);
				if (matches !== null && matches[1]) {
					const filename = matches[1].replace(/['"]/g, "");
					link.setAttribute("download", filename);
				} else {
					link.setAttribute("download", "order.pdf");
				}
			}
			document.body.appendChild(link);
			link.click();
			link.remove();
			return response;
		},
	},
});

export const downloadOrdersExcel = (idOrders, filter = {}) => {
	const { selectAll = false } = filter;
	return {
		type: "DOWNLOAD_LIST_ORDER_EXCEL",
		request: {
			url: `${URL_BASE_EXPORT}/order/excel`,
			params: { idOrders: idOrders.join(","), selectAll },
			responseType: "blob",
		},
		meta: {
			onSuccess: (response) => {
				const link = document.createElement("a");
				link.href = window.URL.createObjectURL(new Blob([response.data]));
				link.setAttribute("download", "orders.xlsx");
				document.body.appendChild(link);
				link.click();
				link.remove();
				return response;
			},
		},
	};
};

export const downloadOrders = (idOrders) => ({
	type: DOWNLOAD_LIST_ORDER,
	request: {
		url: `${URL_BASE_EXPORT}/order/pdf`,
		params: { idOrders: idOrders.join(",") },
		responseType: "blob",
	},
	meta: {
		onSuccess: (response) => {
			const link = document.createElement("a");
			link.href = window.URL.createObjectURL(new Blob([response.data]));
			link.setAttribute("download", "orders.zip");
			document.body.appendChild(link);
			link.click();
			link.remove();
			return response;
		},
	},
});

export const deleteOrderAttachment = (id) => ({
	type: "DELETE_order_ATTACHMENT",
	request: {
		url: `${URL_BASE_ORDER}/${id}/attachment`,
		method: "delete",
	},
});

export const exportOrdersAccountingEntriesExcel = (idOrders, filter = {}) => {
	const { selectAll = false } = filter;
	return {
		type: `${EXPORT_ORDER_ENTRY_ACCOUNT_EXCEL}_LIST`,
		request: {
			url: `${URL_BASE_EXPORT}/order/excel/journal`,
			params: { idOrders: idOrders.join(","), selectAll },
			responseType: "blob",
		},
		meta: {
			onSuccess: (response) => {
				const link = document.createElement("a");
				link.href = window.URL.createObjectURL(new Blob([response.data]));
				const disposition = response.headers["content-disposition"];
				if (disposition && disposition.indexOf("attachment") !== -1) {
					const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
					const matches = filenameRegex.exec(disposition);
					if (matches !== null && matches[1]) {
						const filename = matches[1].replace(/['"]/g, "");
						link.setAttribute("download", filename);
					} else {
						link.setAttribute("download", "écriturecomptable_multiple_commandes.xlsx");
					}
				}
				document.body.appendChild(link);
				link.click();
				link.remove();
				return response;
			},
		},
	};
};

export const rectifyOrder = (id, orderDto, isTraining) => ({
	type: "APPLY_RECTIFY_ORDER",
	request: {
		url: `${URL_BASE_ORDER}/${id}/ocr/correct/apply`,
		method: "put",
		data: orderDto,
		params: {
			isTraining,
		},
	},
	meta: {
		onSuccess: (response) => {
			window.location.reload(false);
			return response;
		},
		onError: () => {},
	},
});

export const fetchOcrData = (id, onSuccess, onError) => ({
	type: "FETCH_OCR_DATA",
	request: { url: `${URL_BASE_ORDER}/${id}/ocr/data` },
	meta: {
		onSuccess: (response) => {
			if (onSuccess) onSuccess(response.data);
			return response;
		},
		onError: () => {
			if (onError) onError();
		},
	},
});

export const fetchOcrImage = (imageId) => ({
	type: "FETCH_OCR_IMAGE",
	request: { url: `${URL_BASE_ORDER}/${imageId}/ocr/data` },
});

export const exportClientsExcel = () => ({
	type: EXPORT_CLIENTS_EXCEL,
	request: {
		url: `${URL_BASE_EXPORT}/order/clients/export`,
		responseType: "blob",
	},
	meta: {
		onSuccess: (response) => {
			const link = document.createElement("a");
			link.href = window.URL.createObjectURL(new Blob([response.data]));
			const disposition = response.headers["content-disposition"];
			if (disposition && disposition.indexOf("attachment") !== -1) {
				const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
				const matches = filenameRegex.exec(disposition);
				if (matches !== null && matches[1]) {
					const filename = matches[1].replace(/['"]/g, "");
					link.setAttribute("download", filename);
				} else {
					link.setAttribute("download", "clients.xlsx");
				}
			}
			document.body.appendChild(link);
			link.click();
			link.remove();
			return response;
		},
	},
});

export const relaunchOrder = (id, onSuccess, onError) => ({
	type: "UPDATE_ORDER",
	request: {
		url: `${URL_RELAUNCH_EXTRACTION_ORDER}/${id}`,
		method: "put",
	},
	meta: {
		onSuccess: (response) => {
			if (onSuccess) onSuccess();
			return response;
		},
		onError: () => {
			if (onError) onError();
		},
	},
});

export const exportOrderSingleAccountingEntryExcel = (idOrder) => ({
	type: `${EXPORT_ORDER_ENTRY_ACCOUNT_EXCEL}_LIST`,
	request: {
		url: `${URL_BASE_EXPORT}/${idOrder}/excel/journal?type=ORD`,
		responseType: "blob",
	},
	meta: {
		onSuccess: (response) => {
			const link = document.createElement("a");
			link.href = window.URL.createObjectURL(new Blob([response.data]));
			const disposition = response.headers["content-disposition"];
			if (disposition && disposition.indexOf("attachment") !== -1) {
				const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
				const matches = filenameRegex.exec(disposition);
				if (matches !== null && matches[1]) {
					const filename = matches[1].replace(/['"]/g, "");
					link.setAttribute("download", filename);
				} else {
					link.setAttribute("download", "écriturecomptable_multiple_invoices.xlsx");
				}
			}
			document.body.appendChild(link);
			link.click();
			link.remove();
			return response;
		},
	},
});
