import React from "react";
import { useDispatch } from "react-redux";
import DeleteIcon from "@material-ui/icons/Delete";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import EditIcon from "@material-ui/icons/Edit";
import Avatar from "@material-ui/core/Avatar";
import { useHistory } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import CardActions from "@material-ui/core/CardActions";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import get from "lodash/get";
import { useTranslation } from "react-i18next";
import { toast } from "utils";
import { getApi } from "redux/store";
import UserProfile from "../../../../App/user-profile.png";
import useStyles from "./style";
import InfoItem from "./components/InfoItem";
import MultiLines from "./components/MultiLines";
import MultiLinesWithTitle from "./components/MultiLinesWithTitle";
import { deleteClient } from "../../../../redux/actions/clients";
import ConfirmDialog from "../../../../components/ConfirmDialog";

function TableCae({
	index,
	onClickRow,
	row,
	infoItems,
	onDeleteItem,
	withActions,
	withLogo,
	admin,
	showActionBtns,
	refreshClients,
	...props
}) {
	const classes = useStyles();
	const history = useHistory();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const getImageURL = (path) => `${getApi()}/order${path}`;
	const [confirmObject, setConfirmObject] = React.useState({
		message: "",
		openConfirm: false,
		cancel: false,
	});

	const onSuccessDelete = () => {
		toast.success(t("client.delete.success"));
		refreshClients();
	};

	const onErrorDelete = (errorMsg) => {
		toast.error(t(errorMsg));
	};

	const confirmDeleteClient = () => {
		setConfirmObject({ message: t("client.delete.confirm"), openConfirm: true });
	};

	const handleDeleteClient = () => {
		dispatch(deleteClient(row.id)).then((response) => {
			if (response.status === 200) {
				onSuccessDelete();
			} else {
				onErrorDelete(response.error.response.data.detail);
			}
		});
	};

	const onCancel = () => {
		setConfirmObject({ message: "", openConfirm: false });
	};

	const onConfirm = () => {
		setConfirmObject({ message: "", openConfirm: false });
		handleDeleteClient();
	};

	const updateClient = () => {
		history.push(`/client/${row?.id}`);
	};

	return (
		<Card key={index} className={classes.root} elevation={1}>
			<CardContent className={classes.content}>
				<ButtonBase focusRipple onClick={() => onClickRow(row.id)}>
					<Grid container spacing={6}>
						{withLogo && (
							<Grid item xs={2} align="center">
								<Grid container item direction="column">
									<Grid item className={classes.avatar}>
										<Avatar
											alt="Client logo"
											src={get(row, "logo") ? getImageURL(row.logo) : UserProfile}
										/>
									</Grid>
									<Grid item>
										<Typography variant="subtitle2" align="center">
											{row.code}
										</Typography>
									</Grid>
								</Grid>
							</Grid>
						)}
						<Grid item xs align="left">
							<Grid container>
								{infoItems &&
									infoItems.map((item) => (
										<>
											{item.multiLines ? (
												<>
													{item.withTitle ? (
														<Grid item xs>
															<MultiLinesWithTitle rows={item.rows} />
														</Grid>
													) : (
														<Grid item xs>
															<MultiLines rows={item.rows} />
														</Grid>
													)}
												</>
											) : (
												<Grid item xs>
													<InfoItem label={item.label} value={item.value} />
												</Grid>
											)}
										</>
									))}
							</Grid>
						</Grid>
					</Grid>
				</ButtonBase>
			</CardContent>
			{withActions && (
				<CardActions>
					<Grid container xs={12} justify="flex-end">
						{admin ? (
							props.children
						) : (
							<>
								<Grid item>
									<IconButton aria-label="download">
										<EditIcon />
									</IconButton>
								</Grid>
								{showActionBtns && (
									<Grid item>
										<IconButton aria-label="delete" onClick={() => onDeleteItem(row.id)}>
											<DeleteIcon />
										</IconButton>
									</Grid>
								)}
								<Grid item>
									<Checkbox
										onChange={(e) => {
											const list = [];
											if (e.target.checked) {
												list.push(parseInt(e.target.value, 10));
											} else {
												list.splice(list.indexOf(parseInt(e.target.value, 10)), 1);
											}
										}}
									/>
								</Grid>
							</>
						)}
					</Grid>
				</CardActions>
			)}
			{!props.isSupplierTraining && (
				<>
					<CardActions>
						{!props.isOrderList && (
							<Grid container justify="flex-end">
								<Tooltip placement="top" title={t("tootlip.action.edit")}>
									<IconButton aria-label="editClient" onClick={() => updateClient(row?.id)}>
										<EditIcon />
									</IconButton>
								</Tooltip>
							</Grid>
						)}
						{showActionBtns && (
							<Grid container justify="flex-end">
								<Tooltip placement="top" title={t("tooltip.action.delete")}>
									<IconButton
										aria-label="deleteClient"
										onClick={() => confirmDeleteClient(row?.id)}
									>
										<DeleteIcon />
									</IconButton>
								</Tooltip>
							</Grid>
						)}
					</CardActions>
					<ConfirmDialog
						message={confirmObject.message}
						open={confirmObject.openConfirm}
						onConfirm={onConfirm}
						onCancel={onCancel}
					/>
				</>
			)}
		</Card>
	);
}

export default TableCae;
