import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    display: "flex",
    marginTop: "15px",
    marginBottom: "1%",
    cursor: "pointer",
    transition: theme.transitions.create("transform", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.short,
    }),
    "&:hover": {
      background: "white !important",
      boxShadow: theme.shadows[25],
      transform: "scale(1.01)",
    },
  },
  content: {
    display: "flex",
    width: "85%",
    flexDirection: "column",
  },
  avatar: {
    alignSelf: "center",
  },
  contentInfoGrid: {},
  deleteIcon: {
    color: theme.palette.error.main,
  },
  downloadIcon: {
    color: theme.palette.success.main,
  },
  cardTable: {
    background: theme.palette.grey[200],
  },
  tableCell: {
    borderBottom: "none",
    fontWeight: "300",
  },
}));
