export default {
	countSuppliers: "Total number of suppliers",
	searchSupplier: "Find a supplier",
	contact: "Contact",
	principalContact: "Main contact",
	outstandingInvoices: "Outstanding invoices",
	addSupplier: "Add supplier",
	"limited.liability.company": "SARL",
	"single.person.limited.liability": "EURL",
	"public.limited.company": "SA",
	"general.partnership": "SNC",
	"simplified.joint.stock.company": "SAS",
	"single.person.simplified.joint.stock.company": "SASU",
	other: "Other",
	companySiret: "SIRET number",
	vatNumber: "VAT number",
	legalForm: "Legal form",
	codeNaf: "NAF Code",
	email: "Email",
	address: "Address",
	fax: "Fax",
	socialCapital: "Share capital",
	name: "Name",
	function: "Position",
	phone: "Phone",
	bank: "Bank",
	iban: "IBAN",
	bic: "BIC",
	rib: "RIB",
	account_journal_auxiliary: "Auxiliary",
	account_journal_number: "Account number",
	noPrincipalContact: "No main contact available",
	details: "Details",
	financialInformation: "Financial information",
	associatedContact: "Associated contacts",
	return: "Back",
	invoice_uploaded_success:
		"The invoice has been successfully added. It will appear in the list if you are the issuer of this invoice.",
	"upload.instructions": "Instructions",
	"upload.instructions1": "Fill the excel sheet with the suppliers' information",
	"upload.instructions2":
		"Fill the column “Logo” with the logo file name. (Example: logofilename.jpg)",
	"upload.instructions3": "Make sure all the fields are of type TEXT",
	"upload.instructions4":
		"Place the filled excel sheet and the suppliers' logos in the same folder",
	"upload.instructions5": "Compress that folder : ",
	"upload.instructions51":
		'Windows: Right Click on the Folder then choose  "Send to"  then click on "Compressed Folder"',
	"upload.instructions52":
		"MAC: Control-click it or tap it using two fingers, then choose Compress from the shortcut menu.",
	"upload.instructions6":
		"Upload the compressed folder to the portal or drag and drop it to the dedicated zone",
	"upload.instructions7": 'Click on "Upload Suppliers"',
	"supplier.modify": "Update supplier",
	"invoice.attachment.label": "File name",
	"invoice.attachment.extension": "File type",
	"invoice.attachment.type": "Document type",
	"invoice.attachment.creationDate": "Date Added",
	"invoice.attachment.size": "size",
	field_required: "Required",
	"add attach": "Add Attachment",
	"attachment.upload": "Add the file",
	"attachment.upload.success": "File Uploaded Successfuly",
	"attachment.upload.error": "Failed to Upload File",
	"Add your Attachment": "Add your Attachment",
	"invoice.attachment.delete.confirm": "Are you sure want to delete this attachment?",
	"attachment.delete.success": "Attachment deleted successfully",
	"workflow.invoice.exists": "Invoice status deletion",
	"workflow.invoice.exists.message":
		"Cannot delete invoice status. One or more invoice are in this status.",
	"workflow.changeInvoicesStatus": "Change invoices status",
	"workflow.deleteStatus.message":
		"{{count}} invoices are in status {{status}}. Please change your status before deleting.",
	"workflow.updateStatus.warning":
		"Are you sure you want to update these invoices status? This action will also save you workflow and it cannot be undone.",
	"workflow.load.error": "Error while fetching workflow",
	"workflow.toolTip.addStatus": "Add status",
	"workflow.toolTip.save": "Save",
	"workflow.toolTip.clone": "Clone",
	"workflow.toolTip.undoCHanges": "Cancel changes",
	"supplier.lockedTraining": "Locked?",
	"workflow.clone.newName": "New Workflow",
	"workflow.clone.code": "Name",
	"workflow.clone.description": "Description",
	"workflow.clone.save": "Save",
	"workflow.clone.success": "Workflow cloned successfuly!",
	"workflow.clone.error": "Error occured while cloning the workflow",
	"invoice.transition.reintegrate": "Reintegrate",
	searchClient: "Find a client",
};
