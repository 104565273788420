import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import {
	Grid,
	Typography,
	Tooltip,
	IconButton,
	FormControl,
	FormControlLabel,
	FormGroup,
	Checkbox,
	Divider,
	Switch,
	OutlinedInput,
	Button,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { toast } from "utils";
import { updateSettings } from "administration/redux/actions";
import { fetchSettingsByModuleName } from "redux/actions/users";
import CircularLoader from "components/Loaders/CircularLoader";
import useStyles from "../style";
import ConfirmDialog from "../../../components/ConfirmDialog";

const InvoiceSettings = () => {
	const { t } = useTranslation();
	const classes = useStyles();
	const dispatch = useDispatch();
	const { errors, handleSubmit, register } = useForm();

	const [confirmObject, setConfirmObject] = useState({
		message: "",
		openConfirm: false,
		cancel: false,
		isLoading: false,
	});
	const [edit, setEdit] = useState(false);
	const [settingsToSave, setSettingsToSave] = useState(null);
	const [settings, setSettings] = useState(null);
	const [loaded, setLoaded] = useState(false);

	const [autoExport, setAutoExport] = useState({ loaded: false, changed: false });
	const [exportJE, setExportJE] = useState({ loaded: false, changed: false });
	const [exportPDF, setExportPDF] = useState({ loaded: false, changed: false });
	const [exportXML, setExportXML] = useState({ loaded: false, changed: false });
	const [exportEXCEL, setExportEXCEL] = useState({ loaded: false, changed: false });
	const [showExportPDF, setShowExportPDF] = useState({ loaded: false, changed: false });
	const [showExportXML, setShowExportXML] = useState({ loaded: false, changed: false });
	const [showExportEXCEL, setShowExportEXCEL] = useState({ loaded: false, changed: false });
	const [deleteAction, setDeleteAction] = useState({ loaded: false, changed: false });
	const [showExportEdi, setShowExportEdi] = useState({ loaded: false, changed: false });
	const [exportEdi, setExportEdi] = useState({ loaded: false, changed: false });
	const [showExportIdoc, setShowExportIdoc] = useState({ loaded: false, changed: false });
	const [exportIdoc, setExportIdoc] = useState({ loaded: false, changed: false });

	const [showExportUBL, setShowExportUBL] = useState({ loaded: false, changed: false });
	const [showExportCII, setShowExportCII] = useState({ loaded: false, changed: false });
	const [showExportFacturX, setShowExportFacturX] = useState({ loaded: false, changed: false });

	const [showExportExcelAccounting, setShowExportExcelAccounting] = useState({
		loaded: false,
		changed: false,
	});
	const [deleteAllAction, setDeleteAllAction] = useState({ loaded: false, changed: false });
	const [relaunchExtraction, setRelaunchExtraction] = useState({
		loaded: false,
		changed: false,
	});
	const [indicatorMyTasks, setIndicatorMyTasks] = useState({ loaded: false, changed: false });
	const [indicatorInvoicesExported, setIndicatorInvoicesExported] = useState({
		loaded: false,
		changed: false,
	});
	const [indicatorInvoicesNotExported, setIndicatorInvoicesNotExported] = useState({
		loaded: false,
		changed: false,
	});
	const [indicatorLastActions, setIndicatorLastActions] = useState({
		loaded: false,
		changed: false,
	});
	const [indicatorLoadInvoices, setIndicatorLoadInvoices] = useState({
		loaded: false,
		changed: false,
	});
	const [indicatorLast5Invoices, setIndicatorLast5Invoices] = useState({
		loaded: false,
		changed: false,
	});

	const getDataFromSettings = (data, code) => data.find((e) => e.code === code);

	const intSettings = (data) => {
		setSettings(data);
		if (getDataFromSettings(data, "AUTO_EXPORT"))
			setAutoExport({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "AUTO_EXPORT").value === "1",
			});

		if (getDataFromSettings(data, "EXPORT_EC"))
			setExportJE({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "EXPORT_EC").value === "1",
			});

		if (getDataFromSettings(data, "EXPORT_PDF"))
			setExportPDF({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "EXPORT_PDF").value === "1",
			});
		if (getDataFromSettings(data, "EXPORT_XML"))
			setExportXML({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "EXPORT_XML").value === "1",
			});

		if (getDataFromSettings(data, "EXPORT_EXCEL"))
			setExportEXCEL({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "EXPORT_EXCEL").value === "1",
			});

		if (getDataFromSettings(data, "INVOICE_EXPORT_PDF"))
			setShowExportPDF({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "INVOICE_EXPORT_PDF").value === "1",
			});
		if (getDataFromSettings(data, "INVOICE_EXPORT_XML"))
			setShowExportXML({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "INVOICE_EXPORT_XML").value === "1",
			});

		if (getDataFromSettings(data, "INVOICE_EXPORT_EXCEL"))
			setShowExportEXCEL({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "INVOICE_EXPORT_EXCEL").value === "1",
			});
		if (getDataFromSettings(data, "INVOICE_RELAUNCH_EXTRACTION"))
			setRelaunchExtraction({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "INVOICE_RELAUNCH_EXTRACTION").value === "1",
			});
		if (getDataFromSettings(data, "INVOICE_DELETE_ACTION"))
			setDeleteAction({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "INVOICE_DELETE_ACTION").value === "1",
			});
		if (getDataFromSettings(data, "INVOICE_DELETE_All_ACTION"))
			setDeleteAllAction({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "INVOICE_DELETE_All_ACTION").value === "1",
			});
		if (getDataFromSettings(data, "INDICATOR_MY_TASKS"))
			setIndicatorMyTasks({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "INDICATOR_MY_TASKS").value === "1",
			});
		if (getDataFromSettings(data, "INDICATOR_INVOICES_EXPORTED"))
			setIndicatorInvoicesExported({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "INDICATOR_INVOICES_EXPORTED").value === "1",
			});
		if (getDataFromSettings(data, "INDICATOR_INVOICES_NOT_EXPORTED"))
			setIndicatorInvoicesNotExported({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "INDICATOR_INVOICES_NOT_EXPORTED").value === "1",
			});
		if (getDataFromSettings(data, "INDICATOR_LAST_ACTIONS"))
			setIndicatorLastActions({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "INDICATOR_LAST_ACTIONS").value === "1",
			});
		if (getDataFromSettings(data, "INDICATOR_LOAD_INVOICES"))
			setIndicatorLoadInvoices({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "INDICATOR_LOAD_INVOICES").value === "1",
			});
		if (getDataFromSettings(data, "INDICATOR_LAST_5_INVOICES"))
			setIndicatorLast5Invoices({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "INDICATOR_LAST_5_INVOICES").value === "1",
			});
		if (getDataFromSettings(data, "INVOICE_EXPORT_EXCEL_ACCOUNTING_ENTRY"))
			setShowExportExcelAccounting({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "INVOICE_EXPORT_EXCEL_ACCOUNTING_ENTRY").value === "1",
			});
		if (getDataFromSettings(data, "EXPORT_EDI"))
			setExportEdi({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "EXPORT_EDI").value === "1",
			});
		if (getDataFromSettings(data, "EXPORT_IDOC"))
			setExportIdoc({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "EXPORT_IDOC").value === "1",
			});
		if (getDataFromSettings(data, "INVOICE_EXPORT_EDI"))
			setShowExportEdi({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "INVOICE_EXPORT_EDI").value === "1",
			});
		if (getDataFromSettings(data, "INVOICE_EXPORT_IDOC"))
			setShowExportIdoc({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "INVOICE_EXPORT_IDOC").value === "1",
			});

		if (getDataFromSettings(data, "INVOICE_EXPORT_UBL"))
			setShowExportUBL({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "INVOICE_EXPORT_UBL").value === "1",
			});
		if (getDataFromSettings(data, "INVOICE_EXPORT_CII"))
			setShowExportCII({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "INVOICE_EXPORT_CII").value === "1",
			});
		if (getDataFromSettings(data, "INVOICE_EXPORT_FACTURX"))
			setShowExportFacturX({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "INVOICE_EXPORT_FACTURX").value === "1",
			});
	};

	const formatBooleanValue = (value) => (value ? "1" : "0");

	const onSubmit = (data) => {
		const _settings = JSON.parse(JSON.stringify(settings));
		_settings.find((e) => e.code === "AUTO_EXPORT").value = formatBooleanValue(autoExport.value);
		_settings.find((e) => e.code === "AUTO_EXPORT").changed = autoExport.changed;
		_settings.find((e) => e.code === "AMOUNT_TOLERANCE").value = data.AMOUNT_TOLERANCE;
		_settings.find((e) => e.code === "AMOUNT_TOLERANCE").changed = true;
		_settings.find((e) => e.code === "INVOICE_MAXIMUM_SIZE").value = data.MAXIMUM_SIZE;
		_settings.find((e) => e.code === "INVOICE_MAXIMUM_SIZE").changed = true;
		_settings.find((e) => e.code === "EXPORT_EC").value = formatBooleanValue(exportJE.value);
		_settings.find((e) => e.code === "EXPORT_EC").changed = exportJE.changed;
		_settings.find((e) => e.code === "EXPORT_PDF").value = formatBooleanValue(exportPDF.value);
		_settings.find((e) => e.code === "EXPORT_PDF").changed = exportPDF.changed;
		_settings.find((e) => e.code === "EXPORT_EXCEL").value = formatBooleanValue(exportEXCEL.value);
		_settings.find((e) => e.code === "EXPORT_EXCEL").changed = exportEXCEL.changed;
		_settings.find((e) => e.code === "EXPORT_XML").value = formatBooleanValue(exportXML.value);
		_settings.find((e) => e.code === "EXPORT_XML").changed = exportXML.changed;
		_settings.find((e) => e.code === "INVOICE_EXPORT_PDF").value = formatBooleanValue(
			showExportPDF.value
		);
		_settings.find((e) => e.code === "INVOICE_EXPORT_PDF").changed = showExportPDF.changed;
		_settings.find((e) => e.code === "INVOICE_EXPORT_EXCEL").value = formatBooleanValue(
			showExportEXCEL.value
		);
		_settings.find((e) => e.code === "INVOICE_EXPORT_EXCEL").changed = showExportEXCEL.changed;
		_settings.find((e) => e.code === "INVOICE_EXPORT_XML").value = formatBooleanValue(
			showExportXML.value
		);
		_settings.find((e) => e.code === "INVOICE_EXPORT_XML").changed = showExportXML.changed;
		_settings.find((e) => e.code === "INVOICE_RELAUNCH_EXTRACTION").value = formatBooleanValue(
			relaunchExtraction.value
		);
		_settings.find((e) => e.code === "INVOICE_RELAUNCH_EXTRACTION").changed =
			relaunchExtraction.changed;
		_settings.find((e) => e.code === "INVOICE_DELETE_ACTION").value = formatBooleanValue(
			deleteAction.value
		);
		_settings.find((e) => e.code === "INVOICE_DELETE_ACTION").changed = deleteAction.changed;
		_settings.find((e) => e.code === "INVOICE_DELETE_All_ACTION").value = formatBooleanValue(
			deleteAllAction.value
		);
		_settings.find((e) => e.code === "INVOICE_DELETE_All_ACTION").changed = deleteAllAction.changed;
		_settings.find(
			(e) => e.code === "INVOICE_EXPORT_EXCEL_ACCOUNTING_ENTRY"
		).value = formatBooleanValue(showExportExcelAccounting.value);
		_settings.find((e) => e.code === "INVOICE_EXPORT_EXCEL_ACCOUNTING_ENTRY").changed =
			showExportExcelAccounting.changed;
		_settings.find((e) => e.code === "INDICATOR_MY_TASKS").value = formatBooleanValue(
			indicatorMyTasks.value
		);
		_settings.find((e) => e.code === "INDICATOR_MY_TASKS").changed = indicatorMyTasks.changed;
		_settings.find((e) => e.code === "INDICATOR_INVOICES_EXPORTED").value = formatBooleanValue(
			indicatorInvoicesExported.value
		);
		_settings.find((e) => e.code === "INDICATOR_INVOICES_EXPORTED").changed =
			indicatorInvoicesExported.changed;
		_settings.find((e) => e.code === "INDICATOR_INVOICES_NOT_EXPORTED").value = formatBooleanValue(
			indicatorInvoicesNotExported.value
		);
		_settings.find((e) => e.code === "INDICATOR_INVOICES_NOT_EXPORTED").changed =
			indicatorInvoicesNotExported.changed;
		_settings.find((e) => e.code === "INDICATOR_LAST_ACTIONS").value = formatBooleanValue(
			indicatorLastActions.value
		);
		_settings.find((e) => e.code === "INDICATOR_LAST_ACTIONS").changed =
			indicatorLastActions.changed;
		_settings.find((e) => e.code === "INDICATOR_LOAD_INVOICES").value = formatBooleanValue(
			indicatorLoadInvoices.value
		);
		_settings.find((e) => e.code === "INDICATOR_LOAD_INVOICES").changed =
			indicatorLoadInvoices.changed;
		_settings.find((e) => e.code === "INDICATOR_LAST_5_INVOICES").value = formatBooleanValue(
			indicatorLast5Invoices.value
		);
		_settings.find((e) => e.code === "INDICATOR_LAST_5_INVOICES").changed =
			indicatorLast5Invoices.changed;

		_settings.find((e) => e.code === "INVOICE_EXPORT_EDI").value = formatBooleanValue(
			showExportEdi.value
		);
		_settings.find((e) => e.code === "INVOICE_EXPORT_EDI").changed = showExportEdi.changed;

		_settings.find((e) => e.code === "EXPORT_EDI").value = formatBooleanValue(exportEdi.value);
		_settings.find((e) => e.code === "EXPORT_EDI").changed = exportEdi.changed;

		_settings.find((e) => e.code === "INVOICE_EXPORT_IDOC").value = formatBooleanValue(
			showExportIdoc.value
		);
		_settings.find((e) => e.code === "INVOICE_EXPORT_IDOC").changed = showExportIdoc.changed;

		_settings.find((e) => e.code === "EXPORT_IDOC").value = formatBooleanValue(exportIdoc.value);
		_settings.find((e) => e.code === "EXPORT_IDOC").changed = exportIdoc.changed;

		_settings.find((e) => e.code === "INVOICE_EXPORT_UBL").value = formatBooleanValue(
			showExportUBL.value
		);
		_settings.find((e) => e.code === "INVOICE_EXPORT_UBL").changed = showExportUBL.changed;

		_settings.find((e) => e.code === "INVOICE_EXPORT_CII").value = formatBooleanValue(
			showExportCII.value
		);
		_settings.find((e) => e.code === "INVOICE_EXPORT_CII").changed = showExportCII.changed;

		_settings.find((e) => e.code === "INVOICE_EXPORT_FACTURX").value = formatBooleanValue(
			showExportFacturX.value
		);
		_settings.find((e) => e.code === "INVOICE_EXPORT_FACTURX").changed = showExportFacturX.changed;

		const settingsUpdate = _settings.filter((setting) => setting.changed === true);

		setSettingsToSave(settingsUpdate);

		setConfirmObject({ message: t("admin.setting.message.confirm"), openConfirm: true });
	};

	const refreshSettings = () => {
		dispatch(fetchSettingsByModuleName("invoice")).then((res) => {
			if (res.data) intSettings(res.data);
			setLoaded(true);
		});
	};

	const onConfirm = () => {
		if (!confirmObject.cancel) {
			setConfirmObject({ ...confirmObject, isLoading: true });
			dispatch(
				updateSettings(
					settingsToSave,
					"invoice",
					() => {
						setConfirmObject({ message: "", openConfirm: false, isLoading: false });
						toast.success(t("admin.config.update-settings"));
						setEdit(false);
					},
					(error) => {
						setConfirmObject({ message: "", openConfirm: false, isLoading: false });
						toast.error(error);
					}
				)
			).then(() => refreshSettings());
		} else {
			setConfirmObject({ message: "", openConfirm: false, cancel: false });
			setEdit(false);
		}
	};

	const onCancel = () => {
		setConfirmObject({ message: "", openConfirm: false });
		if (confirmObject.cancel) setEdit(true);
	};

	useEffect(() => {
		refreshSettings();
	}, [dispatch]);

	if (!loaded) return <CircularLoader />;
	return (
		<>
			<Grid container item xs={12} />
			<Grid
				container
				item
				xs={12}
				className={classes.settingsContainer}
				justify="center"
				alignItems="center"
				alignContent="center"
			>
				{settings && (
					<form onSubmit={handleSubmit(onSubmit)} style={{ width: "60%" }}>
						<Grid container item xs={12} className={classes.loginContainer} spacing={6}>
							<Grid item container xs={12}>
								<Grid container item xs={6}>
									<Typography variant="h6" color="primary">
										{t("admin.settings.invoices")}
									</Typography>
								</Grid>
								<Grid item container xs={6} justify="flex-end">
									<Tooltip placement="top" title={t("tootlip.action.edit")}>
										<IconButton aria-label="edit">
											<EditIcon id="settings-invoice-edit-btn" onClick={() => setEdit(!edit)} />
										</IconButton>
									</Tooltip>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<Grid container>
									<Grid item xs={6} alignItems="center">
										<Typography variant="subtitle2" align="left" color="primary">
											{t("admin.AUTO_EXPORT")}
											{/* <p>{errors.email?.message}</p> */}
										</Typography>

										<Typography variant="body2" align="left">
											{t("admin.invoice.AUTO_EXPORT-description")}
										</Typography>
									</Grid>

									<Grid item xs={6} align="center" justify="center">
										{autoExport.loaded && (
											<Switch
												id="settings-invoice-autoexport"
												defaultChecked={autoExport.value}
												onChange={(e) => {
													setAutoExport({
														...autoExport,
														changed: true,
														value: e.target.checked,
													});
												}}
												name="AUTO_EXPORT"
												color="secondary"
												disabled={!edit}
											/>
										)}
									</Grid>
								</Grid>
							</Grid>

							<Grid item xs={12}>
								<Divider />
							</Grid>

							<Grid container item xs={12}>
								<Grid item xs={12}>
									<Typography variant="subtitle2" align="left" color="primary">
										{t("admin.export-buttons")}
										<p>{errors.email?.message}</p>
									</Typography>
									<Typography variant="body2">
										{t("admin.invoice.export-buttons-description")}
									</Typography>
								</Grid>
								<Grid item container xs={12} justify="center" style={{ display: "flex" }}>
									<Grid item xs={1} />
									<Grid itm xs={5}>
										<FormControl component="fieldset" className={classes.formControl}>
											<FormGroup>
												{exportEXCEL.loaded && (
													<FormControlLabel
														control={
															<Checkbox
																id="settings-invoice-dlExcel"
																checked={exportEXCEL.value}
																onChange={(e) => {
																	setExportEXCEL({
																		...exportEXCEL,
																		changed: true,
																		value: e.target.checked,
																	});
																}}
																name="exportEXCEL"
																disabled={!edit}
															/>
														}
														label={t("admin.download-button.excel")}
													/>
												)}
												{exportXML.loaded && (
													<FormControlLabel
														control={
															<Checkbox
																id="settings-invoice-dltXml"
																checked={exportXML.value}
																onChange={(e) => {
																	setExportXML({
																		...exportXML,
																		changed: true,
																		value: e.target.checked,
																	});
																}}
																name="exportXML"
																disabled={!edit}
															/>
														}
														label={t("admin.download-button.xml")}
													/>
												)}
												{exportEdi.loaded && (
													<FormControlLabel
														control={
															<Checkbox
																id="settings-invoice-dlEdi"
																checked={exportEdi.value}
																onChange={(e) => {
																	setExportEdi({
																		...exportEdi,
																		changed: true,
																		value: e.target.checked,
																	});
																}}
																name="exportEdi"
																disabled={!edit}
															/>
														}
														label={t("admin.download-button.edi")}
													/>
												)}
											</FormGroup>
										</FormControl>
									</Grid>
									<Grid itm xs={5}>
										<FormControl required component="fieldset" className={classes.formControl}>
											<FormGroup>
												{exportXML.loaded && (
													<FormControlLabel
														control={
															<Checkbox
																id="settings-invoice-dlPdf"
																checked={exportPDF.value}
																onChange={(e) => {
																	setExportPDF({
																		...exportPDF,
																		changed: true,
																		value: e.target.checked,
																	});
																}}
																name="exportPDF"
																disabled={!edit}
															/>
														}
														label={t("admin.download-button.pdf")}
													/>
												)}
											</FormGroup>
											<FormGroup>
												{exportJE.loaded && (
													<FormControlLabel
														control={
															<Checkbox
																id="settings-invoice-dlJe"
																checked={exportJE.value}
																onChange={(e) => {
																	setExportJE({
																		...exportJE,
																		changed: true,
																		value: e.target.checked,
																	});
																}}
																name="exportJE"
																disabled={!edit}
															/>
														}
														label={t("admin.download-button.journal-entry")}
													/>
												)}
											</FormGroup>
											<FormGroup>
												{exportIdoc.loaded && (
													<FormControlLabel
														control={
															<Checkbox
																id="settings-invoice-dlIdoc"
																checked={exportIdoc.value}
																onChange={(e) => {
																	setExportIdoc({
																		...exportIdoc,
																		changed: true,
																		value: e.target.checked,
																	});
																}}
																name="exportIdoc"
																disabled={!edit}
															/>
														}
														label={t("admin.download-button.idoc")}
													/>
												)}
											</FormGroup>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>

							<Grid item xs={12}>
								<Divider />
							</Grid>

							<Grid item xs={12}>
								<Grid container spacing={6}>
									<Grid item xs={6} alignItems="center">
										<Typography variant="subtitle2" align="left" color="primary">
											{t("admin.AMOUNT_TOLERANCE")}
											<p>{errors.email?.message}</p>
										</Typography>
										<Typography variant="body2" color="primary">
											{t("admin.invoice.AMOUNT_TOLERANCE-description")}
										</Typography>
									</Grid>
									<Grid item xs={6} align="center" justify="center">
										<OutlinedInput
											id="settings-invoice-amntTolerance"
											inputRef={register({
												required: true,
												validate: (value) => !!value.trim(),
											})}
											name="AMOUNT_TOLERANCE"
											type="text"
											disabled={!edit}
											defaultValue={
												settings &&
												settings.find((e) => e.code === "AMOUNT_TOLERANCE") &&
												settings.find((e) => e.code === "AMOUNT_TOLERANCE").value
											}
											className={classes.amountTolerance}
										/>
										{errors.AMOUNT_TOLERANCE && (
											<Typography color="error">
												{t("admin.setting.AMOUNT_TOLERANCE.required")}
											</Typography>
										)}
									</Grid>
								</Grid>
							</Grid>

							<Grid item xs={12}>
								<Divider />
							</Grid>
							<Grid item xs={12}>
								<Grid container spacing={6}>
									<Grid item xs={6} alignItems="center">
										<Typography variant="subtitle2" align="left" color="primary">
											{t("admin.MAXIMUM_SIZE")}
											<p>{errors.email?.message}</p>
										</Typography>
										<Typography variant="body2" color="primary">
											{t("admin.MAXIMUM_SIZE-description")}
										</Typography>
									</Grid>
									<Grid item xs={6} align="center" justify="center">
										<OutlinedInput
											id="settings-invoice-invoice-min-size"
											inputRef={register({
												required: true,
												validate: (value) => !!value.trim(),
											})}
											name="MAXIMUM_SIZE"
											type="text"
											disabled={!edit}
											defaultValue={
												settings &&
												settings.find((e) => e.code === "INVOICE_MAXIMUM_SIZE") &&
												settings.find((e) => e.code === "INVOICE_MAXIMUM_SIZE").value
											}
											className={classes.amountTolerance}
										/>
										{errors.AMOUNT_TOLERANCE && (
											<Typography color="error">{t("admin.MAXIMUM_SIZE.required")}</Typography>
										)}
									</Grid>
								</Grid>
							</Grid>

							<Grid item xs={12}>
								<Divider />
							</Grid>

							<Grid container item xs={12}>
								<Grid item xs={12}>
									<Typography variant="subtitle2" align="left" color="primary">
										{t("admin.display-indicators")}
										<p>{errors.email?.message}</p>
									</Typography>
									<Typography variant="body2">
										{t("admin.display-indicators-description")}
									</Typography>
								</Grid>
								<Grid item xs={12} justify="center" style={{ display: "flex" }}>
									<Grid item xs={1} />
									<Grid itm xs={5}>
										<FormControl component="fieldset" className={classes.formControl}>
											<FormGroup>
												{indicatorMyTasks.loaded && (
													<FormControlLabel
														control={
															<Checkbox
																id="settings-invoice-myTasks"
																checked={indicatorMyTasks.value}
																onChange={(e) => {
																	setIndicatorMyTasks({
																		...indicatorMyTasks,
																		value: e.target.checked,
																		changed: true,
																	});
																}}
																name="indicatorMyTasks"
																disabled={!edit}
															/>
														}
														label={t("My tasks")}
													/>
												)}
												{indicatorInvoicesExported.loaded && (
													<FormControlLabel
														control={
															<Checkbox
																id="settings-invoice-invExported"
																checked={indicatorInvoicesExported.value}
																onChange={(e) => {
																	setIndicatorInvoicesExported({
																		...indicatorInvoicesExported,
																		changed: true,
																		value: e.target.checked,
																	});
																}}
																name="indicatorInvoicesExported"
																disabled={!edit}
															/>
														}
														label={t("invoice.exported")}
													/>
												)}
												{indicatorInvoicesNotExported.loaded && (
													<FormControlLabel
														control={
															<Checkbox
																id="settings-invoice-invNotExported"
																checked={indicatorInvoicesNotExported.value}
																onChange={(e) => {
																	setIndicatorInvoicesNotExported({
																		...indicatorInvoicesNotExported,
																		changed: true,
																		value: e.target.checked,
																	});
																}}
																name="indicatorInvoicesNotExported"
																disabled={!edit}
															/>
														}
														label={t("invoice.unexported")}
													/>
												)}
											</FormGroup>
										</FormControl>
									</Grid>
									<Grid itm xs={5}>
										<FormControl required component="fieldset" className={classes.formControl}>
											<FormGroup>
												{indicatorLastActions.loaded && (
													<FormControlLabel
														control={
															<Checkbox
																id="settings-invoice-lastActions"
																checked={indicatorLastActions.value}
																onChange={(e) => {
																	setIndicatorLastActions({
																		...indicatorLastActions,
																		changed: true,
																		value: e.target.checked,
																	});
																}}
																name="indicatorLastActions"
																disabled={!edit}
															/>
														}
														label={t("Last actions")}
													/>
												)}
												{indicatorLoadInvoices.loaded && (
													<FormControlLabel
														control={
															<Checkbox
																id="settings-invoice-loadInv"
																checked={indicatorLoadInvoices.value}
																onChange={(e) => {
																	setIndicatorLoadInvoices({
																		...indicatorLoadInvoices,
																		changed: true,
																		value: e.target.checked,
																	});
																}}
																name="indicatorLoadInvoices"
																disabled={!edit}
															/>
														}
														label={t("Upload")}
													/>
												)}
												{indicatorLast5Invoices.loaded && (
													<FormControlLabel
														control={
															<Checkbox
																id="settings-invoice-last5Inv"
																checked={indicatorLast5Invoices.value}
																onChange={(e) => {
																	setIndicatorLast5Invoices({
																		...indicatorLast5Invoices,
																		changed: true,
																		value: e.target.checked,
																	});
																}}
																name="indicatorLast5Invoices"
																disabled={!edit}
															/>
														}
														label={t("admin.setting.last-5-invoices")}
													/>
												)}
											</FormGroup>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>

							<Grid item xs={12}>
								<Divider />
							</Grid>

							<Grid container item xs={12}>
								<Grid item xs={12}>
									<Typography variant="subtitle2" align="left" color="primary">
										{t("admin.download-buttons")}
										<p>{errors.email?.message}</p>
									</Typography>
									<Typography variant="body2">{t("admin.download-buttons-description")}</Typography>
								</Grid>
								<Grid item xs={12} />
								<br />
								<Grid item container xs={12} style={{ display: "flex" }}>
									<Grid item xs={1} />
									<Grid item xs={5} align="left" justify="left" className={classes.downloadButton}>
										<Typography variant="body2">
											{t("admin.setting.relaunch-extraction")}
										</Typography>
									</Grid>
									<Grid
										item
										xs={6}
										align="left"
										justify="left"
										className={classes.downloadButtonRadio}
									>
										{relaunchExtraction.loaded && (
											<Switch
												id="settings-invoice-relaunch-extraction"
												checked={relaunchExtraction.value}
												onChange={(e) => {
													setRelaunchExtraction({
														...relaunchExtraction,
														changed: true,
														value: e.target.checked,
													});
												}}
												name="relaunchExtraction"
												color="secondary"
												disabled={!edit}
											/>
										)}
									</Grid>
									<Grid item xs={1} />
									<Grid item xs={5} align="left" justify="left" className={classes.downloadButton}>
										<Typography variant="body2">{t("EXPORT_XML")}</Typography>
									</Grid>
									<Grid
										item
										xs={6}
										align="left"
										justify="left"
										className={classes.downloadButtonRadio}
									>
										{showExportXML.loaded && (
											<Switch
												id="settings-invoice-exportXml"
												checked={showExportXML.value}
												onChange={(e) => {
													setShowExportXML({
														...showExportXML,
														changed: true,
														value: e.target.checked,
													});
												}}
												name="exportXML"
												color="secondary"
												disabled={!edit}
											/>
										)}
									</Grid>
									<Grid item xs={1} />

									<Grid item xs={5} align="left" justify="left" className={classes.downloadButton}>
										<Typography variant="body2">{t("EXPORT_EXCEL")}</Typography>
									</Grid>
									<Grid
										item
										xs={6}
										align="left"
										justify="left"
										className={classes.downloadButtonRadio}
									>
										{showExportEXCEL.loaded && (
											<Switch
												id="settings-invoice-exportExcel"
												checked={showExportEXCEL.value}
												onChange={(e) => {
													setShowExportEXCEL({
														...showExportEXCEL,
														changed: true,
														value: e.target.checked,
													});
												}}
												name="exportEXCEL"
												color="secondary"
												disabled={!edit}
											/>
										)}
									</Grid>

									<Grid item xs={1} />
									<Grid item xs={5} align="left" justify="left" className={classes.downloadButton}>
										<Typography variant="body2">{t("admin.setting.download-pdf")}</Typography>
									</Grid>
									<Grid
										item
										xs={6}
										align="left"
										justify="left"
										className={classes.downloadButtonRadio}
									>
										{showExportPDF.loaded && (
											<Switch
												id="settings-invoice-exportPdf"
												checked={showExportPDF.value}
												onChange={(e) => {
													setShowExportPDF({
														...showExportPDF,
														changed: true,
														value: e.target.checked,
													});
												}}
												name="exportPDF"
												color="secondary"
												disabled={!edit}
											/>
										)}
									</Grid>
									<Grid item xs={1} />
									<Grid item xs={5} align="left" justify="left" className={classes.downloadButton}>
										<Typography variant="body2">{t("admin.setting.delete")}</Typography>
									</Grid>
									<Grid
										item
										xs={6}
										align="left"
										justify="left"
										className={classes.downloadButtonRadio}
									>
										{deleteAction.loaded && (
											<Switch
												id="settings-invoice-delete-action"
												checked={deleteAction.value}
												onChange={(e) => {
													setDeleteAction({
														...deleteAction,
														changed: true,
														value: e.target.checked,
													});
												}}
												name="AUTO_EXPORT"
												color="secondary"
												disabled={!edit}
											/>
										)}
									</Grid>
									<Grid item xs={1} />
									<Grid item xs={5} align="left" justify="left" className={classes.downloadButton}>
										<Typography variant="body2">
											{t("admin.setting.export_entry_account_excel")}
										</Typography>
									</Grid>
									<Grid
										item
										xs={6}
										align="left"
										justify="left"
										className={classes.downloadButtonRadio}
									>
										{showExportExcelAccounting.loaded && (
											<Switch
												id="settings-invoice-show-export-excel-acc"
												checked={showExportExcelAccounting.value}
												onChange={(e) => {
													setShowExportExcelAccounting({
														...showExportExcelAccounting,
														changed: true,
														value: e.target.checked,
													});
												}}
												name="AUTO_EXPORT"
												color="secondary"
												disabled={!edit}
											/>
										)}
									</Grid>
									{showExportEdi.loaded && (
										<>
											<Grid item xs={1} />
											<Grid
												item
												xs={5}
												align="left"
												justify="left"
												className={classes.downloadButton}
											>
												<Typography variant="body2">{t("admin.setting.download-edi")}</Typography>
											</Grid>
											<Grid
												item
												xs={6}
												align="left"
												justify="left"
												className={classes.downloadButtonRadio}
											>
												<Switch
													id="settings-invoice-show-export-edi"
													checked={showExportEdi.value}
													onChange={(e) => {
														setShowExportEdi({
															...showExportEdi,
															changed: true,
															value: e.target.checked,
														});
													}}
													name="exportEdi"
													color="secondary"
													disabled={!edit}
												/>
											</Grid>
										</>
									)}
									{showExportIdoc.loaded && (
										<>
											<Grid item xs={1} />
											<Grid
												item
												xs={5}
												align="left"
												justify="left"
												className={classes.downloadButton}
											>
												<Typography variant="body2">{t("admin.setting.download-idoc")}</Typography>
											</Grid>
											<Grid
												item
												xs={6}
												align="left"
												justify="left"
												className={classes.downloadButtonRadio}
											>
												<Switch
													id="settings-invoice-show-export-idoc"
													checked={showExportIdoc.value}
													onChange={(e) => {
														setShowExportIdoc({
															...showExportIdoc,
															changed: true,
															value: e.target.checked,
														});
													}}
													name="exportIdoc"
													color="secondary"
													disabled={!edit}
												/>
											</Grid>
										</>
									)}

									{showExportUBL.loaded && (
										<>
											<Grid item xs={1} />
											<Grid
												item
												xs={5}
												align="left"
												justify="left"
												className={classes.downloadButton}
											>
												<Typography variant="body2">{t("admin.setting.download-ubl")}</Typography>
											</Grid>
											<Grid
												item
												xs={6}
												align="left"
												justify="left"
												className={classes.downloadButtonRadio}
											>
												<Switch
													id="settings-invoice-show-export-ubl"
													checked={showExportUBL.value}
													onChange={(e) => {
														setShowExportUBL({
															...showExportUBL,
															changed: true,
															value: e.target.checked,
														});
													}}
													name="exportUBL"
													color="secondary"
													disabled={!edit}
												/>
											</Grid>
										</>
									)}

									{showExportCII.loaded && (
										<>
											<Grid item xs={1} />
											<Grid
												item
												xs={5}
												align="left"
												justify="left"
												className={classes.downloadButton}
											>
												<Typography variant="body2">{t("admin.setting.download-cii")}</Typography>
											</Grid>
											<Grid
												item
												xs={6}
												align="left"
												justify="left"
												className={classes.downloadButtonRadio}
											>
												<Switch
													id="settings-invoice-show-export-cii"
													checked={showExportCII.value}
													onChange={(e) => {
														setShowExportCII({
															...showExportCII,
															changed: true,
															value: e.target.checked,
														});
													}}
													name="exportCII"
													color="secondary"
													disabled={!edit}
												/>
											</Grid>
										</>
									)}

									{showExportFacturX.loaded && (
										<>
											<Grid item xs={1} />
											<Grid
												item
												xs={5}
												align="left"
												justify="left"
												className={classes.downloadButton}
											>
												<Typography variant="body2">
													{t("admin.setting.download-facturx")}
												</Typography>
											</Grid>
											<Grid
												item
												xs={6}
												align="left"
												justify="left"
												className={classes.downloadButtonRadio}
											>
												<Switch
													id="settings-invoice-show-export-facturex"
													checked={showExportFacturX.value}
													onChange={(e) => {
														setShowExportFacturX({
															...showExportFacturX,
															changed: true,
															value: e.target.checked,
														});
													}}
													name="exportFacturX"
													color="secondary"
													disabled={!edit}
												/>
											</Grid>
										</>
									)}

									<Grid item xs={1} />
									<Grid item xs={5} align="left" justify="left" className={classes.downloadButton}>
										<Typography variant="body2">{t("admin.setting.delete-all")}</Typography>
									</Grid>
									<Grid
										item
										xs={6}
										align="left"
										justify="left"
										className={classes.downloadButtonRadio}
									>
										{deleteAllAction.loaded && (
											<Switch
												id="settings-invoice-show-export-delete-all"
												checked={deleteAllAction.value}
												onChange={(e) => {
													setDeleteAllAction({
														...deleteAllAction,
														changed: true,
														value: e.target.checked,
													});
												}}
												name="AUTO_EXPORT"
												color="secondary"
												disabled={!edit}
											/>
										)}
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						<Grid
							container
							item
							xs={12}
							spacing={2}
							className={classes.actions}
							justify="center"
							alignItems="center"
							alignContent="center"
						>
							<Grid item>
								<Button
									id="settings-invoice-cancel-btn"
									color="primary"
									autoFocus
									onClick={() => {
										setConfirmObject({
											message: t("CancelActionMessage"),
											openConfirm: true,
											cancel: true,
										});
									}}
									disabled={!edit}
								>
									{t("cancel")}
								</Button>
							</Grid>
							<Grid item>
								<Button
									id="settings-invoice-confirm-btn"
									variant="contained"
									type="submit"
									color="secondary"
									disabled={!edit}
								>
									{t("confirm")}
								</Button>
							</Grid>
						</Grid>

						<ConfirmDialog
							message={confirmObject.message}
							open={confirmObject.openConfirm}
							isLoading={confirmObject.isLoading}
							onConfirm={onConfirm}
							onCancel={onCancel}
						/>
					</form>
				)}
			</Grid>
		</>
	);
};

export default InvoiceSettings;
