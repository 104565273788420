import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { Grid, Button, Typography, Switch } from "@material-ui/core";
import DropzoneArea from "components/DropZoneArea";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { EXTENTIONS_INVOICES } from "utils/constants";
import { toast, currencyFormat } from "utils";
import { fetchSettingsByModuleName } from "redux/actions/users";
import Filter from "./components/Filter";
import useStyles from "./style";
import OrdersList from "./OrdersList";
import ExtractionFilter from "./components/ExtractionFilter";
import {
	createOrder,
	fetchOrders,
	ordersTotalAmounts,
	fetchCountOrders,
} from "../../redux/actions/orders";

const Orders = () => {
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [selected, setSelected] = useState([]);
	const [expanded, setExpanded] = useState(false);
	const [files, setFiles] = React.useState([]);
	const [withCutter, setWithCutter] = React.useState(false);
	const filterOrders = useSelector(({ filterOrders: i }) => i);
	const settings = useSelector(({ requests }) =>
		get(requests, "queries.FETCH_SETTINGS_MODULE.data")
	);

	const idOrders = useSelector(
		({ requests }) =>
			requests.queries.FETCH_ORDERS &&
			requests.queries.FETCH_ORDERS.data &&
			requests.queries.FETCH_ORDERS.data.content.map((order) => order.id)
	);

	const currentUser = useSelector(({ requests }) =>
		get(requests, "queries.FETCH_CURRENT_USER.data")
	);

	const onloadFiles = (filesParam) => {
		setFiles(filesParam);
	};
	const onDeleteFile = (file) => {
		files.splice(files.indexOf(file), 1);
		toast.info(t("fileRemovedMsg", { filename: file.name }), { autoClose: 3000 });
	};

	const onDrop = () => {
		// toast.success(t("fileAddedMsg"), { autoClose: 3000 });
	};

	const formatSettings = (data) =>
		data && data.reduce((acc, { code, value }) => ({ ...acc, [code]: parseInt(value, 10) }), {});

	const onclickUploadOrders = () => {
		const tmpFiles = [];
		files.forEach((element, i) => {
			dispatch(createOrder(element, withCutter)).then((response) => {
				if (
					response.status === 201 &&
					currentUser &&
					get(currentUser, "commercialOrganizationId")
				) {
					toast.success(t("order_uploaded_success"));
				}
				dispatch(fetchOrders(filterOrders));
				dispatch(fetchCountOrders());
				dispatch(ordersTotalAmounts([]));
				if (response.data && !isEmpty(response.data.nameFile)) {
					tmpFiles.push(response.data.nameFile);
					if (i === files.length - 1) {
						toast.error(`${t("OrdersNotCreatedMessage")} : ${tmpFiles.join(", ")}`);
					}
				}
			});
		});
		setExpanded(!expanded);
	};

	const handleSelectedAll = (checked) => {
		setSelected(checked ? idOrders : []);
	};

	useEffect(() => {
		dispatch(fetchSettingsByModuleName("ORDER"));
		dispatch(fetchOrders({ filterOrders }));
		if (
			history &&
			history.location &&
			history.location.state &&
			!isEmpty(history.location.state.files)
		) {
			const state = { ...history.location.state };
			toast.error(`${t("InvoicesNotCreatedMessage")} : ${state?.files?.join(", ")}`, {
				autoClose: 3000,
			});
			delete state.files;
			history.replace({ ...history.location, state });
		}
	}, []);

	const getDataFromSettings = (data, code) => data.find((e) => e.code === code);

	const totalAmounts = useSelector(({ requests }) =>
		get(requests, "queries.ORDER_TOTAL_AMOUNT.data")
	);
	const totalOrders = useSelector(({ requests }) =>
		get(requests, "queries.FETCH_COUNT_ORDERS.data")
	);

	return (
		<Grid spacing={2} xs={12} container className={classes.firstGrid}>
			<Grid
				spacing={2}
				container
				item
				xs={12}
				className={classes.headerZone}
				justify="center"
				alignItems="stretch"
			>
				<Grid item xs={6}>
					{settings && getDataFromSettings(settings, "ORDER_MAXIMUM_SIZE") && (
						<DropzoneArea
							fileLimit={30}
							maxFileSize={10000000 * getDataFromSettings(settings, "ORDER_MAXIMUM_SIZE").value}
							expanded={expanded}
							showFileNames
							useChipsForPreview
							dropzoneClass={classes.dropzone}
							dropzoneParagraphClass={classes.text}
							onChange={onloadFiles}
							onDelete={onDeleteFile}
							onDrop={onDrop}
							zoneMessage="Add your Order"
							acceptedFiles={EXTENTIONS_INVOICES}
						/>
					)}
				</Grid>
				<Grid container item xs={2}>
					{expanded && (
						<>
							<Button
								fullWidth
								variant="contained"
								color="primary"
								style={{ height: "100px" }}
								className={classes.expand}
								onClick={onclickUploadOrders}
							>
								{t("order.upload")}
							</Button>
							<Grid container style={{ height: "50px" }}>
								<Grid container item xs="10" justify="center" alignItems="center">
									<Typography variant="body2" color="secondary">
										{t("use.doc.cutter")}
									</Typography>
								</Grid>

								<Grid container item xs="2" justify="center" alignItems="center">
									<Switch
										defaultChecked={withCutter}
										onChange={(e) => setWithCutter(e.target.checked)}
										name="AUTO_EXPORT"
										color="secondary"
									/>
								</Grid>
							</Grid>
						</>
					)}
				</Grid>
			</Grid>
			<Grid container item xs={12} direction="row-reverse">
				<Grid container item xs={2} justify="flex-end">
					{!expanded && (
						<Button
							fullWidth
							variant="contained"
							color="primary"
							className={classes.expand}
							onClick={() => {
								setExpanded(!expanded);
							}}
						>
							{t("Add an order")}
						</Button>
					)}
				</Grid>
				<Grid container alignItems="center" justify="flex-end" item xs={6}>
					<ExtractionFilter />
				</Grid>
				<Grid container item direction="column" xs={4}>
					<Grid item>
						<Typography variant="h6" color="secondary">
							{t("Total amount of orders")} :{totalAmounts ? currencyFormat(totalAmounts) : 0}€
						</Typography>
					</Grid>
					<Grid item>
						<Typography variant="subtitle2" color="textSecondary">
							{t("countOrders")} : <span> {totalOrders}</span>
						</Typography>
					</Grid>
				</Grid>
			</Grid>
			<Grid item container xs={12}>
				<Filter
					settings={settings}
					selected={selected}
					setSelected={setSelected}
					handleSelectedAll={handleSelectedAll}
					fromDashboard={
						history.location && history.location.state && history.location.state.fromDashboard
					}
				/>
			</Grid>
			<Grid item xs={12}>
				<OrdersList
					settings={formatSettings(settings)}
					selected={selected}
					setSelected={setSelected}
					fromDashboard={
						history.location && history.location.state && history.location.state.fromDashboard
					}
				/>
			</Grid>
		</Grid>
	);
};

export default Orders;
