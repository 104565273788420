export default {
	lastName: "Name",
	firstName: "Vorname",
	email: "EMail",
	Username: "Nutzername",
	userFunction: "Funktion",
	supplier_name: "Lieferant",
	client_name: "Kundenname",
	job: "Stelle",
	manageUsers: "Benützer Management",
	manageSuppliers: "Lieferantenbereich",
	manageClients: "Kunden verwalten",
	manageGroups: "Gruppen Management",
	manageRoles: "Rollen Management",
	manageWorkflow: "Workflow Management",
	manageLicenseAddOn: "Gestion licences & Add-ons",
	status: "Status",
	ACTIVE: "Aktiv",
	WAITING: "Warteschlange",
	DISABLE: "Nicht aktiv",
	add_user: "Benutzer einfügen",
	add_user_button: "BENUTZER EINFÜGEN ",
	add_group_button: "HANDELSORGANISATION HINZUFÜGEN",
	add_supplier_button: "LIEFERANT EINFÜGEN ",
	add_client_button: "KUNDEN HINZUFUGEN",
	sendEmailCreationUserMessage:
		"Eine Mail mit der ID. und dem Passwort wurde dem Benutzer geschickt, um die Verbindung zu ermöglichen",
	CancelActionMessage: "Sind Sie sicher diese Aktion stornieren zu wollen?",
	searchUser: "Suche",
	userCivility: "Anrede",
	administrator: "Administrator",
	user: "Agent",
	Mr: "Herr",
	Ms: "Fraulein",
	Mrs: "Frau",
	enableDisableActionMessage: "Sind Sie sicher dieser Benutzer aktivieren/deaktivieren zu wollen?",
	edit_user: "Benutzer ändern",
	add_supplier: "Lieferant einfügen",
	edit_supplier: "Lieferant ändern",
	add_client: "Client hinzufügen",
	// validation messages
	lastName_required: "Der Name ist erforderlich",
	lastName_notValid: "Der Name ist ungültig",
	supplierName_notValid: "Der Lieferantenname ist ungültig",
	phone_notValid: "Die Telefonnummer ist ungültig",
	supplier_required: "Der Lieferantenname ist erforderlich",
	firstName_required: "Der Vorname ist erforderlich",
	firstName_notValid: "Der Vorname ist ungültig",
	email_required: "Die EMail-Adresse ist erforderlich",
	email_notValid: "Die EMail-Adresse ist ungültig",
	siren_notValid: "Der SIREN-Code ist nicht gültig",
	siret_notValid: "Die MwSt-Nr. ist ungültig",
	url_notValid: "Die URL ist ungültig",
	name_notValid: "Name ist nicht gültig",
	supplier_created:
		"Lieferant erfolgreich hinzugefügt.Wenn der Lieferant in Ihrer Lieferantenliste nicht angezeigt wird, wenden Sie sich an Ihren Administrator",
	supplier_updated: "Erfolgreiche Aktualisierung vom Lieferant!",
	supplier_error: "Ein Fehler ist während der Lieferantenerstellung aufgetreten",
	supplier_update_error: "Fehlerhafte Aktualisierung von den Lieferanteninformationen",
	job_required: "Die Stelle ist erforderlich",
	civility_required: "Die Anrede ist erforderlich",
	doctype_required: "Dokumenttyp ist erforderlich",
	deleteUserActionMessage: "Sind Sie sicher diesen Benutzer löschen zu wollen?",
	deleteUsersActionMessage: "Sind Sie sicher diese Benützer löschen zu wollen?",
	"admin.settings": "Einstellungen",
	"admin.commercialOrganization": "Kommerzielle Organisation",
	"admin.config.update-settings": "Erfolgreiche Änderung der Einstellungen!",
	"admin.AUTO_EXPORT": "Automatisches Exportieren",
	"admin.email-config": "EMail konfigurieren",
	"admin.ftp-config": "FTP-Konfiguration",
	"admin.import-config": "Konfiguration importieren",
	"admin.folder-config": "Dateien konfigurieren",
	"admin.config.update-message": "Erfolgreiche Aktualisierung von der EMail-Konfiguration!",
	"admin.AMOUNT_TOLERANCE": "Toleranz der Gesamtsummen",
	"admin.MAXIMUM_SIZE": "Maximale Größe einer geladenen Datei",
	field_required: "erforderlich",
	Host: "Host",
	Port: "Port",
	Email: "EMail",
	Password: "Passwort",
	"Read folder": "Lese-Ordner",
	"Processing folder": "Protokol-Ordner",
	"Success folder": "Erfolgsordner",
	"Exception folder": "Ausnahme-Ordner",
	supplierTraining: "Lieferanten-Lernprozeβ",
	clientTraining: "Klant leren",
	deleteTraining: "Lernprozeβ löschen",
	deleteTrainingMessage: "Sind Sie sicher das Training von diesem Lieferant stornieren zu wollen?",
	successdeleteTrainingMessage: "Erfolgreiches Löschen vom Lernprozeβ",
	"admin.download-buttons": "Handlungen an Rechnungen",
	"admin.download-buttons-description":
		"Wählen Sie welche Handlungen Sie auf Ihren Rechnungen ermöglichen möchten.",
	"admin.download-button.excel": "Im Excel herunterladen",
	"admin.download-button.journal-entry": "Im Excel-EC herunterladen",
	"admin.download-button.xml": "Im XML herunterladen",
	"admin.download-button.pdf": "Im PDF herunterladen",
	"admin.download-button.edi": "Im EDI herunterladen",
	"admin.invoice.AUTO_EXPORT-description":
		"Die Einstellung aktiviert das automatische Exportieren der Rechnungen, die vollständig extrahiert und ohne Fehler sind.",
	"admin.invoice.AMOUNT_TOLERANCE-description":
		"Dieses Feld ermöglicht die Einstellung einer Toleranz für die Berechnung der Summen. Die Fehlernotizien werden auf der Basis dieser Toleranz angezeigt.",
	"admin.MAXIMUM_SIZE-description": "Maximale Gröβe in MB von einer Datei zum Herunterladen",
	"admin.display-indicators": "Darstellung der Indikatoren im Dashboard",
	"admin.display-indicators-description":
		"Wählen Sie welche Indikatoren Sie auf Ihrem Dashboard ansehen möchten.",
	"admin.setting.my_tasks": "Meine Aufträge",
	"My orders": "Meine bestellungen",
	"admin.setting.invoices-exported": "Exportierte Rechnungen",
	"admin.setting.invoices-not-exported": "Nicht exportierte Rechnungen",
	"admin.setting.last-actions": "Letzte Handlungen",
	"admin.setting.load-invoices": "Rechnungen aufladen",
	"admin.setting.last-5-invoices": "Letzte 5 Rechnungen",
	"admin.setting.download-pdf": "PDF herunterladen",
	"admin.setting.delete": "Löschen",
	"admin.setting.delete-all": "Alle Rechnungen löschen",
	"admin.setting.AMOUNT_TOLERANCE.required": "Toleranz der Gesamtsummen erforderlich",
	"admin.setting.relaunch-extraction": "Extraktion neu starten",
	"admin.setting.export_entry_account_excel": "Excel-EC exportieren",
	"admin.export-buttons": "Verknüfungen für Downloads zum Exportieren",
	"admin.invoice.export-buttons-description":
		"Stellen Sie ein das Anzeigen der möglichen Download-Verknüpfungen nach dem Exportieren einer Rechnung. Die Verknüpfungen werden nach dem Exportieren in den Informationen der Rechnungen sichtbar.",
	"admin.extraction-level-settings": "Wichtigkeit der extrahierten Felder (Extraktionsstuf)",
	"admin.extraction-level-settings-desciption":
		"Stellen Sie ein die Wichtigkeit der Felder, die für Sie wichtiger sind und, die wesentlich sind. Diese Informationen werden in der Berechnung der Extraktionsstufen berücksichtigt.",
	"admin.level-settings": "Einstellung der Extraktionsstufen",
	"admin.extraction-level.champs": "Felder",
	"admin.extraction-level.status": "Status",
	"admin.extraction-level.mandatory": "Wesentlich",
	"admin.extraction-level.ignored": "Ignoriert",
	"admin.extraction-level.important": "Wichtig",
	"admin.setting.message.confirm": "Sind Sie sicher diese Einstellungen ändern zu wollen?",
	"admin.user.confirm.message.edit": "Sind Sie sicher dieser Benutzer ändern zu wollen?",
	"admin.user.confirm.message.add": "Sind Sie sicher dieser Benutzer einfügen zu wollen?",
	"admin.supplier.confirm.message.add": "Sind Sie sicher dieser Benutzer einfügen zu wollen?",
	"admin.client.confirm.message.add": "Sind Sie sicher dieser Benutzer einfügen zu wollen?",
	"admin.supplier.confirm.message.edit": "Sind Sie sicher dieser Benutzer ändern zu wollen?",
	"admin.config.message.confirm": "Sind Sie sicher diese EMail-Konfiguration ändern zu wollen?",
	"admin.extraction.message.confirm":
		"Sind Sie sicher diese Extraktionsstufen aktualisieren zu wollen?",
	"invoice.user.updated.success": "Erfolgreiche Änderung des Benutzer!",
	"admin.config.ftp.message.confirm":
		"Möchten Sie diese Konfiguration des FTP-Servers wirklich ändern?",
	"admin.config.ftp.update-message":
		"Die Konfiguration des FTP-Servers wurde erfolgreich aktualisiert",
	agent_accounting: "Buchhalter",
	agent_purchase: "Einkäufer",
	"admin.settings.invoice": "Rechnung",
	"admin.settings.order": "Bestellt",
	"admin.settings.invoices": "Rechnungseinstellungen",
	"admin.settings.orders": "Auftragseinstellungen",
	"admin.order.download-buttons": "Aktionen auf Bestellungen",
	"admin.order.download-buttons-description":
		"Wählen Sie im Portal aus, welche Aktionen Sie für Ihre Bestellungen zulassen möchten.",
	"admin.order.AUTO_EXPORT-description":
		"Dieser Parameter aktiviert den automatischen Export von Bestellungen, die vollständig extrahiert wurden und keine Fehlermeldungen aufweisen.",
	"admin.order.AMOUNT_TOLERANCE-description":
		"In diesem Feld können Sie eine Toleranz für die Berechnung der Gesamtsummen einer Bestellung festlegen. Aufgrund dieser Toleranz werden Fehlermeldungen angezeigt.",
	"admin.setting.last-5-orders": "Letzte 5 Bestellungen",
	"admin.order.setting.delete-all": "Alle Bestellungen löschen",
	"admin.order.export-buttons-description":
		"Richten Sie die Anzeige möglicher Download-Verknüpfungen nach dem Exportieren einer Bestellung ein. Die Schaltflächen werden nach dem Export in den Bestelldetails angezeigt.",
	"admin.settings.order.upload": "Bestellungen hochladen",
	"order.exported": "Exportierte Bestellungen",
	"order.unexported": "Nicht exportierte Bestellungen",
	"pagination.rows.per.page": "Zeilen pro Seite:",
	fix_errors: "Kann nicht fortfahren, bitte überprüfen Sie die folgenden Fehler",
	automatic_transition: "Automatischen Übergang zulassen",
	"rule.orderMissing": "Die Bestellnummer existiert nicht",
	"rule.orderNumberAbsent": "Die Bestellnummer ist nicht verfügbar",
	"rule.quantityInvalid":
		"Die in Rechnung gestellte Menge ist höher als die Menge in der Bestellung",
	"rule.unitPriceLineInvalid":
		"Der in Rechnung gestellte Stückpreis unterscheidet sich vom Stückpreis der Bestellung",
	"rule.orderLineMissing": "Die Bestellposition existiert nicht",
	"rule.orderLineMissings": "Die Bestellpositionen existieren nicht",
	"rule.totalAmountInvalid":
		"Der Gesamtbetrag der Rechnung ist höher als der Gesamtbetrag der Bestellung",
	"rule.cumulativeOrderInvalid":
		"Der Gesamtbetrag ist höher als der Gesamtbetrag in der Bestellung nach dem Export",
	"rule.accumulatedQuantityLineInvalid":
		"Die berechnete Menge ist höher als die Menge der Bestellung nach dem Export",
	"rule.incoherenceAmounts":
		"Der Gesamtbetrag der enthaltenen Steuer unterscheidet sich vom steuerfreien Betrag zuzüglich Mehrwertsteuer",
	"rule.totalEmpty": "Der Gesamtbetrag der Rechnung ist leer",
	"rule.DeliveryMissing": "Der Lieferschein existiert nicht",
	"rule.DeliveredQuantityInvalid":
		"Die in Rechnung gestellte Menge ist höher als die im Lieferschein angegebene Liefermenge",
	"rule.sumTotalHtInvalid":
		"Der gesamte steuerfreie Betrag unterscheidet sich von der Summe der steuerfreien Zeilen",
	"rule.duplicatedInvoice": "Die Rechnung existiert bereits",
	transition: "Überleitung",
	transitionbuttonlabel: "Schaltflächenbeschriftung",
	addstatus: "Status hinzufügen",
	saveworkflow: "Arbeitsablauf speichern",
	asksaveworkflow: "Möchten Sie den Workflow wirklich speichern?",
	update: "aktualisieren",
	deletestatus: "Status löschen",
	deletetransition: "Übergang löschen",
	updatestatus: "aktualisieren der stand",
	updatetransition: "Übergang aktualisieren",
	blockedby: "Blockiert von",
	appliedrules: "Angewandte Regeln",
	errorautotransition: "Sie können nicht mehr als einen automatischen Übergang haben",
	AbleCorrect: "Berechtigt für manuelle Korrektur",
	workflow: "Arbeitsablauf",
	"workflow.delete.error":
		"Dieser Status hat einen {{count}}-Übergang, löschen Sie den Übergang, bevor Sie den Status entferneno.",
	"workflow.status.success": "Workflow erfolgreich aktualisiert!",
	"workflow.source.status.litige.message":
		"Der Status in Litigation dürfen keinen ausgehenden Übergang haben",
	"workflow.property.isLitige": "Prozessstatus.",
	manager: "Managerin",
	"admin.client.confirm.message.edit": "Möchten Sie diesen Benutzer wirklich ändern?",
	super_user: "Super-Benutzer",
	"user.super_user.add": "SUPER User hinzufügen",
	"user.super_user.edit": "Superbenutzer bearbeiten.",
	managerSuperusers: "Super-Benutzerverwaltung",
	"admin.download-button.idoc": "Im IDOC herunterladen",
	"admin.setting.download-idoc": "IDOC herunterladen",
	"admin.setting.download-edi": "EDI herunterladen",
	"admin.workflow": "Arbeitsablauf",
	"admin.setting.download-ubl": "UBL herunterladen",
	"admin.setting.download-cii": "CII16B herunterladen",
	"admin.setting.download-xcbl": "XCBL herunterladen",
	"admin.setting.download-facturx": "Factur-X herunterladen",
	"user.field.commercialOrganization": "Kommerzielle Organisation",
	"admin.commercialOrganization.name": "Name",
	"admin.commercialOrganization.description": "Beschreibung",
	"admin.commercialOrganization.suppliers": "Lieferanten",
	"admin.commercialOrganization.clients": "Kunden",
	"admin.commercialOrganization.add": "Hinzufügen",
	"admin.commercialOrganization.remove": "Entfernen",
	"admin.commercialOrganization.edit":
		"Sind Sie sicher, dass Sie diese kommerzielle Organisation bearbeiten möchten?",
	"admin.commercialOrganization.save":
		"Sind Sie sicher, dass Sie diese kommerzielle Organisation retten möchten?",
	"admin.commercialOrganization.editRecord": "Handelsorganisation hinzufügen",
	"admin.commercialOrganization.addRecord": "Kommerzielle Organisation bearbeiten.",
	"admin.commercialOrganization.success": "Kommerzielle Organisation wurde erfolgreich hinzugefügt",
	"admin.commercialOrganizationEdit.success":
		"Die kommerzielle Organisation wurde erfolgreich geändert",
	"admin.commercialOrganization.deleteSuccess": "Kommerzielle Organisation erfolgreich löschen",
	"admin.commercialOrganization.deleteMsg":
		"Sind Sie sicher, dass Sie diese kommerzielle Organisation löschen möchten?",
	"user.commercialOrganization.exists.message": "Die kommerzielle Organisation existiert bereits.",
	"user.commercialOrganization.users.exists.message":
		"Die kommerzielle Organisation kann nicht gelöscht werden.Einige Benutzer sind noch zugewiesen.",
	"user.commercial_organization_showAll": "Alles beeinflussen",
	"user.commercial_organization_showAll.description":
		"Durch die Wahl dieser Option hat der Direktor die Erlaubnis, alle Daten in Bezug auf Rechnungen und Bestellungen zu sehen",
};
