import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import { initFilter } from "administration/redux/reducers/UserFilterReducer";
import CommercialOrganizationTable from "../components/List";
import useStyles from "./Style";
import CommercialOrganizationFilter from "../components/Filter";
import { fetchAllCommercialOrganizations } from "../../../redux/actions/commercialOrganizations";

const CommercialOrganization = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const classes = useStyles();

	const filterUsers = useSelector(({ filterUsers: i }) => i);

	useEffect(() => {
		dispatch(initFilter());
		dispatch(fetchAllCommercialOrganizations(filterUsers));
	}, []);

	const isOrderEnv = useSelector(({ appState }) => appState.isOrderEnv);
	const module = isOrderEnv ? "order" : "invoice";

	return (
		<>
			<Grid container item xs={12}>
				<CommercialOrganizationFilter
					module={`${module}/commercial-organization`}
					showStatusFilter={false}
					showSortFilter={false}
				/>
			</Grid>
			<Grid container item xs={12} className={classes.tableContainer}>
				<CommercialOrganizationTable t={t} classes={classes} module={module} />
			</Grid>
		</>
	);
};

export default CommercialOrganization;
