import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Chip from "@material-ui/core/Chip";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import { updateFilterOrder } from "../../../../redux/orderFilterReducer";
import chipData from "./data";

import useStyles from "./style";

export default () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const filterOrders = useSelector(({ filterOrders: i }) => i);
	const { t } = useTranslation();
	const [selectedChip, setSelectedChip] = React.useState([]);

	useEffect(() => {
		const newFilter = { ...filterOrders, pageNo: 0 };
		newFilter.extractionLvl = selectedChip.filter((i) => i !== "duplicated");
		newFilter.duplicated = !!selectedChip.find((i) => i === "duplicated");
		dispatch(updateFilterOrder(newFilter));
	}, [selectedChip]);

	const handleDelete = (id) => {
		setSelectedChip(selectedChip.filter((i) => i !== id));
	};

	const handleClick = (id) => {
		setSelectedChip([...selectedChip, id]);
	};

	return (
		<div className={classes.chipLayout}>
			{chipData.map(({ labelId, icon, id }) => {
				const isSelected = selectedChip.includes(id);
				return (
					<Chip
						key={id}
						icon={icon}
						label={t(labelId)}
						onClick={() => handleClick(id)}
						onDelete={isSelected ? () => handleDelete(id) : null}
						classes={{
							root: clsx(classes.chip, classes[isSelected ? `${id}Selected` : id], {
								[classes.chipSelected]: isSelected,
							}),
						}}
					/>
				);
			})}
		</div>
	);
};
