import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    // boxShadow: "0 1px 15px 1px rgba(69,65,78,.08)",
    // backgroundColor: theme.palette.background.paper,
    padding: "24px ",
  },
  emptyInput: {
    backgroundImage: "#C0C0C0",
  },
  resize: {
    fontSize: "0.875rem",
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },

  customBadge: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "50%",
    height: "30px",
    width: "30px",
  },
  icon: {
    position: "relative",
    top: "8px",
    right: "8px",
    fontSize: "3rem",
    opacity: "0.3",
  },
  supplementInfosHeader: {
    borderBottom: `3px solid ${theme.palette.primary.dark}`,
  },
  backButton: {
    backgroundColor: "#d63031",
    color: "white",
  },
}));
