import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Button, Typography, TablePagination, CircularProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Query, useQuery } from "@redux-requests/react";
import get from "lodash/get";
import { currencyFormat, scrollToTop } from "utils";
import ConfirmDialog from "components/ConfirmDialog";

import {
	fetchOrders,
	deleteOrder,
	ordersTotalAmounts,
	fetchCountOrders,
	fetchOrderIndicators,
} from "../../../redux/actions/orders";
import { updateFilterOrder } from "../../../redux/orderFilterReducer";
import { FETCH_ORDERS, ORDER_TOTAL_AMOUNT } from "../../../redux/constants";
// import useStyles from "./style";
import OrdersRow from "./OrdersRow";
import useStyle from "./style";

function OrdersList({ settings, isDashboard, setSelected, selected }) {
	const classes = useStyle();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();

	const filterOrders = useSelector(({ filterOrders: i }) => i);
	const [selectedId, setSelectedId] = React.useState(0);
	const [confirmObject, setConfirmObject] = React.useState({
		message: t("orderDeleteMessage"),
		openConfirm: false,
		cancel: false,
		isLoading: false,
	});

	const [defaultPageSize, setDefaultPageSize] = React.useState(
		localStorage.getItem("orders_pageSize_default")
			? localStorage.getItem("orders_pageSize_default")
			: process.env.REACT_APP_DEFAULT_PAGE_SIZE_LIST_INVOICE
	);

	const { data, loading } = useQuery({ type: FETCH_ORDERS });

	const fetchOrdersAfterRelauch = isDashboard
		? {
				invoiceNoOrSupplierCode: "",
				status: [{ code: "TO_VERIFY", label: "To verify" }],
				startDate: null,
				endDate: null,
				pageNo: 0,
				pageSize: isDashboard ? 5 : filterOrders?.pageSize,
		  }
		: filterOrders;

	const handleChangePage = (event, newPage) => {
		dispatch(updateFilterOrder({ ...filterOrders, pageNo: newPage }));
		setSelected([]);
	};

	const handleChangeRowsPerPage = (event) => {
		localStorage.setItem("orders_pageSize_default", event.target.value);
		setDefaultPageSize(event.target.value);
		dispatch(
			updateFilterOrder({
				...filterOrders,
				pageSize: +event.target.value,
				pageNo: 0,
			})
		);
		setSelected([]);
	};

	useEffect(() => {
		dispatch(fetchOrders(filterOrders));
	}, [filterOrders]);
	useEffect(() => {
		if (isDashboard) {
			dispatch(ordersTotalAmounts(["TO_VERIFY"], "_VERIFIED"));
			dispatch(
				fetchOrders({
					orderNoOrClientLabel: "",
					status: [{ code: "TO_VERIFY", label: "To verify" }],
					startDate: null,
					endDate: null,
					pageNo: 0,
					pageSize: 5,
					selectAll: false,
					sortBy: "",
				})
			);
		} else {
			dispatch(fetchOrders(filterOrders));
		}
		scrollToTop();
	}, []);

	const onClickShowAll = () => {
		dispatch(
			updateFilterOrder({
				orderNoOrClientLabel: "",
				status: [{ code: "TO_VERIFY", label: "To verify" }],
				startDate: null,
				endDate: null,
				pageNo: 0,
				pageSize: defaultPageSize,
			})
		);
		history.push("/orders", { fromDashboard: true });
	};

	const handleCloseDeletePopup = () => {
		setConfirmObject({ ...confirmObject, openConfirm: false });
	};

	const toDelete = () => {
		dispatch(
			deleteOrder(selectedId, () => {
				if (isDashboard) {
					dispatch(
						fetchOrders({
							orderNoOrClientLabel: "",
							status: [{ code: "TO_VERIFY", label: "To verify" }],
							pageNo: 0,
							pageSize: 5,
							selectAll: false,
							sortBy: "",
						})
					);
				} else {
					dispatch(fetchOrders(filterOrders));
				}

				dispatch(ordersTotalAmounts(isDashboard ? ["TO_VERIFY"] : []));
				dispatch(fetchCountOrders());
				if (isDashboard) dispatch(fetchOrderIndicators());
				if (!isDashboard) setSelected([]);
			})
		);
		handleCloseDeletePopup();
	};

	return (
		<>
			{isDashboard && (
				<Grid container xs={12}>
					<Grid container direction="column" item xs={6}>
						<Grid item>
							<Typography variant="h6" color="secondary">
								{t("order.total_amoutn_to_verify")} :
								<Query type={`${ORDER_TOTAL_AMOUNT}_VERIFIED`}>
									{(dataParam) => ` ${currencyFormat(get(dataParam, "data"))}`}
								</Query>{" "}
								€
							</Typography>
						</Grid>
						<Grid item>
							<Typography variant="subtitle2" color="textSecondary">
								{t("countOrders")} {t("tobeprocessed")} :
								<Query type={FETCH_ORDERS}>
									{({ data: param }) => ` ${get(param, "totalElements")}`}
								</Query>
							</Typography>
						</Grid>
					</Grid>
					<Grid container direction="row-reverse" alignContent="flex-end" xs={6}>
						{isDashboard && (
							<Button
								align="right"
								color="secondary"
								className={classes.underlineText}
								onClick={() => onClickShowAll()}
							>
								{t("Show all")}
							</Button>
						)}
					</Grid>
				</Grid>
			)}
			{loading ? (
				<Grid container item xs={12} justify="center">
					<CircularProgress />
				</Grid>
			) : (
				<OrdersRow
					data={data}
					settings={settings}
					isDashboard={isDashboard}
					filterOrders={filterOrders}
					setSelectedId={setSelectedId}
					selectedId={selectedId}
					setOpenDeletePopup={setConfirmObject}
					deleteConfirmObject={confirmObject}
					setSelected={setSelected}
					selected={selected}
					fetchOrdersAfterRelauch={fetchOrdersAfterRelauch}
				/>
			)}
			<ConfirmDialog
				open={confirmObject.openConfirm}
				message={confirmObject.message}
				isLoading={confirmObject.isLoading}
				onConfirm={toDelete}
				onCancel={handleCloseDeletePopup}
			/>

			{!isDashboard && (
				<TablePagination
					rowsPerPageOptions={[10, 25, 50, 100]}
					component="div"
					count={data?.totalElements}
					rowsPerPage={filterOrders.pageSize || defaultPageSize}
					page={filterOrders.pageNo}
					onChangePage={handleChangePage}
					onChangeRowsPerPage={handleChangeRowsPerPage}
					labelRowsPerPage={t("pagination.rows.per.page")}
				/>
			)}
		</>
	);
}

export default OrdersList;
