import supllierEn from "../pages/Suppliers/i18n/en";
import administrationEn from "../administration/i18n/en";
import clientEn from "../pages/Clients/i18n/en";

export default {
	...supllierEn,
	...administrationEn,
	...clientEn,
	Copyright: "Copyright",
	Dashboard: "Dashboard",
	"Business management": "Business management",
	Orders: "Orders",
	Invoices: "Invoices",
	Regulations: "Payments",
	Suppliers: "Suppliers",
	Rapports: "Reporting",
	Search: "Search",
	"Add your Invoice": "Add your Invoice or ",
	browse: "browse",
	fileAddedMsg: "File(s) added successfully!",
	fileRemovedMsg: "File {{filename}} removed.",
	"Show all": "Show all",
	id: "Identifiant",
	supplier: "Supplier",
	invoiceNo: "Invoice No",
	issueDate: "Issue date",
	dueDate: "Due date",
	orderDeliveryDate: "Delivery date",
	"Price WT": "Pre-Tax Amount",
	"Taxe Rate": "VAT amount",
	Price: "Amount Incl Tax",
	"Purchase order": "Order",
	"Last actions": "Last actions",
	Today: "Today",
	Yesterday: "Yesterday",
	"Last week": "Last week",
	Status: "Status",
	Date: "Date",
	"My tasks": "My invoices",
	"My orders": "My orders",
	"Invoices to verify": "To verify",
	"Invoices to be checked": "To control",
	"Invoices to Payers": "Ready for payment",
	"Invoice available": "Invoice available",
	"Paid invoices": "Paid",
	"Unpaid invoices": "Unpaid",
	"Total amount of invoices": "Total amount of invoices",
	"Total amount of orders": "Total amount of orders",
	"Total VAT amount": "Total VAT",
	"Statistical overview of purchases": "Invoicing history",
	"Statistical overview of product sales": "Statistical overview of product sales",
	"Total Purchases": "Total purchase orders",
	"Total of expenses": "Invoices Total",
	Late: "Overdue",
	Administrator: "Administration",
	"New suppliers": "New suppliers",
	FOUNDED_RESULT: "Founded result",
	EndDate: "End Date",
	StartDate: "Start Date",
	"Add an invoice": "Add invoice",
	Upload: "Upload invoices",
	Download: "Download",
	Show: "Show",
	Delete: "Delete",
	All: "All",
	SearchInvoice: "Find an invoice...",
	"Delivery address": "Delivery address",
	"Billing address": "Billing address",
	"Payment terms": "Payment terms",
	"Order Details": "Order Details",
	"Invoice Details": "Invoice Details",
	"Reject Invoice": "Reject",
	"Reject Order": "Reject",
	"Correct Extraction": "Rectify",
	Control: "Control",
	ToPay: "Payment",
	"Total HT": "Total HT",
	"VAT amount": "VAT amount",
	Discount: "Discount",
	"Total amount": "Total amount",
	"Invoice overview": "Invoice overview",
	History: "History",
	verificationDate: "Verification date",
	rejectionDate: "Rejection date",
	controlDate: "Control date",
	dateOfReceipt: "Date of receipt",
	rejectMessage: "Are you sure you want to reject this invoice?",
	controlMessage: "Are you sure you want to send this invoice to control?",
	ErrorMessageToControl: "You cannot control this invoice:",
	topayMessage: "Are you sure you want to send this invoice for payment?",
	deleteMessage: "Are you sure you want to delete these invoices?",
	orderDeleteMessageMultiple: "Are you sure you want to delete the selected order(s)?",
	individualDeleteMessage: "Are you sure you want to delete this invoice?",
	orderDeleteMessage: "Are you sure you want to delete this order?",
	rejectionReason: "Rejection reason",
	rejectionDetails: "Rejection details...",
	cancel: "Cancel",
	confirm: "Confirm",
	editForm: "Modification",
	countInvoices: "Total number of invoices ",
	countOrders: "Total number of orders ",
	tobeprocessed: "to verify",
	None: "------------------",
	save: "Save",
	edit: "Edit",
	Actions: "Actions",
	itemCode: "Code",
	designation: "Item",
	quantity: "Qty",
	unitPrice: "Price",
	deliveryDelay: "Delivery delay",
	Commands: "BILLING LINES",
	"order.lines": "COMMAND LINES",
	Total: "Total",
	VAT: "VAT Rate",
	"Pre-Tax-Amount": "Pre-Tax-Amount",
	Canceled: "Canceled",
	totalHt: "Total HT",
	vat: "VAT",
	sortBy: "Sort by",
	createdAt: "Created at",
	updatedAt: "Updated at",
	InvoicesCannotBeDeleted: "The following invoices cannot be deleted ",
	OrdersCannotBeDeleted: "The following order cannot be deleted ",
	InvoicesCannotBeRelaunched: "The following invoices cannot be relaunched ",
	"Integration in progress": "Integration in progress",
	"To verify": "To verify",
	"To control": "To control",
	"To pay": "To pay",
	Payed: "Payed",
	Rejected: "Rejected",
	Exception: "Exception",
	codeArticle: "Item code",
	orderNo: "Purchase order",
	numberOfArticle: "Number of items",
	deliveryMethod: "Delivery method",
	InvoicesNotCreatedMessage: "The following invoices cannot be created",
	invoice: "invoice",
	order: "order",
	training: "Training...",
	trained: "Trained",
	"Company Siret": "Company id",
	"VAT Number": "VAT number",
	"Apply Changes": "Apply changes",
	"invoice Number": "Invoice number",
	phone: "Phone",
	"Email de Contact": "Contact email",
	"Contact principal": "Main contact",
	"Invoice was already paid": "Invoice was already paid",
	"Order has been already delivered": "Order has been already delivered",
	"Goods or Services were never received": "Goods or Services were never received",
	"Goods or services are not available at the moment":
		"Goods or services are not available at the moment",
	"Supporting documents were not received with the invoice":
		"Supporting documents were not received with the invoice",
	"The purchase order number is missing, is incorrect or has been canceled":
		"The purchase order number is missing, is incorrect or has been canceled",
	"The order number is missing, is incorrect or has been canceled":
		"The order number is missing, is incorrect or has been canceled",
	"The amount being billed does not match the agreed upon amount on the contract":
		"The amount being billed does not match the agreed upon amount on the contract",
	"Supporting documents were not received with the order":
		"Supporting documents were not received with the order",
	Other: "Other",
	CREATE_ACTION: "Invoice created",
	EDIT_ACTION: "Modification of the invoice",
	VERIFY_ACTION: "Invoice under verification",
	REJECT_ACTION: "Invoice rejected",
	CONTROL_ACTION: "Invoice sent for control",
	END_TRAINING_ACTION: "Learning completed",
	CORRECT_ACTION: "Learning launched",
	TO_PAY_ACTION: "Payment of the invoice",
	DELET_ACTION: "Deletion of the invoice",
	TO_VALIDATE_EXTERNAL_ACTION: "Validation request",
	RELAUNCH_ACTION: "Released extraction",
	EXPORT_ACTION: "Export of the invoice",
	ORDER_CREATE_ACTION: "Order created",
	ORDER_VERIFY_ACTION: "Order under verification",
	ORDER_REJECT_ACTION: "Order rejected",
	ORDER_CONTROL_ACTION: "Order sent for control",
	ORDER_TO_VALIDATE_EXTERNAL_ACTION: "Validation request",
	ORDER_END_TRAINING_ACTION: "Learning completed",
	ORDER_CORRECT_ACTION: "Learning launched",
	ORDER_TO_PAY_ACTION: "Payment of the order",
	ORDER_DELETE_ACTION: "Deletion of the order",
	ORDER_EDIT_ACTION: "Modification of the order",
	ORDER_RELAUNCH_ACTION: "Released extraction",
	ORDER_EXPORT_ACTION: "Export of the order",
	INVOICE_CHANGE_STATUS: "Invoice passed to status {{status}}",
	INVOICE_CHANGE_STATUS_AUTOMATIC: "Invoice automatically passed to status {{status}}",
	action: "Action",
	details: "Details",
	totalEmptyMessageError: "The total amount of the invoice is not filled",
	supplierEmptyMessageError: "The supplier of the invoice is not filled",
	"invoice.status.integration": "Integration",
	"invoice.status.toverify": "To Verify",
	"invoice.status.tocontrol": "To Control",
	"invoice.status.topay": "To Pay",
	"invoice.status.payed": "Payed",
	"invoice.status.rejected": "Rejected",
	"invoice.status.canceled": "Canceled",
	"invoice.status.exception": "Exception",
	"invoice.status.external_validation": "External Validation",
	"order.status.external_validation": "Pending approval",
	comment: "Comment",
	incohenrenceAmountsMessageError:
		"The calculation of the amounts is incorrect; The amount of VAT and the amount excluding VAT is different from the total amount",
	reason: "Reason",
	Approve: "Approve",
	email: "Email",
	approvedBy: "Approve by",
	"Nb total des factures": "Nb total des factures",
	"Facture en cours": "Invoices in process",
	"Total Orders": "Total number of orders",
	searchOrder: "Find an order",
	VALIDATED: "Valided",
	PARTIALLY_DELIVERED: "Partially Delivered",
	DELIVERED: "Deliverd",
	PARTIALLY_BILLED: "Partially Billed",
	BILLED: "Billed",
	"purchase_order.status.validated": "Valided",
	"purchase_order.status.partiallyDelivered": "Partially Delivered",
	"purchase_order.status.delivered": "Deliverd",
	"purchase_order.status.PartiallyBilled": "Partially Billed",
	"purchase_order.status.billed": "Billed",
	"order Lines": "Order lines",
	delivered: "Delivered",
	"non delivered": "Non delivered",
	orderDate: "Order date",
	invoiceAccumulation: "Invoice accumulation",
	orderType: "Order Type",
	CLOSED: "Closed",
	OPEN: "Open",
	DeliverySlip: "Delivery slip",
	currency: "Currency",
	deliveryNo: "Delivery No",
	label: "Label",
	deliveredQuantity: "Quantity delivered",
	delivery: "Delivery",
	"Delivery Details": "Delivery Details",
	"Delivery Lines": "Delivery lines",
	deliveryItemDate: "Delivery date",
	export: "Export",
	"invoice.status.exported": "Exported",
	"price TTC": "Price TTC",
	unitePriceHT: "Price HT",
	"invoice.exported": "Exported invoices",
	"invoice.unexported": "Non-exported invoices",
	invoice_charge: "Charges",
	order_charge: "Charges",
	"invoice.total_amoutn_to_verify": "Total amount of invoices to verify",
	"invoice.total_invoice": "Total invoices",
	"invoice.duplicated": "Duplicated",
	"extraction.label.fully_extracted": "Fully extracted",
	"extraction.label.partially_extracted": "Partially extracted",
	"extraction.label.not_extracted": "Not extracted",
	"extraction.label.not_invoice": "Other than invoice",
	"this field is mandatory": "this field is mandatory",
	rejectedBy: "Rejected by",
	"invoice.detail.company_siret": "Company id",
	country: "Country",
	duplicated_invoice: "Duplicated invoice",
	"user.roles.user": "User",
	"tootlip.action.edit": "Edit",
	"tooltip.action.delete": "Delete",
	"tooltip.action.edit": "Edit",
	"tooltip.action.relaunch": "Relaunch",
	"tooltip.action.download": "Download",
	"tooltip.action.relaunch_extraction": "Relaunch the extraction",
	"tooltip.action.export_excel": "Export excel",
	"tooltip.action.export_entry_account_excel": "Excel export journal entry",
	"tooltip.action.export_xml": "Export xml",
	"tooltip.action.export_edifact": "Export Edifact",
	"profile.update-password.new-password-required": "New password is required",
	"profile.update-password.password-required": "Password is required",
	"profile.update-password.password-not-matched": "Passwords do not match",
	"profile.update-password.current-password": "Current Password",
	"profile.update-password.new-password": "New Password",
	"profile.update-password.confirm-password": "Confirm Password",
	"profile.update-password.reset-password": "Reset Password",
	"profile.update-password.password-reset": "Password Reset",
	"profile.update-password.password-reset-success": "Your password was changed successfully",
	"profile.update-password.password-email-send": "An email has been sent. please check your mail.",
	"profile.update-password.password-reset-done": "Done",
	"profile.update-password.update-password": "Change password",
	"profile.update-password.email": "Your current Email",
	"profile.update-password.tokenError": "Invalid Token, Password is already updated!",
	"suppliers.add.multiple": "Add Suppliers or ",
	"upload.supplier": "Upload Suppliers",
	"downloqd.template": "download template",
	"supplier.add.button": "Import suppliers",
	"supplier.export.suppliers.excel": "Export suppliers list",
	"supplier.add2.button": "Add a supplier",
	"invoice.status.processing": "Processing",
	"invoice.status.underProcess": "Being processed",
	"invoice.status.InoviceProcessed": "Processed Invoices",
	"invoice.status.InoviceUnderProcess": "Invoices under processing",
	"invoice.status.processed": "Processed",
	"invoice.status.open": "Open",
	"invoice.status.reintegrated": "Reintegrated",
	Administration: "Administration",
	Logout: "Logout",
	Profile: "Profile",
	"invoice.selected.title0": "All invoices are selected",
	"invoice.selected.title1": "The ",
	"invoice.selected.title2": " invoices on this page are all selected.",
	"invoice.selected.title3": "Select all invoices",
	"supplier.add.step1": "General Informations",
	"supplier.add.step2": "Primary Contact",
	"supplier.add.step3": "Financial Informations",
	"supplier.add.next": "Next",
	"supplier.add.previous": "Previous",
	"supplier.add.finish": "Finish",
	"supplier.add.country": "Base Country",
	"supplier.add.suppliername": "Supplier Name",
	"supplier.add.logo": "Logo",
	"supplier.add.capital": "Capital",
	"supplier.add.siret": "Company SIRET",
	"supplier.add.siren": "Company SIREN",
	"supplier.add.linkedin": "LinkedIn",
	"supplier.add.email": "Email",
	"supplier.add.fax": "Fax",
	"supplier.add.civility": "Civility",
	"supplier.add.phone": "Phone",
	"supplier.add.vat": "VAT Number",
	"supplier.add.naf": "Code NAF",
	"supplier.add.bank": "Bank",
	"supplier.add.bic": "BIC",
	"supplier.name.exist.already": "Supplier exists on the system",
	"suppliers.names.exist.already": "Suppliers {{fournisseurs}} already exist on the system!",
	"suppliers.empty.field.name.row.excel": "There is a line with empty name !!",
	"suppliers.error.sample": "The file sent does not comply with the exemplary file.",
	"supplier.add.iban": "IBAN",
	"supplier.add.rib": "RIB",
	"supplier.add.acc": "Account Reference",
	"supplier.add.curr": "Default Currency",
	"supplier.add.function": "Function",
	"supplier.add.firstName": "First Name",
	"supplier.add.lastName": "Last Name",
	"supplier.add.city": "City",
	"supplier.add.street": "Street",
	"supplier.add.zipCode": "Zip Code",
	"supplier.add.confirm": "Are you sure you want to save the changes?",
	"supplier.delete.confirm": "Are you sure you want to delete the supplier?",
	"supplier.delete.success": "Supplier deleted successfully",
	"supplier.have.attached.user.accounts.or.invoices.message":
		"Cannot delete the supplier(s). An invoice/account is attached to it!",
	"client.has.attached.user.accounts.message":
		"Can not delete the client (s).An order / account is attached!",
	"suplier.action.agree": "Agree",
	"suplier.action.disagree": "Disagree",
	"supplier.add.aux": "Auxiliary Account",
	ApplyForApproval: "Apply for approval",
	EXPORT_EXCEL: "Export excel",
	EXPORT_EDI: "Export edi",
	EXPORT_XML: "Export xml",
	EXPORT_PDF: "Export pdf",
	EXPORT_EC: "Export JE",
	"order.absent": "Order absent",
	"amount.greate.thang.goods": "Invoice amount greater than the order",
	"delivery.absent": "Delivery absent",
	"Zip Code": "Zip Code",
	Street: "Street",
	City: "City",
	"supplier.delete.training.error.message": "No learning exists for this provider.",
	"invoice.detail.reject.success": "Invoice was rejected  successfully",
	"invoice.detail.reject.failed": "Invoice wasn't rejected",
	"invoice.detail.marked.selectedField": "Marked the selected field",
	"invoice.detail.apply.validate": "Apply & Validate",
	"invoice.detail.approved.success": "Invoice was approved successfully",
	"invoice.detail.approved.failed": "Invoice wasn't approved",
	"invoice.detail.export.success": "Invoice was exported  successfully",
	"order.detail.export.success": "Order was exported  successfully",
	"order.detail.approved.failed": "Order wasn't approved",
	"invoice.detail.export.failed": "Invoice wasn't exported",
	"order.detail.export.failed": "Order wasn't exported",
	"invoice.detail.message.rectify": "Are you sur you want to save this changes ?",
	"invoice.detail.message.confirmExport": "Are you sur you want to export this invoice ?",
	"order.detail.approved.success": "The order has been successfully approved",
	"order.detail.message.confirmExport": "Are you sur you want to export this order ?",
	"token.validation.noteExist": "Change password request already sent",
	"client.delete.msg": "Are you sure you want to delete this client?",
	"invoice.detail.reintegrated.success": "Invoice was reintegreted  successfully",
	"invoice.detail.reintegrated.failed": "Invoice wasn't reintegreted",

	year: "This year",
	month: "This month",
	week: "This week",
	day: "Today",
	Exported: "Exported",
	Integrated: "Integrated",
	"switch.orders": "ORDERS",
	"switch.invoices": "INVOICES",
	"use.doc.cutter": "Use automatic cutting",
	"Invoice attachements": "Attachements",
	"invoice.attachment.contract": "Contract",
	"invoice.attachment.order": "Order",
	"invoice.attachment.receipt": "Receipt",
	"invoice.attachment.oth": "Other",
	"order.attachment.contract": "Contract",
	"order.attachment.order": "Order",
	"order.attachment.receipt": "Receipt",
	"order.attachment.oth": "Other",
	"attachment.name": "Name",
	"attachment.upload": "Upload",
	"attachment.cancel": "Cancel",
	completeerrors: "Complete Errors",
	completeerrorsphrase: "The task still contains errors",
	warnings: "Warnings",
	ruleerrors: "Rules Errors",
	checkerrors: "Check Errors",
	completetask: "Complete Task",
	correcterrors: "Correct Errors",
	"messenger.addComment": "Add Comment",
	"messenger.postComment": "Poster le post comment",
	"tooltip.action.export_idoc": "Export Idoc",
	EXPORT_IDOC: "Export idoc",
	lockTraining: "Lock Training",
	unlockTraining: "Unlock Training",
	"accountingLines.costCenter": "Cost center",
	"accountingLines.paymentMethod": "Payment method",
	"accountingLines.glAccounts": "Gl account",
	"accountingLines.description": "Description",
	"accountingLines.sign": "Operation",
	"accountingLines.amount": "Amount",
	"accountingLines.addButton": "Add accounting line",
	"accountingLines.summary": "Accounting lines are not balanced",
	"Accounting Lines": "Journal Entry",
	"invoice.digital.extract": "Extracted from the electronic invoice",
	"invoice.digital": "Electronic invoice",
	"invoice.digital.preview":
		"Preview not available: this electronic invoice does not contain a PDF",
	"tooltip.action.export": "Export to",
	"invoice.digital.about": "About the invoice",
	"invoice.digital.type": "Invoice type",
	"invoice.digital.signature.validity": "Signature validity",
	"invoice.digital.format.compliance": "Format compliance",
	"invoice.digital.en16931.compliance": "EN 16931 compliance",
	"invoice.digital.en16931.rules.violated": "Number of EN 16931 rules violated",
	"invoice.digital.chorus.pro.compliance": "Chorus Pro business rules compliance",
	"invoice.digital.chorus.pro.rules.violated": "Number of Chorus Pro rules violated",
	"invoice.digital.readmore": "Read more about this invoice",
	"invoice.go.to": "View invoice",
	"invoiceNo.must.be.present": "The invoice number must be present",
	"supplier.must.be.present": "The supplier must be present",
	"issueDate.must.be.present": "The issue date must be present",
	"total.must.be.present": "The total amount must be present",
	"currency.must.be.present": "The currency must be present",
	"supplier.name.must.be.present": "The supplier's name must be present",
	"supplier.siret.must.be.present": "The supplier's company ID must be present",
	"supplier.vatNumber.must.be.present": "The supplier's VAT number must be present",
	"supplier.country.must.be.present": "The supplier's country must be present",
	"designation.must.be.present": "An invoice line must include the product name",
	"quantity.must.be.present": "An invoice line must include the invoiced quantity",
	"vatRate.must.be.present": "An invoice line must include the VAT rate",
	"price.must.be.present": "An invoice line must include the unit price excluding VAT",
	CREATE_ACTION_LITIGE: "Disputed invoice created ",
	REINTEGRATED: "Reintegrated",
	REINTEGRATE_ACTION_LITIGE: "Reintegrate the disputed invoice",
	REJECT_ACTION_LITIGE: "Invoice rejected",
	REINTEGRATE_ACTION: "Reintegrate the invoice",

	"invoice.litige.already.in.open.status": "this invoice already disputed",
	"invoice.export.entry_account_excel": "Excel accounting entry",
	"invoice.export.error": "Error while exporting invoice",
	"order.export.error": "Error during the export of the order",
	"confirmation.reintegrate": "you really want to reintegrate this invoice",
	"confirmation.nextStatus": "Are you sur you want to change the invoice status to ",
	"confirmation.relance": "Are you sure you want to restart the extraction",
	"confirmation.ExportStatus":
		' Are you sur you want to change the invoice status to " EXPORT " ? ',
	"file.scan.failed": "Antivirus scan failed",
	"file.infected": "Infected file",
	logoScanFailed: "Logo antivirus scan failed",
	logoInfected: "Infected logo",
};
