import React from "react";
import { Grid, TextField, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
// import SubPagePanel from "administration/commonComponents/SubPagePanel";
import { useDispatch, useSelector } from "react-redux";
import { Query } from "@redux-requests/react";
import CircularLoader from "components/Loaders/CircularLoader";
import { FETCH_MESSAGES_HISTORY } from "redux/constants";
import get from "lodash/get";
import { useParams } from "react-router-dom";
import Message from "./components/Message";
import { fetchMessagesHistoryWithClient, postReply } from "../../redux/actions/invoices";
import useStyles from "./style";

// function a11yProps(index) {
// 	return {
// 		id: `simple-tab-${index}`,
// 		"aria-controls": `simple-tabpanel-${index}`,
// 	};
// }

export default () => {
	const { t } = useTranslation();
	const classes = useStyles();
	const dispatch = useDispatch();

	const { idSupplier } = useParams();
	const [reply, setReply] = React.useState(null);
	const [replyButtonDisabled, setReplyButtonDisabled] = React.useState(false);
	const currentUser = useSelector(({ requests }) =>
		get(requests, "queries.FETCH_CURRENT_USER.data")
	);

	React.useEffect(() => {
		const tmpSupplierId = idSupplier || get(currentUser, "supplierId");
		if (tmpSupplierId) dispatch(fetchMessagesHistoryWithClient(tmpSupplierId));
	}, [idSupplier, currentUser]);

	const handleReplyChange = (e) => {
		setReply(e.target.value);
	};

	const handleSendReply = () => {
		if (!reply || reply.trim().length === 0) return;
		setReplyButtonDisabled(true);
		const tmpSupplierId = idSupplier || get(currentUser, "supplierId");
		dispatch(postReply(reply, tmpSupplierId)).then(() => {
			dispatch(fetchMessagesHistoryWithClient(tmpSupplierId));
			setReply("");
			setReplyButtonDisabled(false);
		});
	};

	return (
		<>
			<Grid container xs={12} className={classes.root}>
				<Grid container justify="flex-end" className={classes.replyContainer}>
					<TextField
						id="filled-multiline-static"
						label={t("messenger.addComment")}
						onChange={handleReplyChange}
						value={reply}
						multiline
						rows={4}
						fullWidth
						variant="outlined"
					/>
					<Button
						color="secondary"
						variant="contained"
						className={classes.replyBtn}
						onClick={handleSendReply}
						disabled={replyButtonDisabled}
					>
						{replyButtonDisabled && <CircularLoader />}
						{t("messenger.postComment")}
					</Button>
				</Grid>
				<Grid container xs={12} justify="center">
					<Query type={FETCH_MESSAGES_HISTORY} loadingComponent={CircularLoader}>
						{({ data }) =>
							data.map((row, index) => <Message {...row} index={index} currentUser={currentUser} />)
						}
					</Query>
				</Grid>
			</Grid>
		</>
	);
};
