import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	root: {
		marginBottom: "5px",
	},
	replyContainer: {
		margin: theme.spacing(3, 10),
	},
	replyBtn: {
		marginTop: theme.spacing(3),
	},
	title: {
		paddingTop: "1%",
	},
}));
