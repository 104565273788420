import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import { Paper, Tab, Tabs } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ImportConfig } from "administration/importConfig";
import InvoiceSettings from "administration/settingsPage/InvoiceSettings";
import OrderSettings from "administration/settingsPage/OrderSettings";
import SuppliersCommercialOrganization from "administration/CommercialOrganization/supplier";
import ClientsCommercialOrganization from "administration/CommercialOrganization/client";

import get from "lodash/get";
import Workflow from "../workflow";

import useStyles from "../style";
import SubPageContainer from "../../components/SubPageContainer";
import SubPagePanel from "../../components/SubPagePanel";
import { fetchUsers } from "../redux/actions";
// import { Add } from "./Add";

import { List } from "./List";
import { ListSuppliers } from "./ListSuppliers";
import { ListClients } from "./ListClients";
import { ListSupperUsers } from "./ListSupperUsers";
// import { AddSuplierAccount } from "./AddSupplierAccount";
// import { AddClientAccount } from "./AddClientAccount";
import { ExtractionLevelSetting } from "../extractionLevelSettings";
import { isSupperUser } from "../../utils";
import ListSuppliersSetting from "../suppliers/List";
import ListClientsSetting from "../clients/List";

function a11yProps(index) {
	return {
		id: `supplier-detail-tab-${index}`,
		"aria-controls": `supplier-detail-tabpanel-${index}`,
	};
}

export const UsersHome = () => {
	const isUserModify = useSelector(({ appState }) => appState.isUserModify);
	const isOrderEnv = useSelector(({ appState }) => appState.isOrderEnv);
	const currentUser = useSelector(({ requests }) =>
		get(requests, "queries.FETCH_CURRENT_USER.data")
	);
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const classes = useStyles();
	const routes = [
		"/administration/invoice/commercial-organization",
		"/administration/users",
		"/administration/suppliers",
		"/administration/invoice/settings",
		"/administration/supplier-settings",
		"/administration/invoice/extraction-settings",
		"/administration/invoice/import-config",
		"/administration/workflow",
		"/administration/su",
	];
	const routesOrder = [
		"/administration/order/commercial-organization",
		"/administration/users",
		"/administration/clients",
		"/administration/order/settings",
		"/administration/client-settings",
		"/administration/order/extraction-settings",
		"/administration/order/import-config",
		"/administration/su",
	];

	const history = useHistory();
	const [value, setValue] = React.useState(
		isOrderEnv
			? routesOrder.indexOf(history.location.pathname)
			: routes.indexOf(history.location.pathname)
	);
	const [tempFlag] = React.useState(false);

	const handleChange = (event, newValue) => {
		setValue(newValue);
		history.push(isOrderEnv ? routesOrder[newValue] : routes[newValue]);
	};

	useEffect(() => {
		dispatch(fetchUsers());
	}, []);
	useEffect(() => {
		setValue(
			isOrderEnv
				? routesOrder.indexOf(history.location.pathname)
				: routes.indexOf(history.location.pathname)
		);
	});
	return (
		<Grid item xs={12} sm={12} className={classes.firstGrid}>
			{!isOrderEnv ? (
				<SubPageContainer>
					<Paper position="static" className={classes.appBar}>
						{!isUserModify && (
							<Tabs
								value={value}
								onChange={handleChange}
								aria-label="Assets"
								className={classes.tabs}
								scrollButtons="auto"
								variant="scrollable"
							>
								<Tab
									label={t("admin.commercialOrganization")}
									{...a11yProps(0)} // eslint-disable-line
									classes={{ selected: classes.selectedTab }}
									className={classes.tab}
								/>
								<Tab
									label={t("manageUsers")}
									{...a11yProps(1)} // eslint-disable-line
									classes={{ selected: classes.selectedTab }}
									className={classes.tab}
								/>
								<Tab
									label={t("manageSuppliers")}
									{...a11yProps(2)} // eslint-disable-line
									classes={{ selected: classes.selectedTab }}
									className={classes.tab}
								/>

								<Tab
									label={t("admin.settings")}
									{...a11yProps(3)} // eslint-disable-line
									classes={{ selected: classes.selectedTab }}
									className={classes.tab}
								/>
								<Tab
									label={t("supplierTraining")}
									{...a11yProps(4)} // eslint-disable-line
									classes={{ selected: classes.selectedTab }}
									className={classes.tab}
								/>
								<Tab
									label={t("admin.level-settings")}
									{...a11yProps(5)} // eslint-disable-line
									classes={{ selected: classes.selectedTab }}
									className={classes.tab}
								/>
								<Tab
									label={t("admin.import-config")}
									{...a11yProps(6)} // eslint-disable-line
									classes={{ selected: classes.selectedTab }}
									className={classes.tab}
								/>
								<Tab
									label={t("workflow")}
									{...a11yProps(7)} // eslint-disable-line
									classes={{ selected: classes.selectedTab }}
									className={classes.tab}
								/>
								{isSupperUser(get(currentUser, "roles")) && (
									<Tab
										label={t("managerSuperusers")}
										{...a11yProps(8)} // eslint-disable-line
										classes={{ selected: classes.selectedTab }}
										className={classes.tab}
									/>
								)}
								{tempFlag && (
									<>
										<Tab
											label={t("manageGroups")}
											{...a11yProps(1)} // eslint-disable-line
											classes={{ selected: classes.selectedTab }}
											className={classes.tab}
										/>
										<Tab
											label={t("manageRoles")}
											{...a11yProps(2)} // eslint-disable-line
											classes={{ selected: classes.selectedTab }}
											className={classes.tab}
										/>
										<Tab
											label={t("manageWorkflow")}
											{...a11yProps(2)} // eslint-disable-line
											classes={{ selected: classes.selectedTab }}
											className={classes.tab}
										/>
										<Tab
											label={t("manageLicenseAddOn")}
											{...a11yProps(2)} // eslint-disable-line
											classes={{ selected: classes.selectedTab }}
											className={classes.tab}
										/>
									</>
								)}
							</Tabs>
						)}
					</Paper>
					<SubPagePanel value={value} index={0}>
						<Grid container xs={12} className={classes.firstGrid}>
							<SuppliersCommercialOrganization />
						</Grid>
					</SubPagePanel>
					<SubPagePanel value={value} index={1}>
						<Grid container xs={12} className={classes.firstGrid}>
							<List />
						</Grid>
					</SubPagePanel>
					<SubPagePanel value={value} index={2}>
						<Grid container xs={12} className={classes.firstGrid}>
							<ListSuppliers />
						</Grid>
					</SubPagePanel>
					<SubPagePanel value={value} index={3}>
						<Grid container xs={12} className={classes.firstGrid}>
							<InvoiceSettings />
						</Grid>
					</SubPagePanel>

					<SubPagePanel value={value} index={4} className="h-100">
						<Grid container xs={12} className={classes.firstGrid}>
							<ListSuppliersSetting />
						</Grid>
					</SubPagePanel>
					<SubPagePanel value={value} index={5}>
						<Grid container xs={12} className={classes.firstGrid}>
							<ExtractionLevelSetting />
						</Grid>
					</SubPagePanel>
					<SubPagePanel value={value} index={6}>
						<Grid container xs={12} className={classes.firstGrid}>
							<ImportConfig />
						</Grid>
					</SubPagePanel>
					<SubPagePanel value={value} index={7}>
						<Grid container xs={12} className={classes.firstGrid}>
							<Workflow />
						</Grid>
					</SubPagePanel>
					{isSupperUser(get(currentUser, "roles")) && (
						<SubPagePanel value={value} index={8}>
							<Grid container xs={12} className={classes.firstGrid}>
								<ListSupperUsers />
							</Grid>
						</SubPagePanel>
					)}
				</SubPageContainer>
			) : (
				<SubPageContainer>
					<Paper position="static" className={classes.appBar}>
						{!isUserModify && (
							<Tabs
								value={value}
								onChange={handleChange}
								aria-label="Assets"
								className={classes.tabs}
								scrollButtons="auto"
								variant="scrollable"
							>
								<Tab
									label={t("admin.commercialOrganization")}
									{...a11yProps(0)} // eslint-disable-line
									classes={{ selected: classes.selectedTab }}
									className={classes.tab}
								/>
								<Tab
									label={t("manageUsers")}
									{...a11yProps(1)} // eslint-disable-line
									classes={{ selected: classes.selectedTab }}
									className={classes.tab}
								/>

								<Tab
									label={t("manageClients")}
									{...a11yProps(2)} // eslint-disable-line
									classes={{ selected: classes.selectedTab }}
									className={classes.tab}
								/>
								<Tab
									label={t("admin.settings")}
									{...a11yProps(3)} // eslint-disable-line
									classes={{ selected: classes.selectedTab }}
									className={classes.tab}
								/>
								<Tab
									label={t("clientTraining")}
									{...a11yProps(4)} // eslint-disable-line
									classes={{ selected: classes.selectedTab }}
									className={classes.tab}
								/>
								<Tab
									label={t("admin.level-settings")}
									{...a11yProps(5)} // eslint-disable-line
									classes={{ selected: classes.selectedTab }}
									className={classes.tab}
								/>
								<Tab
									label={t("admin.import-config")}
									{...a11yProps(6)} // eslint-disable-line
									classes={{ selected: classes.selectedTab }}
									className={classes.tab}
								/>
								{isSupperUser(get(currentUser, "roles")) && (
									<Tab
										label={t("managerSuperusers")}
										{...a11yProps(7)} // eslint-disable-line
										classes={{ selected: classes.selectedTab }}
										className={classes.tab}
									/>
								)}
							</Tabs>
						)}
					</Paper>
					<SubPagePanel value={value} index={0}>
						<Grid container xs={12} className={classes.firstGrid}>
							<ClientsCommercialOrganization />
						</Grid>
					</SubPagePanel>
					<SubPagePanel value={value} index={1}>
						<Grid container xs={12} className={classes.firstGrid}>
							<List />
						</Grid>
					</SubPagePanel>

					<SubPagePanel value={value} index={2}>
						<Grid container xs={12} className={classes.firstGrid}>
							<ListClients />
						</Grid>
					</SubPagePanel>
					<SubPagePanel value={value} index={3}>
						<Grid container xs={12} className={classes.firstGrid}>
							<OrderSettings />
						</Grid>
					</SubPagePanel>
					<SubPagePanel value={value} index={4} className="h-100">
						<Grid container xs={12} className={classes.firstGrid}>
							<ListClientsSetting />
						</Grid>
					</SubPagePanel>
					<SubPagePanel value={value} index={5}>
						<Grid container xs={12} className={classes.firstGrid}>
							<ExtractionLevelSetting />
						</Grid>
					</SubPagePanel>
					<SubPagePanel value={value} index={6}>
						<Grid container xs={12} className={classes.firstGrid}>
							<ImportConfig />
						</Grid>
					</SubPagePanel>
					{isSupperUser(get(currentUser, "roles")) && (
						<SubPagePanel value={value} index={7}>
							<Grid container xs={12} className={classes.firstGrid}>
								<ListSupperUsers />
							</Grid>
						</SubPagePanel>
					)}
				</SubPageContainer>
			)}
		</Grid>
	);
};

export default UsersHome;
