import {
	FETCH_SUPPLIER,
	FETCH_SUPPLIERS,
	SUPPLIERS_NOT_DELETED,
	DELETE_SUPPLIERS,
	FETCH_SUPPLIER_INVOICES,
	SUPPLIER_INVOICES_OUTSTANDING_TOTAL_AMOUNT,
	FETCH_COUNTRIES,
	INVOICE_TOTAL_AMOUNT_BY_SUPPLIER,
	INVOICE_VAT_AMOUNT_BY_SUPPLIER,
	FETCH_LEGAL_FORM,
	FETCH_SUPPLIER_IMPORT_SAMPLE,
	CHECK_SUPPLIERS_NAME,
	EXPORT_SUPPLIERS_EXCEL,
	FETCH_ALL_SUPPLIERS,
} from "../../constants";
import {
	URL_SUPPLIER,
	URL_SUPPLIER_INVOICES_OUTSTANDING,
	URL_COUNTRY,
	URL_CREATE_SUPPLIER,
	URL_LEGAL_FORM,
	URL_BASE_INVOICE,
	URL_CREATE_MULTI_SUPPLIER,
	URL_SUPPLIER_CODE_CHECK,
	URL_BASE_EXPORT,
} from "../../constants/endpoint";

export const checkSupplierCode = (data, onSuccess, onError) => ({
	type: CHECK_SUPPLIERS_NAME,
	request: {
		url: `${URL_SUPPLIER_CODE_CHECK}`,
		method: "post",
		data,
	},
	meta: {
		onSuccess: (rep) => {
			if (onSuccess) onSuccess(rep);
		},
		onError: () => {
			if (onError) onError();
		},
	},
});

export const fetchSuppliers = (filter = {}) => {
	const { nameOrContactLastNameOrContactFirstName, pageNo, pageSize, sortBy } = filter;
	return {
		type: FETCH_SUPPLIERS,
		request: {
			url: `${URL_SUPPLIER}`,
			params: {
				nameOrContactLastNameOrContactFirstName,
				pageNo,
				pageSize,
				sortBy,
			},
		},
	};
};

export const listAllSuppliers = () => ({
	type: FETCH_ALL_SUPPLIERS,
	request: {
		url: `${URL_SUPPLIER}/listAll`,
	},
});

export const deleteSupplier = (id) => ({
	type: FETCH_SUPPLIER,
	request: {
		url: `${URL_SUPPLIER}/${id}`,
		method: "delete",
	},
});

export const suppliersNotDeleted = (data) => ({
	type: SUPPLIERS_NOT_DELETED,
	payload: data,
});

export const deleteSuppliers = (idSuppliers, onSuccess) => ({
	type: DELETE_SUPPLIERS,
	request: {
		url: `${URL_SUPPLIER}`,
		method: "delete",
		params: { idSuppliers: idSuppliers.join(",") },
	},
	meta: {
		onSuccess: (response, requestAction, store) => {
			if (onSuccess) {
				onSuccess();
			}
			store.dispatch(fetchSuppliers());
			return store.dispatch(suppliersNotDeleted(response.data));
		},
	},
});

export const supplierInvoicesTotalAmounts = (id) => ({
	type: SUPPLIER_INVOICES_OUTSTANDING_TOTAL_AMOUNT,
	request: {
		url: `${URL_SUPPLIER_INVOICES_OUTSTANDING}/${id}`,
		method: "get",
	},
});

export const fetchSupplierInvoices = (id) => ({
	type: FETCH_SUPPLIER_INVOICES,
	request: {
		url: `/invoice/invoices/${id}`,
	},
});

export const fetchSupplier = (id) => ({
	type: FETCH_SUPPLIER,
	request: { url: `${URL_SUPPLIER}/${id}` },
});

export const fetchCountries = () => ({
	type: `${FETCH_COUNTRIES}`,
	request: {
		url: `${URL_COUNTRY}`,
	},
});

export const invoicesTotalAmountsBySupplier = (id, status) => {
	const _status = status ? `_${status}` : "";
	return {
		type: `${INVOICE_TOTAL_AMOUNT_BY_SUPPLIER}${_status}`,
		request: {
			url: `${URL_BASE_INVOICE}/invoices/${id}/totalAmounts`,
			params: { status },
		},
	};
};

// // eslint-disable-next-line arrow-body-style
// export const invoicesCountBySupplier = (id, status) => {
//   return {
//     type: `${INVOICES_COUNT_BY_SUPPLIER}`,
//     request: {
//       url: `${URL_BASE_INVOICE}/invoices/${id}/count`,
//       params: { status },
//     },
//   };
// };

export const invoicesVATAmountsBySupplier = (id, status) => {
	const _status = status ? `_${status}` : "";
	return {
		type: `${INVOICE_VAT_AMOUNT_BY_SUPPLIER}${_status}`,
		request: {
			url: `${URL_BASE_INVOICE}/invoices/${id}/totalVAT`,
			params: { status },
		},
	};
};

export const createSupplier = (supplier, file) => {
	const data = new FormData();
	data.append("file", file);
	data.append("supplier", JSON.stringify(supplier));
	return {
		type: "CREATE_SUPPLIER",
		request: {
			url: URL_CREATE_SUPPLIER,
			method: "post",
			data,
			headers: { "Content-Type": "multipart/form-data" },
		},
	};
};

export const modifySupplier = (supplier, file) => {
	const data = new FormData();
	data.append("file", file);
	data.append("supplier", JSON.stringify(supplier));
	return {
		type: "UPDATE_SUPPLIER",
		request: {
			url: URL_CREATE_SUPPLIER,
			method: "put",
			data,
			headers: { "Content-Type": "multipart/form-data" },
		},
	};
};

export const fetchLegalForms = () => ({
	type: FETCH_LEGAL_FORM,
	request: {
		url: URL_LEGAL_FORM,
	},
});

export const createMultiSupplier = (file) => {
	const data = new FormData();
	data.append("file", file);
	return {
		type: "CREATE_MULTI_SUPPLIER",
		request: {
			url: URL_CREATE_MULTI_SUPPLIER,
			method: "post",
			data,
			headers: { "Content-Type": "multipart/form-data" },
		},
	};
};

export const downloadImportSample = () => ({
	type: FETCH_SUPPLIER_IMPORT_SAMPLE,
	request: {
		url: `${URL_BASE_INVOICE}/supplier/sample`,
		responseType: "blob",
	},
	meta: {
		onSuccess: (response) => {
			const link = document.createElement("a");
			link.href = window.URL.createObjectURL(new Blob([response.data]));
			const disposition = response.headers["content-disposition"];
			if (disposition && disposition.indexOf("attachment") !== -1) {
				const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
				const matches = filenameRegex.exec(disposition);
				if (matches !== null && matches[1]) {
					const filename = matches[1].replace(/['"]/g, "");
					link.setAttribute("download", filename);
				} else {
					link.setAttribute("download", "invoice.pdf");
				}
			}
			document.body.appendChild(link);
			link.click();
			link.remove();
			return response;
		},
	},
});

export const exportSuppliersExcel = () => ({
	type: EXPORT_SUPPLIERS_EXCEL,
	request: {
		url: `${URL_BASE_EXPORT}/suppliers/export`,
		responseType: "blob",
	},
	meta: {
		onSuccess: (response) => {
			const link = document.createElement("a");
			link.href = window.URL.createObjectURL(new Blob([response.data]));
			const disposition = response.headers["content-disposition"];
			if (disposition && disposition.indexOf("attachment") !== -1) {
				const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
				const matches = filenameRegex.exec(disposition);
				if (matches !== null && matches[1]) {
					const filename = matches[1].replace(/['"]/g, "");
					link.setAttribute("download", filename);
				} else {
					link.setAttribute("download", "suppliers.xlsx");
				}
			}
			document.body.appendChild(link);
			link.click();
			link.remove();
			return response;
		},
	},
});
