import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
	Card,
	CardContent,
	Grid,
	ButtonBase,
	Typography,
	Avatar,
	Chip,
	Tooltip,
	IconButton,
	Checkbox,
	CardActions,
} from "@material-ui/core";
import LoadingButton from "components/LoadingButton";

import LaunchIcon from "@material-ui/icons/Launch";
import DeleteIcon from "@material-ui/icons/Delete";
import GetAppIcon from "@material-ui/icons/GetApp";
import Description from "@material-ui/icons/Description";
import ConfirmDialog from "components/ConfirmDialog";
import get from "lodash/get";
import clsx from "clsx";
import { getApi } from "redux/store";
import UserProfile from "../../../App/user-profile.png";
import ExtractionLevelBadge from "../../ExtractionLevelBadge";
import useStyles from "../style";
import { getStatusChipColor, toast } from "../../../utils";
import {
	fetchInvoices,
	downloadInvoice,
	relaunchInvoice,
	fetchLastThreeInvoices,
	fetchInvoiceIndicators,
} from "../../../redux/actions/invoices";
import { initFilter } from "../../../redux/filterReducer";
import ExportMenu from "../ExportMenu";

function TrainingStatus({ onTraining, training, trained }) {
	if (onTraining === true) {
		return <Chip variant="outlined" color="secondary" label={training} />;
	}
	if (onTraining === false) return <Chip variant="outlined" color="primary" label={trained} />;
	return <></>;
}

const SettingsDisplay = ({ value, children }) => (value ? children : <></>);

export default ({
	data,
	settings,
	isDashboard,
	filterInvoices,
	setSelectedId,
	setOpenDeletePopup,
	OpenDeletePopup,
	setSelected,
	selected,
}) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const [confirmObject, setConfirmObject] = useState({
		message: t("confirmation.relance"),
		openConfirm: false,
		cancel: false,
		isLoading: false,
	});
	const [isLoading, setIsLoading] = React.useState({
		excel: false,
		xml: false,
		pdf: { id: [], turning: false },
		edi: false,
		idoc: false,
		ec: false,
	});

	const [currentId, setCurrentId] = useState(0);

	const statusIndicators = useSelector(
		({ requests }) =>
			requests.queries.FETCH_FILTER_STATUS_INVOICE &&
			requests.queries.FETCH_FILTER_STATUS_INVOICE.data
	);

	const onClickRow = (id) => {
		dispatch(initFilter());
		history.push(`invoices/details/${id}`, { filterContext: filterInvoices });
	};

	const [defaultPageSize] = React.useState(
		localStorage.getItem("invoices_pageSize_default")
			? localStorage.getItem("invoices_pageSize_default")
			: process.env.REACT_APP_DEFAULT_PAGE_SIZE_LIST_INVOICE
	);

	const fetchInvoicesAfterRelauch = isDashboard
		? {
				invoiceNoOrSupplierCode: "",
				status: [statusIndicators?.find((s) => s?.code === "TO_VERIFY")],
				startDate: null,
				endDate: null,
				pageNo: 0,
				pageSize: defaultPageSize,
		  }
		: filterInvoices;

	const onConfirmRelaunch = () => {
		setConfirmObject({ ...confirmObject, isLoading: true });
		dispatch(
			relaunchInvoice(currentId, () => {
				dispatch(fetchInvoices(fetchInvoicesAfterRelauch));
				dispatch(fetchLastThreeInvoices());
				dispatch(fetchInvoiceIndicators());
			})
		).then(() => setConfirmObject({ ...confirmObject, openConfirm: false, isLoading: false }));
	};
	const onCancelRelaunch = () => {
		setConfirmObject({ ...confirmObject, openConfirm: false, isLoading: false });
	};

	return (
		<>
			{data &&
				data.content &&
				data.content.map((row, index) => (
					<Card
						key={index}
						className={clsx(
							classes.root,
							get(row, "status.code") !== "INTEGRATION" &&
								row.extractionLevel === "N1" &&
								classes.fullyExtracted,
							get(row, "status.code") !== "INTEGRATION" &&
								row.extractionLevel === "N2" &&
								classes.partiallyExtracted,
							get(row, "status.code") !== "INTEGRATION" &&
								row.extractionLevel === "N3" &&
								classes.nonExtracted
						)}
						elevation={1}
					>
						<CardContent className={classes.content}>
							<ButtonBase focusRipple onClick={() => onClickRow(row.id)}>
								<Grid container xs={12}>
									<Grid container item xs={4}>
										<Grid container item xs={4} direction="column">
											<Grid item className={classes.avatar}>
												<Avatar
													alt="Remy Sharp"
													src={
														row && row.supplier && row.supplier.logo
															? `${getApi()}/invoice${row.supplier.logo}`
															: UserProfile
													}
												/>
											</Grid>
											<Grid item>
												<Typography variant="subtitle2" align="center">
													{row.supplier && row.supplier.label}
												</Typography>
											</Grid>
										</Grid>
										<Grid container item xs={8} direction="column">
											<Grid item>
												<Typography variant="body1">{row.invoiceNo}</Typography>
											</Grid>
											<Grid item>
												<Typography variant="caption" color="textSecondary">
													{row.issueDate}
												</Typography>
											</Grid>
											<Grid item>
												<Chip
													label={
														row.status.displayName ? t(row.status.displayName) : t(row.status.label)
													}
													size="small"
													style={{
														backgroundColor: getStatusChipColor(row.status.code),
														color: "white",
													}}
												/>
											</Grid>
										</Grid>
									</Grid>
									<Grid container item xs={2} direction="column" alignItems="center">
										<Grid item>
											<Typography variant="subtitle2" color="textSecondary">
												{t("createdAt")}
											</Typography>
										</Grid>
										<Grid item>
											<Typography variant="caption" color="textSecondary">
												{row.createdAt}
											</Typography>
										</Grid>
										<Grid item>
											<TrainingStatus
												onTraining={row.onTraining}
												training={t("training")}
												trained={t("trained")}
											/>
										</Grid>
									</Grid>
									<Grid container item xs={4}>
										<Grid container item direction="column" xs={6}>
											<Grid item>
												<Typography variant="subtitle2" color="textSecondary" align="right">
													{t("Pre-Tax-Amount")}
												</Typography>
											</Grid>
											<Grid item>
												<Typography variant="subtitle2" color="textSecondary" align="right">
													{t("VAT amount")}
												</Typography>
											</Grid>
											<Grid item>
												<Typography variant="body1" color="primary" align="right">
													{t("Total")}
												</Typography>
											</Grid>
										</Grid>
										<Grid container item direction="column" xs={6}>
											<Grid item>
												<Typography variant="body2" color="textSecondary" align="right">
													{row.additionalAmountsHT &&
														row.additionalAmountsHT.filter((amount) => amount.principal)[0] &&
														row.additionalAmountsHT.filter((amount) => amount.principal)[0]
															.amount}{" "}
													€
												</Typography>
											</Grid>
											<Grid item>
												<Typography variant="body2" color="textSecondary" align="right">
													{row.additionalAmountsTVA &&
														row.additionalAmountsTVA.filter((amount) => amount.principal)[0] &&
														row.additionalAmountsTVA.filter((amount) => amount.principal)[0]
															.amount}{" "}
													€
												</Typography>
											</Grid>
											<Grid item>
												<Typography variant="body1" color="primary" align="right">
													{row.total} €
												</Typography>
											</Grid>
										</Grid>
									</Grid>
									{get(row, "status.code") !== "INTEGRATION" ? (
										<Grid container item xs={2} alignItems="center" justify="center">
											<ExtractionLevelBadge level={row.extractionLevel} />
											{row.digitalInvoice && (
												<Tooltip title={t("invoice.digital")} placement="top">
													<Description style={{ color: "#3C4D6F" }} />
												</Tooltip>
											)}
											{row.duplicated && (
												<Tooltip title={t("duplicated_invoice")} arrow>
													<Chip
														label="D"
														size="small"
														style={{
															backgroundColor: "#31bbd0",
															color: "white",
														}}
													/>
												</Tooltip>
											)}
										</Grid>
									) : (
										<Grid item xs={2} />
									)}
								</Grid>
							</ButtonBase>
						</CardContent>
						<CardActions>
							{settings && (
								<>
									<Grid item>
										<SettingsDisplay value={settings.INVOICE_RELAUNCH_EXTRACTION}>
											<Tooltip placement="top" title={t("tooltip.action.relaunch_extraction")}>
												<IconButton
													id={`invoice-list-action-relaunch-${index}`}
													aria-label="relaunch"
													disabled={!row.canBeRelaunched || row.digitalInvoice}
													onClick={() => {
														setCurrentId(row.id);
														setConfirmObject({ ...confirmObject, openConfirm: true });
													}}
												>
													<LaunchIcon />
												</IconButton>
											</Tooltip>
										</SettingsDisplay>
									</Grid>

									<Grid item>
										<SettingsDisplay value={settings.INVOICE_EXPORT_PDF}>
											<Tooltip placement="top" title={t("tooltip.action.download")}>
												<IconButton
													id={`invoice-list-action-download-${index}`}
													aria-label="download"
													disabled={isLoading?.pdf?.id.includes(row.id) && isLoading?.pdf?.turning}
													onClick={() => {
														setIsLoading({
															...isLoading,
															pdf: { id: [...isLoading?.pdf?.id, row.id], turning: true },
														});
														const ids = [...isLoading?.pdf?.id, row.id];
														dispatch(
															downloadInvoice(
																row.id,
																"inv",
																() => toast.error(t("invoice.export.error")),
																() => {
																	const i = ids.indexOf(row.id);

																	if (i > -1) {
																		ids.splice(i, 1); // 2nd parameter means remove one item only
																		setIsLoading({
																			...isLoading,
																			pdf: { id: [...ids], turning: false },
																		});
																	}
																}
															)
														);
													}}
												>
													<LoadingButton
														isLoading={
															isLoading?.pdf?.id.includes(row.id) && isLoading?.pdf?.turning
														}
													>
														<GetAppIcon />
													</LoadingButton>
													{/* {isLoading?.pdf ? <CircularProgress size={14} /> : <GetAppIcon />} */}
												</IconButton>
											</Tooltip>
										</SettingsDisplay>
									</Grid>

									<Grid item>
										<ExportMenu index={index} row={row} settings={settings} />
									</Grid>

									<Grid item>
										<SettingsDisplay value={settings.INVOICE_DELETE_ACTION}>
											<Tooltip placement="top" title={t("tooltip.action.delete")}>
												<IconButton
													id={`invoice-list-action-delete-${index}`}
													aria-label="delete"
													disabled={!(row.status && row.status.code === "TO_VERIFY")}
													onClick={() => {
														setOpenDeletePopup({
															...OpenDeletePopup,
															openConfirm: true,
															isLoading: false,
														});
														setSelectedId(row.id);
													}}
												>
													<DeleteIcon />
												</IconButton>
											</Tooltip>
										</SettingsDisplay>
									</Grid>
								</>
							)}
							{!isDashboard && (
								<Grid item xs>
									<Checkbox
										id={`invoice-list-action-check-${index}`}
										value={parseInt(row.id, 10)}
										checked={selected.includes(parseInt(row.id, 10))}
										onChange={(e) => {
											const list = [...selected];
											// eslint-disable-next-line
											e.target.checked
												? list.push(parseInt(e.target.value, 10))
												: list.splice(list.indexOf(parseInt(e.target.value, 10)), 1);
											setSelected(list);
										}}
									/>
								</Grid>
							)}
						</CardActions>
					</Card>
				))}
			<ConfirmDialog
				message={confirmObject.message}
				open={confirmObject.openConfirm}
				isLoading={confirmObject.isLoading}
				onConfirm={onConfirmRelaunch}
				onCancel={onCancelRelaunch}
			/>
		</>
	);
};
