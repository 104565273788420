import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import Typography from "@material-ui/core/Typography";

import Button from "@material-ui/core/Button";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useForm } from "react-hook-form";
import {
	createInvoiceLitigeRejection,
	createInvoiceRejection,
	fetchRejectionMotifs,
} from "redux/actions/invoices";
import { useDispatch } from "react-redux";
import { CircularProgress } from "@material-ui/core";

function DialogReject({ open, setOpen, ...props }) {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [rejectionMotifs, setRejectionMotifs] = React.useState(props.rejectionMotifs);
	const [isLoadingReject, setIsLoadingReject] = React.useState(false);

	useEffect(() => {
		dispatch(fetchRejectionMotifs({ token: null })).then((result) => {
			if (result.data && !rejectionMotifs) {
				setRejectionMotifs(result.data);
			}
		});
	}, []);

	const { errors, handleSubmit, register } = useForm();

	const handleCloseRejectPopup = () => {
		setOpen(!open);
	};

	const onSubmit = (data) => {
		const listMotifs = rejectionMotifs && rejectionMotifs.invoiceRejectionMotifList;
		const motif = listMotifs.find((motifParam) => t(motifParam.label) === data.motif);
		const { invoicePersistedId, invoiceLitigeId } = props;
		setIsLoadingReject(true);

		dispatch(
			createInvoiceRejection(
				invoicePersistedId,
				{ invoiceId: invoicePersistedId, motif: motif.code, comment: data.comment },
				() => {
					dispatch(createInvoiceLitigeRejection(invoiceLitigeId)).then(() => {
						props.onClose(false);
						handleCloseRejectPopup();
						setIsLoadingReject(false);
					});
				},
				() => {
					props.onClose(true);
					handleCloseRejectPopup();
					setIsLoadingReject(false);
				}
			)
		);
	};

	return (
		<Dialog
			open={open}
			onClose={handleCloseRejectPopup}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">{t("rejectMessage")}</DialogTitle>
			<form onSubmit={handleSubmit(onSubmit)}>
				<DialogContent>
					<Autocomplete
						options={rejectionMotifs && rejectionMotifs.invoiceRejectionMotifList}
						getOptionLabel={({ label = "" }) => t(label)}
						id="rejectionReason"
						renderInput={(params) => (
							<div>
								<TextField
									{...params} // eslint-disable-line
									inputRef={register({ required: true })}
									variant="standard"
									label={t("rejectionReason")}
									margin="normal"
									name="motif"
								/>
								{errors.motif && (
									<Typography variant="subtitle2" align="left" color="error">
										{t("this field is mandatory")}
									</Typography>
								)}
							</div>
						)}
					/>
					<TextField
						inputRef={register}
						name="comment"
						id="outlined-multiline-static"
						label={t("rejectionDetails")}
						multiline
						fullWidth
						rows={4}
						defaultValue=""
						variant="outlined"
					/>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleCloseRejectPopup}
						color="secondary"
						disabled={isLoadingReject}
						autoFocus
					>
						{t("cancel")}
					</Button>
					<Button type="submit" color="secondary" disabled={isLoadingReject}>
						{isLoadingReject && <CircularProgress size={14} />}&nbsp;
						{t("confirm")}
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
}

export default DialogReject;
