import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import FlipCameraAndroidOutlinedIcon from "@material-ui/icons/FlipCameraAndroidOutlined";
import AssignmentIcon from "@material-ui/icons/Assignment";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import { isSimpleUserOnly, isPurchaseOrder, isClientOnly, isSupplierOnly } from "utils";
import get from "lodash/get";

import { toggleEnv } from "../../appSlice";
import useStyles from "./style";

export default ({ className, currentUser }) => {
	const isOrderEnv = useSelector(({ appState }) => appState.isOrderEnv);

	const history = useHistory();
	const dispatch = useDispatch();
	const classes = useStyles();
	const [isDetailOrEditPage, setIsDetailOrEditPage] = React.useState(false);

	const { t } = useTranslation();

	const switchInvoice = () => {
		switch (history.location.pathname) {
			case "/":
				return "/home";
			case "/orders":
				return "/invoices";
			case "/client":
				return "/supplier";
			case "/administration/su":
			case "/administration/users":
				return history.location.pathname;
			case "/administration/clients":
				return "/administration/suppliers";
			case "/administration/client-settings":
				return "/administration/supplier-settings";
			case "/administration/order/settings":
				return "/administration/invoice/settings";
			case "/administration/order/commercial-organization":
				return "/administration/invoice/commercial-organization";
			case "/administration/order/extraction-settings":
				return "/administration/invoice/extraction-settings";
			case "/administration/order/import-config":
				return "/administration/invoice/import-config";
			default:
				return "/";
		}
	};

	useEffect(() => {
		history.listen((location) => {
			setIsDetailOrEditPage(
				location.pathname.includes("/details") || location.pathname.includes("/edit")
			);
		});
	}, [history]);
	const switchOrder = () => {
		switch (history.location.pathname) {
			case "/home":
				return "/";
			case "/":
				return "/home";
			case "/invoices":
				return "/orders";
			case "/supplier":
				return "/client";
			case "/administration/users":
			case "/administration/su":
				return history.location.pathname;
			case "/administration/suppliers":
				return "/administration/clients";
			case "/administration/supplier-settings":
				return "/administration/client-settings";
			case "/administration/invoice/settings":
				return "/administration/order/settings";
			case "/administration/invoice/commercial-organization":
				return "/administration/order/commercial-organization";
			case "/administration/invoice/extraction-settings":
				return "/administration/order/extraction-settings";
			case "/administration/invoice/import-config":
				return "/administration/order/import-config";
			default:
				return "/";
		}
	};
	const changeSite = () => {
		dispatch(toggleEnv());
	};

	return isSimpleUserOnly(get(currentUser, "roles", [])) ||
		isClientOnly(get(currentUser, "roles", [])) ||
		isSupplierOnly(get(currentUser, "roles", [])) ||
		isPurchaseOrder(get(currentUser, "roles", [])) ? null : (
		<ButtonGroup variant="contained" color="secondary" className={className}>
			<Button
				size="medium"
				startIcon={<AssignmentIcon />}
				className={isOrderEnv ? classes.disabled : classes.enabled}
				onClick={changeSite}
				component={Link}
				to={switchInvoice}
				disabled={isDetailOrEditPage}
			>
				{t("switch.invoices")}
			</Button>
			<Button
				size="medium"
				startIcon={<FlipCameraAndroidOutlinedIcon />}
				className={isOrderEnv ? classes.enabled : classes.disabled}
				onClick={changeSite}
				component={Link}
				to={switchOrder}
				disabled={isDetailOrEditPage}
			>
				{t("switch.orders")}
			</Button>
		</ButtonGroup>
	);
};
