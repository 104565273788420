import supllierDe from "../pages/Suppliers/i18n/de";
import clientDr from "../pages/Clients/i18n/de";
import administrationDe from "../administration/i18n/de";

export default {
	...supllierDe,
	...administrationDe,
	...clientDr,
	Copyright: "Alle Rechte vorbehalten",
	Dashboard: "Dashboard",
	Unternehmensführung: "Kommerzielle Führung",
	Orders: "Bestellungen",
	Invoices: "Rechnungen",
	Regulations: "Regelungen",
	Suppliers: "Lieferanten",
	Rapports: "Berichten",
	Search: "Suchen",
	"Rechnung einfügen": "Rechnungen stellen oder ",
	browse: "durchsuchen",
	fileAddedMsg: "Datei(en) erfolgreich hinzugefügt!",
	fileRemovedMsg: "Datei {{filename}}} entfernt.",
	"Show all": "Alles anzeigen",
	id: "Identifizierung",
	supplier: "Lieferant",
	invoiceNo: "Rechnungsnummer",
	issueDate: "Erstellungsdatum",
	dueDate: "Fristdatum",
	orderDeliveryDate: "Lieferdatum",
	"Price WT": "Bruttobetrag",
	"Taxe Rate": "Mw-St.",
	Price: "Gesamtbetrag",
	"Purchase order": "Bestellschein",
	"Last actions": "Letzte Handlungen",
	Today: "Heute",
	Yesterday: "Gestern",
	"Last week": "Letzte Woche",
	Status: "Status",
	Date: "Datum",
	"My tasks": "Meine Aufträge",
	"Invoices to verify": "Rechnungen zu prüfen",
	"To verify": "Zu prüfen",
	"Invoices to be checked": "Rechnungen zu kontrollieren",
	"Invoices to Payers": "Rechnungen zu bezahlen",
	"Invoice available": "Verfügbare Rechnung",
	"Paid invoices": "Bezahlte Rechnungen",
	"Unpaid invoices": "Unbezahlte Rechnungen",
	"Total amount of invoices": "Gesamtbetrag der Rechnungen",
	"Total amount of orders": "Gesamtanzahl der Bestellungen",
	"Total VAT amount": "Gesamtbetrag der Umsatzsteuer",
	"Statistical overview of purchases": "Statistische Übersicht der Einkäufe",
	"Statistical overview of product sales": "Statistische Übersicht der Produktverkäufe",
	"Total Purchases": "Gesamt der Einkäufe",
	"Total of expenses": "Gesamt der Rechnungen",
	Late: "In Verspätung",
	Administrator: "Verwaltung",
	"New suppliers": "Neue Lieferanten",
	FOUNDED_RESULT: "Résultat trouvé",
	EndDate: "Enddatum",
	StartDate: "Startdatum",
	"Add an invoice": "Rechnung einfügen",
	Upload: "Rechnung aufladen",
	Download: "Herunterladen",
	Show: "Anzeigen",
	Delete: "Löschen",
	All: "Alle",
	SearchInvoice: "Rechnung suchen...",
	"Delivery address": "Lieferadresse",
	"Billing address": "Rechnungsadresse",
	"Payment terms": "Zahlungsart",
	"Order Details": "Bestellungs-Details",
	"Invoice Details": "Rechnungs-Details",
	"Reject Invoice": "Ablehnen",
	"Reject Order": "Ablehnen",
	"Correct Extraction": "Korrigieren",
	Control: "Kontrollieren",
	ToPay: "Auszahlung einrichten",
	"Total HT": "Bruttobetrag",
	"VAT amount": "Betrag der Mw-St.",
	Discount: "Skonto",
	"Total amount": "Gesamtbetrag",
	"Invoice overview": "Rechnungsübersicht",
	History: "Historie",
	verificationDate: "Prüfungsdatum",
	rejectionDate: "Ablehnungsdatum",
	controlDate: "Kontrolldatum",
	dateOfReceipt: "Empfangsdatum",
	rejectMessage: "Sind Sie sicher diese Rechnung ablehnen zu wollen?",
	controlMessage: "Sind Sie sicher diese Rechnung kontrollieren zu wollen?",
	ErrorMessageToControl: "Sie können nicht diese Rechnung kontrollieren:",
	topayMessage: "Sind Sie sicher die Auszahlung dieser Rechnung starten zu wollen?",
	deleteMessage: "Sind Sie sicher diese Rechnungen löschen zu wollen?",
	individualDeleteMessage: "Sind Sie sicher diese Rechnung löschen zu wollen?",
	orderDeleteMessage: "Sind Sie sicher, dass Sie diese Bestellung löschen möchten?",
	rejectionReason: "Ablehnungsgrund",
	rejectionDetails: "Ablehnungs-Details...",
	cancel: "Abbrechen",
	confirm: "Bestätigen",
	editForm: "Änderung",
	countInvoices: "Gesamtanzahl von den Rechnungen ",
	countOrders: "Gesamtanzahl von den Aufträge ",
	tobeprocessed: "zu bearbeiten",
	None: "------------------",
	save: "Speichern",
	edit: "Ändern",
	Actions: "Handlungen",
	itemCode: "Referenz",
	designation: "Artikel",
	quantity: "Qtät",
	unitPrice: "Preis",
	deliveryDate: "Lieferungszeit",
	// New added
	Total: "Gesamt",
	VAT: "Taux de TVA",
	"Pre-Tax-Amount": "Bruttobetrag",
	Commands: "BERECHNUNGSZEILEN",
	"order.lines": "BEFEHLSZEILEN",
	totalHt: "Bruttobetrag",
	vat: "Mw-St.",
	sortBy: "Aussortieren nach",
	createdAt: "Kreirt am",
	updatedAt: "Aktualisierungsdatum",
	InvoicesCannotBeDeleted: "Manche Rechnungen können nicht gelöscht werden",
	OrdersCannotBeDeleted: "Einige Befehle können nicht gelöscht werden ",
	InvoicesCannotBeRelaunched: "Manche Rechnungen können nicht neu geschickt werden",
	"Integration in progress": "Im Integrationsprozeβ",
	"To control": "Zu kontrollieren",
	"To pay": "Zu bezahlen",
	Payed: "Bezahlt",
	Rejected: "Abgelehnt",
	Exception: "Ausnahme",
	Canceled: "Storniert",
	codeArticle: "Artikel-Nr.",
	deliveryDelay: "Lieferungszeit",
	orderNo: "Bestellungsnummer",
	numberOfArticle: "Anzahl der Artikeln",
	deliveryMethod: "Lieferungsart",
	InvoicesNotCreatedMessage: "Folgende Rechnungen können nicht kreirt werden",
	invoice: "Rechnung",
	order: "Bestellung",
	training: "Im Lernprozeβ...",
	trained: "Gelernt",
	"Company Siret": "Identifizierung",
	"VAT Number": "USt-IdNr",
	"Apply Changes": "Anwenden",
	"invoice Number": "Rechnungsnummer",
	phone: "Telefon",
	"Email de Contact": "EMail des Kontakts",
	"Contact principal": "Ansprechspartner",
	"Invoice was already paid": "Die Rechnung ist schon bezahlt worden",
	"Order has been already delivered": "Bestellung wurde bereits geliefert",
	"Apply for approval": "Bestätigung anfragen",
	ApplyForApproval: "Bestätigungsanfrage",
	comment: "Kommentar",
	"Goods or Services were never received": "Die Waren oder die Leistungen sind nie erhalten worden",
	"Goods or services are not available at the moment":
		"Waren oder Dienstleistungen sind im Moment nicht verfügbar",
	"Supporting documents were not received with the invoice":
		"Die Belege sind nicht mit der Rechnung erhalten worden",
	"The purchase order number is missing, is incorrect or has been canceled":
		"Die Bestellnummer ist fehlend, ungültig oder wurde storniert",
	"The order number is missing, is incorrect or has been canceled":
		"Die Bestellnummer fehlt, ist falsch oder wurde storniert",
	"The amount being billed does not match the agreed upon amount on the contract":
		"Der berechnete Betrag stimmt nicht mit dem im Vertrag vereinbarten Betrag",
	"Supporting documents were not received with the order":
		"Die Quittungen wurden nicht mit der Bestellung erhalten",
	Other: "Sonstiges",
	CREATE_ACTION: "Schaffung von der Rechnung",
	EDIT_ACTION: "Wijziging van de factuur",
	VERIFY_ACTION: "Prüfung von der Rechnung",
	REJECT_ACTION: "Ablehnung von der Rechnung",
	CONTROL_ACTION: "Kontrollieren von der Rechnung",
	END_TRAINING_ACTION: "Lernprozeβ abgeschlossen",
	CORRECT_ACTION: "Lernprozeβ gestartet",
	TO_PAY_ACTION: "Auszahlung von der Rechnung",
	DELET_ACTION: "Löschung von der Rechnung",
	TO_VALIDATE_EXTERNAL_ACTION: "Bestätigungsanfrage",
	RELAUNCH_ACTION: "Extrahierung gestartet",
	EXPORT_ACTION: "Exportieren von der Rechnung",
	ORDER_CREATE_ACTION: "Auftrag erstellt",
	ORDER_VERIFY_ACTION: "Bestellung unter Überprüfung.",
	ORDER_REJECT_ACTION: "Auftrag abgelehnt",
	ORDER_CONTROL_ACTION: "Bestellung zur Steuerung gesendet",
	ORDER_TO_VALIDATE_EXTERNAL_ACTION: "Validierungsanfrage.",
	ORDER_END_TRAINING_ACTION: "Lernen abgeschlossen.",
	ORDER_CORRECT_ACTION: "Lernprozeβ gestartet",
	ORDER_TO_PAY_ACTION: "Zahlung der Bestellung",
	ORDER_DELETE_ACTION: "Deletion der Bestellung",
	ORDER_EDIT_ACTION: "Änderung der Bestellung",
	INVOICE_CHANGE_STATUS: "Rechnung an den Status weitergegeben {{status}}",
	INVOICE_CHANGE_STATUS_AUTOMATIC: "Rechnung automatisch an Status {{status}} übergeben",
	ORDER_RELAUNCH_ACTION: "Freigegebene Extraktion.",
	ORDER_EXPORT_ACTION: "Export der Bestellung",
	action: "Handlung",
	details: "Einzelheiten",
	supplierEmptyMessageError: "Der Lieferant wurde nicht erkannt",
	incohenrenceAmountsMessageError:
		"Fehler bei den Gesamtbeträge : Der Nettobetrag stimmt nicht mit der Summe von dem Bruttobetrag plus MwSt.",
	"invoice.status.integration": "Integration",
	"invoice.status.toverify": "To Verify",
	"invoice.status.tocontrol": "To Control",
	"invoice.status.topay": "To Pay",
	"invoice.status.payed": "Payed",
	"invoice.status.rejected": "Rejected",
	"invoice.status.canceled": "Canceled",
	"invoice.status.exception": "Exception",
	"invoice.status.external_validation": " Im Bestätigungsprozeβ",
	"invoice.status.open": "Offen",
	"invoice.status.reintegrated": "Wieder eingegliedert",
	"order.status.external_validation": " Im Bestätigungsprozeβ",
	reason: "Grund",
	Approve: "Bestätigen",
	email: "EMail",
	approvedBy: "Bestätigt bei",
	"Nb total des factures": "Gesamtanzahl der Bestellungen",
	"Facture en cours": "Offene Rechnungen",
	"Total Orders": "Gesamtanzahl der Bestellungen",
	searchOrder: "Bestellung finden",
	VALIDATED: "Bestätigt",
	PARTIALLY_DELIVERED: "Teilweise ausgeliefert",
	DELIVERED: "GELIEFERT",
	PARTIALLY_BILLED: "Teilweise berechnet",
	BILLED: "Berechnet",
	"purchase_order.status.validated": "Bestätigt",
	"purchase_order.status.partiallyDelivered": "Teilweise ausgeliefert",
	"purchase_order.status.delivered": "GELIEFERT",
	"purchase_order.status.PartiallyBilled": "Teilweise berechnet",
	"purchase_order.status.billed": "Berechnet",
	"order Lines": "Die Bestellungszeilen",
	delivered: "Geliefert",
	"non delivered": "Nicht geliefert",
	orderDate: "Bestellungsdatum",
	"this operation is failed": "L'opération a échoué",
	invoiceAccumulation: "Anhäufung von Rechnungen",
	"error.duplicateSuppliers":
		"Die USt-Nr. {{VatNumber}} gehört mehreren Lieferanten. Bitte Lieferant auswählen",
	orderType: "Bestellungsart",
	CLOSED: "Geschlossen",
	OPEN: "Offen",
	DeliverySlip: "Lieferschein",
	currency: "Währung",
	deliveryNo: "Bestellungsnummer",
	label: "Formulierung",
	deliveredQuantity: "Gelieferte Quantität",
	delivery: "Lieferung",
	"Delivery Details": "Empfang",
	"Delivery Lines": "Wareneingänge",
	deliveryItemDate: "Lieferungsdatum",
	export: "Exportieren",
	"invoice.status.exported": "Exportiert",
	"price TTC": "Nettopreis",
	unitePriceHT: "Bruttopreis",
	"invoice.exported": "Exportierte Rechnungen",
	"invoice.unexported": "Nicht exportierte Rechnungen",
	invoice_charge: "Spesen",
	order_charge: "Spesen",
	"invoice.total_amoutn_to_verify": "Gesamtbetrag der Rechnungen noch zu prüfen",
	"invoice.total_invoice": "Rechnungen Anzahl",
	"invoice.duplicated": "Duplikat",
	"extraction.label.fully_extracted": "Vollständig extrahiert",
	"extraction.label.partially_extracted": "Teilweise extrahiert",
	"extraction.label.not_extracted": "Nicht extrahiert",
	"extraction.label.not_invoice": "Keine Rechnung",
	"this field is mandatory": "Dieses Feld ist erforderlich",
	rejectedBy: "Abgelehnt bei",
	"invoice.detail.company_siret": "Mw-StNr. des Unternehmens",
	country: "Land",
	duplicated_invoice: "Rechnung gedoppelt",
	"user.roles.user": "Benutzer",
	"tootlip.action.edit": "Ändern",
	"tooltip.action.delete": "Löschen",
	"tooltip.action.edit": "Modifikator",
	"tooltip.action.relaunch": "Neu starten",
	"tooltip.action.download": "Herunterladen",
	"tooltip.action.relaunch_extraction": "Extrahierung neu starten",
	"tooltip.action.export_excel": "Excel exportieren",
	"tooltip.action.export_entry_account_excel": "Excel-EC exportieren",
	"tooltip.action.export_xml": "XML exportieren",
	"profile.update-password.new-password-required": "Neues Passwort erforderlich",
	"profile.update-password.password-required": "Passwort ist erforderlich",
	"profile.update-password.password-not-matched": "Passwörter stimmen nicht überein",
	"profile.update-password.current-password": "Aktuelles Passwort",
	"profile.update-password.new-password": "Neues Passwort",
	"profile.update-password.confirm-password": "Kennwort bestätigen",
	"profile.update-password.reset-password": "Passwort zurücksetzen",
	"profile.update-password.password-reset": "Passwort zurücksetzen",
	"profile.update-password.password-reset-success": "Ihr Kennwort wurde erfolgreich geändert",
	"profile.update-password.password-email-send":
		"Eine E-Mail wurde gesendet. Bitte überprüfen Sie Ihre E-Mails.",
	"profile.update-password.password-reset-done": "Fertig",
	"profile.update-password.update-password": "Passwort ändern",
	"profile.update-password.email": "Ihre aktuelle E-Mail",
	"profile.update-password.tokenError": "Ungültiges Token, Passwort wurde bereits aktualisiert!",
	"suppliers.add.multiple": "Ihre Lieferanten stellen oder ",
	"upload.supplier": "Lieferanten aufladen",
	"downloqd.template": "Modell herunterladen",
	"supplier.add.button": "Lieferanten importieren",
	"supplier.add2.button": "Lieferant einfügen",
	"supplier.export.suppliers.excel": "Exportieren Sie die Liste der Lieferanten",
	"invoice.status.processing": "Im Bearbeitungsprozeβ",
	"invoice.status.underProcess": "Im Bearbeitungsprozeβ",
	"invoice.status.InoviceProcessed": "Verarbeitete Rechnungen",
	"invoice.status.InoviceUnderProcess": "Rechnungen in Bearbeitung",
	"invoice.status.processed": "Verarbeitet",
	Administration: "Administration",
	Logout: "Ausloggen",
	Profile: "Profil",
	"invoice.selected.title0": "Alle Rechnungen sind ausgewählt",
	"invoice.selected.title1": "Die ",
	"invoice.selected.title2": " Rechnungen von dieser Seite sind alle ausgewählt.",
	"invoice.selected.title3": "Alle Rechnungen auswählen",
	"supplier.add.step1": "Allgemeine Informationen",
	"supplier.add.step2": "Ansprechspartner",
	"supplier.add.step3": "Finanzielle Informationen",
	"supplier.add.next": "Nächstes",
	"supplier.add.previous": "Vorheriges",
	"supplier.add.finish": "Enden",
	"supplier.add.country": "Land",
	"supplier.add.suppliername": "Lieferantenname",
	"supplier.add.logo": "Logo",
	"supplier.add.capital": "Kapital",
	"supplier.add.siret": "MwSt-Nr.",
	"supplier.add.siren": "Firma SIREN",
	"supplier.add.linkedin": "LinkedIn",
	"supplier.add.email": "EMail",
	"supplier.add.fax": "Fax",
	"supplier.add.civility": "Anrede",
	"supplier.add.phone": "Telefon",
	"supplier.add.vat": "USt-IdNr.",
	"supplier.add.naf": "Stellung im Beruf",
	"supplier.add.bank": "Bank",
	"supplier.add.bic": "BIC",
	"supplier.add.iban": "IBAN",
	"supplier.add.rib": "Bankkoordinaten",
	"supplier.add.acc": "Buchungskonto",
	"supplier.add.curr": "Standardmäβige Währung",
	"supplier.add.function": "Funktion",
	"supplier.add.firstName": "Vorname",
	"supplier.add.lastName": "Name",
	"supplier.add.city": "Stadt",
	"supplier.add.street": "Strasse",
	"supplier.add.zipCode": "Posteinzahl",
	"supplier.add.confirm": "Sind Sie sicher die Änderungen speichern zu wollen?",
	"supplier.delete.confirm": "Möchten Sie den Anbieter wirklich löschen?",
	"supplier.delete.success": "Erfolgreiches Löschen vom Lieferant",
	"supplier.have.attached.user.accounts.or.invoices.message":
		"Ein Fehler ist bei dem Löschen vom Lieferant aufgetreten",
	"client.has.attached.user.accounts.message":
		"Kann den Client nicht löschen (n).Eine Bestellung / Konto ist beigefügt!",
	"suplier.action.agree": "Annehmen",
	"suplier.action.disagree": "Ablehnen",
	"supplier.add.aux": "Zusatzkonto",
	"supplier.name.exist.already": "Lieferant existiert auf dem System",
	"suppliers.names.exist.already": "Lieferanten {{fournisseurs}} Es gibt bereits im System!",
	"suppliers.empty.field.name.row.excel": "Il y a une ligne sans nom !!",
	"suppliers.error.sample": "Die gesendete Datei entspricht nicht der beispielhaften Datei.",
	EXPORT_EXCEL: "Excel exportieren",
	EXPORT_EDI: "EDI exportieren",
	EXPORT_XML: "XML exportieren",
	EXPORT_PDF: "PDF exportieren",
	EXPORT_EC: "EC exportieren",
	"order.absent": "Abwesendes Befehl",
	"amount.greate.thang.goods": "Rechnungsbetrag höher als die Bestellung",
	"delivery.absent": "Abwesende Lieferung",
	"Zip Code": "Posteinzahl",
	Street: "Strasse",
	City: "Stadt",
	"supplier.delete.training.error.message": "Kein Lernprozeβ existiert für diesen Lieferant",
	"invoice.detail.reject.success": "Erfolgreiche Ablehnung von der Rechnung",
	"invoice.detail.reject.failed": "Die Rechnung ist nicht abgelehnt worden",
	"invoice.detail.approved.success": "Erfolgreiches Annehmen von der Rechnung",
	"invoice.detail.marked.selectedField": "Het geselecteerde veld gemarkeerd",
	"invoice.detail.apply.validate": "Toepassen & valideren",
	"invoice.detail.approved.failed": "Die Rechnung wurde nicht angenommen",
	"invoice.detail.export.success": "Erfolgreiches Exportieren von der Rechnung",
	"order.detail.export.success": "Bestellung wurde erfolgreich exportiert",
	"invoice.detail.export.failed": "Die Rechnung ist nicht exportiert worden",
	"order.detail.export.failed": "Bestellung wurde nicht exportiert",
	"invoice.detail.message.rectify": "Sind Sie sicher die Änderungen speichern zu wollen?",
	"invoice.detail.message.confirmExport": "Sind Sie sicher die Rechnung exportieren zu wollen?",
	"order.detail.approved.success": "Die Bestellung wurde erfolgreich genehmigt",
	"order.detail.message.confirmExport": "Möchten Sie diese Bestellung wirklich exportieren?",
	"order.detail.approved.failed": "Die Bestellung wurde nicht genehmigt",
	"token.validation.noteExist": "Kennwortanforderung ändern bereits gesendet",
	"client.delete.msg": "Möchten Sie diesen Client wirklich löschen?",
	year: "Dieses Jahr",
	month: "Diesen Monat",
	week: "In dieser Woche",
	day: "Heute",
	Exported: "Exportiert",
	Integrated: "Integriert",
	"switch.orders": "AUFTRÄGE",
	"switch.invoices": "RECHNUNGEN",
	"Invoice attachements": "Anhänge",
	"invoice.attachment.contract": "Vertrag",
	"invoice.attachment.order": "Auftrag",
	"invoice.attachment.receipt": "Kassenbon",
	"invoice.attachment.oth": "Andere",
	"order.attachment.contract": "Vertrag",
	"order.attachment.order": "Auftrag",
	"order.attachment.receipt": "Kassenbon",
	"order.attachment.oth": "Andere",
	"attachment.name": "Name",
	"attachment.upload": "Hochladen",
	"attachment.cancel": "Abbrechen",
	completeerrors: "Komplette Fehler",
	completeerrorsphrase: "Die Aufgabe enthält noch Fehler",
	warnings: "Warnungen",
	ruleerrors: "Regelfehler",
	checkerrors: "Fehler prüfen",
	completetask: "Erledige Aufgabe",
	correcterrors: "Fehler korrigieren",
	orderDeleteMessageMultiple:
		"Möchten Sie sicher, dass Sie die ausgewählten Reihenfolge löschen möchten?",
	"messenger.addComment": "Einen Kommentar hinzufügen",
	"messenger.postComment": "Kommentar hinzufügen",
	"tooltip.action.export_edifact": "Edifact Exportieren",
	"tooltip.action.export_idoc": "Idoc Exportieren",
	EXPORT_IDOC: "IDOC exportieren",
	lockTraining: "Learning deaktivieren",
	unlockTraining: "Lernen aktivieren",
	"accountingLines.costCenter": "Kostenstelle",
	"accountingLines.paymentMethod": "Zahlungsmethode",
	"accountingLines.glAccounts": "Gl-Konto",
	"accountingLines.description": "Beschreibung",
	"accountingLines.sign": "Operation",
	"accountingLines.amount": "Betrag",
	"accountingLines.addButton": "Buchungszeile hinzufügen",
	"accountingLines.summary": "Die Buchungszeilen sind nicht ausgeglichen",
	"invoice.digital.extract": "Auszug aus der elektronischen Rechnung",
	"invoice.digital": "Elektronischen Rechnung",
	"invoice.digital.preview":
		"Vorschau nicht verfügbar: Diese elektronische Rechnung enthält keine PDF-Datei",
	"tooltip.action.export": "Exportieren nach",
	"invoice.digital.about": "Über die Rechnung",
	"invoice.digital.type": "Rechnungsart",
	"invoice.digital.signature.validity": "Gültigkeit der Unterschrift",
	"invoice.digital.format.compliance": "Einhaltung des Formats",
	"invoice.digital.en16931.compliance": "Einhaltung der EN 16931",
	"invoice.digital.en16931.rules.violated": "Anzahl der verletzten Regeln der EN 16931",
	"invoice.digital.chorus.pro.compliance": "Einhaltung der Chorus Pro Geschäftsregeln",
	"invoice.digital.chorus.pro.rules.violated": "Anzahl der verletzten Chorus-Pro-Regeln",
	"invoice.go.to": "Rechnung ansehen",
	"invoiceNo.must.be.present": "Die Rechnungsnummer muss vorhanden sein",
	"supplier.must.be.present": "Der Lieferant muss anwesend sein",
	"issueDate.must.be.present": "Das Ausstellungsdatum muss vorhanden sein",
	"total.must.be.present": "Der Gesamtbetrag muss vorhanden sein",
	"currency.must.be.present": "Die Währung muss vorhanden sein",
	"supplier.name.must.be.present": "Der Name des Anbieters muss vorhanden sein",
	"supplier.siret.must.be.present": "Der Firmenausweis des Lieferanten muss vorhanden sein",
	"supplier.vatNumber.must.be.present":
		"Die Mehrwertsteuernummer des Lieferanten muss vorhanden sein.",
	"supplier.country.must.be.present": "Das Land des Lieferanten muss vorhanden sein",
	"designation.must.be.present": "Eine Rechnungszeile muss den Produktnamen enthalten",
	"quantity.must.be.present": "Eine Rechnungszeile muss die berechnete Menge enthalten",
	"vatRate.must.be.present": "Eine Rechnungszeile muss den Mehrwertsteuersatz enthalten",
	"price.must.be.present": "Eine Rechnungszeile muss den Stückpreis ohne Mehrwertsteuer enthalten",
	"Disputed Invoices": "Umstrittene Rechnungen",
	"invoice.export.entry_account_excel": "Excel-Buchführung",
	"invoice.export.error": "Fehler beim Exportieren von Rechnung",
	"order.export.error": "Fehler während des Exports der Bestellung",
	CREATE_ACTION_LITIGE: "Erstellen einer strittigen Rechnung ",
	REINTEGRATE_ACTION_LITIGE: "Wiedereingliederung der strittigen Rechnung",
	REINTEGRATE_ACTION: "Wiedereingliederung der Rechnung",
	REJECT_ACTION_LITIGE: "Rechnungsablehnung",

	"invoice.litige.already.in.open.status": "diese Rechnung liegt bereits vor strittigen",
	"confirmation.reintegrate": "Sie möchten diese Rechnung wirklich wiederherstellen ",
	"confirmation.nextStatus": "Möchten Sie den Status der Rechnung wirklich auf ändern? ",
	"confirmation.relance": "Möchten Sie die Extraktion wirklich neu starten?",
	"confirmation.ExportStatus":
		' Möchten Sie den Status der Rechnung wirklich auf ändern? " EXPORT " ? ',
	"file.scan.failed": "Antivirus-Scan fehlgeschlagen",
	"file.infected": "Infizierte Datei",
	logoScanFailed: "Logo-Antivirus-Scan fehlgeschlagen",
	logoInfected: "Infiziertes Logo",
};
