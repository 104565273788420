import supllierFr from "../pages/Suppliers/i18n/fr";
import clientFr from "../pages/Clients/i18n/fr";
import administrationFr from "../administration/i18n/fr";

export default {
	...supllierFr,
	...administrationFr,
	...clientFr,
	Copyright: "Tous droits réservés",
	Dashboard: "Tableau de Bord",
	"Business management": "Gestion Commerciale",
	Orders: "Commandes",
	Invoices: "Factures",
	Regulations: "Règlements",
	Suppliers: "Fournisseurs",
	Rapports: "Rapports",
	Search: "Rechercher",
	"Add your Invoice": "Déposer votre facture ",
	browse: "Parcourir",
	fileAddedMsg: "Fichier(s) ajouté(s) avec succès!",
	fileRemovedMsg: "Fichier {{filename}} supprimé.",
	"Show all": "Afficher tout",
	id: "Identifiant",
	supplier: "Fournisseur",
	invoiceNo: "N° Facture",
	issueDate: "Date d'émission",
	dueDate: "Date d'échéance",
	orderDeliveryDate: "Date de livraison",
	"Price WT": "Montant HT",
	"Taxe Rate": "TVA",
	Price: "Parcourir",
	"Purchase order": "Bon de Commande",
	"Last actions": "Dernières actions",
	Today: "Aujourd'hui",
	Yesterday: "Hier",
	"Last week": "Semaine dernière",
	Status: "Statut",
	Date: "Date",
	"My tasks": "Mes tâches",
	"Invoices to verify": "A vérifier",
	"To verify": "A vérifier",
	"Invoices to be checked": "A contrôler",
	"Invoices to Payers": "A payer",
	"Invoice available": "Facture disponible",
	"Paid invoices": "Factures payées",
	"Unpaid invoices": "Factures non payées",
	"Total amount of invoices": "Montant total des factures",
	"Total amount of orders": "Montant total des commandes",
	"Total VAT amount": "Montant total de TVA",
	"Statistical overview of purchases": "Aperçu statistique des achats",
	"Statistical overview of product sales": "Aperçu statistique des ventes des produits",
	"Total Purchases": "Total des achats",
	"Total of expenses": "Total des factures",
	Late: "En retard",
	Administrator: "Administration",
	"New suppliers": "Nouveaux fournisseurs",
	FOUNDED_RESULT: "Résultat trouvé",
	EndDate: "Date Fin",
	StartDate: "Date Début",
	"Add an invoice": "Ajouter une facture",
	Upload: "Charger les factures",
	Download: "Télécharger",
	Show: "Afficher",
	Delete: "Supprimer",
	All: "Tous",
	SearchInvoice: "Rechecher une facture...",
	"Delivery address": "Adresse de livraison",
	"Billing address": "Adresse de facturation",
	"Payment terms": "Moyen de paiement",
	"Order Details": "Détails Commande",
	"Invoice Details": "Détails Facture",
	"Reject Invoice": "Rejeter",
	"Reject Order": "Rejeter",
	"Correct Extraction": "Corriger",
	Control: "Contrôler",
	ToPay: "Mettre en paiment",
	"Total HT": "Total HT",
	"VAT amount": "Montant de TVA",
	Discount: "Remise",
	"Total amount": "Montant Total",
	"Invoice overview": "Aperçu de la Facture",
	History: "Historique",
	verificationDate: "Date de vérification",
	rejectionDate: "Date de rejet",
	controlDate: "Date de mise en contrôle",
	dateOfReceipt: "Date de réception",
	rejectMessage: "Voulez-vous vraiment rejeter cette facture?",
	controlMessage: "Voulez-vous vraiment mettre cette facture en contrôle?",
	ErrorMessageToControl: "Vous ne pouvez pas mettre cette facture en contrôle:",
	topayMessage: "Voulez-vous vraiment mettre cette facture en paiement?",
	deleteMessage: "Êtes-vous sûr de vouloir supprimer ces factures?",
	individualDeleteMessage: "Êtes-vous sûr de vouloir supprimer cette facture?",
	orderDeleteMessage: "Êtes-vous sûr de vouloir supprimer cette commande?",
	rejectionReason: "Motif de rejet",
	rejectionDetails: "Détails du Rejet...",
	cancel: "Annuler",
	confirm: "Confirmer",
	editForm: "Modification",
	countInvoices: "Nombre total de factures ",
	countOrders: "Nombre total de commandes ",
	tobeprocessed: "à traiter ",
	None: "------------------",
	save: "Enregistrer",
	edit: "Modifier",
	Actions: "Actions",
	itemCode: "Code",
	designation: "Article",
	quantity: "Qté",
	unitPrice: "Prix",
	deliveryDate: "Délai de livraison",
	// New added
	Total: "Total",
	VAT: "Taux de TVA",
	"Pre-Tax-Amount": "Montant HT",
	Commands: "LIGNES DE FACTURATION",
	"order.lines": "LIGNES DE COMMANDE",
	totalHt: "Total HT",
	vat: "TVA",
	sortBy: "Trier par",
	createdAt: "Créée le",
	updatedAt: "Date de mise à jour",
	InvoicesCannotBeDeleted: "Certaines factures ne peuvent pas être supprimées",
	OrdersCannotBeDeleted: "Certaines commandes ne peuvent pas être supprimées ",
	InvoicesCannotBeRelaunched: "Certaines factures ne peuvent pas être relancées",
	"Integration in progress": "Intégration en cours",
	"To control": "A contrôler",
	"To pay": "A payer",
	Payed: "Payée",
	Rejected: "Rejetée",
	Exception: "Exception",
	Canceled: "Annulée",
	codeArticle: "Code Article",
	deliveryDelay: "Délai de livraison",
	orderNo: "N ° de commande",
	numberOfArticle: "Nombre d'articles",
	deliveryMethod: "Méthode de livraison",
	InvoicesNotCreatedMessage: "Les factures suivantes ne peuvent pas être créées",
	invoice: "facture",
	order: "commande",
	training: "Apprentissage en cours...",
	trained: "Appris",
	"Company Siret": "Identifiant",
	"VAT Number": "Numéro de TVA",
	"Apply Changes": "Appliquer",
	"invoice Number": "N° Facture",
	phone: "Téléphone",
	"Email de Contact": "Email du contact",
	"Contact principal": "Contact principal",
	"Invoice was already paid": "La facture a déjà été payée",
	"Order has been already delivered": "La commande a déjà été livrée",
	"Goods or services are not available at the moment":
		"Les biens ou services ne sont pas disponibles pour le moment",
	"Apply for approval": "Demander approbation",
	ApplyForApproval: "Demande d'approbation",
	comment: "Commentaire",
	"Goods or Services were never received": "Les biens ou services n'ont jamais été reçus",
	"Supporting documents were not received with the invoice":
		"Les pièces justificatives n'ont pas été reçues avec la facture",
	"The purchase order number is missing, is incorrect or has been canceled":
		"Le numéro du bon de commande est manquant, est incorrect ou a été annulé",
	"The order number is missing, is incorrect or has been canceled":
		"Le numéro de commande est manquant, est incorrect ou a été annulé",
	"The amount being billed does not match the agreed upon amount on the contract":
		"Le montant facturé ne correspond pas au montant convenu sur le contrat",
	"Supporting documents were not received with the order":
		"Les pièces justificatives n'ont pas été reçues avec la commande",

	Other: "Autre",
	CREATE_ACTION: "Création de la facture",
	EDIT_ACTION: "Modification de la facture",
	VERIFY_ACTION: "Vérification de la facutre",
	REJECT_ACTION: "Rejet de la facture",
	CONTROL_ACTION: "Mise en contrôle de la facture",
	END_TRAINING_ACTION: "Apprentissage effectué",
	CORRECT_ACTION: "Lancement d'apprentissage",
	TO_PAY_ACTION: "Mise en paiment de la facture",
	DELET_ACTION: "Suppression de la facture",
	TO_VALIDATE_EXTERNAL_ACTION: "Demande d'approbation",
	RELAUNCH_ACTION: "Extraction relancée",
	EXPORT_ACTION: "Export de la facture",
	IMPORT_ACTION: "Import de la facture",
	ORDER_CREATE_ACTION: "Commande créée",
	ORDER_VERIFY_ACTION: "Commande sous vérification",
	ORDER_REJECT_ACTION: "Commande rejeté",
	ORDER_CONTROL_ACTION: "Commande envoyée pour le contrôle",
	ORDER_TO_VALIDATE_EXTERNAL_ACTION: "Demande de validation",
	ORDER_END_TRAINING_ACTION: "Apprentissage terminé",
	ORDER_CORRECT_ACTION: "Lancement d'apprentissage",
	ORDER_TO_PAY_ACTION: "Paiement de la commande",
	ORDER_DELETE_ACTION: "Suppression de la commande",
	ORDER_EDIT_ACTION: "Modification de la commande",
	ORDER_RELAUNCH_ACTION: "Extraction libérée",
	ORDER_EXPORT_ACTION: "Exportation de la commande",
	INVOICE_CHANGE_STATUS: "Facture passée au statut {{status}}",
	INVOICE_CHANGE_STATUS_AUTOMATIC: "Facture passée automatiquement au statut {{status}}",
	action: "Action",
	details: "Détails",
	supplierEmptyMessageError: "Le fournisseur de la facture n'est pas reconnu",
	incohenrenceAmountsMessageError:
		"Erreur sur les cumuls des montants : Le montant TTC est différent de la somme des montants HT et TVA",
	"invoice.status.integration": "Integration",
	"invoice.status.toverify": "À verifier",
	"invoice.status.tocontrol": "À controler",
	"invoice.status.topay": "À payer",
	"invoice.status.payed": "Payé",
	"invoice.status.rejected": "Rejetée",
	"invoice.status.canceled": "Annulé",
	"invoice.status.exception": "Exception",
	"invoice.status.external_validation": " En cours d'approbation",
	"invoice.status.open": "Ouverte",
	"invoice.status.reintegrated": "Réintégrée",

	"order.status.external_validation": " En cours d'approbation",
	reason: "Motif",
	Approve: "Approuver",
	email: "Email",
	approvedBy: "Approuvé par",
	"Nb total des factures": "Nb total des factures",
	"Facture en cours": "Factures en cours",
	"Total Orders": "Nombre total de commandes",
	searchOrder: "Trouver une commande",
	VALIDATED: "Validée",
	PARTIALLY_DELIVERED: "Partiellement Délivrée",
	DELIVERED: "LIVRÉ",
	PARTIALLY_BILLED: "Partiellement facturé",
	BILLED: "Facturé",
	"purchase_order.status.validated": "Validé",
	"purchase_order.status.partiallyDelivered": "Partiellement Délivrée",
	"purchase_order.status.delivered": "LIVRÉ",
	"purchase_order.status.PartiallyBilled": "Partiellement facturé",
	"purchase_order.status.billed": "Facturé",
	"order Lines": "Les lignes de commande du référentiel",
	delivered: "Livrée",
	"non delivered": "Non livrée",
	orderDate: "Date de la commande",
	"this operation is failed": "L'opération a échoué",
	invoiceAccumulation: "Accumulation de factures",
	"error.duplicateSuppliers":
		"Le numéro de TVA {{VatNumber}} correspond à plusieurs fournisseurs. Veuillez choisir le fournisseur",
	orderType: "Type de commande",
	CLOSED: "Fermée",
	OPEN: "Ouverte",
	DeliverySlip: "Bordereau de livraison",
	currency: "Devise",
	deliveryNo: "N ° de livraison",
	label: "Libéllé",
	deliveredQuantity: "Quantité livrée",
	delivery: "Livraison",
	"Delivery Details": "Réception",
	"Delivery Lines": "Entrées marchandises",
	deliveryItemDate: "Date de livraison",
	export: "Exporter",
	"invoice.status.exported": "Exportée",
	"price TTC": "Prix TTC",
	unitePriceHT: "Prix HT",
	"invoice.exported": "Factures exportées",
	"invoice.unexported": "Factures non exportées",
	invoice_charge: "Frais",
	order_charge: "Frais",
	"invoice.total_amoutn_to_verify": "Montant total des factures à vérifier",
	"invoice.total_invoice": "Nombre de factures",
	"invoice.duplicated": "Doublon",
	"extraction.label.fully_extracted": "Totalement extraite",
	"extraction.label.partially_extracted": "Partiellement extraite",
	"extraction.label.not_extracted": "Non extraite",
	"extraction.label.not_invoice": "Autre que facture",
	"this field is mandatory": "ce champ est obligatoire",
	rejectedBy: "Rejeté par",
	"invoice.detail.company_siret": "Identifiant Entreprise",
	country: "Pays",
	duplicated_invoice: "Facture dupliquée",
	"user.roles.user": "Utilisateur",
	"tootlip.action.edit": "Modifier",
	"tooltip.action.delete": "Supprimer",
	"tooltip.action.edit": "Modifier",
	"tooltip.action.relaunch": "Relancer",
	"tooltip.action.download": "Télécharger",
	"tooltip.action.relaunch_extraction": "Relancer l'extraction",
	"tooltip.action.export_excel": "Exporter excel",
	"tooltip.action.export_entry_account_excel": "Export Excel écriture Comptable",
	"tooltip.action.export_xml": "Exporter xml",
	"tooltip.action.export_edifact": "Exporter Edifact",
	"tooltip.action.export_idoc": "Exporter Idoc",
	"profile.update-password.current-password-required": "Le mot de passe actuel est requis",
	"profile.update-password.new-password-required": "Un nouveau mot de passe est requis",
	"profile.update-password.password-required": "Mot de passe requis",
	"profile.update-password.password-not-matched": "Les mots de passe ne sont pas identiques.",
	"profile.update-password.current-password": "Mot de passe actuel",
	"profile.update-password.new-password": "Nouveau mot de passe",
	"profile.update-password.confirm-password": "Confirmer le mot de passe",
	"profile.update-password.reset-password": "Réinitialiser le mot de passe",
	"profile.update-password.password-reset": "Réinitialiser le mot de passe",
	"profile.update-password.password-reset-success": "Votre mot de passe à été changé avec succès",
	"profile.update-password.password-reset-done": "Terminer",
	"profile.update-password.update-password": "Modifier le mot de passe",
	"profile.update-password.email": "Votre email actuel",
	"profile.update-password.tokenError": "Jeton non valide, le mot de passe est déjà mis à jour!",
	"profile.update-password.password-email-send":
		"Un e-mail a été envoyé. veuillez vérifier votre e-mail.",
	"suppliers.add.multiple": "Déposer votre fournisseurs ou",
	"upload.supplier": "Charger les fournisseurs",
	"downloqd.template": "télécharger le modèle",
	"supplier.add.button": "Importer des fournisseurs",
	"supplier.export.suppliers.excel": "Exporter la liste des fournisseurs",
	"supplier.add2.button": "Ajouter un fournisseur",
	"invoice.status.processing": "En traitement",
	"invoice.status.underProcess": "En cours de traitement",
	"invoice.status.InoviceProcessed": "Factures Traitées",
	"invoice.status.InoviceUnderProcess": "Factures en cours de traitement",
	"invoice.status.processed": "Traitée",
	Administration: "Administration",
	Logout: "Se déconnecter",
	Profile: "Profil",
	"invoice.selected.title0": "Toutes les factures sont sélectionnées",
	"invoice.selected.title1": "Les ",
	"invoice.selected.title2": " factures de cette page sont toutes sélectionnées.",
	"invoice.selected.title3": "Sélectionnez toutes les factures",
	"supplier.add.step1": "Informations générales",
	"supplier.add.step2": "Premier contact",
	"supplier.add.step3": "Informations financières",
	"supplier.add.next": "Suivant",
	"supplier.add.previous": "Précédent",
	"supplier.add.finish": "Finir",
	"supplier.name.exist.already": "Fournisseur existe sur le système",
	"suppliers.names.exist.already":
		"Le(s) fournisseur(s) {{fournisseurs}} existe déjà sur le système!",
	"suppliers.error.sample": "Le fichier envoyé n'est pas conforme avec le fichier modèle.",
	"suppliers.empty.field.name.row.excel": "Il y a une ligne sans nom !!",
	"supplier.add.country": "Pays de base",
	"supplier.add.suppliername": "Nom du fournisseur",
	"supplier.add.logo": "Logo",
	"supplier.add.capital": "Capitale",
	"supplier.add.siret": "Société SIRET",
	"supplier.add.siren": "Société SIREN",
	"supplier.add.linkedin": "LinkedIn",
	"supplier.add.email": "E-mail",
	"supplier.add.fax": "Fax",
	"supplier.add.civility": "Civilité",
	"supplier.add.phone": "Téléphone",
	"supplier.add.vat": "Numéro de TVA",
	"supplier.add.naf": "Code NAF",
	"supplier.add.bank": "Banque",
	"supplier.add.bic": "BIC",
	"supplier.add.iban": "IBAN",
	"supplier.add.rib": "RIB",
	"supplier.add.acc": "Compte comptable",
	"supplier.add.curr": "Devise par défaut",
	"supplier.add.function": "Fonction",
	"supplier.add.firstName": "Prénom",
	"supplier.add.lastName": "Nom",
	"supplier.add.city": "Ville",
	"supplier.add.street": "Rue",
	"supplier.add.zipCode": "Code postal",
	"supplier.add.confirm": "Voulez-vous vraiment enregistrer les modifications?",
	"supplier.delete.confirm": "Voulez-vous vraiment supprimer le fournisseur?",
	"supplier.delete.success": "Fournisseur supprimé avec succès",
	"supplier.have.attached.user.accounts.or.invoices.message":
		"Impossible de supprimer le/les fournisseur(s). Une facture/un compte est y rattaché !",
	"client.has.attached.user.accounts.message":
		"Impossible de supprimer le/les client(s). Une commande/un compte est y rattaché !",
	"suplier.action.agree": "Accepter",
	"suplier.action.disagree": "Refuser",
	"supplier.add.aux": "Compte auxiliaire",
	EXPORT_EXCEL: "Exporter excel",
	EXPORT_EDI: "Exporter edi",
	EXPORT_XML: "Exporter xml",
	EXPORT_PDF: "Exporter pdf",
	EXPORT_EC: "Exporter EC",
	"order.absent": "Commande absente",
	"amount.greate.thang.goods": "Montant de la facture supérieur à la commande",
	"delivery.absent": "Absence de livraison",
	"Zip Code": "Code postal",
	Street: "Rue",
	City: "Ville",
	"supplier.delete.training.error.message": "Aucun apprentissage n'existe pour ce fournisseur.",
	"invoice.detail.reject.success": "La facture a été rejetée avec succès",
	"invoice.detail.reject.failed": "La facture n'a pas été rejetée",
	"invoice.detail.approved.success": "La facture a été approuvée avec succès",
	"invoice.detail.approved.failed": "La facture n'a pas été approuvée",
	"invoice.detail.apply.validate": "Appliquer & Apprendre",
	"invoice.detail.marked.selectedField": "Marquer le champ sélectionné",
	"invoice.detail.export.success": "La facture a été exportée avec succès",
	"order.detail.export.success": "La commande a été exportée avec succès",
	"invoice.detail.export.failed": "La facture n'a pas été exportée",
	"order.detail.export.failed": "La commande n'a pas été exportée",
	"invoice.detail.message.rectify": "Êtes-vous sûr de vouloir enregistrer ces modifications?",
	"invoice.detail.message.confirmExport": "Êtes-vous sûr de vouloir exporter cette facture?",
	"order.detail.message.confirmExport": "Voulez-vous vraiment exporter cette commande?",
	"order.detail.approved.failed": "La commande n'a pas été approuvé",
	"order.detail.approved.success": "La commande a été approuvée avec succès",
	"token.validation.noteExist": "Demande de changement de mot de passe déjà envoyée",
	"invoice.detail.reintegrated.success": "La facture a été réintégrée avec succès",
	"invoice.detail.reintegrated.failed": "La facture n'a pas été réintégrée",

	year: "Cette année",
	month: "Ce mois",
	week: "Cette semaine",
	day: "Aujourd'hui",
	Exported: "Exportées",
	Integrated: "Integrées",
	"switch.orders": "COMMANDES",
	"switch.invoices": "FACTURES",
	"use.doc.cutter": "Utiliser la découpe automatique",
	"Invoice attachements": "Pièces jointes",
	"invoice.attachment.contract": "Contrat",
	"invoice.attachment.order": "Commande",
	"invoice.attachment.receipt": "Reçu",
	"invoice.attachment.oth": "Autre",
	"order.attachment.contract": "Contrat",
	"order.attachment.order": "Commande",
	"order.attachment.receipt": "Reçu",
	"order.attachment.oth": "Autre",
	"attachment.name": "Nom",
	"attachment.upload": "Télécharger",
	"attachment.cancel": "Annuler",
	completeerrors: "Accomplir les tâches",
	completeerrorsphrase: "La tâche contient toujours des erreurs",
	warnings: "Mises en garde",
	ruleerrors: "Erreurs de règle",
	checkerrors: "Vérifier les erreurs",
	completetask: "Terminer la tâche",
	correcterrors: "Corriger les erreurs",
	orderDeleteMessageMultiple:
		"Êtes-vous sûr de vouloir supprimer la ou les commandes sélectionnées?",
	"messenger.addComment": "Ajouter un commentaire",
	"messenger.postComment": "Poster le commentaire",
	EXPORT_IDOC: "Exporter idoc",
	lockTraining: "Désactiver l'apprentissage",
	unlockTraining: "Activer l'apprentissage",
	"accountingLines.costCenter": "Centre de coûts",
	"accountingLines.paymentMethod": "Mode de paiement",
	"accountingLines.glAccounts": "Compte comptable",
	"accountingLines.description": "Description",
	"accountingLines.sign": "Operation",
	"accountingLines.amount": "Montant",
	"accountingLines.addButton": "Ajouter une ligne comptable",
	"accountingLines.summary": "Les lignes comptables ne sont pas équilibrées",
	"Accounting Lines": "Ecritures comptables",
	"invoice.digital.extract": "Extraite depuis la facture électronique",
	"invoice.digital": "Facture électronique",
	"invoice.digital.preview":
		"Aperçu indisponible : cette facture électronique ne contient pas de PDF",
	"tooltip.action.export": "Exporter au format",
	"invoice.export.entry_account_excel": "Excel écriture comptable",
	"invoice.export.error": "Erreur lors de l'exportation de facture",
	"order.export.error": "Erreur lors de l'exportation de le commande",
	"invoice.digital.about": "A propos de la facture",
	"invoice.digital.type": "Type de facture",
	"invoice.digital.signature.validity": "Validité de la signature",
	"invoice.digital.format.compliance": "Conformité du format",
	"invoice.digital.en16931.compliance": "Respect de la norme EN 16931",
	"invoice.digital.en16931.rules.violated": "Nombre de règles EN 16931 enfreintes",
	"invoice.digital.chorus.pro.compliance": "Respect des règles de gestion Chorus Pro",
	"invoice.digital.chorus.pro.rules.violated": "Nombre de règles de gestion enfreintes",
	"invoice.digital.readmore": "En savoir plus sur la facture",
	"invoice.go.to": "Voir la facture",
	"invoiceNo.must.be.present": "Le numéro de la facture doit être présent",
	"supplier.must.be.present": "Le fournisseur doit être présent",
	"issueDate.must.be.present": "La date d'émission doit être présente",
	"total.must.be.present": "Le montant total doit être présent",
	"currency.must.be.present": "La devise doit être présente",
	"supplier.name.must.be.present": "La raison sociale du fournisseur doit être présente",
	"supplier.siret.must.be.present": "L'identifiant entreprise du fournisseur doit être présent",
	"supplier.vatNumber.must.be.present": "Le numéro TVA du fournisseur doit être présent",
	"supplier.country.must.be.present": "Le pays du fournisseur doit être présent",
	"designation.must.be.present": "Une ligne de facture doit inclure le nom de l'article",
	"quantity.must.be.present": "Une ligne de facture doit inclure la quantité facturée",
	"vatRate.must.be.present": "Une ligne de facture doit inclure le taux TVA",
	"price.must.be.present": "Une ligne de facture doit inclure le prix unitaire HT",
	"Disputed Invoices": "Factures Litigieuses",
	CREATE_ACTION_LITIGE: "Création d'une facture en litige ",
	REINTEGRATE_ACTION_LITIGE: "Réintégration de la facture en litige",
	REINTEGRATE_ACTION: "Réintégration de la facture",
	REJECT_ACTION_LITIGE: "Rejet de la facture",

	"invoice.litige.already.in.open.status": "cette facture est déja en litige",
	"confirmation.reintegrate": "Êtes-vous sûr de vouloir réintégrer cette facture ? ",
	"confirmation.nextStatus": "Êtes-vous sûr de vouloir changer le statut de la facture vers ",
	"confirmation.relance": "Êtes-vous sûr de vouloir relancer l'extraction ?",
	"confirmation.ExportStatus":
		' Êtes-vous sûr  de vouloir changer le statut de la facture vers " EXPORTER " ? ',
	"file.scan.failed": "L'analyse antivirus a échoué",
	"file.infected": "Fichier infecté",
	logoScanFailed: "L'analyse antivirus du logo a échoué",
	logoInfected: "Logo infecté",
};
