import React, { useEffect } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Chip from "@material-ui/core/Chip";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@material-ui/core";
import useStyles from "./style";
import { fetchLastThreeInvoices } from "../../../../redux/actions/invoices/index";
import { initFilter, updateFilter } from "../../../../redux/filterReducer";
import { getStatusChipColor } from "../../../../utils/index";
import CircularLoader from "../../../../components/Loaders/CircularLoader";

function LastActions(props) {
	const classes = useStyles();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();

	const lastInvoices = useSelector(
		({ requests }) =>
			requests.queries.FETCH_LAST_ACTIONS_INVOICE &&
			requests.queries.FETCH_LAST_ACTIONS_INVOICE.data
	);

	useEffect(() => {
		dispatch(fetchLastThreeInvoices());
	}, []);
	const onClickRow = (id) => {
		dispatch(initFilter());
		history.push(`invoices/details/${id}`);
	};
	const onClickShowAll = () => {
		dispatch(
			updateFilter({
				invoiceNoOrSupplierCode: "",
				status: [],
				startDate: null,
				endDate: null,
				sortBy: "updatedAt",
				pageNo: 0,
				pageSize: 10,
			})
		);
		history.push("/invoices", { fromDashboard: true });
	};
	return (
		<Card
			className="h-100"
			{...props} // eslint-disable-line
		>
			<CardHeader
				title={t("Last actions")}
				titleTypographyProps={{
					variant: "subtitle1",
					color: "primary",
				}}
				classes={{
					action: classes.select,
				}}
				action={
					<Button
						align="right"
						color="secondary"
						className={classes.underlineText}
						onClick={() => onClickShowAll()}
					>
						{t("Show all")}
					</Button>
				}
			/>
			<CardContent className="p-0">
				<Table aria-label="Actions Table">
					<TableHead>
						<TableRow>
							<TableCell>{t("invoiceNo")}.</TableCell>
							<TableCell align="center">{t("Status")}</TableCell>
							<TableCell align="center">{t("updatedAt")}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{lastInvoices ? (
							lastInvoices.map((row, i) => (
								<TableRow
									hover
									key={i}
									onClick={() => onClickRow(row.id)}
									className={classes.hover}
								>
									<TableCell component="th" scope="row">
										{row.invoiceNo}
									</TableCell>
									<TableCell align="center">
										<Chip
											label={t(
												row.status.displayName ? t(row.status.displayName) : t(row.status.label)
											)}
											size="small"
											style={{
												backgroundColor: getStatusChipColor(row.status.code),
												color: "white",
											}}
										/>
									</TableCell>
									<TableCell align="center">{row.updatedAt}</TableCell>
								</TableRow>
							))
						) : (
							<CircularLoader />
						)}
					</TableBody>
				</Table>
			</CardContent>
		</Card>
	);
}

export default LastActions;
