import React, { useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import get from "lodash/get";
import { Button, Input } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";

import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import { toast } from "utils";
import { updateMailConfig, fetchMailConfig } from "../../redux/actions";
import useStyles from "../style";
import ConfirmDialog from "../../../components/ConfirmDialog";

export const EmailConfig = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const classes = useStyles();
	const { errors, handleSubmit, register } = useForm();
	const [mailConfig, setMailConfig] = React.useState(null);
	const [edit, setEdit] = React.useState(false);
	const [confirmObject, setConfirmObject] = React.useState({
		message: "",
		openConfirm: false,
		cancel: false,
		isLoading: false,
	});
	const [mailConfigToSave, setMailConfigToSave] = React.useState(null);
	const isOrderEnv = useSelector(({ appState }) => appState.isOrderEnv);

	useEffect(() => {
		dispatch(fetchMailConfig(isOrderEnv ? "order" : "invoice")).then((res) =>
			setMailConfig(res.data)
		);
	}, [isOrderEnv]);

	const onSubmit = (data) => {
		setMailConfigToSave(data);

		setConfirmObject({ message: t("admin.config.message.confirm"), openConfirm: true });
	};
	const onConfirm = () => {
		if (!confirmObject.cancel) {
			setConfirmObject({ ...confirmObject, isLoading: true });
			dispatch(
				updateMailConfig(
					{ ...mailConfigToSave, id: get(mailConfig, "id"), type: get(mailConfig, "type") },
					() => {
						//  setOpenSendPopup(true);
						setConfirmObject({ message: "", openConfirm: false, isLoading: false });
						toast.success(t("admin.config.update-message"));
						setEdit(false);
					},
					(error) => {
						// setErrorMessage(error);
						setConfirmObject({ message: "", openConfirm: false, isLoading: false });
						toast.error(error);
					}
				)
			);
		} else {
			setConfirmObject({ message: "", openConfirm: false, cancel: false });
			setEdit(false);
		}
	};
	const onCancel = () => {
		setConfirmObject({ message: "", openConfirm: false });
		if (confirmObject.cancel) setEdit(true);
	};

	return (
		<>
			<Grid container item xs={12} />
			<Grid container item xs={12} justify="center" alignItems="center" alignContent="center">
				{mailConfig && (
					<form onSubmit={handleSubmit(onSubmit)}>
						<Grid container item xs={12} className={classes.loginContainer} spacing={6}>
							<Grid item container xs={12}>
								<Grid container item xs={6}>
									<Typography variant="h6" color="primary">
										{t("admin.email-config")}
									</Typography>
								</Grid>
								<Grid item container xs={6} justify="flex-end">
									<Tooltip placement="top" title={t("tootlip.action.edit")}>
										<IconButton aria-label="delete">
											<EditIcon onClick={() => setEdit(!edit)} />
										</IconButton>
									</Tooltip>
								</Grid>
							</Grid>
							<Grid item xs={6}>
								<Typography variant="subtitle2" align="left" color="primary">
									{t("Host")}
									<span style={{ color: "red" }}>*</span>
									<br />
									<Input
										inputRef={register({
											required: { value: true, message: t("field_required") },
										})}
										name="mailServerUrl"
										type="text"
										defaultValue={get(mailConfig, "mailServerUrl")}
										disabled={!edit}
										fullWidth
									/>
									<p>{errors.email?.message}</p>
								</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography variant="subtitle2" align="left" color="primary">
									{t("Port")}
									<span style={{ color: "red" }}>*</span>
									<br />
									<Input
										inputRef={register({
											required: { value: true, message: t("field_required") },
										})}
										name="mailServerPort"
										type="number"
										disabled={!edit}
										defaultValue={get(mailConfig, "mailServerPort")}
										fullWidth
									/>
									<p>{errors.email?.message}</p>
								</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography variant="subtitle2" align="left" color="primary">
									{t("Email")}
									<span style={{ color: "red" }}>*</span>
									<br />
									<Input
										inputRef={register({
											required: { value: true, message: t("field_required") },
										})}
										name="mailServerLogin"
										type="email"
										disabled={!edit}
										defaultValue={get(mailConfig, "mailServerLogin")}
										fullWidth
									/>
									<p>{errors.email?.message}</p>
								</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography variant="subtitle2" align="left" color="primary">
									{t("Password")}
									<span style={{ color: "red" }}>*</span>
									<br />
									<Input
										inputRef={register({
											required: { value: true, message: t("field_required") },
										})}
										name="mailServerPassword"
										type="password"
										disabled={!edit}
										defaultValue={get(mailConfig, "mailServerPassword")}
										fullWidth
									/>
									<p>{errors.email?.message}</p>
								</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography variant="subtitle2" align="left" color="primary">
									{t("Read folder")}
									<span style={{ color: "red" }}>*</span>
									<br />
									<Input
										inputRef={register({
											required: { value: true, message: t("field_required") },
										})}
										name="folderRead"
										type="text"
										disabled={!edit}
										defaultValue={get(mailConfig, "folderRead")}
										fullWidth
									/>
									<p>{errors.email?.message}</p>
								</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography variant="subtitle2" align="left" color="primary">
									{t("Processing folder")}
									<span style={{ color: "red" }}>*</span>
									<br />
									<Input
										inputRef={register({
											required: { value: true, message: t("field_required") },
										})}
										name="folderProcess"
										type="text"
										disabled={!edit}
										defaultValue={get(mailConfig, "folderProcess")}
										fullWidth
									/>
									<p>{errors.email?.message}</p>
								</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography variant="subtitle2" align="left" color="primary">
									{t("Success folder")}
									<span style={{ color: "red" }}>*</span>
									<br />
									<Input
										inputRef={register({
											required: { value: true, message: t("field_required") },
										})}
										name="folderSuccess"
										type="text"
										disabled={!edit}
										defaultValue={get(mailConfig, "folderSuccess")}
										fullWidth
									/>
									<p>{errors.email?.message}</p>
								</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography variant="subtitle2" align="left" color="primary">
									{t("Exception folder")}
									<span style={{ color: "red" }}>*</span>
									<br />
									<Input
										inputRef={register({
											required: { value: true, message: t("field_required") },
										})}
										name="folderException"
										type="text"
										disabled={!edit}
										defaultValue={get(mailConfig, "folderException")}
										fullWidth
									/>
									<p>{errors.email?.message}</p>
								</Typography>
							</Grid>
						</Grid>
						<Grid
							container
							item
							xs={12}
							spacing={2}
							className={classes.actions}
							justify="center"
							alignItems="center"
							alignContent="center"
						>
							<Grid item>
								<Button
									color="primary"
									autoFocus
									disabled={!edit}
									onClick={() => {
										setConfirmObject({
											message: t("CancelActionMessage"),
											openConfirm: true,
											cancel: true,
										});
									}}
								>
									{t("cancel")}
								</Button>
							</Grid>
							<Grid item>
								<Button variant="contained" type="submit" color="secondary" disabled={!edit}>
									{t("confirm")}
								</Button>
							</Grid>
						</Grid>

						<ConfirmDialog
							message={confirmObject.message}
							open={confirmObject.openConfirm}
							isLoading={confirmObject.isLoading}
							onConfirm={onConfirm}
							onCancel={onCancel}
						/>
					</form>
				)}
			</Grid>
		</>
	);
};

export default EmailConfig;
