export const URL_BASE_INVOICE = "/invoice";
export const URL_BASE_IMPORT = "/import";
export const URL_BASE_MESSENGER = "/messenger";
export const URL_BASE_EXPORT = "/export";
export const URL_BASE_ORDER = "/order";
export const URL_BASE_CLIENT = "/order/client";
export const URL_INVOICE_TOTAL_AMOUNT = `${URL_BASE_INVOICE}/totalAmounts`;
export const URL_INVOICE_TOTAL_VAT_AMOUNT = `${URL_BASE_INVOICE}/totalvatAmounts`;
export const URL_INVOICE = `${URL_BASE_INVOICE}/`;
export const URL_CREATE_INVOICE = `${URL_BASE_INVOICE}/upload`;

export const URL_STATUS = `${URL_BASE_INVOICE}/status`;
export const URL_SUPPLIER = `${URL_BASE_INVOICE}/supplier`;
export const URL_CLIENT = `${URL_BASE_ORDER}/client`;
export const URL_SUPPLIER_CODE_CHECK = `${URL_BASE_INVOICE}/supplierCheck`;
export const URL_COUNT_SUPPLIER = `${URL_BASE_INVOICE}/supplier/count`;
export const URL_COUNT_CLIENTS = `${URL_BASE_CLIENT}/count`;
export const URL_COUNT_ORDERS = `${URL_BASE_ORDER}/count`;
export const URL_PAYMENT_TERM = `${URL_BASE_INVOICE}/paymentTerm`;
export const URL_DELIVERY_METHOD = `${URL_BASE_INVOICE}/deliveryMethod`;
export const URL_MOTIF = `${URL_BASE_INVOICE}/rejectionMotif`;
export const URL_CREATE_INVOICE_REJECTION = `/reject`;
export const URL_VERIFY_INVOICE = `/verify`;
export const URL_LAST_ACTION_INVOICE = `${URL_BASE_INVOICE}/lastInvoices`;
export const URL_SUPPLIER_INVOICES = `${URL_BASE_INVOICE}/bySupplier`;
export const URL_SUPPLIER_INVOICES_OUTSTANDING = `${URL_BASE_INVOICE}/outstandingInvoice/bySupplier`;
export const URL_COUNTRY = `${URL_BASE_INVOICE}/supplier/address/country`;
export const URL_INVOICE_INDICATORS = `${URL_BASE_INVOICE}/indicators`;
export const URL_INVOICE_TOTAL_OVERDUE_AMOUNT = `${URL_BASE_INVOICE}/overdue`;
export const URL_ORDERS = `${URL_BASE_INVOICE}/po/orders`;
export const URL_TO_PAY_INVOICE = `${URL_BASE_INVOICE}/topay`;
export const URL_INVOICE_HISTORY = `/history`;
export const URL_CREATE_SUPPLIER = `${URL_BASE_INVOICE}/supplier`;
export const URL_LEGAL_FORM = `${URL_BASE_INVOICE}/legalform`;
export const URL_RELAUNCH_EXTRACTION_INVOICE = `${URL_BASE_INVOICE}/relaunch`;
export const URL_RELAUNCH_EXTRACTION_ORDER = `${URL_BASE_ORDER}/relaunch`;
export const URL_CURRENT_USER = `/admin/user/current-user`;
export const URL_UPDATE_PASSWORD = `/admin/user/userPassword`;
export const URL_CREATE_MULTI_SUPPLIER = `${URL_BASE_INVOICE}/suppliers/upload`;
export const URL_LIST_SETTING_WITH_CODE = `/admin/setting/list`;
export const URL_LIST_SETTING = `/admin/setting/`;
export const URL_LIST_SETTING_MODULE = `/admin/setting/list/module/`;
export const URL_EXPORT_INVOICE_ENTRY_ACCOUNT_EXCEL = `${URL_BASE_INVOICE}/export/excel/entryaccount`;
export const URL_DOCUMENTS_INVOICES = `${URL_BASE_INVOICE}/documents`;
export const URL_INVOICE_XML = `/export`;
export const URL_CREATE_ORDER = `${URL_BASE_ORDER}/upload`;
export const URL_ORDER_TOTAL_AMOUNT = `${URL_BASE_ORDER}/totalAmounts`;
export const URL_DELIVERY_METHOD_ORDER = `${URL_BASE_ORDER}/deliveryMethod`;
export const URL_PAYMENT_TERM_ORDER = `${URL_BASE_ORDER}/paymentTerm`;
export const URL_MOTIF_ORDER = `${URL_BASE_ORDER}/rejectionMotif`;
export const URL_ORDER_INDICATORS = `${URL_BASE_ORDER}/indicators`;
export const URL_LAST_ACTION_ORDER = `${URL_BASE_ORDER}/lastOrders`;
export const URL_ORDER_TOTAL_VAT_AMOUNT = `${URL_BASE_ORDER}/totalvatAmounts`;
export const URL_ORDERS_BY_CLIENT = `${URL_BASE_CLIENT}/orders`;
export const URL_ORDER_TOTAL_OVERDUE_AMOUNT = `${URL_BASE_ORDER}/overdue`;
export const URL_CLIENT_CODE_CHECK = `${URL_BASE_CLIENT}/clientCheck`;
export const URL_CREATE_CLIENT = `${URL_BASE_CLIENT}/add`;
export const URL_UPDATE_CLIENT = `${URL_BASE_CLIENT}/update`;
export const URL_CREATE_MULTI_CLIENTS = `${URL_BASE_CLIENT}/upload`;
export const URL_VERIFY_ORDER = `/verify`;
export const URL_ORDER_STATUS = `${URL_BASE_ORDER}/status`;
export const URL_BASE_EXPORTS_EDIFACT = "/exports/exportEdi";
export const URL_BASE_EXPORTS_ORDER_EDIFACT = "/exports/exportEdi/order";
export const URL_BASE_EXPORTS_ORDER_IDOC = "/exports/idoc/order";
export const URL_BASE_EXPORTS_IDOC = "/exports/idoc";
export const URL_FETCH_INVOICE_DOC_TYPES = `${URL_BASE_INVOICE}/attachments/types`;
export const URL_FETCH_INVOICE_ATTACHMENTS = `${URL_BASE_INVOICE}/attachment`;
export const URL_CREATE_INVOICE_ATTACHMENT = `${URL_BASE_INVOICE}/attach/upload`;
export const URL_CREATE_ORDER_ATTACHMENT = `${URL_BASE_ORDER}/attach/upload`;
export const URL_FETCH_ORDER_DOC_TYPES = `${URL_BASE_ORDER}/attachments/types`;
export const URL_WORKFLOW = `${URL_BASE_INVOICE}/workflow`;
export const URL_GLACCOUNTS = `${URL_BASE_INVOICE}/glAccounts`;
export const URL_COSTCENTERS = `${URL_BASE_INVOICE}/costCenters`;
export const URL_PAYMENTMETHODS = `${URL_BASE_INVOICE}/paymentMethods`;

export const URL_BASE_BFF = "/bff/api";
export const URL_BASE_EXPORT_INVOICE = `${URL_BASE_BFF}/export`;
export const URL_FETCH_COMMERCIAL_ORGANIZATION = `/admin/commercial-organization`;
export const URL_BASE_BFF_EXPORT = `${URL_BASE_BFF}/export`;
export const URL_EXPORT_DIGITAL_INVOICE = `${URL_BASE_BFF_EXPORT}/digital`;
export const URL_EXPORT_INVOICE_EDIFACT = `${URL_BASE_BFF_EXPORT}/edi/invoice`;
export const URL_EXPORT_ORDER_EDIFACT = `${URL_BASE_BFF_EXPORT}/edi/order`;
export const URL_EXPORT_INVOICE_IDOC = `${URL_BASE_BFF_EXPORT}/idoc/invoice`;
export const URL_EXPORT_ORDER_IDOC = `${URL_BASE_BFF_EXPORT}/idoc/order`;
