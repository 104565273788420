import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
	TableContainer,
	Paper,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Grid,
	IconButton,
	Button,
	Typography,
	Tooltip,
} from "@material-ui/core";
// import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";
import get from "lodash/get";
import { bytesToSize, toast } from "utils";
import {
	downloadOrderAttachment,
	deleteOrderAttachment,
	fetchOrderAttachments,
} from "redux/actions/orders";
import ConfirmDialog from "components/ConfirmDialog";
import useStyles from "./style";

const InvoiceAttachmentsTable = () => {
	const { t } = useTranslation();
	const classes = useStyles();
	const dispatch = useDispatch();

	const { idOrder } = useParams();

	const attachments = useSelector(({ requests }) =>
		get(requests, "queries.FETCH_ORDER_ATTACHMENTS.data")
	);

	const [confirmObject, setConfirmObject] = React.useState({
		message: "",
		openConfirm: false,
		cancel: false,
		isLoading: false,
	});
	const [attachmentId, setAttachmentId] = React.useState(null);

	const onCancel = () => {
		setConfirmObject({ message: "", openConfirm: false, isLoading: false });
	};

	const onConfirm = () => {
		setConfirmObject({ ...confirmObject, isLoading: true });
		dispatch(deleteOrderAttachment(attachmentId)).then((res) => {
			if (res.error) {
				toast.error(get(res, "error.response.data.detail"));
			} else {
				dispatch(fetchOrderAttachments(idOrder));
				toast.success(t("attachment.delete.success"));
			}
			onCancel();
		});
	};

	const downloadAttachment = (id) => {
		dispatch(downloadOrderAttachment(id));
	};

	return (
		<>
			<Grid xs={12}>
				<TableContainer component={Paper}>
					<Table className={classes.table} aria-label="simple table">
						<TableHead>
							<TableRow>
								<TableCell>{t("invoice.attachment.label")}</TableCell>
								<TableCell>{t("invoice.attachment.extension")}</TableCell>
								<TableCell>{t("invoice.attachment.type")}</TableCell>
								<TableCell>{t("invoice.attachment.creationDate")}</TableCell>
								<TableCell>{t("invoice.attachment.size")}</TableCell>
								<TableCell>&nbsp;</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{attachments &&
								attachments.map((row, index) => (
									<TableRow key={index}>
										<TableCell>
											<Tooltip placement="top" title={get(row, "name", "")}>
												<Button
													color="secondary"
													variant="text"
													onClick={() => downloadAttachment(row.id)}
												>
													<Typography variant="subtitle2" className={classes.download_link}>
														{get(row, "name", "")}
													</Typography>
												</Button>
											</Tooltip>
										</TableCell>
										<TableCell>{get(row, "extension", "")?.toUpperCase()}</TableCell>
										<TableCell>{t(get(row, "orderAttachmentTypeDto.label", ""))}</TableCell>
										<TableCell>{get(row, "createdAt", "")}</TableCell>
										<TableCell>{bytesToSize(get(row, "size", 0))}</TableCell>
										<TableCell>
											<Grid container xs={12}>
												{/* <Grid item xs={6}>
													<IconButton color="primary" aria-label="View">
														<VisibilityIcon />
													</IconButton>
												</Grid> */}
												<Grid item xs={6}>
													<Tooltip placement="top" title={t("tooltip.action.delete")}>
														<IconButton
															onClick={() => {
																setAttachmentId(row.id);
																setConfirmObject({
																	message: t("invoice.attachment.delete.confirm"),
																	openConfirm: true,
																});
															}}
															color="primary"
															aria-label="Delete"
														>
															<DeleteIcon style={{ color: "red" }} />
														</IconButton>
													</Tooltip>
												</Grid>
											</Grid>
										</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>
				</TableContainer>
			</Grid>
			<ConfirmDialog
				message={confirmObject.message}
				open={confirmObject.openConfirm}
				isLoading={confirmObject.isLoading}
				onConfirm={onConfirm}
				onCancel={onCancel}
			/>
		</>
	);
};

export default InvoiceAttachmentsTable;
