import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	firstGrid: {
		paddingTop: "1%",
		width: "calc(100% + 48px)",
	},
	expand: {
		transform: "rotate(0deg)",
		marginLeft: "auto",
		marginBottom: "5px",
		transition: theme.transitions.create("transform", {
			duration: theme.transitions.duration.shortest,
		}),
	},
	dropzone: {
		background: "transparent",
		maxWidth: "100%",
		minHeight: "80%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
		"& .MuiDropzoneArea-icon": {
			color: theme.palette.primary.main,
			opacity: 0.8,
		},
	},
	selectAll: {
		flexGrow: 1,
		width: "100%",
		backgroundColor: theme.palette.background.paper,
		marginTop: "24px",
	},
}));
