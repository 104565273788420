import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
	Card,
	CardContent,
	Grid,
	ButtonBase,
	Typography,
	Avatar,
	Chip,
	Tooltip,
	IconButton,
	Checkbox,
	CardActions,
} from "@material-ui/core";
import LaunchIcon from "@material-ui/icons/Launch";
import DescriptionIcon from "@material-ui/icons/Description";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import DeleteIcon from "@material-ui/icons/Delete";
import GetAppIcon from "@material-ui/icons/GetApp";
import get from "lodash/get";
import clsx from "clsx";
import { getApi } from "redux/store";
import {
	downloadIdocOrder,
	downloadEdifactOrder,
	downloadOrder,
	downloadOrderExcel,
	downloadXMLOrder,
	relaunchOrder,
	fetchOrders,
	fetchLastThreeOrders,
	fetchOrderIndicators,
} from "redux/actions/orders";
import LoadingButton from "components/LoadingButton";
import ConfirmDialog from "components/ConfirmDialog";

import ExtractionLevelBadge from "../../../../components/ExtractionLevelBadge";
import useStyles from "../style";
import { invoiceStatusColors, toast } from "../../../../utils";
import { initFilterOrder } from "../../../../redux/orderFilterReducer";
import { ReactComponent as ExportEdiIcon } from "../../../../components/InvoicesToValidate/InvoiceTable/export-edi.svg";
import { ReactComponent as ExportIdocIcon } from "../../../../components/InvoicesToValidate/InvoiceTable/export-idoc.svg";
import UserProfile from "../../../../App/user-profile.png";

function TrainingStatus({ onTraining, training, trained }) {
	if (onTraining === true) {
		return <Chip variant="outlined" color="secondary" label={training} />;
	}
	if (onTraining === false) return <Chip variant="outlined" color="primary" label={trained} />;
	return <></>;
}

const SettingsDisplay = ({ value, children }) => (value ? children : <></>);

export default ({
	data,
	settings,
	isDashboard,
	fetchOrdersAfterRelauch,
	setSelectedId,
	setOpenDeletePopup,
	deleteConfirmObject,
	setSelected,
	selected,
}) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();

	const [confirmObject, setConfirmObject] = React.useState({
		message: t("confirmation.relance"),
		openConfirm: false,
		cancel: false,
		isLoading: false,
	});

	const [isLoading, setIsLoading] = React.useState({
		excel: { id: [], turning: false },
		xml: { id: [], turning: false },
		pdf: { id: [], turning: false },
		edi: { id: [], turning: false },
		idoc: { id: [], turning: false },
		ec: { id: [], turning: false },
	});

	const [currentId, setCurrentId] = React.useState(0);

	const onClickRow = (id) => {
		dispatch(initFilterOrder());
		history.push(`orders/details/${id}`);
	};

	const handleRelaunch = () => {
		setConfirmObject({ ...confirmObject, openConfirm: false, isLoading: true });
		dispatch(relaunchOrder(currentId)).then(() => {
			dispatch(fetchOrders(fetchOrdersAfterRelauch));
			dispatch(fetchLastThreeOrders());
			dispatch(fetchOrderIndicators());
			setConfirmObject({ ...confirmObject, openConfirm: false, isLoading: false });
		});
	};

	const onCancelRelaunch = () => {
		setConfirmObject({ ...confirmObject, openConfirm: false, isLoading: false });
	};

	const handleExportIdoc = (id) => {
		setIsLoading({
			...isLoading,
			idoc: { id: [...isLoading?.idoc?.id, id], turning: true },
		});
		const ids = [...isLoading?.idoc?.id, id];
		dispatch(
			downloadIdocOrder(
				id,
				() => toast.error(t("order.export.error")),
				() => {
					const i = ids.indexOf(id);

					if (i > -1) {
						ids.splice(i, 1); // 2nd parameter means remove one item only
						setIsLoading({
							...isLoading,
							idoc: { id: [...ids], turning: false },
						});
					}
				}
			)
		);
	};

	const handleExportEdi = (id) => {
		setIsLoading({
			...isLoading,
			edi: { id: [...isLoading?.edi?.id, id], turning: true },
		});
		const ids = [...isLoading?.edi?.id, id];
		dispatch(
			downloadEdifactOrder(
				id,
				() => toast.error(t("order.export.error")),
				() => {
					const i = ids.indexOf(id);

					if (i > -1) {
						ids.splice(i, 1); // 2nd parameter means remove one item only
						setIsLoading({
							...isLoading,
							edi: { id: [...ids], turning: false },
						});
					}
				}
			)
		);
	};

	const handleExportXml = (id) => {
		setIsLoading({
			...isLoading,
			xml: { id: [...isLoading?.xml?.id, id], turning: true },
		});
		const ids = [...isLoading?.xml?.id, id];
		dispatch(
			downloadXMLOrder(
				id,
				() => toast.error(t("order.export.error")),
				() => {
					const i = ids.indexOf(id);

					if (i > -1) {
						ids.splice(i, 1); // 2nd parameter means remove one item only
						setIsLoading({
							...isLoading,
							xml: { id: [...ids], turning: false },
						});
					}
				}
			)
		);
	};

	const handleExportOrder = (id) => {
		setIsLoading({
			...isLoading,
			pdf: { id: [...isLoading?.pdf?.id, id], turning: true },
		});
		const ids = [...isLoading?.pdf?.id, id];
		dispatch(
			downloadOrder(
				id,
				"ord",
				() => toast.error(t("order.export.error")),
				() => {
					const i = ids.indexOf(id);

					if (i > -1) {
						ids.splice(i, 1); // 2nd parameter means remove one item only
						setIsLoading({
							...isLoading,
							pdf: { id: [...ids], turning: false },
						});
					}
				}
			)
		);
	};

	const handleExportExcel = (id) => {
		setIsLoading({
			...isLoading,
			excel: { id: [...isLoading?.excel?.id, id], turning: true },
		});
		const ids = [...isLoading?.excel?.id, id];
		dispatch(
			downloadOrderExcel(
				id,
				() => toast.error(t("order.export.error")),
				() => {
					const i = ids.indexOf(id);

					if (i > -1) {
						ids.splice(i, 1); // 2nd parameter means remove one item only
						setIsLoading({
							...isLoading,
							excel: { id: [...ids], turning: false },
						});
					}
				}
			)
		);
	};

	const getImageURL = (path) => `${getApi()}/order${path}`;
	return (
		<>
			{data &&
				data.content &&
				data.content.map((row, index) => (
					<Card
						key={index}
						className={clsx(
							classes.root,
							get(row, "status.code") !== "INTEGRATION" &&
								row.extractionLevel === "N1" &&
								classes.fullyExtracted,
							get(row, "status.code") !== "INTEGRATION" &&
								row.extractionLevel === "N2" &&
								classes.partiallyExtracted,
							get(row, "status.code") !== "INTEGRATION" &&
								row.extractionLevel === "N3" &&
								classes.nonExtracted
						)}
						elevation={1}
					>
						<CardContent className={classes.content}>
							<ButtonBase focusRipple onClick={() => onClickRow(row.id)}>
								<Grid container xs={12}>
									<Grid container item xs={4}>
										<Grid container item xs={4} direction="column">
											<Grid item className={classes.avatar}>
												<Avatar
													alt={row.code || "Client icon"}
													src={row?.client?.logo ? getImageURL(row?.client?.logo) : UserProfile}
												/>
											</Grid>
											<Grid item>
												<Typography variant="subtitle2" align="center">
													{row.client && row.client.label}
												</Typography>
											</Grid>
										</Grid>
										<Grid container item xs={8} direction="column">
											<Grid item>
												<Typography variant="body1">{row.orderNo}</Typography>
											</Grid>
											<Grid item>
												<Typography variant="caption" color="textSecondary">
													{row.issueDate}
												</Typography>
											</Grid>
											<Grid item>
												<Chip
													label={t(row.status.label)}
													size="small"
													style={{
														backgroundColor: invoiceStatusColors[row.status.code],
														color: "white",
													}}
												/>
											</Grid>
										</Grid>
									</Grid>
									<Grid container item xs={2} direction="column" alignItems="center">
										<Grid item>
											<Typography variant="subtitle2" color="textSecondary">
												{t("createdAt")}
											</Typography>
										</Grid>
										<Grid item>
											<Typography variant="caption" color="textSecondary">
												{row.createdAt}
											</Typography>
										</Grid>
										<Grid item>
											<TrainingStatus
												onTraining={row.onTraining}
												training={t("training")}
												trained={t("trained")}
											/>
										</Grid>
									</Grid>
									<Grid container item xs={4}>
										<Grid container item direction="column" xs={6}>
											<Grid item>
												<Typography variant="subtitle2" color="textSecondary" align="right">
													{t("Pre-Tax-Amount")}
												</Typography>
											</Grid>
											<Grid item>
												<Typography variant="subtitle2" color="textSecondary" align="right">
													{t("VAT amount")}
												</Typography>
											</Grid>
											<Grid item>
												<Typography variant="body1" color="primary" align="right">
													{t("Total")}
												</Typography>
											</Grid>
										</Grid>
										<Grid container item direction="column" xs={6}>
											<Grid item>
												<Typography variant="body2" color="textSecondary" align="right">
													{row.additionalAmountsHT &&
														row.additionalAmountsHT.filter((amount) => amount.principal)[0] &&
														row.additionalAmountsHT.filter((amount) => amount.principal)[0]
															.amount}{" "}
													€
												</Typography>
											</Grid>
											<Grid item>
												<Typography variant="body2" color="textSecondary" align="right">
													{row.additionalAmountsTVA &&
														row.additionalAmountsTVA.filter((amount) => amount.principal)[0] &&
														row.additionalAmountsTVA.filter((amount) => amount.principal)[0]
															.amount}{" "}
													€
												</Typography>
											</Grid>
											<Grid item>
												<Typography variant="body1" color="primary" align="right">
													{row.total} €
												</Typography>
											</Grid>
										</Grid>
									</Grid>
									{get(row, "status.code") !== "INTEGRATION" ? (
										<Grid container item xs={2} alignItems="center" justify="center">
											<ExtractionLevelBadge level={row.extractionLevel} />
											{row.duplicated && (
												<Tooltip title={t("duplicated_order")} arrow>
													<Chip
														label="D"
														size="small"
														style={{
															backgroundColor: "#31bbd0",
															color: "white",
														}}
													/>
												</Tooltip>
											)}
										</Grid>
									) : (
										<Grid item xs={1} />
									)}
								</Grid>
							</ButtonBase>
						</CardContent>
						<CardActions>
							{settings && (
								<>
									<Grid item>
										<SettingsDisplay value={settings.ORDER_RELAUNCH_EXTRACTION}>
											<Tooltip placement="top" title={t("tooltip.action.relaunch_extraction")}>
												<IconButton
													aria-label="relaunch"
													disabled={!row.canBeRelaunched}
													onClick={(e) => {
														e.stopPropagation();
														setCurrentId(row.id);
														setConfirmObject({ ...confirmObject, openConfirm: true });
													}}
												>
													<LaunchIcon />
												</IconButton>
											</Tooltip>
										</SettingsDisplay>
									</Grid>
									<Grid item>
										<SettingsDisplay value={settings.ORDER_EXPORT_IDOC}>
											<Tooltip placement="top" title={t("tooltip.action.export_idoc")}>
												<IconButton
													aria-label="exportIdoc"
													onClick={(e) => {
														e.stopPropagation();
														handleExportIdoc(row.id);
													}}
												>
													<LoadingButton
														isLoading={
															isLoading?.idoc?.id.includes(row.id) && isLoading?.idoc?.turning
														}
													>
														<ExportIdocIcon style={{ width: "24px", height: "24px" }} />
													</LoadingButton>
												</IconButton>
											</Tooltip>
										</SettingsDisplay>
									</Grid>
									<Grid item>
										<SettingsDisplay value={settings.ORDER_EXPORT_EDI}>
											<Tooltip placement="top" title={t("tooltip.action.export_edifact")}>
												<IconButton
													aria-label="exportEdifact"
													onClick={(e) => {
														e.stopPropagation();
														handleExportEdi(row.id);
													}}
												>
													<LoadingButton
														isLoading={
															isLoading?.edi?.id.includes(row.id) && isLoading?.edi?.turning
														}
													>
														<ExportEdiIcon style={{ width: "24px", height: "24px" }} />
													</LoadingButton>
												</IconButton>
											</Tooltip>
										</SettingsDisplay>
									</Grid>
									<Grid item>
										<SettingsDisplay value={settings.ORDER_EXPORT_XML}>
											<Tooltip placement="top" title={t("tooltip.action.export_xml")}>
												<IconButton
													aria-label="exportXML"
													onClick={(e) => {
														e.stopPropagation();
														handleExportXml(row.id);
													}}
												>
													<LoadingButton
														isLoading={
															isLoading?.xml?.id.includes(row.id) && isLoading?.xml?.turning
														}
													>
														<ImportExportIcon />
													</LoadingButton>
												</IconButton>
											</Tooltip>
										</SettingsDisplay>
									</Grid>
									<Grid item>
										<SettingsDisplay value={settings.ORDER_EXPORT_PDF}>
											<Tooltip placement="top" title={t("tooltip.action.download")}>
												<IconButton
													aria-label="download"
													onClick={(e) => {
														e.stopPropagation();
														handleExportOrder(row.id);
													}}
												>
													<LoadingButton
														isLoading={
															isLoading?.pdf?.id.includes(row.id) && isLoading?.pdf?.turning
														}
													>
														<GetAppIcon />
													</LoadingButton>
												</IconButton>
											</Tooltip>
										</SettingsDisplay>
									</Grid>
									<Grid item>
										<SettingsDisplay value={settings.ORDER_EXPORT_EXCEL}>
											<Tooltip placement="top" title={t("tooltip.action.export_excel")}>
												<IconButton
													aria-label="download"
													disabled={get(row, "status.code") === "INTEGRATION"}
													onClick={(e) => {
														e.stopPropagation();
														handleExportExcel(row.id);
													}}
												>
													<LoadingButton
														isLoading={
															isLoading?.excel?.id.includes(row.id) && isLoading?.excel?.turning
														}
													>
														<DescriptionIcon />
													</LoadingButton>
												</IconButton>
											</Tooltip>
										</SettingsDisplay>
									</Grid>
									<Grid item>
										<SettingsDisplay value={settings.ORDER_DELETE_ACTION}>
											<Tooltip placement="top" title={t("tooltip.action.delete")}>
												<IconButton
													aria-label="delete"
													disabled={!(row.status && row.status.code === "TO_VERIFY")}
													onClick={(e) => {
														e.stopPropagation();
														setOpenDeletePopup({ ...deleteConfirmObject, openConfirm: true });
														setSelectedId(row.id);
													}}
												>
													<DeleteIcon />
												</IconButton>
											</Tooltip>
										</SettingsDisplay>
									</Grid>
								</>
							)}
							{!isDashboard && (
								<Grid item>
									<Checkbox
										value={parseInt(row.id, 10)}
										checked={selected.includes(parseInt(row.id, 10))}
										onClick={(e) => {
											e.stopPropagation();
										}}
										onChange={(e) => {
											const list = [...selected];
											// eslint-disable-next-line
											e.target.checked
												? list.push(parseInt(e.target.value, 10))
												: list.splice(list.indexOf(parseInt(e.target.value, 10)), 1);
											setSelected(list);
										}}
									/>
								</Grid>
							)}
						</CardActions>
					</Card>
				))}
			<ConfirmDialog
				message={confirmObject.message}
				open={confirmObject.openConfirm}
				isLoading={confirmObject.isLoading}
				onConfirm={handleRelaunch}
				onCancel={onCancelRelaunch}
			/>
		</>
	);
};
