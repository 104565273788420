export default {
	countSuppliers: "إجمالي عدد الموردين",
	searchSupplier: "العثور على المورد",
	name: "اسم",
	contact: "جهة الاتصال",
	principalContact: "جهة الاتصال الرئيسية",
	outstandingInvoices: "الفواتير المستحقة",
	addSupplier: "إضافة مورد",
	"": "",
	associatedContact: "جهات الاتصال المرتبطة",
	financialInformation: "معلومات مالية",
	return: "عودة",
	account_journal_auxiliary: "مساعد",
	account_journal_number: "حساب محاسبة",
	invoice_uploaded_success:
		"تمت إضافة الفاتورة بنجاح. سيظهر في القائمة إذا كنت مُصدر هذه الفاتورة.",
	"limited.liability.company": "SARL",
	"single.person.limited.liability": "EURL",
	"public.limited.company": "SA",
	"general.partnership": "SNC",
	"simplified.joint.stock.company": "SAS",
	"single.person.simplified.joint.stock.company": "SASU",
	other: "آخر",
	companySiret: "رقم SIRET",
	vatNumber: "ظريبه الشراء",
	legalForm: "استمارة قانونية",
	codeNaf: "كود NAF",
	email: "بريد الالكتروني",
	address: "العنوان",
	fax: "فاكس",
	socialCapital: "رأس المال",
	function: "موقع",
	phone: "هاتف",
	bank: "مصرف",
	iban: "IBAN",
	bic: "BIC",
	rib: "RIB",
	noPrincipalContact: "لا يوجد جهة اتصال رئيسية متاحة",
	details: "تفاصيل",
	"upload.instructions": "تعليمات",
	"upload.instructions1": "املأ ورقة Excel بمعلومات الموردين",
	"upload.instructions2": 'املأ العمود "Logo" باسم ملف الشعار. (مثال: logofilename.jpg)',
	"upload.instructions3": "تأكد من أن جميع الحقول من النوع TEXT",
	"upload.instructions4": "ضع ورقة Excel المعبأة وشعارات الموردين في نفس المجلد",
	"upload.instructions5": "ضغط هذا المجلد:",
	"upload.instructions51":
		"نظام التشغيل Windows: انقر بزر الماوس الأيمن فوق المجلد ثم اختر إرسال إلى ثم انقر فوق المجلد المضغوط",
	"upload.instructions52":
		"MAC: انقر عليه مع الضغط على مفتاح التحكم أو اضغط عليه بإصبعين ، ثم اختر ضغط من قائمة الاختصارات.",
	"upload.instructions6":
		"قم بتحميل المجلد المضغوط إلى البوابة الإلكترونية أو اسحبه وأفلته في المنطقة المخصصة",
	"upload.instructions7": "انقر فوق تحميل الموردين",
	"supplier.modify": "تحديث المورد ",
	"invoice.attachment.label": "الصياغة",
	"invoice.attachment.extension": "نوع الملف",
	"invoice.attachment.type": "نوع الوثيقة",
	"invoice.attachment.creationDate": "تاريخ الإضافة",
	"invoice.attachment.size": "الحجم",
	field_required: "مطلوب",
	"add attach": "إضافة مرفق",
	"attachment.upload": "أضف الملف",
	"attachment.upload.success": "تم تحميل الملف بنجاح",
	"attachment.upload.error": "فشل تحميل الملف",
	"Add your Attachment": "أضف المرفق الخاص بك",
	"invoice.attachment.delete.confirm": "هل أنت متأكد من أنك تريد حذف هذا المرفق؟",
	"attachment.delete.success": "تم حذف المرفق بنجاح",
	"workflow.invoice.exists": "حذف حالة الفاتورة",
	"workflow.invoice.exists.message":
		"لا يمكن حذف حالة الفاتورة.فاتورة واحدة أو أكثر في هذه الحالة.",
	"workflow.changeInvoicesStatus": "تغيير الفواتير الحالة",
	"workflow.updateStatus.warning":
		"هل أنت متأكد من أنك تريد تحديث حالة الفواتير هذه؟ سيوفر هذا الإجراء أيضا سير العمل ولا يمكن التراجع عنه.",
	"supplier.lockedTraining": "مقفل؟",
	"workflow.load.error": "خطأ أثناء جلب سير العمل",
	"workflow.toolTip.addStatus": "إضافة الحالة",
	"workflow.toolTip.save": "يحفظ",
	"workflow.toolTip.clone": "استنساخ",
	"workflow.toolTip.undoCHanges": "إلغاء التغييرات",
	"workflow.clone.newName": "سير العمل الجديد",
	"workflow.clone.code": "اسم",
	"workflow.clone.description": "وصف",
	"workflow.clone.save": "يحفظ",
	"workflow.clone.success": "سير العمل المستنسخة بنجاح!",
	"workflow.clone.error": "حدث خطأ أثناء استنساخ سير العمل",
	searchClient: "ابحث عن عميل",
	"invoice.transition.reintegrate": "إعادة دمج الفاتورة",
};
