import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import GroupIcon from "@material-ui/icons/Group";
import { useTranslation } from "react-i18next";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { toast } from "utils";
import {
	fetchClient,
	checkClientCode,
	createClient,
	modifyClient,
} from "../../../redux/actions/clients";
import Financial from "../../supplier/components/Financial";
import Contact from "../../supplier/components/Contact";
import GeneralInformation from "../../supplier/components/GeneralInformation";
import useStyles from "./style";
import ConfirmDialog from "../../../components/ConfirmDialog";

const AddClient = () => {
	const { t } = useTranslation();
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const { idClient } = useParams();
	const getSteps = () => [t("client.add.step1"), t("client.add.step2"), t("client.add.step3")];
	const steps = getSteps();
	const [activeStep, setActiveStep] = useState(0);
	const [client, setClient] = React.useState({ contacts: [] });
	const [contact, setContact] = React.useState({});
	const [logo, setLogo] = React.useState(null);
	const { errors, handleSubmit, register } = useForm();
	const [isEdit, setIsEdit] = React.useState(false);
	const [confirmObject, setConfirmObject] = React.useState({
		message: "",
		openConfirm: false,
		cancel: false,
		isLoading: false,
	});

	const clientToUpdate = useSelector(({ requests }) => get(requests.queries, "FETCH_CLIENT.data"));

	const updateClient = (e) => {
		setClient({ ...client, [e.target.name]: e.target.value });
	};

	const updateClientContact = (e) => {
		setContact({ ...contact, [e.target.name]: e.target.value });
	};

	const handleBack = () => {
		if (activeStep === 0) history.goBack();
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleReset = () => {
		setActiveStep(0);
	};

	const onSubmit = () => {
		if (!idClient) {
			// check client's name in the first step of the form
			dispatch(
				checkClientCode(
					client,
					(rep) => {
						if (!rep.data) {
							// true if the name exists already
							setActiveStep((prevActiveStep) => prevActiveStep + 1);
							if (activeStep === 2) {
								setConfirmObject({ message: t("supplier.add.confirm"), openConfirm: true });
							}
						} else {
							toast.error(t("client.name.exist.already"));
						}
					},
					() => {
						// bad request
						toast.error(t("client.error"));
					}
				)
			);
		} else {
			setActiveStep((prevActiveStep) => prevActiveStep + 1);
			if (activeStep === 2) {
				setConfirmObject({ message: t("client.add.confirm"), openConfirm: true, isLoading: false });
			}
		}
	};

	const getStepContent = (stepIndex) => {
		switch (stepIndex) {
			case 0:
				return (
					<GeneralInformation
						register={register}
						errors={errors}
						setSupplier={setClient}
						updateSupplier={updateClient}
						supplier={client}
						edit={isEdit}
						setLogo={setLogo}
						isClient
					/>
				);
			case 1:
				return (
					<Contact
						register={register}
						errors={errors}
						updateSupplier={updateClient}
						supplier={client}
						contact={contact}
						updateSupplierContact={updateClientContact}
					/>
				);
			case 2:
				return (
					<Financial
						register={register}
						errors={errors}
						updateSupplier={updateClient}
						supplier={client}
					/>
				);
			default:
				return "Unknown stepIndex";
		}
	};

	useEffect(() => {
		if (idClient) dispatch(fetchClient(idClient));
	}, []);

	useEffect(() => {
		if (idClient && clientToUpdate && Number(idClient) === clientToUpdate.id) {
			setIsEdit(true);
			setClient(clientToUpdate);
			if (clientToUpdate.contacts && clientToUpdate.contacts.length > 0) {
				setContact(clientToUpdate.contacts[0]);
			}
		}
	}, [clientToUpdate]);

	const onCancel = () => {
		setConfirmObject({ message: "", openConfirm: false, isLoading: false });
		setActiveStep(activeStep - 1);
	};

	const onConfirm = () => {
		client.countryCode = client.countryCode || "FR";
		client.currency = client.currency || "EUR";
		if (!isEmpty(contact)) {
			if (idClient) {
				client.contacts[0] = contact;
			} else {
				client?.contacts.push(contact);
			}
		}
		setClient({ ...client });
		setConfirmObject({ ...confirmObject, isLoading: true });
		if (idClient) {
			dispatch(modifyClient(client, logo)).then((res) => {
				if (res.action.response?.status) {
					setConfirmObject({ message: "", openConfirm: false, isLoading: false });
					toast.success(t("client.updated"));
					history.push(`/client`);
				} else {
					onCancel();
					toast.error(t("clien.error"));
				}
			});
		} else {
			dispatch(createClient(client, logo)).then((res) => {
				if (res.action.response?.status) {
					setConfirmObject({ message: "", openConfirm: false, isLoading: false });
					toast.success(t("client.created"));
					history.push(`/client`);
				} else {
					onCancel();
					toast.error(t("client.error"));
				}
			});
		}
	};

	return (
		<Grid container xs={12} justify="center" alignContent="center" alignItems="center">
			<Grid container item xs={12}>
				<Typography variant="caption" color="primary" className={classes.headerBreadCumb}>
					<GroupIcon fontSize="small" className={classes.headerPath} />
					{t("manageUsers")} {" > "} {idClient ? t("client.modify") : t("client.add2.button")}
				</Typography>
			</Grid>
			<Grid container xs={12} justify="center">
				<div className={classes.root}>
					<Stepper activeStep={activeStep} alternativeLabel>
						{steps.map((label) => (
							<Step key={label}>
								<StepLabel>{label}</StepLabel>
							</Step>
						))}
					</Stepper>
					<div>
						{activeStep === steps.length ? (
							<div>
								<Typography className={classes.instructions}>All steps completed</Typography>
								<Button onClick={handleReset}>Reset</Button>
							</div>
						) : (
							<div>
								<form onSubmit={handleSubmit(onSubmit)}>
									<Grid
										container
										item
										xs={12}
										justify="center"
										className={classes.formContainer}
										style={{ padding: "20px" }}
									>
										{getStepContent(activeStep)}
									</Grid>
									<Grid container justify="center" style={{ marginTop: "50px" }}>
										<Button variant="outlined" onClick={handleBack} className={classes.backButton}>
											{activeStep === 0 ? t("cancel") : t("client.add.previous")}
										</Button>
										<Button variant="contained" color="secondary" type="submit">
											{activeStep === steps.length - 1
												? t("client.add.finish")
												: t("client.add.next")}
										</Button>
									</Grid>
								</form>
							</div>
						)}
					</div>
				</div>
			</Grid>
			<ConfirmDialog
				message={confirmObject.message}
				open={confirmObject.openConfirm}
				isLoading={confirmObject.isLoading}
				onConfirm={onConfirm}
				onCancel={onCancel}
			/>
		</Grid>
	);
};

export default AddClient;
