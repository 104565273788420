import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import {
	Button,
	Checkbox,
	FormControl,
	Input,
	InputLabel,
	ListItemText,
	MenuItem,
	Select,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
// import isEmpty from "lodash/isEmpty";
import { fetchUsers } from "../redux/actions/index";
import { updateFilter } from "../redux/reducers/UserFilterReducer";
import { SearchFilter, useQuery } from "../../components/Filter";
import useStyles from "../style";

const statusList = [
	{ code: "ACTIVE", label: "ACTIVE" },
	{ code: "DISABLE", label: "DISABLE" },
];

function UserFilter({ sortByList, typeUser, module }) {
	const filterUsers = {
		userName: "",
		status: [],
		pageNo: 0,
		pageSize: 10,
		sortBy: "",
		typeUser: null,
	};

	const classes = useStyles();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const query = useQuery();
	const [status, setStatus] = React.useState([]);
	const [, setSortBy] = React.useState("");

	const handleChangeStatus = (event) => {
		const list = statusList.filter((s) => event.target.value.includes(s.label));
		dispatch(updateFilter({ ...filterUsers, status: list, pageNo: 0, typeUser }));
		setStatus(event.target.value);
		dispatch(fetchUsers({ ...filterUsers, status: list, pageNo: 0, typeUser }));
	};
	const handleChangeSortBy = (event) => {
		setSortBy(event.target.value);
		dispatch(updateFilter({ ...filterUsers, pageNo: 0, sortBy: event.target.value, typeUser }));
		dispatch(
			fetchUsers({
				...filterUsers,
				pageNo: 0,
				sortBy: event.target.value,
				typeUser,
			})
		);
	};

	const handleFilterValueChange = () => {
		dispatch(
			updateFilter({
				...filterUsers,
				userName: query.get("q"),
				pageNo: 0,
				typeUser,
			})
		);
		dispatch(
			fetchUsers({
				...filterUsers,
				userName: query.get("q"),
				pageNo: 0,
				typeUser,
			})
		);
	};

	useEffect(() => {
		setTimeout(() => {
			handleFilterValueChange();
		}, 0);
	}, [query.get("q")]);

	return (
		<Grid container xs={12} justify="space-between">
			<Grid container item xs={2}>
				<SearchFilter
					callback={handleFilterValueChange}
					placeHolder={t("searchUser")}
					style={classes.searchIcon}
					value={query.get("q")}
				/>
			</Grid>
			<Grid container item xs={2}>
				<FormControl fullWidth>
					<InputLabel id="status">{t("status")}</InputLabel>
					<Select
						labelId="sytatus"
						id="status"
						multiple
						value={status}
						onChange={handleChangeStatus}
						input={<Input />}
						renderValue={(selected) => selected.map((s) => t(s)).join(", ")}
					>
						{statusList &&
							statusList.map((row, i) => (
								<MenuItem key={i} value={row.label}>
									<Checkbox checked={status.indexOf(row.label) > -1} />
									<ListItemText primary={t(row.label)} />
								</MenuItem>
							))}
					</Select>
				</FormControl>
			</Grid>
			<Grid container item xs={2}>
				<FormControl fullWidth>
					<InputLabel id="sortBy">{t("sortBy")}</InputLabel>
					<Select
						labelId="sortBy"
						id="sortById"
						onChange={handleChangeSortBy}
						input={<Input />}
						renderValue={(selected) => t(selected)}
					>
						<MenuItem value="">
							<ListItemText primary="" />
						</MenuItem>
						{sortByList &&
							sortByList.map((row, i) => (
								<MenuItem key={i} value={row}>
									<ListItemText primary={t(row)} />
								</MenuItem>
							))}
					</Select>
				</FormControl>
			</Grid>
			<Grid container item xs={2}>
				<Button
					type="submit"
					fullWidth
					color="secondary"
					variant="contained"
					onClick={() => {
						history.push(`/administration/${module}/add`);
					}}
				>
					{typeUser === "USER"
						? t("add_user_button")
						: typeUser === "SUPPLIER"
						? t("add_supplier_button")
						: typeUser === "GROUPS"
						? t("add_group_button")
						: t("add_client_button")}
				</Button>
			</Grid>
		</Grid>
	);
}

export default UserFilter;
