import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import { Divider } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import Badge from "@material-ui/core/Badge";
import get from "lodash/get";
import { useHistory } from "react-router";
import { currencyFormat } from "utils";
import { getApi } from "redux/store";
import useStyles from "./style";
import { fetchCountOrders } from "../../redux/actions/orders";
import {
	fetchClient,
	ordersTotalAmountsByClient,
	ordersVATAmountsByClient,
	fetchCountClients,
	ordersTotalAmountsOverDueByClient,
	fetchOrdersByClient,
} from "../../redux/actions/clients";
import CircularLoader from "../../components/Loaders/CircularLoader";
import PaidOrder from "./components/PaidOrders";
import UnpaidOrder from "./components/UnpaidOrders";
import UserProfile from "../../App/user-profile.png";
import SubPageContainer from "../../components/SubPageContainer";
import SubPagePanel from "../../components/SubPagePanel";
import { DetailsLegalInformation } from "./components/DetailsLegalInformation";
import { DetailsClientContact } from "./components/DetailsClientContact";
import { DetailsFinancialInformations } from "./components/DetailsFinancialInformations";
import OrdersTable from "./components/OrdersTable";


function a11yProps(index) {
	return {
		id: `client-detail-tab-${index}`,
		"aria-controls": `client-detail-tabpanel-${index}`,
	};
}

function ClientDetails(props) {
	const dispatch = useDispatch();
	const classes = useStyles();
	const { t } = useTranslation();
	const history = useHistory();
	const [value, setValue] = React.useState(0);
	const [valueI, setValueI] = React.useState(0);
	const client = useSelector(({ requests }) => get(requests.queries, "FETCH_CLIENT.data"));

	const clientOrders = useSelector(({ requests }) =>
		get(requests.queries, "FETCH_ORDERS_BY_CLIENT.data")
	);

	const totalOrdersAmount = useSelector(({ requests }) =>
		get(requests.queries, "ORDER_TOTAL_AMOUNT_BY_CLIENT.data")
	);
	const totalOrdersAmountPayed = useSelector(({ requests }) =>
		get(requests.queries, "ORDER_TOTAL_AMOUNT_BY_CLIENT_EXPORTED.data")
	);

	const totalOrdersAmountUnpaid = useSelector(({ requests }) =>
		get(requests.queries, "ORDER_TOTAL_AMOUNT_BY_CLIENT_UNPAID.data")
	);
	const totalVatAmount = useSelector(({ requests }) =>
		get(requests.queries, "ORDER_VAT_AMOUNT_BY_CLIENT_EXPORTED.data")
	);

	const currentUser = useSelector(({ requests }) =>
		get(requests, "queries.FETCH_CURRENT_USER.data")
	);

	const totalAmountsOverDueByClient = useSelector(
		({ requests }) => requests.queries.ORDER_TOTAL_OVERDUE_AMOUNT_BY_CLIENT?.data
	);

	// const [filesNotPersisted, setFilesNotPersisted] = React.useState([]);

	// eslint-disable-next-line no-shadow
	// const filterOrders = useSelector(({ filterOrders }) => filterOrders);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const handleChangeInformtionTab = (event, newValue) => {
		setValueI(newValue);
	};

	useEffect(() => {
		dispatch(fetchClient(props.match.params.idClient));
		dispatch(fetchCountClients());
		dispatch(fetchCountOrders());
		dispatch(ordersTotalAmountsOverDueByClient(props.match.params.idClient));
		dispatch(fetchOrdersByClient(props.match.params.idClient));
		dispatch(ordersTotalAmountsByClient(props.match.params.idClient));
		dispatch(ordersTotalAmountsByClient(props.match.params.idClient, "EXPORTED"));
		dispatch(ordersTotalAmountsByClient(props.match.params.idClient, "UNPAID"));
		dispatch(ordersVATAmountsByClient(props.match.params.idClient, "EXPORTED"));
		// dispatch(fetchDeliveryItemsByClient(props.match.params.idClient));
	}, [dispatch, props.match.params.idClient]);

	const onClickRow = (idOrder) => {
		history.push(`/orders/details/${idOrder}`);
	};

	return (
		<>
			{client ? (
				<Grid container xs={12} className={classes.rootContainer}>
					<Grid container item xs={8}>
						<Card style={{ width: "100%" }}>
							<Grid item container xs={12}>
								<Grid
									container
									item
									className={classes.clientItem}
									direction="column"
									spacing={4}
									justify="center"
									alignItems="center"
									xs={3}
								>
									<Grid item container justify="center" alignItems="center">
										<Badge
											overlap="circle"
											anchorOrigin={{
												vertical: "bottom",
												horizontal: "right",
											}}
											classes={{ badge: classes.customBadge }}
										>
											<Avatar
												alt="Remy Sharp"
												src={
													client && client.logo
														? `${getApi()}/order${client.logo}`
														: UserProfile
												}
												className={classes.large}
											/>
										</Badge>
									</Grid>

									<Grid item>
										<Typography variant="h6" color="primary">
											{get(client, "label")}
										</Typography>
									</Grid>
								</Grid>
								<Grid container item xs={1}>
									<Divider orientation="vertical" flexItem />
								</Grid>
								<Grid container item xs={8}>
									<Paper position="static" className={classes.appBar} style={{ width: "100%" }}>
										<Tabs
											value={valueI}
											onChange={handleChangeInformtionTab}
											aria-label="Assets"
											className={classes.tabs}
										>
											<Tab
												label={t("details")}
												{...a11yProps(0)} // eslint-disable-line
												classes={{ selected: classes.selectedTab }}
											/>
											<Tab
												label={t("associatedContact")}
												{...a11yProps(1)} // eslint-disable-line
												classes={{ selected: classes.selectedTab }}
											/>
											<Tab
												label={t("financialInformation")}
												{...a11yProps(2)} // eslint-disable-line
												classes={{ selected: classes.selectedTab }}
											/>
										</Tabs>
									</Paper>
									<SubPagePanel value={valueI} index={0} className={classes.fullwidth}>
										<DetailsLegalInformation client={client} />
									</SubPagePanel>
									<SubPagePanel value={valueI} index={1} className={classes.fullwidth}>
										<DetailsClientContact
											contact={client && client.contacts && client.contacts[0]}
										/>
									</SubPagePanel>
									<SubPagePanel value={valueI} index={2} className={classes.fullwidth}>
										<DetailsFinancialInformations client={client} />
									</SubPagePanel>
								</Grid>
							</Grid>
						</Card>
					</Grid>

					<Grid continer item direction="column" xs={4}>
						<Grid item xs={12}>
							<PaidOrder
								elevation={26}
								square
								totalVatAmount={totalVatAmount}
								totalPaidAmount={totalOrdersAmountPayed}
								style={{ background: "#f8f9fa" }}
								currentUser={currentUser}
							/>
						</Grid>
						<Grid item xs={12}>
							<UnpaidOrder
								elevation={26}
								square
								unpaidOrdersAmount={totalOrdersAmountUnpaid}
								style={{ background: "#f8f9fa" }}
								overdue={totalAmountsOverDueByClient}
								currentUser={currentUser}
							/>
						</Grid>
					</Grid>
				</Grid>
			) : (
				<CircularLoader />
			)}
			<Grid container xs={12} className={classes.ordersDetails} spacing="6">
				<Grid container direction="column" item xs={6}>
					<Grid item>
						<Typography variant="h6" color="secondary">
							{t("Total amount of orders")} :
							{totalOrdersAmount >= 0 ? (
								`${currencyFormat(totalOrdersAmount)} €`
							) : (
								<CircularLoader />
							)}
						</Typography>
					</Grid>
					<Grid item>
						<Typography variant="subtitle2" color="textSecondary">
							{t("countOrders")} :{clientOrders ? get(clientOrders, "length") : 0}
						</Typography>
					</Grid>
				</Grid>
			</Grid>

			<Grid container spacing={4} className={classes.ordersTable}>
				<Grid item xs={12} sm={12}>
					<SubPageContainer>
						<Grid
							spacing={2}
							container
							item
							xs={12}
							className={classes.headerZone}
							justify="center"
							alignItems="stretch"
						>
							<Grid container item xs={2} />
						</Grid>

						<Paper position="static" className={classes.appBar}>
							<Tabs
								value={value}
								onChange={handleChange}
								aria-label="Assets"
								className={classes.tabs}
							>
								<Tab
									label={t("Orders")}
									{...a11yProps(0)} // eslint-disable-line
									classes={{ selected: classes.selectedTab }}
								/>
								<Tab
									label={t("delivery")}
									{...a11yProps(1)} // eslint-disable-line
									classes={{ selected: classes.selectedTab }}
								/>
							</Tabs>
						</Paper>
						<SubPagePanel value={value} index={0}>
							{get(clientOrders, "length") >= 0 ? (
								<OrdersTable
									isDashboard
									isClientDetails
									clientOrders={clientOrders}
									totalOrdersAmount={totalOrdersAmount}
									onClickOrder={onClickRow}
									idClient={props.match.params.idClient}
								/>
							) : (
								<CircularLoader />
							)}
						</SubPagePanel>
						<SubPagePanel value={value} index={2} />
					</SubPageContainer>
				</Grid>
			</Grid>
		</>
	);
}

export default ClientDetails;
