import React, { useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@material-ui/icons/Delete";
import GetAppIcon from "@material-ui/icons/GetApp";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import DescriptionIcon from "@material-ui/icons/Description";
import Checkbox from "@material-ui/core/Checkbox";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import LaunchIcon from "@material-ui/icons/Launch";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import { toast } from "utils";
import ConfirmDialog from "components/ConfirmDialog";
import LoadingButton from "components/LoadingButton";

import { SearchFilter, useQuery } from "../../../../components/Filter";

import useStyles from "../../style";

import { invoicesTotalAmounts, relaunchInvoices } from "../../../../redux/actions/invoices";
import {
	deleteOrders,
	fetchOrders,
	fetchListStatus,
	downloadOrdersXML,
	downloadOrdersExcel,
	downloadOrders,
	ordersTotalAmounts,
	fetchCountOrders,
} from "../../../../redux/actions/orders";
import { initFilterOrder, updateFilterOrder } from "../../../../redux/orderFilterReducer";

const columns = ["orderNo", "issueDate", "updatedAt", "client"];
const INVALID_STATUS = ["TO_PAY", "PAYED", "CANCELED", "PROCESSING"];

function Filter(props) {
	const dispatch = useDispatch();
	const filterOrders = useSelector(({ filterOrders: i }) => i);
	const idOrders = useSelector(
		({ requests }) =>
			requests.queries.FETCH_ORDERS &&
			requests.queries.FETCH_ORDERS.data &&
			requests.queries.FETCH_ORDERS.data.content
	);

	const classes = useStyles();
	const { t } = useTranslation();
	const query = useQuery();

	const [status, setStatus] = React.useState([]);
	const [sortBy, setSortBy] = React.useState("");
	const [checkedAll, setCheckedAll] = React.useState(false);
	const [checkedindeterminate, setCheckedindeterminate] = React.useState(false);
	const [selectAll, setSelectAll] = React.useState(false);
	const [isLoading, setIsLoading] = React.useState({
		pdf: false,
		xml: false,
		excel: false,
		ec: false,
	});

	const [confirmObject, setConfirmObject] = React.useState({
		message: t("deleteMessage"),
		openConfirm: false,
		cancel: false,
		isLoading: false,
	});

	const onFilterChange = (newFilter) => {
		const filter = { ...filterOrders, ...newFilter, pageNo: 0 };
		dispatch(updateFilterOrder(filter));
		props.fetchOrders(filter);
	};

	const handleChangeStatus = (event) => {
		const list = props.statusList.filter((s) => event.target.value.includes(s.label));
		setStatus(event.target.value);
		onFilterChange({ status: list });
	};

	const handleChangeSortBy = (event) => {
		setSortBy(event.target.value);
		onFilterChange({ sortBy: event.target.value });
	};

	useEffect(() => {
		setCheckedAll(
			idOrders &&
				props.selected &&
				props.selected.length === idOrders.length &&
				idOrders.length !== 0
		);
		setCheckedindeterminate(
			idOrders &&
				props.selected &&
				!isEmpty(props.selected) &&
				props.selected.length < idOrders.length
		);
	}, [props.selected]);

	useEffect(() => {
		props.fetchListStatus();

		setStatus(filterOrders.status.map((s) => s.label));

		setSortBy(filterOrders.sortBy);
		const interval = setInterval(() => {
			props.fetchOrders(filterOrders);
			dispatch(ordersTotalAmounts([]));
			dispatch(fetchCountOrders());
		}, 20000);
		return () => clearInterval(interval);
	}, [props.orders]);

	const handleCloseDeletePopup = () => {
		setConfirmObject({ ...confirmObject, openConfirm: false });
	};

	const selectAllItems = () => {
		setSelectAll(true);
		dispatch(updateFilterOrder({ ...filterOrders, selectAll: true }));
	};

	const getDataFromSettings = (data, code) => data.find((e) => e.code === code);
	const toDelete = () => {
		setConfirmObject({ ...confirmObject, isLoading: true });
		dispatch(
			deleteOrders(
				props.selected,
				getDataFromSettings(props.settings, "ORDER_DELETE_All_ACTION")?.value === "1",
				handleCloseDeletePopup()
			)
		).then((response) => {
			if (!isEmpty(response.payload)) {
				toast.error(`${t("OrdersCannotBeDeleted")} : ${response.payload.join(", ")}`);
			}
			dispatch(initFilterOrder());
			setStatus([]);
			dispatch(invoicesTotalAmounts([]));
			props.setSelected([]);
			setConfirmObject({ ...confirmObject, openConfirm: false, isLoading: false });
		});
	};

	const handleFilterValueChange = () => {
		onFilterChange({ orderNoOrClientLabel: query.get("q") });
	};

	useEffect(() => {
		handleFilterValueChange();
	}, [query.get("q")]);

	return (
		<>
			<ConfirmDialog
				open={confirmObject.openConfirm}
				message={confirmObject.message}
				onCancel={handleCloseDeletePopup}
				onConfirm={toDelete}
				isLoading={confirmObject.isLoading}
			/>
			{/* <Dialog
				open={openDeletePopup}
				onClose={handleCloseDeletePopup}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{t("orderDeleteMessageMultiple")}</DialogTitle>

				<DialogActions>
					<Button onClick={handleCloseDeletePopup} color="secondary" autoFocus>
						{t("cancel")}
					</Button>
					<Button onClick={toDelete} type="submit" color="secondary">
						{t("confirm")}
					</Button>
				</DialogActions>
			</Dialog> */}
			<Grid container spacing={1} xs={12}>
				<Grid item xs={3}>
					<SearchFilter
						callback={handleFilterValueChange}
						placeHolder={t("searchOrders")}
						style={classes.searchIcon}
						value={query.get("q")}
					/>
				</Grid>

				<Grid item container xs={3} justify="center">
					<FormControl fullWidth>
						<InputLabel id="status">{t("Status")}</InputLabel>
						<Select
							labelId="status"
							id="statusId"
							multiple
							value={status}
							onChange={handleChangeStatus}
							input={<Input />}
							renderValue={(selected) => selected.map((s) => t(s)).join(", ")}
						>
							{props.statusList
								.filter(({ code }) => !INVALID_STATUS.includes(code))
								.map((row) => (
									<MenuItem value={row.label}>
										<Checkbox checked={status.indexOf(row.label) > -1} />
										<ListItemText primary={t(row.label)} />
									</MenuItem>
								))}
						</Select>
					</FormControl>
				</Grid>
				<Grid item container xs={3} justify="center">
					<FormControl fullWidth>
						<InputLabel id="sortBy">{t("sortBy")}</InputLabel>
						<Select
							labelId="sortBy"
							id="sortById"
							value={sortBy}
							input={<Input />}
							onChange={handleChangeSortBy}
							renderValue={(selected) => t(selected)}
						>
							<MenuItem value="">
								<ListItemText primary="" />
							</MenuItem>
							{columns.map((row, i) => (
								<MenuItem key={i} value={row}>
									<ListItemText primary={t(row)} />
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
				<Grid
					item
					container
					xs={3}
					style={{ marginLeft: "-12px" }}
					alignItems="flex-end"
					justify="flex-end"
					className="pr-0"
				>
					<Grid item>
						{props.settings &&
							getDataFromSettings(props.settings, "ORDER_RELAUNCH_EXTRACTION")?.value === "1" &&
							!isEmpty(props.selected) && (
								<Tooltip placement="top" title={t("tooltip.action.relaunch_extraction")}>
									<IconButton
										aria-label="relaunch"
										disabled={isEmpty(props.selected)}
										onClick={() => {
											dispatch(relaunchInvoices(props.selected)).then((response) => {
												if (!isEmpty(response.data)) {
													toast.error(
														`${t("OrdersCannotBeRelaunched")} : ${response.data.join(", ")}`
													);
												}
												dispatch(fetchOrders());
												props.setSelected([]);
											});
										}}
									>
										<LaunchIcon />
									</IconButton>
								</Tooltip>
							)}
					</Grid>
					<Grid item>
						{props.settings &&
							getDataFromSettings(props.settings, "ORDER_EXPORT_XML")?.value === "1" &&
							!isEmpty(props.selected) && (
								<Tooltip placement="top" title={t("tooltip.action.export_xml")}>
									<IconButton
										aria-label="exportXML"
										disabled={isEmpty(props.selected)}
										onClick={() => {
											setIsLoading({ ...isLoading, xml: true });
											dispatch(downloadOrdersXML(props.selected, filterOrders)).then(() => {
												props.setSelected([]);
												setIsLoading({ ...isLoading, xml: false });
											});
										}}
									>
										<LoadingButton isLoading={isLoading.xml}>
											<ImportExportIcon />
										</LoadingButton>
									</IconButton>
								</Tooltip>
							)}
					</Grid>
					<Grid item>
						{props.settings &&
							getDataFromSettings(props.settings, "ORDER_EXPORT_PDF")?.value === "1" &&
							!isEmpty(props.selected) && (
								<Tooltip placement="top" title={t("tooltip.action.download")}>
									<IconButton
										aria-label="download"
										disabled={isEmpty(props.selected)}
										onClick={() => {
											setIsLoading({ ...isLoading, pdf: true });
											dispatch(downloadOrders(props.selected)).then(() => {
												props.setSelected([]);
												setIsLoading({ ...isLoading, pdf: false });
											});
										}}
									>
										<LoadingButton isLoading={isLoading.pdf}>
											<GetAppIcon />
										</LoadingButton>
									</IconButton>
								</Tooltip>
							)}
					</Grid>
					<Grid item>
						{props.settings &&
							getDataFromSettings(props.settings, "ORDER_EXPORT_EXCEL")?.value === "1" &&
							!isEmpty(props.selected) && (
								<Tooltip placement="top" title={t("tooltip.action.export_excel")}>
									<IconButton
										aria-label="download"
										disabled={isEmpty(props.selected)}
										onClick={() => {
											setIsLoading({ ...isLoading, excel: true });
											dispatch(downloadOrdersExcel(props.selected, filterOrders)).then(() => {
												props.setSelected([]);
												setIsLoading({ ...isLoading, excel: false });
											});
										}}
									>
										<LoadingButton isLoading={isLoading.excel}>
											<DescriptionIcon />
										</LoadingButton>
									</IconButton>
								</Tooltip>
							)}
					</Grid>
					{/* <Grid item>
						{props.settings &&
							getDataFromSettings(props.settings, "ORDER_EXPORT_EXCEL_ACCOUNTING_ENTRY")?.value ===
							"1" &&
							!isEmpty(props.selected) && (
								<Tooltip placement="top" title={t("tooltip.action.export_entry_account_excel")}>
									<IconButton
										aria-label="download"
										disabled={isEmpty(props.selected)}
										onClick={() => {
											dispatch(
												exportOrdersAccountingEntriesExcel(props.selected, filterOrders)
											).then(() => props.setSelected([]));
										}}
									>
										<AccountBalanceWalletIcon />
									</IconButton>
								</Tooltip>
							)}
					</Grid> */}
					<Grid item>
						{props.settings &&
							(getDataFromSettings(props.settings, "ORDER_DELETE_ACTION")?.value === "1" ||
								getDataFromSettings(props.settings, "ORDER_DELETE_All_ACTION")?.value === "1") &&
							!isEmpty(props.selected) && (
								<Tooltip placement="top" title={t("tooltip.action.delete")}>
									<IconButton
										aria-label="delete"
										disabled={isEmpty(props.selected)}
										onClick={() => {
											setConfirmObject({ ...confirmObject, openConfirm: true });
										}}
									>
										<DeleteIcon />
									</IconButton>
								</Tooltip>
							)}
					</Grid>
					<Grid item>
						<Checkbox
							checked={checkedAll}
							indeterminate={checkedindeterminate}
							onChange={(e) => {
								props.handleSelectedAll(e.target.checked);
								setSelectAll(false);
							}}
						/>
					</Grid>
				</Grid>
			</Grid>
			{checkedAll && (
				<Card className={classes.selectAll}>
					<CardContent>
						<Grid container direction="row" justify="center" alignItems="center">
							{selectAll ? (
								<Grid item xs>
									<Typography className={classes.title} gutterBottom align="center">
										{t("order.selected.title0")}
									</Typography>
								</Grid>
							) : (
								<>
									<Grid item xs={3}>
										<Typography
											color="textSecondary"
											align="right"
											// gutterBottom
										>
											{t("order.selected.title1")}
											{props.selected.length}

											{t("order.selected.title2")}
										</Typography>
									</Grid>
									<Grid item xs={3}>
										<Button onClick={() => selectAllItems()}>
											<Typography
												className={classes.title}
												align="center"
												justify="center"
												color="secondary"
											>
												{t("order.selected.title3")}
											</Typography>
										</Button>
									</Grid>
								</>
							)}
						</Grid>
					</CardContent>
				</Card>
			)}
		</>
	);
}

const mapStateToProps = (state) => {
	const dataQueris = state.requests.queries;
	return {
		orders: dataQueris.FETCH_ORDERS && dataQueris.FETCH_ORDERS.data,
		statusList: get(dataQueris, "LIST_FETCH_ORDER_STATUS.data.orderStatusList", []),
	};
};
export default connect(mapStateToProps, { fetchListStatus, fetchOrders })(Filter);
