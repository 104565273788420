import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import isEmpty from "lodash/isEmpty";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import Button from "@material-ui/core/Button";
import FlagIcon from "@material-ui/icons/Flag";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import AddIcon from "@material-ui/icons/Add";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import MuiAlert from "@material-ui/lab/Alert";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import { useForm } from "react-hook-form";
import WarningIcon from "@material-ui/icons/Warning";
import ErrorIcon from "@material-ui/icons/Error";
import Description from "@material-ui/icons/Description";
import clsx from "clsx";
import { Tooltip } from "@material-ui/core";
import get from "lodash/get";
import { useHistory } from "react-router";
import { toast, isSupplierOnly } from "utils";
import { fetchSettingsByModuleName } from "redux/actions/users";
import { fetchWorkflow, validateTransition } from "administration/redux/actions";
import History from "./Components/History";
import AmountsComponent from "./Components/AmountsComponent";
import DesignCanvas from "./Components/DesignCanvas";
import Erreur from "../../../Assets/img/erreur.png";
import {
	fetchInvoice,
	fetchGlAccounts,
	fetchCostCenters,
	fetchPaymentMethods,
	fetchDocumentInvoice,
	fetchPaymentTerms,
	updateInvoice,
	fetchRejectionMotifs,
	createInvoiceRejection,
	verifyInvoice,
	fetchBillingLines,
	fetchDeliveryMethods,
	rectifyInvoice,
	topayInvoice,
	createInvoiceApproval,
	exportXMLFileToServer,
	fetchCountSuppliers,
	fetchCountInvoices,
	downloadXMLInvoice,
	downloadInvoiceExcel,
	downloadInvoice,
	exportSingleAccountingEntryExcel,
	updateInvoiceStatus,
	downloadEdifactInvoice,
	downloadIdocInvoice,
	validateStatus,
} from "../../../redux/actions/invoices";
import { fetchSuppliers } from "../../../redux/actions/suppliers";
import { updateFormulaire } from "../../../redux/formulaireReducer";
import { INTEGRATION, TO_VERIFY_LIST, TREATED, REJECTED } from "../../../redux/constants";
import ApprovalDialog from "./Components/ApprovalDialog";
import Attachements from "./Components/Attachements";
import DeliveryItemsSection from "./Components/DeliveryItemsSection";
import PDFViewer from "./Components/PDFViewer";
import PDFJSBackend from "./Components/pdfjs";
import CircularLoader from "../../../components/Loaders/CircularLoader";
import useStyles from "./style";
import ConfirmDialog from "../../../components/ConfirmDialog";
import AccountingLines from "./Components/AccountingLines";
import DigitalSummary from "./Components/DigitalSummary";
import { isInternal } from "../utils";

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`scrollable-auto-tabpanel-${index}`}
			aria-labelledby={`scrollable-auto-tab-${index}`}
			{...other} // eslint-disable-line
		>
			{value === index && (
				<Grid container xs={12} style={{ margin: 0 }}>
					{children}
				</Grid>
			)}
		</div>
	);
}
TabPanel.propTypes = {
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};
function a11yProps(index) {
	return {
		id: `scrollable-auto-tab-${index}`,
		"aria-controls": `scrollable-auto-tabpanel-${index}`,
	};
}

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />; // eslint-disable-line
}

const DialogReject = ({ open, setOpen, ...props }) => {
	const { t } = useTranslation();
	const [rejectionMotifs, setRejectionMotifs] = React.useState(props.rejectionMotifs);
	const [isLoading, setIsLoading] = React.useState(false);

	useEffect(() => {
		if (!rejectionMotifs) {
			setRejectionMotifs(props.rejectionMotifs);
		}
	});

	const { errors, handleSubmit, register } = useForm();

	const handleCloseRejectPopup = () => {
		setOpen(!open);
		setIsLoading(false);
	};

	const onSubmit = (data) => {
		setIsLoading(true);
		const listMotifs = rejectionMotifs && rejectionMotifs.invoiceRejectionMotifList;
		const motif = listMotifs.find((motifParam) => t(motifParam.label) === data.motif);
		const id = props.invoicePersisted && props.invoicePersisted.id;
		props.createInvoiceRejection(
			id,
			{ invoiceId: id, motif: motif.code, comment: data.comment },
			() => {
				props.onClose(false);
				handleCloseRejectPopup();
			},
			() => {
				props.onClose(true);
				handleCloseRejectPopup();
			}
		);
	};

	return (
		<Dialog
			open={open}
			onClose={handleCloseRejectPopup}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">{t("rejectMessage")}</DialogTitle>
			<form onSubmit={handleSubmit(onSubmit)}>
				<DialogContent>
					<Autocomplete
						options={rejectionMotifs && rejectionMotifs.invoiceRejectionMotifList}
						getOptionLabel={({ label = "" }) => t(label)}
						id="rejectionReason"
						renderInput={(params) => (
							<div>
								<TextField
									{...params} // eslint-disable-line
									inputRef={register({ required: true })}
									variant="standard"
									label={t("rejectionReason")}
									margin="normal"
									name="motif"
								/>
								{errors.motif && (
									<Typography variant="subtitle2" align="left" color="error">
										{t("this field is mandatory")}
									</Typography>
								)}
							</div>
						)}
					/>
					<TextField
						inputRef={register}
						name="comment"
						id="outlined-multiline-static"
						label={t("rejectionDetails")}
						multiline
						fullWidth
						rows={4}
						defaultValue=""
						variant="outlined"
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseRejectPopup} color="secondary" disabled={isLoading} autoFocus>
						{t("cancel")}
					</Button>
					<Button type="submit" color="secondary" disabled={isLoading}>
						{isLoading && <CircularProgress size={14} />}&nbsp; {t("confirm")}
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
};

const DigitalChip = () => {
	const { t } = useTranslation();

	return (
		<Tooltip title={t("invoice.digital.extract")}>
			<Chip
				icon={<Description style={{ width: "12px", height: "12px" }} />}
				color="primary"
				style={{
					width: "15px",
					height: "15px",
					paddingLeft: "8px",
				}}
				size="small"
			/>
		</Tooltip>
	);
};

function Detail(props) {
	const classes = useStyles();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const [previewFile, setPreviewFile] = React.useState(null);
	const [mimeType, setMimeType] = React.useState(null);
	const filterContext =
		history.location && history.location.state && history.location.state.filterContext;

	const [value, setValue] = React.useState(0);
	const [scValue, setScValue] = React.useState(0);

	const [openRejectPopup, setOpenRejectPopup] = React.useState(false);
	const [openApplyForApprovalPopup, setOpenApplyForApprovalPopup] = React.useState(false);

	const [openAlertesDialog, setOpenAlertesDialog] = React.useState(false);
	const [warningsToFix, setWarningsToFix] = React.useState([]);

	const [isEditFacture] = React.useState(false);
	const [rectify, setRectify] = React.useState(false);
	const [showRectify, setShowRectify] = React.useState(false);
	const [billingLines, setBillingLines] = React.useState([]);

	const [invoice, setInvoice] = React.useState({});
	const [order, setOrder] = React.useState(props.invoice ? props.invoice.order : {});
	const [supplier, setSupplier] = React.useState("");
	const [location, setLocation] = React.useState(null);
	const [dataErrors] = React.useState([]);
	const [settings, setSettings] = React.useState({ loaded: false, data: [] });
	const [isSelectedField, setSelectedFied] = React.useState(true);

	const [transitions, setTransitions] = React.useState([]);
	const [currentTransition, setCurrentTransition] = React.useState([]);
	const [workflow, setWorkflow] = React.useState({});

	const [dataFromXml, setDataFromXml] = React.useState({});
	const [fromDigitalInvoice, setFromDigitalInvoice] = React.useState({});
	const [isDigitalInvoice, setDigitalInvoice] = React.useState(false);
	const [isDocumentInternal, setIsDocumentInternal] = React.useState(false);
	const [openNextSatus, setOpenNextSatus] = React.useState(false);
	const [nextStatusLoading, setNextStatusLoading] = React.useState(false);
	const [openExportSatus, setOpenExportSatus] = React.useState(false);
	const [confirmTransition, setConfirmTransition] = React.useState({});
	const [isLoading, setIsLoading] = React.useState({
		excel: false,
		xml: false,
		pdf: false,
		edi: false,
		idoc: false,
		ec: false,
	});

	const [confirmObject, setConfirmObject] = React.useState({
		message: "",
		openConfirm: false,
		cancel: false,
		rectify: false,
		control: false,
		export: false,
		training: false,
		isLoading: false,
	});

	// eslint-disable-next-line no-shadow
	const formulaire = useSelector(({ formulaire }) => formulaire);
	const currentUser = useSelector(({ requests }) =>
		get(requests, "queries.FETCH_CURRENT_USER.data")
	);
	const pendingValidation = props?.invoicePersisted?.status?.code === "TO_VALIDATE_EXTERNAL";

	const getDataFromSettings = (code) =>
		get(
			settings.data.find((e) => e.code === code),
			"value"
		) === "1";

	const isEmptyOrNull = (v) => v === null || v === "";

	const isSupplier = isSupplierOnly(get(currentUser, "roles", []));

	useEffect(() => {
		// dispatch(invoicesTotalAmounts());

		dispatch(fetchCountSuppliers());
		dispatch(fetchCountInvoices());
		dispatch(fetchDeliveryMethods());
		if (!isSupplier)
			dispatch(fetchSettingsByModuleName("invoice")).then((res) => {
				if (res.data) setSettings({ loaded: true, data: res.data });
			});
		fetchInvoice(props.match.params.idInvoice ? parseFloat(props.match.params.idInvoice) : 0);
		setInvoice(props.invoicePersisted);
		if (props.invoicePersisted && props.invoicePersisted.status)
			setShowRectify(props.invoicePersisted.status.rectify);
		const _formulaire = JSON.parse(JSON.stringify(formulaire));
		props.updateFormulaire({
			..._formulaire,
			invoiceAccounting: props.invoicePersisted?.invoiceAccounting,
		});
		if (props.invoicePersisted) setOrder(props.invoicePersisted.order);
		if (props.invoicePersisted) setBillingLines(props.invoicePersisted.billingLinesDtos);
		if (props.invoicePersisted)
			setDataFromXml(JSON.parse(get(props.invoicePersisted, "dataFromXml")));
		if (props.invoicePersisted)
			setFromDigitalInvoice(JSON.parse(get(props.invoicePersisted, "fromDigitalInvoice")));
		if (props.invoicePersisted) setDigitalInvoice(get(props.invoicePersisted, "digitalInvoice"));
		if (props.invoicePersisted)
			setIsDocumentInternal(isInternal(get(props.invoicePersisted, "source")));
		setSupplier(
			props.invoicePersisted && props.invoicePersisted.supplier
				? props.invoicePersisted.supplier.label
				: ""
		);
		dispatch(
			fetchBillingLines(props.match.params.idInvoice ? parseFloat(props.match.params.idInvoice) : 0)
		);
		dispatch(fetchWorkflow()).then((res) => {
			let transtionList = [];
			if (res.data && props.invoicePersisted) {
				if (pendingValidation && props?.invoicePersisted?.approvedOldStatus) {
					const previousStatus = res?.data?.status.find(
						(st) => st.id === Number(props?.invoicePersisted?.approvedOldStatus)
					);
					transtionList = res?.data?.transitions.filter(
						(transition) => transition?.sourceStatusCode === previousStatus?.code
					);
				} else {
					transtionList = res.data.transitions.filter(
						(trEl) => trEl.sourceStatusCode === props.invoicePersisted.status.code
					);
				}
				const pos = transtionList.map((e) => e.targetStatusCode?.toUpperCase()).indexOf("REJECTED");
				if (pos !== -1) {
					const rejectedTransaction = transtionList[pos];
					// eslint-disable-next-line prefer-destructuring
					transtionList[pos] = transtionList[0];
					transtionList[0] = rejectedTransaction;
				}
				setTransitions(transtionList);
				setWorkflow(res.data);
			}
		});
		if (props.invoicePersisted) {
			dispatch(validateStatus(props.invoicePersisted?.id));
		}

		if (props.invoicePersisted) {
			// get rules of current clicked transition
			if (props.invoicePersisted.status) {
				// get alertes
				const alerts = props.invoicePersisted.alertes.alertes;

				if (alerts && alerts.length > 0) {
					setWarningsToFix(alerts);
				}
			}
		}
	}, [props.invoicePersisted]);

	const handleSupplierFieldChange = (event) => {
		const { target } = event;
		setSupplier(target.value);
		const selectedSupplier = props.suppliers.content.find((s) => event.target.value === s.label);
		setInvoice({
			...invoice,
			supplier: selectedSupplier,
		});
		const _formulaire = JSON.parse(JSON.stringify(formulaire));
		_formulaire.invoiceDto.supplier.label.value = event.target.value;
		if (selectedSupplier) {
			_formulaire.invoiceDto.supplier.id.value = selectedSupplier.id;
			_formulaire.invoiceDto.supplier.vatNumber.value = selectedSupplier.vatNumber;
			_formulaire.invoiceDto.supplier.companySiret.value = selectedSupplier.companySiret;
		} else {
			_formulaire.invoiceDto.supplier.vatNumber.value = "";
			_formulaire.invoiceDto.supplier.companySiret.value = "";
		}
		if (isEmptyOrNull(event.target.value)) {
			// eslint-disable-line
			_formulaire.invoiceDto.supplier.label.location = {
				top: null,
				right: null,
				left: null,
				bottom: null,
			};
		}
		props.updateFormulaire(_formulaire);
	};

	const addAccountingLine = () => {
		const _formulaire = JSON.parse(JSON.stringify(formulaire));
		if (!_formulaire?.invoiceAccounting?.accountingLines) {
			_formulaire.invoiceAccounting = { accountingLines: [] };
		}
		_formulaire.invoiceAccounting.accountingLines.push({ sign: "D" });
		props.updateFormulaire(_formulaire);
	};

	const deleteAccountingLine = (index) => {
		const _formulaire = JSON.parse(JSON.stringify(formulaire));

		const filteredItems = _formulaire.invoiceAccounting.accountingLines
			.slice(0, index)
			.concat(
				_formulaire.invoiceAccounting.accountingLines.slice(
					index + 1,
					_formulaire.invoiceAccounting.accountingLines.length
				)
			);
		_formulaire.invoiceAccounting.accountingLines = filteredItems;
		props.updateFormulaire(_formulaire);
	};

	const handleAccountingLineChange = (index, name, accountingLineValue) => {
		const _formulaire = JSON.parse(JSON.stringify(formulaire));
		if (!_formulaire?.invoiceAccounting?.accountingLines) {
			_formulaire.invoiceAccounting = { accountingLines: [] };
		}
		_formulaire.invoiceAccounting.accountingLines[index][name] = accountingLineValue;
		props.updateFormulaire(_formulaire);
	};

	const handleAccountingChange = (name, accountingValue) => {
		const _formulaire = JSON.parse(JSON.stringify(formulaire));
		if (!_formulaire?.invoiceAccounting?.accountingLines) {
			_formulaire.invoiceAccounting = { accountingLines: [] };
		}
		if (name === "costCenter") {
			_formulaire.invoiceAccounting[name] = props.costCenters.find(
				(costCenter) => costCenter.id === accountingValue
			);
		} else {
			_formulaire.invoiceAccounting[name] = props.paymentMethods.find(
				(paymentMethod) => paymentMethod.id === accountingValue
			);
		}
		props.updateFormulaire(_formulaire);
	};

	const exportEdi = () => {
		setTransitions([]);
		setNextStatusLoading(true);
		dispatch(
			exportXMLFileToServer(
				props.match.params.idInvoice,
				() => {
					toast.success(t("invoice.detail.export.success"));
					dispatch(fetchInvoice(props.match.params.idInvoice));
					setOpenExportSatus(false);
					setNextStatusLoading(false);
				},
				() => {
					toast.error(t("invoice.detail.export.failed"));
					setOpenExportSatus(false);
					setNextStatusLoading(false);
				}
			)
		);
	};

	useEffect(() => {
		if (props.document) {
			const blob = new Blob([props.document], { type: mimeType });
			const fileURL = URL.createObjectURL(blob);
			setPreviewFile(fileURL);
		}
	}, [props.document]);
	useEffect(() => {
		props.fetchInvoice(props.match.params.idInvoice ? parseFloat(props.match.params.idInvoice) : 0);
		props.fetchDocumentInvoice(
			props.match.params.idInvoice ? parseFloat(props.match.params.idInvoice) : 0,
			"inv",
			null,
			setMimeType
		);
		props.fetchSuppliers({
			nameOrContactLastNameOrContactFirstName: "",
			pageNo: 0,
			pageSize: null,
			sortBy: "label",
		});
		props.fetchPaymentTerms();
		props.fetchRejectionMotifs({ token: null });
		props.fetchGlAccounts();
		props.fetchCostCenters();
		props.fetchPaymentMethods();
	}, []);

	const theme = useTheme();

	// temp fix
	useEffect(
		() => () => {
			window.location.reload(false);
		},
		[]
	);

	const handleChangeFirstTab = (event, newValue) => {
		setValue(newValue);
	};
	const handleChangeSecondTab = (event, newScValue) => {
		setScValue(newScValue);
	};
	const onChangeBilling = (event, i, cellule) => {
		const newRows = billingLines.slice();
		newRows[i][cellule] = event.target.value;
		setBillingLines(newRows);
	};
	const minimizeValueDesignation = (v) => (v && v.length > 45 ? `${v.substr(0, 44)}...` : v);
	const minimizeValueCode = (v) => (v && v.length > 9 ? `${v.substr(0, 8)}...` : v);

	const updateSelectedItem = (v) => {
		if (isSelectedField) dispatch(updateFormulaire({ ...formulaire, selectedItem: v }));
	};

	const getSuppliersOptions = () => {
		if (!isEmpty(invoice.suppliers)) {
			return invoice.suppliers;
		}
		return get(props, "suppliers.content", []);
	};

	const status = get(props.invoicePersisted, "status");

	const statusCode = get(status, "code");

	const getInvoiceStatusLabel = (arg) => {
		if (isSupplierOnly(get(currentUser, "roles", []))) {
			if (TO_VERIFY_LIST.includes(arg.code)) {
				return t("invoice.status.underProcess");
			}
			if (TREATED.includes(arg.code)) {
				return t("invoice.status.processed");
			}
			if (REJECTED.includes(arg.code)) {
				return t("Rejected");
			}
		}
		return status.displayName ? t(status.displayName) : t(status.label);
	};

	const onExportError = () => {
		toast.error(t("invoice.export.error"));
	};

	const exportEXCEl = () => {
		setIsLoading({ ...isLoading, excel: true });
		dispatch(
			downloadInvoiceExcel(
				props.match.params.idInvoice,
				() => {
					onExportError();
				},
				() => {
					setIsLoading({ ...isLoading, excel: false });
				}
			)
		);
	};

	const exportXML = () => {
		setIsLoading({ ...isLoading, xml: true });
		dispatch(
			downloadXMLInvoice(
				props.match.params.idInvoice,
				() => {
					onExportError();
				},
				() => {
					setIsLoading({ ...isLoading, xml: false });
				}
			)
		);
	};

	const exportPDF = () => {
		setIsLoading({ ...isLoading, pdf: true });
		dispatch(
			downloadInvoice(
				props.match.params.idInvoice,
				"inv",
				() => {
					onExportError();
				},
				() => {
					setIsLoading({ ...isLoading, pdf: false });
				}
			)
		);
	};

	const exportEdifact = () => {
		setIsLoading({ ...isLoading, edi: true });
		dispatch(
			downloadEdifactInvoice(
				props.match.params.idInvoice,
				() => {
					onExportError();
				},
				() => {
					setIsLoading({ ...isLoading, edi: false });
				}
			)
		);
	};
	const exportIdoc = () => {
		setIsLoading({ ...isLoading, idoc: true });
		dispatch(
			downloadIdocInvoice(
				props.match.params.idInvoice,
				() => {
					onExportError();
				},
				() => {
					setIsLoading({ ...isLoading, idoc: false });
				}
			)
		);
	};
	const exportEC = () => {
		setIsLoading({ ...isLoading, ec: true });
		dispatch(
			exportSingleAccountingEntryExcel(
				props.match.params.idInvoice,
				() => {
					onExportError();
				},
				() => {
					setIsLoading({ ...isLoading, ec: false });
				}
			)
		);
	};

	const onCloseDialogReject = (error) => {
		if (error) {
			toast.error(t("invoice.detail.reject.failed"));
		} else {
			toast.success(t("invoice.detail.reject.success"));
		}
	};
	const initConfirmObject = () => {
		setConfirmObject({
			message: "",
			openConfirm: false,
			cancel: false,
			rectify: false,
			control: false,
			training: false,
		});
	};
	const onConfirm = () => {
		if (confirmObject.cancel) {
			initConfirmObject();
			window.location.reload(false);
		}

		if (confirmObject.rectify) {
			initConfirmObject();
			props.rectifyInvoice(props.match.params.idInvoice, formulaire, confirmObject.training);
		}
		if (confirmObject.control) {
			if (isEmpty(dataErrors)) {
				props.verifyInvoice(props.invoicePersisted && props.invoicePersisted.id);
				initConfirmObject();
			} else {
				initConfirmObject();
			}
		}
		if (confirmObject.export) {
			initConfirmObject();
			exportEdi();
		}
	};
	const onCancel = () => {
		setConfirmObject({ message: "", openConfirm: false });
	};

	const updateLocation = (loca) => {
		if (isSelectedField) setLocation(loca);
	};
	const addBillingLine = (index) => {
		if (rectify) {
			const _formulaire = JSON.parse(JSON.stringify(formulaire));
			const item = {
				id: {},
				codeArticle: {},
				designation: {},
				quantity: {},
				vat: {},
				price: {},
				totalHt: {},
				deliveryDelay: {},
			};
			_formulaire.invoiceDto.billingLinesDtos.splice(index, 0, item);
			_formulaire.invoiceDto.billingLinesDtos[index].designation.value = "";
			_formulaire.invoiceDto.billingLinesDtos[index].codeArticle.value = "";
			_formulaire.invoiceDto.billingLinesDtos[index].quantity.value = "";
			_formulaire.invoiceDto.billingLinesDtos[index].vat.value = "";
			_formulaire.invoiceDto.billingLinesDtos[index].price.value = "";
			_formulaire.invoiceDto.billingLinesDtos[index].price.value = "";
			_formulaire.invoiceDto.billingLinesDtos[index].totalHt.value = "";
			props.updateFormulaire(_formulaire);
		} else {
			const newRows = billingLines ? billingLines.slice() : [];
			newRows.push({});
			setBillingLines(newRows);
		}
	};

	const finalWarnings = warningsToFix.filter((w) => w.severity.toLowerCase().trim() === "warning");
	const finalErrors = warningsToFix.filter((w) => w.severity.toLowerCase().trim() === "error");

	const finalWarningsCount = warningsToFix.filter((w) => w.severity.toLowerCase() === "warning")
		?.length;
	const finalErrorsCount = warningsToFix.filter((w) => w.severity.toLowerCase() === "error")
		?.length;

	const updateStatusWf = (invoiceId, transitionCode) => {
		dispatch(updateInvoiceStatus(invoiceId, transitionCode, workflow?.code))
			.then((res) => {
				if (res.status === 200) window.location.reload();
				else if (res?.action?.error?.response?.data) {
					toast.error(t(res.action.error.response.data.title));
				}
			})
			.catch((error) => console.log(error));
	};

	const handleTransitionButtonClick = (transition) => {
		setNextStatusLoading(true);
		dispatch(
			validateTransition(
				props.invoicePersisted.id,
				transition.sourceStatusCode,
				transition.targetStatusCode
			)
		).then((res) => {
			setOpenNextSatus(false);
			setNextStatusLoading(false);
			if (res.data) {
				const allAlerts = res.data.alerts.error.concat(res.data.alerts.warning);
				setWarningsToFix(allAlerts);
				setCurrentTransition(transition.code);

				if (allAlerts.length > 0) {
					setOpenAlertesDialog(!openAlertesDialog);
				} else {
					updateStatusWf(invoice.id, transition.code);
				}
			}
		});
		// checkAndDoTransition(transition);
	};

	const onConfirmNextStatus = () => {
		handleTransitionButtonClick(confirmTransition);
	};
	const onCancelNextStatus = () => {
		setOpenNextSatus(false);
	};

	const onConfirmExportStatus = () => {
		exportEdi();
	};
	const onCancelExportStatus = () => {
		setOpenExportSatus(false);
	};

	return (
		<Grid container xs={12} style={{ padding: "10px 0" }}>
			<Grid container item xs={12} justify="space-evenly">
				<Grid container item xs={5}>
					<Grid
						container
						item
						xs
						direction="row"
						alignItems="flex-start"
						className={classes.detailHeader}
					>
						<Grid container item xs={3}>
							<Grid container item xs={12} justify="flex-start">
								<Button
									variant="contained"
									fullWidth
									color="secondary"
									className={classes.backBtn}
									onClick={() => history.push("/invoices", { filterContext, fromDetails: true })}
								>
									{t("return")}
								</Button>
							</Grid>
						</Grid>
						<Grid container item xs={9}>
							<Grid container xs={12} item alignItems="center" justify="flex-end">
								<Grid item>
									{props.invoicePersisted && props.invoicePersisted.status ? (
										<Chip
											align="left"
											className={isDigitalInvoice && classes.alignStatus}
											label={
												props.invoicePersisted &&
												props.invoicePersisted.status &&
												getInvoiceStatusLabel(props.invoicePersisted.status)
											}
											color="secondary"
										/>
									) : (
										<CircularLoader />
									)}
								</Grid>
								<Grid item>
									{isDigitalInvoice && !isEmptyOrNull(dataFromXml) ? (
										<DigitalSummary dataFromXml={dataFromXml} />
									) : null}
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				{(!isSupplier ||
					!isEmpty(get(invoice, "purchaseOrder.deliveryItems", [])) ||
					TREATED.includes(statusCode)) && (
					<Grid container item xs={rectify ? 6 : 5}>
						&nbsp;
					</Grid>
				)}
			</Grid>
			<Grid container item xs={12} className={classes.detailsContent} justify="space-evenly">
				{(!isSupplier ||
					!isEmpty(get(invoice, "purchaseOrder.deliveryItems", [])) ||
					TREATED.includes(statusCode)) && (
					<Grid container item xs={5} className={classes.invoiceOrderTabs}>
						<Grid item xs={12}>
							<AppBar position="static" color="white" elevation={0} className={classes.rootAppbar}>
								<Tabs
									value={value}
									onChange={handleChangeFirstTab}
									indicatorColor="secondary"
									textColor="secondary"
									variant="fullWidth"
									aria-label="tab facture"
								>
									{(!isSupplier || TREATED.includes(statusCode)) && (
										<Tab label={t("Invoice Details")} {...a11yProps(0)} />
									)}
									{!isSupplier && <Tab label={t("Order Details")} {...a11yProps(1)} />}
									{!isEmpty(get(invoice, "purchaseOrder.deliveryItems", [])) &&
										get(props.invoicePersisted, "status.code") !== INTEGRATION && (
											// eslint-disable-next-line
											<Tab label={t("Delivery Details")} {...a11yProps(2)} />
										)}
									{(!isSupplier || TREATED.includes(statusCode)) && (
										<Tab label={t("Accounting Lines")} {...a11yProps(2)} />
									)}
								</Tabs>
							</AppBar>
							<TabPanel section={1} value={value} index={2} dir={theme.direction}>
								<AccountingLines
									rectify={rectify}
									formulaire={formulaire}
									invoice={invoice}
									addAccountingLine={addAccountingLine}
									handleAccountingLineChange={handleAccountingLineChange}
									deleteAccountingLine={deleteAccountingLine}
									glAccounts={props.glAccounts}
									costCenters={props.costCenters}
									paymentMethods={props.paymentMethods}
									handleAccountingChange={handleAccountingChange}
								/>
							</TabPanel>
							{(!isSupplier || TREATED.includes(statusCode)) && (
								<TabPanel
									section={1}
									value={value}
									index={0}
									dir={theme.direction}
									className={classes.tabInvoiceContainer}
								>
									{invoice ? (
										<Grid container item xs={12}>
											<Grid container item xs={12} className={classes.tabInvoice} spacing={2}>
												<Grid
													container
													item
													xs={12}
													justify="center"
													alignItems="flex-end"
													spacing={1}
													className={classes.noPaddingY}
												>
													<Grid item xs={6}>
														<Typography variant="subtitle2" align="left" color="primary">
															{t("supplier")}
															{"	"}
															{invoice && isDigitalInvoice && fromDigitalInvoice?.supplierLabel ? (
																<DigitalChip />
															) : null}
															<br />
															<>
																{rectify ? (
																	<>
																		<Select
																			labelId="supplier"
																			id="supplier"
																			className={classes.resize}
																			value={supplier}
																			onChange={handleSupplierFieldChange}
																			fullWidth
																			disabled={
																				invoice &&
																				get(invoice, "digitalInvoice") &&
																				isEmpty(invoice.suppliers)
																			}
																			displayEmpty
																			InputProps={{
																				classes: {
																					input: classes.resize,
																				},
																			}}
																			input={<Input name="supplier" id="supplier" />}
																		>
																			<MenuItem value="">
																				<ListItemText primary="" />
																			</MenuItem>
																			{getSuppliersOptions().map((s, i) => (
																				<MenuItem key={i} value={s.label}>
																					<ListItemText primary={s.label} />
																				</MenuItem>
																			))}
																		</Select>
																	</>
																) : (
																	<Typography
																		variant="body2"
																		align="left"
																		className={classes.blackColor}
																	>
																		{get(invoice, "supplier.label")}
																		<Divider />
																	</Typography>
																)}
															</>
														</Typography>
													</Grid>
													<Grid item xs={6}>
														<Typography variant="subtitle2" align="left" color="primary">
															{t("invoice Number")}
															{"	"}
															{invoice && isDigitalInvoice && fromDigitalInvoice?.invoiceNo ? (
																<DigitalChip />
															) : null}
															<br />
															{isEditFacture || rectify ? (
																<TextField
																	fullWidth
																	id="invoiceNo"
																	onClick={() => {
																		updateSelectedItem("invoiceNo");
																		updateLocation(formulaire.invoiceDto.invoiceNo.location);
																	}}
																	onChange={(e) => {
																		const _formulaire = JSON.parse(JSON.stringify(formulaire));
																		_formulaire.invoiceDto.invoiceNo.value = e.target.value;
																		if (isEmptyOrNull(e.target.value)) {
																			_formulaire.invoiceDto.invoiceNo.location = {
																				top: null,
																				right: null,
																				left: null,
																				bottom: null,
																			};
																		}
																		props.updateFormulaire(_formulaire);
																	}}
																	disabled={
																		invoice && isDigitalInvoice && fromDigitalInvoice?.invoiceNo
																	}
																	value={
																		isDigitalInvoice && fromDigitalInvoice?.invoiceNo
																			? get(invoice, "invoiceNo")
																			: formulaire.invoiceDto.invoiceNo.value
																	}
																	className={clsx(
																		"classes.resize",
																		rectify &&
																			isEmptyOrNull(formulaire.invoiceDto.invoiceNo.value) &&
																			classes.emptyInput
																	)}
																	InputProps={{
																		classes: {
																			input: classes.resize,
																		},
																	}}
																	InputLabelProps={{
																		shrink: true,
																	}}
																/>
															) : (
																<Typography
																	variant="body2"
																	align="left"
																	className={classes.blackColor}
																>
																	{props.invoicePersisted && props.invoicePersisted.invoiceNo}

																	<Divider />
																</Typography>
															)}
														</Typography>
													</Grid>
												</Grid>

												<Grid
													container
													item
													xs={12}
													justify="center"
													alignItems="flex-end"
													spacing={2}
												>
													<Grid item xs={6}>
														<Typography variant="subtitle2" align="left" color="primary">
															{t("VAT Number")}
															{"	"}
															{invoice &&
															isDigitalInvoice &&
															fromDigitalInvoice?.supplierVatNumber ? (
																<DigitalChip />
															) : null}
															<>
																{rectify ? (
																	<Input
																		className={classes.resize}
																		fullWidth
																		disabled={
																			invoice &&
																			isDigitalInvoice &&
																			(fromDigitalInvoice?.supplierVatNumber ||
																				fromDigitalInvoice?.supplierCompanySiret)
																		}
																		value={
																			isDigitalInvoice &&
																			(fromDigitalInvoice?.supplierVatNumber ||
																				fromDigitalInvoice?.supplierCompanySiret)
																				? get(invoice, "supplier.vatNumber")
																				: get(formulaire.invoiceDto, "supplier.vatNumber.value")
																		}
																	/>
																) : (
																	<Typography
																		variant="body2"
																		align="left"
																		className={classes.blackColor}
																	>
																		{get(invoice, "supplier.vatNumber")}
																		<Divider />
																	</Typography>
																)}
															</>
														</Typography>
													</Grid>
													<Grid item xs={6}>
														<Typography variant="subtitle2" align="left" color="primary">
															{t("invoice.detail.company_siret")}
															{"	"}
															{invoice &&
															isDigitalInvoice &&
															fromDigitalInvoice?.supplierCompanySiret ? (
																<DigitalChip />
															) : null}
															<br />
															{isEditFacture || rectify ? (
																<TextField
																	fullWidth
																	id="companySiret"
																	// type="date"
																	onClick={() => {
																		updateSelectedItem("companySiret");
																		updateLocation(
																			formulaire.invoiceDto.supplier.companySiret.location
																		);
																	}}
																	onChange={(e) => {
																		if (rectify) {
																			const _formulaire = JSON.parse(JSON.stringify(formulaire));
																			_formulaire.invoiceDto.supplier.companySiret.value =
																				e.target.value;
																			if (isEmptyOrNull(e.target.value)) {
																				_formulaire.invoiceDto.supplier.companySiret.location = {
																					top: null,
																					right: null,
																					left: null,
																					bottom: null,
																				};
																			}
																			props.updateFormulaire(_formulaire);
																		} else {
																			setInvoice({
																				...invoice,
																				dueDate: e.target.value,
																			});
																		}
																	}}
																	value={
																		isDigitalInvoice && fromDigitalInvoice?.supplierCompanySiret
																			? get(invoice, "supplier.companySiret")
																			: get(formulaire.invoiceDto, "supplier.companySiret.value")
																	}
																	className={classes.textField}
																	disabled={
																		invoice &&
																		isDigitalInvoice &&
																		fromDigitalInvoice?.supplierCompanySiret
																	}
																	InputProps={{
																		classes: {
																			input: classes.resize,
																		},
																	}}
																	InputLabelProps={{
																		shrink: true,
																	}}
																/>
															) : (
																<Typography
																	variant="body2"
																	align="left"
																	className={classes.blackColor}
																>
																	{get(invoice, "supplier.companySiret")}
																	<Divider />
																</Typography>
															)}
														</Typography>
													</Grid>
												</Grid>

												<Grid
													container
													item
													xs={12}
													justify="center"
													alignItems="flex-end"
													spacing={1}
												>
													<Grid item xs={6}>
														<Typography variant="subtitle2" align="left" color="primary">
															{t("issueDate")}
															{"	"}
															{invoice && isDigitalInvoice && fromDigitalInvoice?.issueDate ? (
																<DigitalChip />
															) : null}
															<br />
															{isEditFacture || rectify ? (
																<TextField
																	fullWidth
																	id="issueDate"
																	// type="date"
																	onClick={() => {
																		updateSelectedItem("issueDate");
																		updateLocation(formulaire.invoiceDto.issueDate.location);
																	}}
																	onChange={(e) => {
																		if (rectify) {
																			const _formulaire = JSON.parse(JSON.stringify(formulaire));
																			_formulaire.invoiceDto.issueDate.value = e.target.value;
																			if (isEmptyOrNull(e.target.value)) {
																				_formulaire.invoiceDto.issueDate.location = {
																					top: null,
																					right: null,
																					left: null,
																					bottom: null,
																				};
																			}
																			props.updateFormulaire(_formulaire);
																		} else {
																			setInvoice({
																				...invoice,
																				issueDate: e.target.value,
																			});
																		}
																	}}
																	disabled={
																		invoice && isDigitalInvoice && fromDigitalInvoice?.issueDate
																	}
																	value={
																		isDigitalInvoice && fromDigitalInvoice?.issueDate
																			? get(invoice, "issueDate")
																			: rectify
																			? formulaire.invoiceDto.issueDate.value
																			: get(invoice, "issueDate")
																	}
																	className={classes.textField}
																	InputProps={{
																		classes: {
																			input: clsx(
																				"classes.resize",
																				rectify &&
																					isEmptyOrNull(formulaire.invoiceDto.issueDate.value) &&
																					classes.emptyInput
																			),
																		},
																	}}
																	InputLabelProps={{
																		shrink: true,
																	}}
																/>
															) : (
																<Typography
																	variant="body2"
																	align="left"
																	className={classes.blackColor}
																>
																	{get(invoice, "issueDate")}

																	<Divider />
																</Typography>
															)}
														</Typography>
													</Grid>

													<Grid item xs={6}>
														<Typography variant="subtitle2" align="left" color="primary">
															{t("dueDate")}
															{"	"}
															{invoice && isDigitalInvoice && fromDigitalInvoice?.dueDate ? (
																<DigitalChip />
															) : null}
															<br />
															{isEditFacture || rectify ? (
																<TextField
																	fullWidth
																	id="dueDate"
																	// type="date"
																	onClick={() => {
																		updateSelectedItem("dueDate");
																		updateLocation(formulaire.invoiceDto.dueDate.location);
																	}}
																	onChange={(e) => {
																		if (rectify) {
																			const _formulaire = JSON.parse(JSON.stringify(formulaire));
																			_formulaire.invoiceDto.dueDate.value = e.target.value;
																			if (isEmptyOrNull(e.target.value)) {
																				_formulaire.invoiceDto.dueDate.location = {
																					top: null,
																					right: null,
																					left: null,
																					bottom: null,
																				};
																			}
																			props.updateFormulaire(_formulaire);
																		} else {
																			setInvoice({
																				...invoice,
																				dueDate: e.target.value,
																			});
																		}
																	}}
																	disabled={
																		invoice && isDigitalInvoice && fromDigitalInvoice?.dueDate
																	}
																	value={
																		isDigitalInvoice && fromDigitalInvoice?.dueDate
																			? get(invoice, "dueDate")
																			: rectify
																			? formulaire.invoiceDto.dueDate.value
																			: get(invoice, "dueDate")
																	}
																	className={classes.textField}
																	InputProps={{
																		classes: {
																			input: clsx(
																				"classes.resize",
																				rectify &&
																					isEmptyOrNull(formulaire.invoiceDto.dueDate.value) &&
																					classes.emptyInput
																			),
																		},
																	}}
																	InputLabelProps={{
																		shrink: true,
																	}}
																/>
															) : (
																<Typography
																	variant="body2"
																	align="left"
																	className={classes.blackColor}
																>
																	{get(invoice, "dueDate")}

																	<Divider />
																</Typography>
															)}
														</Typography>
													</Grid>
												</Grid>

												<Grid container item xs={12} alignItems="flex-end" spacing={1}>
													<Grid item xs={6}>
														<Typography variant="subtitle2" align="left" color="primary">
															{t("orderNo")}
															{"	"}
															{invoice &&
															isDigitalInvoice &&
															fromDigitalInvoice?.purchaseOrderNo ? (
																<DigitalChip />
															) : null}
															{isEditFacture || rectify ? (
																<Input
																	className={clsx(
																		"classes.resize",
																		rectify &&
																			isEmptyOrNull(formulaire.invoiceDto.order.orderNo.value) &&
																			classes.emptyInput
																	)}
																	fullWidth
																	onClick={() => {
																		updateSelectedItem("order.orderNo");
																		updateLocation(formulaire.invoiceDto.order.orderNo.location);
																	}}
																	disabled={
																		invoice &&
																		isDigitalInvoice &&
																		fromDigitalInvoice?.purchaseOrderNo
																	}
																	value={
																		isDigitalInvoice && fromDigitalInvoice?.purchaseOrderNo
																			? get(invoice, "purchaseOrderNo")
																			: rectify
																			? formulaire.invoiceDto.order.orderNo.value
																			: get(invoice, "purchaseOrderNo")
																	}
																	onChange={(e) => {
																		if (rectify) {
																			const _formulaire = JSON.parse(JSON.stringify(formulaire));
																			_formulaire.invoiceDto.order.orderNo.value = e.target.value;
																			if (isEmptyOrNull(e.target.value)) {
																				_formulaire.invoiceDto.order.orderNo.location = {
																					top: null,
																					right: null,
																					left: null,
																					bottom: null,
																				};
																			}
																			props.updateFormulaire(_formulaire);
																		} else {
																			setOrder({ ...order, orderNo: e.target.value });
																		}
																	}}
																/>
															) : (
																<Typography
																	variant="body2"
																	align="left"
																	className={classes.blackColor}
																>
																	{get(invoice, "purchaseOrderNo")}
																	<Divider />
																</Typography>
															)}
														</Typography>
													</Grid>

													<Grid item xs={6}>
														<Typography variant="subtitle2" align="left" color="primary">
															{t("DeliverySlip")}
															{"	"}
															{invoice && isDigitalInvoice && fromDigitalInvoice?.deliverySlip ? (
																<DigitalChip />
															) : null}
															<br />
															{isEditFacture || rectify ? (
																<Input
																	className={clsx(
																		"classes.resize",
																		rectify &&
																			isEmptyOrNull(formulaire.invoiceDto.deliverySlip.value) &&
																			classes.emptyInput
																	)}
																	fullWidth
																	onChange={(e) => {
																		if (rectify) {
																			const _formulaire = JSON.parse(JSON.stringify(formulaire));
																			_formulaire.invoiceDto.deliverySlip.value = e.target.value;
																			if (isEmptyOrNull(e.target.value)) {
																				_formulaire.invoiceDto.deliverySlip.location = {
																					top: null,
																					right: null,
																					left: null,
																					bottom: null,
																				};
																			}
																			props.updateFormulaire(_formulaire);
																		} else {
																			setInvoice({
																				...invoice,
																				deliverySlip: e.target.value,
																			});
																		}
																	}}
																	onClick={() => {
																		updateSelectedItem("deliverySlip");
																		updateLocation(formulaire.invoiceDto.deliverySlip.location);
																	}}
																	disabled={
																		invoice && isDigitalInvoice && fromDigitalInvoice?.deliverySlip
																	}
																	value={
																		isDigitalInvoice && fromDigitalInvoice?.deliverySlip
																			? get(invoice, "deliverySlip")
																			: rectify
																			? formulaire.invoiceDto.deliverySlip.value
																			: get(invoice, "deliverySlip")
																	}
																/>
															) : (
																<Typography
																	variant="body2"
																	align="left"
																	className={classes.blackColor}
																>
																	{get(invoice, "deliverySlip")}
																	<Divider />
																</Typography>
															)}
														</Typography>
													</Grid>
												</Grid>

												<Grid
													container
													item
													xs={12}
													justify="center"
													alignItems="flex-end"
													spacing={1}
												>
													<Grid item xs={6}>
														<Typography variant="subtitle2" align="left" color="primary">
															{t("Delivery address")}
															{"	"}
															{invoice &&
															isDigitalInvoice &&
															fromDigitalInvoice?.deliveryAddress ? (
																<DigitalChip />
															) : null}
															<br />
															{isEditFacture || rectify ? (
																<Input
																	className={clsx(
																		"classes.resize",
																		rectify &&
																			isEmptyOrNull(formulaire.invoiceDto.deliveryAddress.value) &&
																			classes.emptyInput
																	)}
																	fullWidth
																	rows={3}
																	multiline
																	onChange={(e) => {
																		if (rectify) {
																			const _formulaire = JSON.parse(JSON.stringify(formulaire));
																			_formulaire.invoiceDto.deliveryAddress.value = e.target.value;
																			if (isEmptyOrNull(e.target.value)) {
																				_formulaire.invoiceDto.deliveryAddress.location = {
																					top: null,
																					right: null,
																					left: null,
																					bottom: null,
																				};
																			}
																			props.updateFormulaire(_formulaire);
																		} else {
																			setInvoice({
																				...invoice,
																				deliveryAddress: e.target.value,
																			});
																		}
																	}}
																	onClick={() => {
																		updateSelectedItem("deliveryAddress");
																		updateLocation(formulaire.invoiceDto.deliveryAddress.location);
																	}}
																	disabled={
																		invoice &&
																		isDigitalInvoice &&
																		fromDigitalInvoice?.deliveryAddress
																	}
																	value={
																		isDigitalInvoice && fromDigitalInvoice?.deliveryAddress
																			? get(invoice, "deliveryAddress")
																			: rectify
																			? formulaire.invoiceDto.deliveryAddress.value
																			: get(invoice, "deliveryAddress")
																	}
																/>
															) : (
																<Typography
																	variant="body2"
																	align="left"
																	className={classes.blackColor}
																>
																	{get(invoice, "deliveryAddress")}
																	<Divider />
																</Typography>
															)}
														</Typography>
													</Grid>
													<Grid item xs={6}>
														<Typography variant="subtitle2" align="left" color="primary">
															{t("Billing address")}
															{"	"}
															{invoice && isDigitalInvoice && fromDigitalInvoice?.billingAddress ? (
																<DigitalChip />
															) : null}
															{isEditFacture || rectify ? (
																<Input
																	className={clsx(
																		"classes.resize",
																		rectify &&
																			isEmptyOrNull(formulaire.invoiceDto.billingAddress.value) &&
																			classes.emptyInput
																	)}
																	fullWidth
																	rows={3}
																	multiline
																	onClick={() => {
																		updateSelectedItem("billingAddress");
																		updateLocation(formulaire.invoiceDto.billingAddress.location);
																	}}
																	onChange={(e) => {
																		if (rectify) {
																			const _formulaire = JSON.parse(JSON.stringify(formulaire));
																			_formulaire.invoiceDto.billingAddress.value = e.target.value;
																			if (isEmptyOrNull(e.target.value)) {
																				_formulaire.invoiceDto.billingAddress.location = {
																					top: null,
																					right: null,
																					left: null,
																					bottom: null,
																				};
																			}
																			props.updateFormulaire(_formulaire);
																		} else {
																			setInvoice({
																				...invoice,
																				billingAddress: e.target.value,
																			});
																		}
																	}}
																	disabled={
																		invoice &&
																		isDigitalInvoice &&
																		fromDigitalInvoice?.billingAddress
																	}
																	value={
																		isDigitalInvoice && fromDigitalInvoice?.billingAddress
																			? get(invoice, "billingAddress")
																			: rectify
																			? formulaire.invoiceDto.billingAddress.value
																			: get(invoice, "billingAddress")
																	}
																/>
															) : (
																<Typography
																	variant="body2"
																	align="left"
																	className={classes.blackColor}
																>
																	{rectify
																		? formulaire.invoiceDto.billingAddress.value
																		: invoice && invoice.billingAddress}
																	{/* {invoice && invoice.billingAddress} */}
																	<Divider />
																</Typography>
															)}
														</Typography>
													</Grid>
												</Grid>
												<Grid container item xs={12} alignItems="flex-end" spacing={1}>
													<Grid item xs={6}>
														<Typography variant="subtitle2" align="left" color="primary">
															{t("country")}
															{"	"}
															{invoice && isDigitalInvoice && fromDigitalInvoice?.country ? (
																<DigitalChip />
															) : null}
															<br />
															{isEditFacture || rectify ? (
																<Input
																	className={clsx(
																		"classes.resize",
																		rectify &&
																			isEmptyOrNull(get(formulaire, "invoiceDto.country.value")) &&
																			classes.emptyInput
																	)}
																	fullWidth
																	onChange={(e) => {
																		if (rectify) {
																			const _formulaire = JSON.parse(JSON.stringify(formulaire));
																			_formulaire.invoiceDto.country.value = e.target.value;
																			if (isEmptyOrNull(e.target.value)) {
																				_formulaire.invoiceDto.country.location = {
																					top: null,
																					right: null,
																					left: null,
																					bottom: null,
																				};
																			}
																			props.updateFormulaire(_formulaire);
																		} else {
																			setInvoice({
																				...invoice,
																				country: e.target.value,
																			});
																		}
																	}}
																	onClick={() => {
																		updateSelectedItem("country");
																		updateLocation(get(formulaire, "invoiceDto.country.location"));
																	}}
																	disabled={
																		invoice && isDigitalInvoice && fromDigitalInvoice?.country
																	}
																	value={
																		isDigitalInvoice && fromDigitalInvoice?.country
																			? get(invoice, "country")
																			: rectify
																			? get(formulaire, "invoiceDto.country.value")
																			: get(invoice, "country")
																	}
																/>
															) : (
																<Typography
																	variant="body2"
																	align="left"
																	className={classes.blackColor}
																>
																	{get(invoice, "country")}
																	<Divider />
																</Typography>
															)}
														</Typography>
													</Grid>
													<Grid item xs={6}>
														<Typography variant="subtitle2" align="left" color="primary">
															{t("currency")}
															{"	"}
															{invoice && isDigitalInvoice && fromDigitalInvoice?.currency ? (
																<DigitalChip />
															) : null}
															<br />
															{isEditFacture || rectify ? (
																<Input
																	className={clsx(
																		"classes.resize",
																		rectify &&
																			isEmptyOrNull(formulaire.invoiceDto.currency.value) &&
																			classes.emptyInput
																	)}
																	fullWidth
																	onChange={(e) => {
																		if (rectify) {
																			const _formulaire = JSON.parse(JSON.stringify(formulaire));
																			_formulaire.invoiceDto.currency.value = e.target.value;
																			if (isEmptyOrNull(e.target.value)) {
																				_formulaire.invoiceDto.currency.location = {
																					top: null,
																					right: null,
																					left: null,
																					bottom: null,
																				};
																			}
																			props.updateFormulaire(_formulaire);
																		} else {
																			setInvoice({
																				...invoice,
																				currency: e.target.value,
																			});
																		}
																	}}
																	onClick={() => {
																		updateSelectedItem("currency");
																		updateLocation(formulaire.invoiceDto.currency.location);
																	}}
																	disabled={
																		invoice && isDigitalInvoice && fromDigitalInvoice?.currency
																	}
																	value={
																		isDigitalInvoice && fromDigitalInvoice?.currency
																			? get(invoice, "currency")
																			: rectify
																			? formulaire.invoiceDto.currency.value
																			: get(invoice, "currency")
																	}
																/>
															) : (
																<Typography
																	variant="body2"
																	align="left"
																	className={classes.blackColor}
																>
																	{get(invoice, "currency")}
																	<Divider />
																</Typography>
															)}
														</Typography>
													</Grid>
												</Grid>
											</Grid>

											{(!isSupplier || TREATED.includes(statusCode)) && (
												<Grid
													id="billing-line-container"
													container
													xs={12}
													direction="row"
													justify="space-between"
													className={classes.cardBilling}
												>
													<Grid
														container
														item
														alignItems="center"
														xs={10}
														className={classes.headerBillingCard}
													>
														<Grid item alignContent="center">
															<Typography variant="subtitle2" align="left" color="primary">
																{t("Commands")}
																{"	"}
																{invoice && isDigitalInvoice && fromDigitalInvoice?.billingLines ? (
																	<DigitalChip />
																) : null}
															</Typography>
														</Grid>
													</Grid>
													{finalWarnings && finalWarnings.length > 0 && (
														<Tooltip title="check errors" arrow>
															<Grid container xs={1} alignItems="center">
																<WarningIcon style={{ color: theme.custom.color.warning }} />
															</Grid>
														</Tooltip>
													)}
													{finalErrors && finalErrors.length > 0 && (
														<Tooltip title="check errors" arrow>
															<Grid container xs={1} alignItems="center">
																<ErrorIcon style={{ color: theme.custom.color.error }} />
															</Grid>
														</Tooltip>
													)}
													<Grid container item alignItems="center" justify="center" xs={12}>
														<TableContainer
															component={Paper}
															elevation={0}
															className={classes.tableContainer}
														>
															<Table aria-label="spanning table">
																<TableHead>
																	<TableRow>
																		<TableCell>
																			<Typography
																				className={classes.tableCell1}
																				variant="subtitle2"
																				align="left"
																			>
																				{t("itemCode")}
																			</Typography>
																		</TableCell>
																		<TableCell>
																			<Typography
																				className={classes.tableCell3}
																				variant="subtitle2"
																				align="left"
																			>
																				{t("designation")}
																			</Typography>
																		</TableCell>
																		<TableCell>
																			<Typography
																				className={classes.tableCell2}
																				variant="subtitle2"
																				align="left"
																			>
																				{t("quantity")}
																			</Typography>
																		</TableCell>
																		<TableCell>
																			<Typography
																				className={classes.tableCell1}
																				variant="subtitle2"
																				align="left"
																			>
																				{t("unitPrice")}
																			</Typography>
																		</TableCell>
																		<TableCell>
																			<Typography
																				className={classes.tableCell2}
																				variant="subtitle2"
																				align="left"
																			>
																				{t("vat")}
																			</Typography>
																		</TableCell>
																		<TableCell>
																			<Typography
																				className={classes.tableCell4}
																				variant="subtitle2"
																				align="left"
																			>
																				{t("totalHt")}
																			</Typography>
																		</TableCell>
																		<TableCell>
																			{rectify &&
																				formulaire.invoiceDto.billingLinesDtos &&
																				isEmpty(formulaire.invoiceDto.billingLinesDtos) && (
																					<IconButton
																						aria-label="add"
																						onClick={() => addBillingLine(0)}
																						disabled={
																							isDigitalInvoice && fromDigitalInvoice?.billingLines
																						}
																					>
																						<AddIcon />
																					</IconButton>
																				)}
																		</TableCell>
																	</TableRow>
																</TableHead>
																<TableBody>
																	{rectify ? (
																		<>
																			{formulaire.invoiceDto.billingLinesDtos &&
																				!isEmpty(formulaire.invoiceDto.billingLinesDtos) &&
																				formulaire.invoiceDto.billingLinesDtos.map((row, i) => (
																					<TableRow key={row.id}>
																						<>
																							<TableCell>
																								<Input
																									className={clsx(
																										"classes.tableCell1",
																										isEmptyOrNull(
																											formulaire.invoiceDto.billingLinesDtos[i]
																												.codeArticle.value
																										) && classes.emptyInput
																									)}
																									fullWidth
																									value={row.codeArticle.value}
																									onClick={() => {
																										updateSelectedItem(`table.${i}.codeArticle`);
																										updateLocation(
																											formulaire.invoiceDto.billingLinesDtos[i]
																												.codeArticle.location
																										);
																									}}
																									onChange={(event) => {
																										const _formulaire = JSON.parse(
																											JSON.stringify(formulaire)
																										);
																										_formulaire.invoiceDto.billingLinesDtos[
																											i
																										].codeArticle.value = event.target.value;

																										props.updateFormulaire(_formulaire);
																									}}
																									disabled={
																										fromDigitalInvoice?.billingLines[i]?.codeArticle
																									}
																								/>
																							</TableCell>
																							<TableCell>
																								<Input
																									className={clsx(
																										"classes.tableCell3",
																										rectify &&
																											isEmptyOrNull(
																												formulaire.invoiceDto.billingLinesDtos[i]
																													.designation.value
																											) &&
																											classes.emptyInput
																									)}
																									fullWidth
																									multiline
																									value={row.designation.value}
																									onClick={() => {
																										updateSelectedItem(`table.${i}.designation`);
																										updateLocation(
																											formulaire.invoiceDto.billingLinesDtos[i]
																												.designation.location
																										);
																									}}
																									onChange={(event) => {
																										const _formulaire = JSON.parse(
																											JSON.stringify(formulaire)
																										);
																										_formulaire.invoiceDto.billingLinesDtos[
																											i
																										].designation.value = event.target.value;

																										props.updateFormulaire(_formulaire);
																									}}
																									disabled={
																										fromDigitalInvoice?.billingLines[i]?.designation
																									}
																								/>
																							</TableCell>
																							<TableCell>
																								{" "}
																								<Input
																									className={clsx(
																										"classes.tableCell2",
																										isEmptyOrNull(
																											formulaire.invoiceDto.billingLinesDtos[i]
																												.quantity.value
																										) && classes.emptyInput
																									)}
																									fullWidth
																									value={row.quantity.value}
																									onClick={() => {
																										updateSelectedItem(`table.${i}.quantity`);
																										updateLocation(
																											formulaire.invoiceDto.billingLinesDtos[i]
																												.quantity.location
																										);
																									}}
																									onChange={(event) => {
																										const _formulaire = JSON.parse(
																											JSON.stringify(formulaire)
																										);
																										_formulaire.invoiceDto.billingLinesDtos[
																											i
																										].quantity.value = event.target.value;

																										props.updateFormulaire(_formulaire);
																									}}
																									disabled={
																										fromDigitalInvoice?.billingLines[i]?.quantity
																									}
																								/>
																							</TableCell>
																							<TableCell>
																								<Input
																									className={clsx(
																										"classes.tableCell1",
																										isEmptyOrNull(
																											formulaire.invoiceDto.billingLinesDtos[i]
																												.price.value
																										) && classes.emptyInput
																									)}
																									fullWidth
																									value={row.price.value}
																									onClick={() => {
																										updateSelectedItem(`table.${i}.price`);
																										updateLocation(
																											formulaire.invoiceDto.billingLinesDtos[i]
																												.price.location
																										);
																									}}
																									onChange={(event) => {
																										const _formulaire = JSON.parse(
																											JSON.stringify(formulaire)
																										);
																										_formulaire.invoiceDto.billingLinesDtos[
																											i
																										].price.value = event.target.value;

																										props.updateFormulaire(_formulaire);
																									}}
																									disabled={
																										fromDigitalInvoice?.billingLines[i]?.price
																									}
																								/>
																							</TableCell>
																							<TableCell>
																								{" "}
																								<Input
																									className={clsx(
																										"classes.tableCell2",
																										isEmptyOrNull(
																											formulaire.invoiceDto.billingLinesDtos[i].vat
																												.value
																										) && classes.emptyInput
																									)}
																									fullWidth
																									value={row.vat.value}
																									onClick={() => {
																										updateSelectedItem(`table.${i}.vat`);
																										updateLocation(
																											formulaire.invoiceDto.billingLinesDtos[i].vat
																												.location
																										);
																									}}
																									onChange={(event) => {
																										const _formulaire = JSON.parse(
																											JSON.stringify(formulaire)
																										);
																										_formulaire.invoiceDto.billingLinesDtos[
																											i
																										].vat.value = event.target.value;

																										props.updateFormulaire(_formulaire);
																									}}
																									disabled={
																										fromDigitalInvoice?.billingLines[i]?.vat
																									}
																								/>
																							</TableCell>
																							<TableCell>
																								{" "}
																								<Input
																									className={clsx(
																										"classes.tableCell4",
																										rectify &&
																											isEmptyOrNull(
																												formulaire.invoiceDto.billingLinesDtos[i]
																													.totalHt.value
																											) &&
																											classes.emptyInput
																									)}
																									fullWidth
																									value={row.totalHt.value}
																									onClick={() => {
																										updateSelectedItem(`table.${i}.totalHt`);
																										updateLocation(
																											formulaire.invoiceDto.billingLinesDtos[i]
																												.totalHt.location
																										);
																									}}
																									onChange={(event) => {
																										const _formulaire = JSON.parse(
																											JSON.stringify(formulaire)
																										);
																										_formulaire.invoiceDto.billingLinesDtos[
																											i
																										].totalHt.value = event.target.value;

																										props.updateFormulaire(_formulaire);
																									}}
																									disabled={
																										fromDigitalInvoice?.billingLines[i]?.totalHt
																									}
																								/>
																							</TableCell>
																							<TableCell>
																								<IconButton
																									aria-label="add"
																									onClick={() => {
																										const _formulaire = JSON.parse(
																											JSON.stringify(formulaire)
																										);
																										_formulaire.invoiceDto.billingLinesDtos.splice(
																											i,
																											1
																										);
																										props.updateFormulaire(_formulaire);
																									}}
																									disabled={isDigitalInvoice}
																								>
																									<DeleteOutline />
																								</IconButton>
																							</TableCell>
																							<TableCell>
																								<IconButton
																									aria-label="add"
																									onClick={() => addBillingLine(i + 1)}
																									disabled={isDigitalInvoice}
																								>
																									<AddIcon />
																								</IconButton>
																							</TableCell>
																						</>
																					</TableRow>
																				))}
																		</>
																	) : (
																		<>
																			{billingLines &&
																				!isEmpty(billingLines) &&
																				billingLines.map((row, i) => (
																					<TableRow key={row.id}>
																						{isEditFacture ? (
																							<>
																								<TableCell>
																									{" "}
																									<Input
																										className={classes.tableCell1}
																										fullWidth
																										value={row.codeArticle}
																										onChange={(event) =>
																											onChangeBilling(event, i, "codeArticle")
																										}
																									/>
																								</TableCell>
																								<TableCell>
																									{" "}
																									<Input
																										className={classes.tableCell3}
																										fullWidth
																										value={row.designation}
																										onChange={(event) =>
																											onChangeBilling(event, i, "designation")
																										}
																									/>
																								</TableCell>
																								<TableCell>
																									{" "}
																									<Input
																										className={classes.tableCell2}
																										fullWidth
																										value={row.quantity}
																										onChange={(event) =>
																											onChangeBilling(event, i, "quantity")
																										}
																									/>
																								</TableCell>
																								<TableCell>
																									{" "}
																									<Input
																										className={classes.tableCell1}
																										fullWidth
																										value={row.price}
																										onChange={(event) =>
																											onChangeBilling(event, i, "price")
																										}
																									/>
																								</TableCell>
																								<TableCell>
																									{" "}
																									<Input
																										className={classes.tableCell2}
																										fullWidth
																										value={row.vat}
																										onChange={(event) =>
																											onChangeBilling(event, i, "vat")
																										}
																									/>
																								</TableCell>
																								<TableCell>
																									{" "}
																									<Input
																										className={classes.tableCell1}
																										fullWidth
																										value={row.totalHt}
																										onChange={(event) =>
																											onChangeBilling(event, i, "totalHt")
																										}
																									/>
																								</TableCell>
																								<TableCell>
																									<IconButton
																										aria-label="add"
																										onClick={() => {
																											const newRows = billingLines.slice();
																											newRows.splice(i, 1);
																											setBillingLines(newRows);
																										}}
																									>
																										<DeleteOutline />
																									</IconButton>
																								</TableCell>
																							</>
																						) : (
																							<>
																								<Tooltip title={row.codeArticle} arrow>
																									<TableCell>
																										<Typography
																											className={classes.tableCell1}
																											variant="subtitle"
																											align="left"
																										>
																											{minimizeValueCode(row.codeArticle)}
																										</Typography>
																									</TableCell>
																								</Tooltip>
																								<Tooltip title={row.designation} arrow>
																									<TableCell>
																										<Typography
																											className={classes.tableCell3}
																											variant="subtitle"
																											align="left"
																										>
																											{minimizeValueDesignation(row.designation)}
																										</Typography>
																									</TableCell>
																								</Tooltip>
																								<TableCell>
																									<Typography
																										className={classes.tableCell2}
																										variant="subtitle"
																										align="left"
																									>
																										{row.quantity}
																									</Typography>
																								</TableCell>
																								<TableCell>
																									<Typography
																										className={classes.tableCell1}
																										variant="subtitle"
																										align="left"
																									>
																										{row.price}
																									</Typography>
																								</TableCell>
																								<TableCell>
																									<Typography
																										className={classes.tableCell2}
																										variant="subtitle"
																										align="left"
																									>
																										{row.vat}
																									</Typography>
																								</TableCell>
																								<TableCell>
																									<Typography
																										className={classes.tableCell1}
																										variant="subtitle"
																										align="left"
																									>
																										{row.totalHt}
																									</Typography>
																								</TableCell>
																							</>
																						)}
																					</TableRow>
																				))}
																		</>
																	)}
																	{rectify ? (
																		<AmountsComponent
																			formulaire={formulaire}
																			rectify={rectify}
																			location={location}
																			setLocation={updateLocation}
																			invoice={invoice}
																			fromDigitalInvoice={fromDigitalInvoice}
																		/>
																	) : (
																		<>
																			<TableRow>
																				<TableCell rowSpan={100} />
																				<TableCell rowSpan={100} />
																				<TableCell rowSpan={100} />
																				<TableCell rowSpan={100} />
																			</TableRow>

																			{invoice &&
																				invoice.additionalAmountsHT &&
																				invoice.additionalAmountsHT.map((row) => (
																					<TableRow>
																						<TableCell align="right">{t("Total HT")}</TableCell>

																						<TableCell align="right">
																							{get(row, "amount")} {get(invoice, "currencySymbol")}
																						</TableCell>
																					</TableRow>
																				))}
																			{invoice &&
																				invoice.additionalAmountsTVA &&
																				invoice.additionalAmountsTVA.map((row) => (
																					<TableRow>
																						<TableCell align="right"> {t("VAT amount")}</TableCell>

																						<TableCell align="right">
																							{get(row, "amount")} {get(invoice, "currencySymbol")}
																						</TableCell>
																					</TableRow>
																				))}

																			<TableRow>
																				<TableCell align="right"> {t("Discount")}</TableCell>

																				<TableCell align="right">
																					{get(invoice, "discount")}{" "}
																					{get(invoice, "currencySymbol")}
																				</TableCell>
																			</TableRow>
																			{invoice &&
																				invoice.additionalAmountsCharge &&
																				invoice.additionalAmountsCharge.map((row) => (
																					<TableRow>
																						<TableCell align="right">
																							{" "}
																							{t("invoice_charge")}
																						</TableCell>

																						<TableCell align="right">
																							{get(row, "amount")} {get(invoice, "currencySymbol")}
																						</TableCell>
																					</TableRow>
																				))}
																			<TableRow>
																				<TableCell align="right">{t("Total amount")}</TableCell>
																				<TableCell align="right">
																					{get(invoice, "total")}{" "}
																					{invoice && invoice.currencySymbol}
																				</TableCell>
																			</TableRow>
																		</>
																	)}
																</TableBody>
															</Table>
															<>
																{get(invoice, "status.code") !== "TO_VALIDATE_EXTERNAL" && (
																	<Grid container xs={12} className={classes.approveContainer}>
																		<>
																			{get(invoice, "approvedBy") && (
																				<Grid item xs={6}>
																					<Typography
																						variant="subtitle2"
																						align="left"
																						color="primary"
																					>
																						{get(invoice, "isApproved") === false
																							? t("rejectedBy")
																							: t("approvedBy")}

																						<Typography
																							variant="body2"
																							align="left"
																							style={{
																								wordWrap: "break-word",
																								paddingRight: "20px",
																							}}
																						>
																							{get(invoice, "approvedBy")}
																						</Typography>
																					</Typography>
																				</Grid>
																			)}
																			{get(invoice, "approvedComment") && (
																				<Grid item xs={6}>
																					<Typography
																						variant="subtitle2"
																						align="left"
																						color="primary"
																					>
																						{t("comment")}

																						<Typography
																							variant="body2"
																							align="left"
																							className={classes.blackColor}
																						>
																							{get(invoice, "approvedComment")}
																							<Divider />
																						</Typography>
																					</Typography>
																				</Grid>
																			)}
																		</>
																	</Grid>
																)}
																<Divider />
																<>
																	{dataErrors &&
																		!isEmpty(dataErrors) &&
																		get(props.invoicePersisted, "status.code") !== INTEGRATION &&
																		dataErrors.map((row) => (
																			<Grid item className={classes.alert}>
																				<Alert severity="error" fullWidth>
																					{row.withParam
																						? t(row.message, { VatNumber: row.param })
																						: t(row)}
																				</Alert>
																			</Grid>
																		))}
																</>
																<>
																	<Grid id="error-warning-container" container item xs={12}>
																		{warningsToFix &&
																			warningsToFix.map((alrt) => (
																				<Grid item className={classes.alert}>
																					<Alert severity={alrt.severity.toLowerCase()} fullWidth>
																						{alrt.message}
																					</Alert>
																				</Grid>
																			))}
																	</Grid>
																</>
															</>
														</TableContainer>
													</Grid>
												</Grid>
											)}
										</Grid>
									) : (
										<CircularLoader />
									)}
								</TabPanel>
							)}
							<TabPanel section={1} value={value} index={1} dir={theme.direction}>
								<Grid container item xs={12} className={classes.orderTab} spacing={4}>
									<Grid container item xs={12} justify="center" alignItems="flex-end" spacing={4}>
										<Grid item xs={6}>
											<Typography variant="subtitle2" align="left" color="primary">
												{t("orderNo")}
												{"	"}
												{invoice && isDigitalInvoice && fromDigitalInvoice?.purchaseOrderNo ? (
													<DigitalChip />
												) : null}
												{isEditFacture || rectify ? (
													<Input
														className={clsx(
															"classes.resize",
															rectify &&
																isEmptyOrNull(formulaire.invoiceDto.order.orderNo.value) &&
																classes.emptyInput
														)}
														fullWidth
														onClick={() => {
															updateSelectedItem("order.orderNo");
															updateLocation(formulaire.invoiceDto.order.orderNo.location);
														}}
														disabled={
															invoice && isDigitalInvoice && fromDigitalInvoice?.purchaseOrderNo
														}
														value={
															isDigitalInvoice && fromDigitalInvoice?.purchaseOrderNo
																? get(invoice, "purchaseOrderNo")
																: rectify
																? formulaire.invoiceDto.order.orderNo.value
																: get(invoice, "purchaseOrderNo")
														}
														onChange={(e) => {
															if (rectify) {
																const _formulaire = JSON.parse(JSON.stringify(formulaire));
																_formulaire.invoiceDto.order.orderNo.value = e.target.value;
																if (isEmptyOrNull(e.target.value)) {
																	_formulaire.invoiceDto.order.orderNo.location = {
																		top: null,
																		right: null,
																		left: null,
																		bottom: null,
																	};
																}
																props.updateFormulaire(_formulaire);
															} else {
																setOrder({ ...order, orderNo: e.target.value });
															}
														}}
													/>
												) : (
													<Typography variant="body2" align="left" className={classes.blackColor}>
														{get(invoice, "purchaseOrderNo")}
														<Divider />
													</Typography>
												)}
											</Typography>
										</Grid>

										<Grid item xs={6}>
											<Typography variant="subtitle2" align="left" color="primary">
												{t("issueDate")}
												<br />
												<Typography variant="body2" align="left" className={classes.blackColor}>
													{get(invoice, "purchaseOrder.issueDate")}

													<Divider />
												</Typography>
											</Typography>
										</Grid>
									</Grid>
								</Grid>
								<Grid container item xs={12} className={classes.cardBilling}>
									<Grid
										container
										item
										alignItems="center"
										xs={12}
										className={classes.headerBillingCard}
									>
										<Grid item alignContent="center">
											<Typography variant="subtitle2" align="left" color="primary">
												{t("order Lines")}
											</Typography>
										</Grid>
									</Grid>
									<Grid container item alignItems="center" justify="center" xs={12}>
										<TableContainer
											component={Paper}
											elevation={0}
											className={classes.tableContainer}
										>
											<Table aria-label="spanning table">
												<TableHead>
													<TableRow>
														<TableCell>
															<Typography
																className={classes.tableCell1}
																variant="subtitle2"
																align="left"
															>
																{t("itemCode")}
															</Typography>
														</TableCell>
														<TableCell>
															<Typography
																className={classes.tableCell2}
																variant="subtitle2"
																align="left"
															>
																{t("designation")}
															</Typography>
														</TableCell>

														<TableCell>
															<Typography
																className={classes.tableCell2}
																variant="subtitle2"
																align="left"
															>
																{t("quantity")}
															</Typography>
														</TableCell>

														<TableCell>
															<Typography
																className={classes.tableCell1}
																variant="subtitle2"
																align="left"
															>
																{t("unitePriceHT")}
															</Typography>
														</TableCell>

														<TableCell>
															<Typography
																className={classes.tableCell2}
																variant="subtitle2"
																align="left"
															>
																{t("vat")}
															</Typography>
														</TableCell>

														<TableCell>
															<Typography
																className={classes.tableCell1}
																variant="subtitle2"
																align="left"
															>
																{t("price TTC")}
															</Typography>
														</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													<>
														{get(invoice, "purchaseOrder.purchaseOrderLines") &&
															!isEmpty(get(invoice, "purchaseOrder.purchaseOrderLines")) &&
															invoice.purchaseOrder.purchaseOrderLines.map((row, i) => (
																<TableRow key={i}>
																	<>
																		<Tooltip title={row.codeArticle} arrow>
																			<TableCell>
																				<Typography
																					className={classes.tableCell1}
																					variant="subtitle"
																					align="left"
																				>
																					{minimizeValueCode(row.codeArticle)}
																				</Typography>
																			</TableCell>
																		</Tooltip>
																		<Tooltip title={row.article} arrow>
																			<TableCell>
																				<Typography
																					className={classes.tableCell3}
																					variant="subtitle"
																					align="left"
																				>
																					{minimizeValueDesignation(row.article)}
																				</Typography>
																			</TableCell>
																		</Tooltip>
																		<TableCell>
																			<Typography
																				className={classes.tableCell2}
																				variant="subtitle"
																				align="left"
																			>
																				{row.quantity}
																			</Typography>
																		</TableCell>
																		<TableCell>
																			<Typography
																				className={classes.tableCell2}
																				variant="subtitle"
																				align="left"
																			>
																				{row.price}
																			</Typography>
																		</TableCell>
																		<TableCell>
																			<Typography
																				className={classes.tableCell1}
																				variant="subtitle"
																				align="left"
																			>
																				{row.vat}
																			</Typography>
																		</TableCell>
																		<TableCell>
																			<Typography
																				className={classes.tableCell1}
																				variant="subtitle"
																				align="left"
																			>
																				{row.totalHt}
																			</Typography>
																		</TableCell>
																	</>
																</TableRow>
															))}
													</>
													<TableRow>
														<TableCell rowSpan={6} />
														<TableCell rowSpan={6} />
														<TableCell rowSpan={6} />
														<TableCell rowSpan={6} />
														<TableCell
															align="right"
															className={clsx(rectify && classes.rectifyHover)}
														>
															{t("Total HT")}
														</TableCell>
														<TableCell align="right">
															{get(invoice, "purchaseOrder.amountHT")}{" "}
															{get(invoice, "purchaseOrder.currency")}
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell
															align="right"
															className={clsx(rectify && classes.rectifyHover)}
														>
															{t("VAT amount")}
														</TableCell>
														<TableCell align="right">
															{get(invoice, "purchaseOrder.amountTVA")}{" "}
															{get(invoice, "purchaseOrder.currency")}
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell
															align="right"
															className={clsx(rectify && classes.rectifyHover)}
														>
															{t("Total amount")}
														</TableCell>
														<TableCell align="right">
															{get(invoice, "purchaseOrder.totalAmount")}{" "}
															{get(invoice, "purchaseOrder.currency")}
														</TableCell>
													</TableRow>
												</TableBody>
											</Table>
										</TableContainer>
									</Grid>
								</Grid>
							</TabPanel>
							{!isEmpty(get(invoice, "purchaseOrder.deliveryItems", [])) &&
								get(props.invoicePersisted, "status.code") !== INTEGRATION && (
									<TabPanel section={2} value={value} index={2} dir={theme.direction}>
										<DeliveryItemsSection
											deliveryItems={get(invoice, "purchaseOrder.deliveryItems")}
											orderNo={get(invoice, "purchaseOrder.orderNo")}
										/>
									</TabPanel>
								)}
						</Grid>
					</Grid>
				)}

				<Grid container item xs={rectify ? 6 : 5}>
					<Grid item xs={12}>
						{!isDocumentInternal && rectify ? (
							<DesignCanvas
								selectedField={(e) => {
									setSelectedFied(e);
								}}
								invoiceId={props.match.params.idInvoice}
								location={location}
								previewFile={previewFile}
								isSelectedField={isSelectedField}
								t={t}
							/>
						) : (
							<>
								<AppBar
									position="relative"
									color="default"
									elevation={0}
									className={classes.rootAppbar}
								>
									<Tabs
										className={classes.root}
										value={scValue}
										onChange={handleChangeSecondTab}
										indicatorColor="secondary"
										textColor="secondary"
										variant="fullWidth"
										aria-label="full width tabs example2"
									>
										{/* eslint-disable-next-line */}
										<Tab label={t("Invoice overview")} {...a11yProps(0)} />
										{/* eslint-disable-next-line */}
										{!isSupplier && <Tab label={t("History")} {...a11yProps(1)} />}
										<Tab label={t("Invoice attachements")} {...a11yProps(isSupplier ? 1 : 2)} />
									</Tabs>
								</AppBar>
								<TabPanel
									style={{
										minHeight: "800px",
									}}
									section={2}
									value={scValue}
									index={0}
									dir={theme.direction}
								>
									{previewFile ? (
										mimeType === "application/pdf" && previewFile ? (
											<>
												<PDFViewer
													backend={PDFJSBackend}
													src={previewFile}
													style={{
														height: "800px",
													}}
												/>
											</>
										) : (
											<img src={previewFile} className={classes.previewImg} alt="preview" />
										)
									) : (
										<CircularLoader />
									)}
								</TabPanel>
								{!isSupplier && (
									<TabPanel section={2} value={scValue} index={1} dir={theme.direction}>
										<History
											idInvoice={props.match.params.idInvoice}
											{...props} //eslint-disable-line
										/>
									</TabPanel>
								)}
								<TabPanel
									section={3}
									value={scValue}
									index={isSupplier ? 1 : 2}
									dir={theme.direction}
									style={{
										minHeight: "800px",
									}}
								>
									<Attachements />
								</TabPanel>
							</>
						)}
					</Grid>
				</Grid>
			</Grid>
			<Grid container item xs={12} className={classes.detailsContent} justify="space-evenly">
				{!rectify ? (
					<>
						<Grid container item xs={5} justify="space-evenly">
							{get(props, "invoicePersisted.status.code") === "EXPORTED" && settings.loaded && (
								<Grid container item xs={12} spacing={1} style={{ marginTop: "20px" }}>
									{getDataFromSettings("EXPORT_EXCEL") && (
										<Grid item xs={4}>
											<Button
												variant="contained"
												fullWidth
												color="secondary"
												onClick={() => {
													exportEXCEl();
												}}
												disabled={isLoading?.excel}
											>
												{isLoading?.excel && <CircularProgress size={14} />}&nbsp;
												{t("EXPORT_EXCEL")}
											</Button>
										</Grid>
									)}

									{getDataFromSettings("EXPORT_XML") && (
										<Grid item xs={4}>
											<Button
												variant="contained"
												fullWidth
												color="secondary"
												onClick={() => {
													exportXML();
												}}
												disabled={isLoading?.xml}
											>
												{isLoading?.xml && <CircularProgress size={14} />}&nbsp;
												{t("EXPORT_XML")}
											</Button>
										</Grid>
									)}
									{getDataFromSettings("EXPORT_PDF") && (
										<Grid item xs={4}>
											<Button
												variant="contained"
												fullWidth
												color="secondary"
												onClick={() => {
													exportPDF();
												}}
												disabled={isLoading?.pdf}
											>
												{isLoading?.pdf && <CircularProgress size={14} />}&nbsp;
												{t("EXPORT_PDF")}
											</Button>
										</Grid>
									)}
									{getDataFromSettings("EXPORT_EDI") && (
										<Grid item xs={4}>
											<Button
												variant="contained"
												fullWidth
												color="secondary"
												onClick={() => {
													exportEdifact();
												}}
												disabled={isLoading?.edi}
											>
												{isLoading?.edi && <CircularProgress size={14} />}&nbsp;
												{t("EXPORT_EDI")}
											</Button>
										</Grid>
									)}
									{getDataFromSettings("EXPORT_IDOC") && (
										<Grid item xs={4}>
											<Button
												variant="contained"
												fullWidth
												color="secondary"
												onClick={() => {
													exportIdoc();
												}}
												disabled={isLoading?.idoc}
											>
												{isLoading?.idoc && <CircularProgress size={14} />}&nbsp;
												{t("EXPORT_IDOC")}
											</Button>
										</Grid>
									)}

									{getDataFromSettings("EXPORT_EC") && (
										<Grid item xs={4}>
											<Button
												variant="contained"
												fullWidth
												color="secondary"
												onClick={() => {
													exportEC();
												}}
												disabled={isLoading?.ec}
											>
												{isLoading?.ec && <CircularProgress size={14} />}&nbsp;
												{t("EXPORT_EC")}
											</Button>
										</Grid>
									)}
								</Grid>
							)}
							{!isSupplier && (
								<Grid
									container
									item
									xs={12}
									direction="row"
									justify="flex-start"
									style={{ marginTop: "20px" }}
								>
									{showRectify && props.invoicePersisted && (
										<Grid item xs={4} style={{ padding: "2px" }}>
											<Button
												variant="outlined"
												fullWidth
												className={classes.button}
												color="secondary"
												onClick={() => setRectify(!rectify)}
											>
												{t("Correct Extraction")}
											</Button>
										</Grid>
									)}
									{transitions.map((transition) => {
										if (transition.targetStatusCode.toUpperCase() === "REJECTED") {
											return (
												<Grid item xs={4} style={{ padding: "2px" }}>
													<Button
														disableRipple
														variant="contained"
														fullWidth
														className={clsx(classes.button, classes.rejectButton)}
														onClick={() => {
															setOpenRejectPopup(true);
														}}
														disabled={pendingValidation}
													>
														{t("Reject Invoice")}
													</Button>
													<DialogReject
														open={openRejectPopup}
														setOpen={setOpenRejectPopup}
														onClose={onCloseDialogReject}
														{...props} // eslint-disable-line
													/>
												</Grid>
											);
										}
										if (
											transition.targetStatusCode === "TO_VALIDATE_EXTERNAL" &&
											!props.invoicePersisted.duplicated
										) {
											return props.invoicePersisted.isApproved ? (
												<></>
											) : (
												<Grid item xs={4} style={{ padding: "2px" }}>
													<>
														<Button
															className={classes.button}
															variant="outlined"
															fullWidth
															color="secondary"
															onClick={() => {
																setOpenApplyForApprovalPopup(true);
															}}
															disabled={pendingValidation}
														>
															{t("Apply for approval")}
														</Button>
													</>

													<ApprovalDialog
														open={openApplyForApprovalPopup}
														setOpen={setOpenApplyForApprovalPopup}
														{...props} // eslint-disable-line
													/>
												</Grid>
											);
										}
										if (
											transition.targetStatusCode === "EXPORTED" &&
											!props.invoicePersisted.duplicated
										) {
											return (
												<Grid item xs={4} style={{ padding: "2px" }}>
													<Button
														variant="contained"
														fullWidth
														className={classes.button}
														color="secondary"
														onClick={() => {
															setOpenExportSatus(true);
														}}
														disabled={pendingValidation}
													>
														{t("export")}
													</Button>
												</Grid>
											);
										}
										if (!props.invoicePersisted.duplicated) {
											return (
												<Grid item xs={4} style={{ padding: "2px" }}>
													<Button
														variant="contained"
														fullWidth
														className={classes.button}
														color="secondary"
														onClick={() => {
															setConfirmTransition(transition);
															setOpenNextSatus(true);
														}}
														disabled={pendingValidation}
													>
														{t(transition.buttonLabel) || transition.description}
													</Button>
												</Grid>
											);
										}
										return <></>;
									})}
								</Grid>
							)}
							<Grid container item xs={5} className={classes.header} spacing={1}>
								<Grid item xs={4} style={{ padding: "0 4px 0 0" }}>
									{" "}
								</Grid>
							</Grid>
						</Grid>
						<Grid container item xs={5} justify="space-evenly">
							&nbsp;
						</Grid>
					</>
				) : (
					<>
						<Grid container item xs={5}>
							<div style={{ position: "absolute", top: "1110px", width: "39%" }}>
								<Grid
									container
									item
									xs={12}
									className={classes.rectifyHeader}
									spacing={1}
									direction="row-reverse"
								>
									<Grid item xs={4}>
										<Button
											variant="outlined"
											fullWidth
											color="secondary"
											disabled={
												(props.invoicePersisted?.supplier &&
													props.invoicePersisted?.supplier?.lockedTraining) ||
												isDocumentInternal
											}
											onClick={() => {
												setConfirmObject({
													message: t("invoice.detail.message.rectify"),
													openConfirm: true,
													rectify: true,
													training: true,
												});
											}}
										>
											{t("invoice.detail.apply.validate")}
										</Button>
									</Grid>
									<Grid item xs={4}>
										<Button
											variant="outlined"
											fullWidth
											color="secondary"
											onClick={() => {
												setConfirmObject({
													message: t("invoice.detail.message.rectify"),
													openConfirm: true,
													rectify: true,
													training: false,
												});
											}}
										>
											{t("Apply Changes")}
										</Button>
									</Grid>

									<Grid item xs={4}>
										<Button
											variant="outlined"
											fullWidth
											className={classes.rejectButton}
											onClick={() => {
												setConfirmObject({
													message: t("CancelActionMessage"),
													openConfirm: true,
													cancel: true,
												});
											}}
										>
											{t("cancel")}
										</Button>
									</Grid>
								</Grid>
							</div>
						</Grid>
						<Grid container item xs={6} justify="space-evenly">
							&nbsp;
						</Grid>
					</>
				)}
			</Grid>
			{/* <Grid container item xs={12} justify="space-evenly" style={{ height: "40px" }}> */}

			<Dialog
				open={openAlertesDialog}
				onClose={() => setOpenAlertesDialog(!openAlertesDialog)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				maxWidth="xs"
				fullWidth
			>
				<DialogTitle style={{ color: "#0da8ad" }} id="alert-dialog-title">
					<Typography variant="h6">{t("completeerrors")}</Typography>
					<Typography variant="h6">{t("completeerrorsphrase")}</Typography>
				</DialogTitle>

				<DialogContent>
					<Grid direction="row" justify="space-between" container>
						<Grid>
							{finalErrorsCount !== 0 && (
								<Grid>
									<FlagIcon htmlColor="red" />
									<b>
										{t("ruleerrors")}: {finalErrorsCount}
									</b>
								</Grid>
							)}
							{warningsToFix.length > 0 && (
								<Grid>
									<FlagIcon htmlColor="orange" />
									<b>
										{t("warnings")}: {finalWarningsCount}
									</b>
								</Grid>
							)}
						</Grid>

						<img src={Erreur} style={{ width: 200, height: 180 }} alt="error icon" />
					</Grid>
				</DialogContent>
				<DialogActions>
					<Grid container xs={12} direction="row" justify="space-evenly">
						{finalErrorsCount === 0 ? (
							<>
								<Button
									onClick={() => {
										setOpenAlertesDialog(!openAlertesDialog);
										document.getElementById("error-warning-container").scrollIntoView();
									}}
									type="submit"
									color="secondary"
									variant="contained"
								>
									{t("checkerrors")}
								</Button>
								<Button
									onClick={() => {
										updateStatusWf(invoice.id, currentTransition);
									}}
									type="submit"
									color="secondary"
									variant="outlined"
								>
									{t("completetask")}
								</Button>
							</>
						) : (
							<Button
								onClick={() => {
									const prevWarnings = [...warningsToFix];
									// eslint-disable-next-line no-return-assign
									prevWarnings.forEach((warning) => (warning.severity = "error"));
									setWarningsToFix(prevWarnings);
									setOpenAlertesDialog(!openAlertesDialog);
								}}
								type="submit"
								color="secondary"
								variant="contained"
							>
								{t("correcterrors")}
							</Button>
						)}
					</Grid>
				</DialogActions>
			</Dialog>
			<ConfirmDialog
				message={confirmObject.message}
				open={confirmObject.openConfirm}
				onConfirm={onConfirm}
				onCancel={onCancel}
			/>
			<ConfirmDialog
				message={`${t("confirmation.nextStatus")} " ${
					t(confirmTransition.buttonLabel) || confirmTransition.description
				} " ?`}
				open={openNextSatus}
				isLoading={nextStatusLoading}
				onConfirm={onConfirmNextStatus}
				onCancel={onCancelNextStatus}
			/>
			<ConfirmDialog
				message={t("confirmation.ExportStatus")}
				open={openExportSatus}
				onConfirm={onConfirmExportStatus}
				onCancel={onCancelExportStatus}
				isLoading={nextStatusLoading}
			/>

			<Grid container item xs={12} direction="column" className={classes.headerAction} />
		</Grid>
	);
}

const mapStateToProps = (state) => {
	const dataQueris = state.requests.queries;
	const dataMutations = state && state.mutations;
	const { formulaire } = state;
	return {
		statusList:
			dataQueris.LIST_FETCH_STATUS &&
			dataQueris.LIST_FETCH_STATUS.data &&
			dataQueris.LIST_FETCH_STATUS.data.invoiceStatusList,
		suppliers: dataQueris.FETCH_SUPPLIERS && dataQueris.FETCH_SUPPLIERS.data,
		supplier: dataQueris.FETCH_SUPPLIER && dataQueris.FETCH_SUPPLIER.data,
		status: dataQueris.FETCH_STATUS && dataQueris.FETCH_STATUS.data,
		paymentTerm: dataQueris.FETCH_PAYMENT_TERM && dataQueris.FETCH_PAYMENT_TERM.data,
		paymentTerms: dataQueris.FETCH_PAYMENT_TERMS && dataQueris.FETCH_PAYMENT_TERMS.data,
		invoicePersisted: dataQueris.FETCH_INVOICE && dataQueris.FETCH_INVOICE.data,
		document: dataQueris.FETCH_DOCUMENT_INVOICE && dataQueris.FETCH_DOCUMENT_INVOICE.data,
		response: dataQueris.RESPONSE && dataQueris.RESPONSE.data,
		rejectionMotifs: dataQueris.FETCH_REJECTION_MOTIFS && dataQueris.FETCH_REJECTION_MOTIFS.data,
		rejectionError:
			dataMutations &&
			dataMutations.CREATE_INVOICE_REJECTION &&
			dataMutations.CREATE_INVOICE_REJECTION.error,
		selectedItem: formulaire.selectedItem,
		glAccounts: dataQueris.LIST_FETCH_GLACCOUNTS && dataQueris.LIST_FETCH_GLACCOUNTS.data,
		costCenters: dataQueris.LIST_FETCH_COSTCENTERS && dataQueris.LIST_FETCH_COSTCENTERS.data,
		paymentMethods:
			dataQueris.LIST_FETCH_PAYMENTMETHODS && dataQueris.LIST_FETCH_PAYMENTMETHODS.data,
	};
};

const mapDispatchToProps = {
	fetchInvoice,
	fetchDocumentInvoice,
	fetchSuppliers,
	updateInvoice,
	fetchRejectionMotifs,
	createInvoiceRejection,
	verifyInvoice,
	fetchPaymentTerms,
	fetchBillingLines,
	rectifyInvoice,
	topayInvoice,
	updateFormulaire,
	createInvoiceApproval,
	fetchGlAccounts,
	fetchCostCenters,
	fetchPaymentMethods,
};

export default connect(mapStateToProps, mapDispatchToProps)(Detail);
