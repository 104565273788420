import * as React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import {
	Button,
	Dialog,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	Menu,
	MenuItem,
	Tooltip,
} from "@material-ui/core";
import Stack from "@mui/material/Stack";
import get from "lodash/get";
import { toast } from "utils";
import { isInternal } from "pages/Invoices/utils";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ErrorOutline from "@material-ui/icons/ErrorOutline";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";

import {
	downloadInvoiceExcel,
	exportSingleAccountingEntryExcel,
	downloadXMLInvoice,
	downloadEdifactInvoice,
	downloadIdocInvoice,
	downloadDigitalInvoice,
} from "../../../redux/actions/invoices";

export default function ExportMenu({ row, settings, index }) {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const history = useHistory();

	const isDocumentInternal = isInternal(row.source);

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [settingsLst, setSettingsLst] = React.useState([]);
	const showMenu = Boolean(anchorEl);

	const showMenuItem =
		settingsLst &&
		settingsLst?.length > 0 &&
		settingsLst?.filter((s) => s > 0 || s === true).length > 0;

	const [loading, setLoading] = React.useState(false);

	const openMenu = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const closeMenu = () => {
		setAnchorEl(null);
	};

	const enableLoading = () => {
		setLoading(true);
	};

	const disableLoading = () => {
		setLoading(false);
	};

	const [showDialog, setShowDialog] = React.useState(false);
	const [errors, setErrors] = React.useState([]);

	const onError = () => {
		toast.error(t("invoice.detail.export.failed"));
	};

	const handleError = (error) => {
		if (error.response.status === 500) {
			toast.error(t("invoice.detail.export.failed"));
		} else {
			setShowDialog(true);
			setErrors(error.response.data.detail.replace("[", "").replace("]", "").split(","));
		}
	};

	const closeDialog = () => {
		setShowDialog(false);
	};

	React.useEffect(() => {
		setSettingsLst([
			settings?.INVOICE_EXPORT_EXCEL,
			settings?.INVOICE_EXPORT_EXCEL_ACCOUNTING_ENTRY,
			settings?.INVOICE_EXPORT_XML,
			settings?.INVOICE_EXPORT_IDOC,
			settings?.INVOICE_EXPORT_EDI,
			settings?.INVOICE_EXPORT_UBL,
			settings?.INVOICE_EXPORT_UBL && !isDocumentInternal,
			settings?.INVOICE_EXPORT_CII,
			settings?.INVOICE_EXPORT_CII && !isDocumentInternal,
			settings?.INVOICE_EXPORT_FACTURX && !isDocumentInternal,
		]);
	}, [settings]);

	return (
		<>
			<Tooltip placement="top" title={t("tooltip.action.export")}>
				<div>
					{loading ? (
						<Box sx={{ display: "flex" }}>
							<CircularProgress size={14} />
						</Box>
					) : (
						<>
							{showMenuItem && (
								<IconButton
									id={`invoice-export-dropdown-${index}`}
									onClick={(e) => {
										openMenu(e);
									}}
								>
									<ImportExportIcon style={{ color: "#707070" }} />
								</IconButton>
							)}
						</>
					)}
					<Menu
						anchorEl={anchorEl}
						anchorOrigin={{
							vertical: "top",
							horizontal: "left",
						}}
						transformOrigin={{
							vertical: "top",
							horizontal: "right",
						}}
						open={showMenu}
						onClose={closeMenu}
						style={{ display: "flex" }}
						id={`invoice-export-dropdown-container-${index}`}
					>
						{settingsLst[0] ? (
							<MenuItem
								id={`invoice-export-excel-${index}`}
								disabled={get(row, "status.code") === "INTEGRATION"}
								onClick={() => {
									enableLoading();
									dispatch(downloadInvoiceExcel(row.id, onError, disableLoading));
									closeMenu();
								}}
							>
								Excel
							</MenuItem>
						) : null}

						{settingsLst[1] ? (
							<MenuItem
								id={`invoice-export-acc-${index}`}
								disabled={get(row, "status.code") === "INTEGRATION"}
								onClick={() => {
									enableLoading();
									dispatch(exportSingleAccountingEntryExcel(row.id, onError, disableLoading));
									closeMenu();
								}}
							>
								{t("invoice.export.entry_account_excel")}
							</MenuItem>
						) : null}

						{settingsLst[2] ? (
							<MenuItem
								id={`invoice-export-xml-${index}`}
								onClick={() => {
									enableLoading();
									dispatch(downloadXMLInvoice(row.id, onError, disableLoading));
									closeMenu();
								}}
							>
								XML
							</MenuItem>
						) : null}

						{settingsLst[3] ? (
							<MenuItem
								id={`invoice-export-idoc-${index}`}
								onClick={() => {
									enableLoading();
									dispatch(downloadIdocInvoice(row.id, onError, disableLoading));
									closeMenu();
								}}
							>
								IDOC
							</MenuItem>
						) : null}

						{settingsLst[4] ? (
							<MenuItem
								id={`invoice-export-edi-${index}`}
								onClick={() => {
									enableLoading();
									dispatch(downloadEdifactInvoice(row.id, onError, disableLoading));
									closeMenu();
								}}
							>
								EDIFACT
							</MenuItem>
						) : null}

						{settingsLst[5] ? (
							<MenuItem
								id={`invoice-export-ubl21-${index}`}
								onClick={() => {
									enableLoading();
									dispatch(downloadDigitalInvoice("ubl", row.id, handleError, disableLoading));
									closeMenu();
								}}
							>
								UBL
							</MenuItem>
						) : null}

						{settingsLst[6] ? (
							<MenuItem
								id={`invoice-export-ubl-minim-${index}`}
								onClick={() => {
									enableLoading();
									dispatch(
										downloadDigitalInvoice("ublminimal", row.id, handleError, disableLoading)
									);
									closeMenu();
								}}
							>
								UBL Minimal
							</MenuItem>
						) : null}

						{settingsLst[7] ? (
							<MenuItem
								id={`invoice-export-cii-${index}`}
								onClick={() => {
									enableLoading();
									dispatch(downloadDigitalInvoice("cii", row.id, handleError, disableLoading));
									closeMenu();
								}}
							>
								CII
							</MenuItem>
						) : null}

						{settingsLst[8] ? (
							<MenuItem
								id={`invoice-export-cii16b-${index}`}
								onClick={() => {
									enableLoading();
									dispatch(
										downloadDigitalInvoice("ciiminimal", row.id, handleError, disableLoading)
									);
									closeMenu();
								}}
							>
								CII Minimal
							</MenuItem>
						) : null}

						{settingsLst[9] ? (
							<MenuItem
								id={`invoice-export-facturex-${index}`}
								onClick={() => {
									enableLoading();
									dispatch(downloadDigitalInvoice("facturx", row.id, handleError, disableLoading));
									closeMenu();
								}}
							>
								Factur-X
							</MenuItem>
						) : null}
					</Menu>
				</div>
			</Tooltip>
			<Dialog
				open={showDialog}
				onClose={closeDialog}
				aria-labelledby="scroll-dialog-title"
				aria-describedby="scroll-dialog-description"
			>
				<DialogTitle id={`scroll-dialog-title-${index}`}>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							flex: "wrap",
							flexDirection: "column",
							color: "red",
							fontSize: 18,
						}}
					>
						<ErrorOutline color="error" fontSize="large" style={{ fontSize: 60 }} />
						{t("invoice.detail.export.failed")}
					</div>
				</DialogTitle>
				<DialogContent>
					<DialogContentText id={`scroll-dialog-description-${index}`} tabIndex={-1}>
						<div
							style={{
								display: "flex",
								flex: "wrap",
								flexDirection: "column",
								alignItems: "center",
								color: "black",
							}}
						>
							{errors.map((error) => (
								<li style={{ lineHeight: 2 }}>{t(error.trim())}</li>
							))}
							<br />
						</div>
					</DialogContentText>
					<Stack direction="row" justifyContent="center" spacing={2} style={{ marginBottom: 14 }}>
						<Button
							id="bff-export-error-dialog-return-btn"
							variant="contained"
							startIcon={<ArrowBackIcon />}
							onClick={closeDialog}
							style={{
								color: "white",
								backgroundColor: "#0da8ad",
							}}
						>
							{t("return")}
						</Button>
						<Button
							id="bff-export-error-dialog-goto-btn"
							variant="contained"
							endIcon={<VisibilityIcon />}
							onClick={() => history.push(`invoices/details/${row.id}`)}
							style={{
								color: "white",
								backgroundColor: "#0da8ad",
							}}
						>
							{t("invoice.go.to")}
						</Button>
					</Stack>
				</DialogContent>
			</Dialog>
		</>
	);
}
