import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import {
	Button,
	Typography,
	TextField,
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
	CircularProgress,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

const DialogReject = ({ open, setOpen, ...props }) => {
	const { t } = useTranslation();
	const [rejectionMotifs, setRejectionMotifs] = React.useState(props.rejectionMotifs);
	const [isLoading, setIsLoading] = React.useState(false);

	useEffect(() => {
		if (!rejectionMotifs) {
			setRejectionMotifs(props.rejectionMotifs);
		}
	});

	const { errors, handleSubmit, register } = useForm();

	const handleCloseRejectPopup = () => {
		setOpen(!open);
		setIsLoading(false);
	};

	const onSubmit = (data) => {
		setIsLoading(true);
		const listMotifs = rejectionMotifs && rejectionMotifs.orderRejectionMotifList;
		const motif = listMotifs.find((motifParam) => t(motifParam.label) === data.motif);
		const id = props.orderPersisted && props.orderPersisted.id;
		props.createOrderRejection(
			id,
			{ orderId: id, motif: motif.code, comment: data.comment },
			() => {
				props.onClose(false);
				handleCloseRejectPopup();
			},
			() => {
				props.onClose(true);
				handleCloseRejectPopup();
			}
		);
	};

	return (
		<Dialog
			open={open}
			onClose={handleCloseRejectPopup}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">{t("orderRejectMessage")}</DialogTitle>
			<form onSubmit={handleSubmit(onSubmit)}>
				<DialogContent>
					<Autocomplete
						options={rejectionMotifs && rejectionMotifs.orderRejectionMotifList}
						getOptionLabel={({ label = "" }) => t(label)}
						id="rejectionReason"
						renderInput={(params) => (
							<div>
								<TextField
									{...params} // eslint-disable-line
									inputRef={register({ required: true })}
									variant="standard"
									label={t("rejectionReason")}
									margin="normal"
									name="motif"
								/>
								{errors.motif && (
									<Typography variant="subtitle2" align="left" color="error">
										{t("this field is mandatory")}
									</Typography>
								)}
							</div>
						)}
					/>
					<TextField
						inputRef={register}
						name="comment"
						id="outlined-multiline-static"
						label={t("rejectionDetails")}
						multiline
						fullWidth
						rows={4}
						defaultValue=""
						variant="outlined"
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseRejectPopup} color="secondary" disabled={isLoading} autoFocus>
						{t("cancel")}
					</Button>
					<Button type="submit" color="secondary" disabled={isLoading}>
						{isLoading && <CircularProgress size={14} />}&nbsp; {t("confirm")}
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
};

export default DialogReject;
