import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(() => ({
	disabled: {
		color: "rgba(0, 0, 0, 0.26)",
		boxShadow: "none",
		backgroundColor: "rgba(0, 0, 0, 0.12)",
		"&:hover": {
			background: "white",
			transform: "scale(1.01)",
		},
	},
	enabled: {
		cursor: "default",
		pointerEvents: "none",
	},
}));
