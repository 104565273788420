import React, { useEffect } from "react";
import { Grid, Card, CardContent, Typography, CardActions, Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { get, orderBy } from "lodash";
import { listAllSuppliers } from "redux/actions/suppliers";
import { listAllClients } from "../../../../redux/actions/clients";

const CommercialOrganizationSuppliers = ({
	t,
	classes,
	selectedSuppliers,
	setSelectedSuppliers,
	supplierSearchValue,
}) => {
	const dispatch = useDispatch();
	const isOrderEnv = useSelector(({ appState }) => appState.isOrderEnv);
	const suppliers = useSelector(({ requests }) =>
		get(requests, "queries.FETCH_ALL_SUPPLIERS.data")
	);
	const clients = useSelector(({ requests }) => get(requests, "queries.FETCH_ALL_CLIENTS.data"));
	const filterUsers = useSelector(({ filterUsers: i }) => i);

	useEffect(() => {
		if (isOrderEnv) {
			dispatch(listAllClients());
		} else {
			dispatch(listAllSuppliers());
		}
	}, [filterUsers, dispatch]);

	const addSupplier = (id) => {
		if (selectedSuppliers?.includes(id)) {
			setSelectedSuppliers(selectedSuppliers?.filter((val) => val !== id));
		} else {
			setSelectedSuppliers([...selectedSuppliers, id]);
		}
	};

	const renderSuppliersList = () => {
		if (!clients && !suppliers) return <></>;
		let data = [];
		if (isOrderEnv) {
			if (clients) {
				data = [...clients];
			}
		} else if (suppliers) {
			data = [...suppliers];
		}

		data = data?.map((val) => ({
			...val,
			selected: selectedSuppliers?.includes(get(val, "id")),
		}));
		const newData = orderBy(data, ["selected", (row) => row?.code?.toLowerCase()], ["desc", "asc"]);

		return newData
			.filter((sup) => sup?.code?.toLowerCase()?.includes(supplierSearchValue?.toLowerCase() || ""))
			.map((supplier, index) => (
				<Card
					key={`commercial-organization-supplier-${index}`}
					className={classes.root}
					elevation={1}
				>
					<CardContent className={classes.content}>
						<Grid container item xs={12} direction="row" justify="space-between">
							<Grid container item xs={9} justify="flex-start">
								<Typography>{get(supplier, "code")}</Typography>
							</Grid>
						</Grid>
					</CardContent>
					<CardActions className={classes.actionButton}>
						<Grid
							container
							xs={12}
							justify="flex-end"
							alignContent="flex-end"
							alignItems="flex-end"
						>
							<Button
								className={
									selectedSuppliers?.includes(get(supplier, "id"))
										? classes.notSelected
										: classes.selectedSupplier
								}
								variant="contained"
								fullWidth
								onClick={() => addSupplier(get(supplier, "id"))}
							>
								{selectedSuppliers?.includes(get(supplier, "id"))
									? t("admin.commercialOrganization.remove")
									: t("admin.commercialOrganization.add")}
							</Button>
						</Grid>
					</CardActions>
				</Card>
			));
	};

	return (
		<Grid container item xs={12} style={{ maxHeight: "600px", overflowY: "scroll" }}>
			{renderSuppliersList()}
		</Grid>
	);
};

export default CommercialOrganizationSuppliers;
