import {
	Avatar,
	Button,
	ButtonBase,
	Card,
	CardActions,
	CardContent,
	Checkbox,
	Chip,
	Dialog,
	DialogActions,
	DialogTitle,
	Grid,
	IconButton,
	TablePagination,
	Tooltip,
	Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { getQuery } from "@redux-requests/core";
import clsx from "clsx";
import get from "lodash/get";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { deleteInvoiceLitige, fetchInvoicesLitige } from "redux/actions/invoices";
import { LIST_FETCH_INVOICE_LITIGE } from "redux/constants";
import { getApi } from "redux/store";
import { getStatusChipColor, scrollToTop } from "utils";
import UserProfile from "../../../../../App/user-profile.png";
import useStyles from "../../../../../components/InvoicesToValidate/style";

function InvoicesLitigeTable({
	settings,
	setSelected,
	selected,
	filterLitigeData,
	setFilterLitigeData,
}) {
	const classes = useStyles();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();

	const [selectedId, setSelectedId] = React.useState(0);
	const [defaultPageSize, setDefaultPageSize] = React.useState(25);
	const [pageNo, setPageNo] = React.useState(0);

	const [openDeletePopup, setOpenDeletePopup] = React.useState(false);

	const { data } = useSelector((state) => getQuery(state, { type: LIST_FETCH_INVOICE_LITIGE }));
	const SettingsDisplay = ({ value, children }) => (value ? children : <></>);

	const handleChangePage = (event, newPage) => {
		setPageNo(newPage);
		if (filterLitigeData && setFilterLitigeData)
			setFilterLitigeData({ ...filterLitigeData, pageNo: newPage });
	};

	const handleChangeRowsPerPage = (event) => {
		setDefaultPageSize(event.target.value);
		if (filterLitigeData && setFilterLitigeData)
			setFilterLitigeData({ ...filterLitigeData, pageSize: event.target.value });
		setSelected([]);
	};
	const onClickRow = (id) => {
		history.push(`InvoicesLitige/details/${id}`);
	};

	useEffect(() => {
		if (filterLitigeData) dispatch(fetchInvoicesLitige(filterLitigeData));

		scrollToTop();
	}, [filterLitigeData]);

	const ConfirmationDialog = ({ open, setOpen, id }) => {
		const handleCloseDeletePopup = () => {
			setOpen(false);
		};

		const toDelete = () => {
			dispatch(
				deleteInvoiceLitige(id, () => {
					dispatch(fetchInvoicesLitige());
				})
			);
			handleCloseDeletePopup();
		};

		return (
			<Dialog
				open={open}
				onClose={handleCloseDeletePopup}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{t("individualDeleteMessage")}</DialogTitle>

				<DialogActions>
					<Button onClick={handleCloseDeletePopup} color="secondary" autoFocus>
						{t("cancel")}
					</Button>
					<Button onClick={() => toDelete(id)} type="submit" color="secondary">
						{t("confirm")}
					</Button>
				</DialogActions>
			</Dialog>
		);
	};

	return (
		<>
			<>
				{data &&
					data.content &&
					data.content.map((row, index) => (
						<Card
							key={index}
							className={clsx(
								classes.root,
								get(row, "status.code") !== "INTEGRATION" &&
									row.invoice.extractionLevel === "N1" &&
									classes.fullyExtracted,
								get(row, "status.code") !== "INTEGRATION" &&
									row.invoice.extractionLevel === "N2" &&
									classes.partiallyExtracted,
								get(row, "status.code") !== "INTEGRATION" &&
									row.invoice.extractionLevel === "N3" &&
									classes.nonExtracted
							)}
							elevation={1}
						>
							<CardContent className={classes.content}>
								<ButtonBase focusRipple onClick={() => onClickRow(row.id)}>
									<Grid container xs={12}>
										<Grid container item xs={4}>
											<Grid container item xs={4} direction="column">
												<Grid item className={classes.avatar}>
													<Avatar
														alt="Remy Sharp"
														src={
															row && row.invoice.supplier && row.invoice.supplier.logo
																? `${getApi()}/invoice${row.invoice.supplier.logo}`
																: UserProfile
														}
													/>
												</Grid>
												<Grid item>
													<Typography variant="subtitle2" align="center">
														{row.invoice.supplier && row.invoice.supplier.label}
													</Typography>
												</Grid>
											</Grid>
											<Grid container item xs={8} direction="column">
												<Grid item>
													<Typography variant="body1">{row.invoice.invoiceNo}</Typography>
												</Grid>
												<Grid item>
													<Typography variant="caption" color="textSecondary">
														{row.invoice.issueDate}
													</Typography>
												</Grid>
												<Grid item>
													<Chip
														label={t(row.statusLitige.label)}
														size="small"
														style={{
															backgroundColor: getStatusChipColor(row.statusLitige.code),
															color: "white",
														}}
													/>
												</Grid>
											</Grid>
										</Grid>
										<Grid container item xs={2} direction="column" alignItems="center">
											<Grid item>
												<Typography variant="subtitle2" color="textSecondary">
													{t("createdAt")}
												</Typography>
											</Grid>
											<Grid item>
												<Typography variant="caption" color="textSecondary">
													{row.createdAt}
												</Typography>
											</Grid>
										</Grid>
										<Grid container item xs={4}>
											<Grid container item direction="column" xs={6}>
												<Grid item>
													<Typography variant="subtitle2" color="textSecondary" align="right">
														{t("Pre-Tax-Amount")}
													</Typography>
												</Grid>
												<Grid item>
													<Typography variant="subtitle2" color="textSecondary" align="right">
														{t("VAT amount")}
													</Typography>
												</Grid>
												<Grid item>
													<Typography variant="body1" color="primary" align="right">
														{t("Total")}
													</Typography>
												</Grid>
											</Grid>
											<Grid container item direction="column" xs={6}>
												<Grid item>
													<Typography variant="body2" color="textSecondary" align="right">
														{row.invoice.additionalAmountsHT &&
															row.invoice.additionalAmountsHT.filter(
																(amount) => amount.principal
															)[0] &&
															row.invoice.additionalAmountsHT.filter(
																(amount) => amount.principal
															)[0].amount}{" "}
														€
													</Typography>
												</Grid>
												<Grid item>
													<Typography variant="body2" color="textSecondary" align="right">
														{row.invoice.additionalAmountsTVA &&
															row.invoice.additionalAmountsTVA.filter(
																(amount) => amount.principal
															)[0] &&
															row.invoice.additionalAmountsTVA.filter(
																(amount) => amount.principal
															)[0].amount}{" "}
														€
													</Typography>
												</Grid>
												<Grid item>
													<Typography variant="body1" color="primary" align="right">
														{row.invoice.total} €
													</Typography>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</ButtonBase>
							</CardContent>
							<CardActions>
								{settings && (
									<>
										<Grid item>
											<SettingsDisplay value={settings.INVOICE_DELETE_ACTION}>
												<Tooltip placement="top" title={t("tooltip.action.delete")}>
													<IconButton
														aria-label="delete"
														disabled={
															!(row.statusLitige && row.statusLitige.code === "START_LITIGE")
														}
														onClick={() => {
															setOpenDeletePopup(true);
															setSelectedId(row.id);
														}}
													>
														<DeleteIcon />
													</IconButton>
												</Tooltip>
											</SettingsDisplay>
										</Grid>
									</>
								)}

								<Grid item xs>
									<Checkbox
										value={parseInt(row.id, 10)}
										checked={selected.includes(parseInt(row.id, 10))}
										onChange={(e) => {
											const list = [...selected];
											// eslint-disable-next-line
											e.target.checked
												? list.push(parseInt(e.target.value, 10))
												: list.splice(list.indexOf(parseInt(e.target.value, 10)), 1);
											setSelected(list);
										}}
									/>
								</Grid>
							</CardActions>
						</Card>
					))}
			</>
			<ConfirmationDialog open={openDeletePopup} setOpen={setOpenDeletePopup} id={selectedId} />

			<TablePagination
				rowsPerPageOptions={[5, 10, 25, 50, 100]}
				component="div"
				count={data?.totalElements ? data?.totalElements : 0}
				rowsPerPage={defaultPageSize}
				page={pageNo}
				onChangePage={handleChangePage}
				onChangeRowsPerPage={handleChangeRowsPerPage}
				labelRowsPerPage={t("pagination.rows.per.page")}
			/>
		</>
	);
}

export default InvoicesLitigeTable;
