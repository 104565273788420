import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Button, Typography, TablePagination, CircularProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import get from "lodash/get";
import { useHistory } from "react-router-dom";
import { Query, useQuery } from "@redux-requests/react";
import ConfirmDialog from "components/ConfirmDialog";
import {
	fetchInvoices,
	deleteInvoice,
	invoicesTotalAmounts,
	fetchCountInvoices,
	fetchInvoiceIndicators,
} from "../../redux/actions/invoices";
import { updateFilter } from "../../redux/filterReducer";
import { currencyFormat, scrollToTop } from "../../utils";
import { INVOICE_TOTAL_AMOUNT, LIST_FETCH_INVOICE } from "../../redux/constants";
import useStyles from "./style";
// import CircularLoader from "../Loaders/CircularLoader";
import InvoiceTable from "./InvoiceTable";

function InvoicesTable({ settings, isDashboard, setSelected, selected }) {
	const classes = useStyles();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();

	const filterInvoices = useSelector(({ filterInvoices: i }) => i);
	const [filterContext, setFilterContext] = React.useState(
		history.location && history.location.state && history.location.state.filterContext
	);
	const [selectedId, setSelectedId] = React.useState(0);
	const [confirmObject, setConfirmObject] = React.useState({
		message: t("individualDeleteMessage"),
		openConfirm: false,
		cancel: false,
		isLoading: false,
	});
	const [defaultPageSize, setDefaultPageSize] = React.useState(
		localStorage.getItem("invoices_pageSize_default")
			? localStorage.getItem("invoices_pageSize_default")
			: process.env.REACT_APP_DEFAULT_PAGE_SIZE_LIST_INVOICE
	);

	// const { data } = useSelector((state) => getQuery(state, { type: LIST_FETCH_INVOICE }));
	const { data, loading } = useQuery({ type: "LIST_FETCH_INVOICE" });
	const statusIndicators = useSelector(
		({ requests }) =>
			requests.queries.FETCH_FILTER_STATUS_INVOICE &&
			requests.queries.FETCH_FILTER_STATUS_INVOICE.data
	);

	const handleChangePage = (event, newPage) => {
		dispatch(updateFilter({ ...filterInvoices, pageNo: newPage }));
		setFilterContext({ ...filterContext, pageNo: newPage });
		setSelected([]);
	};

	const handleChangeRowsPerPage = (event) => {
		localStorage.setItem("invoices_pageSize_default", event.target.value);
		setDefaultPageSize(event.target.value);
		dispatch(
			updateFilter({
				...filterInvoices,
				pageSize: event.target.value,
				pageNo: 0,
			})
		);
		setSelected([]);
	};

	useEffect(() => {
		if (isDashboard) {
			dispatch(invoicesTotalAmounts(["TO_VERIFY"], "_VERIFIED"));
			dispatch(
				fetchInvoices({
					invoiceNoOrSupplierCode: "",
					status: [statusIndicators?.find((s) => s?.code === "TO_VERIFY")],
					startDate: null,
					endDate: null,
					pageNo: 0,
					pageSize: 5,
					selectAll: false,
					sortBy: "",
					period: "",
				})
			);
		} else if (filterContext) {
			const tmp = { ...filterInvoices, pageNo: filterContext.pageNo };
			dispatch(fetchInvoices({ ...tmp }));
		} else {
			dispatch(fetchInvoices({ ...filterInvoices }));
		}
		scrollToTop();
	}, [filterInvoices, statusIndicators]);

	const onClickShowAll = () => {
		dispatch(
			updateFilter({
				invoiceNoOrSupplierCode: "",
				status: [statusIndicators?.find((s) => s?.code === "TO_VERIFY")],
				startDate: null,
				endDate: null,
				pageNo: 0,
				pageSize: defaultPageSize,
			})
		);
		history.push("/invoices", { fromDashboard: true });
	};

	const handleCloseDeletePopup = () => {
		setConfirmObject({ ...confirmObject, openConfirm: false, isLoading: false });
	};

	const handleDeleteInvoice = () => {
		setConfirmObject({ ...confirmObject, isLoading: true });
		dispatch(
			deleteInvoice(
				selectedId,
				() => {
					handleCloseDeletePopup();
					if (isDashboard) {
						dispatch(
							fetchInvoices({
								...filterInvoices,
								pageSize: 5,
								pageNo: 0,
								status: [statusIndicators?.find((s) => s?.code === "TO_VERIFY")],
							})
						);
					} else {
						dispatch(fetchInvoices(filterInvoices));
					}
					dispatch(invoicesTotalAmounts(isDashboard ? ["TO_VERIFY"] : []));
					dispatch(fetchCountInvoices());
					if (isDashboard) dispatch(fetchInvoiceIndicators());
					if (setSelected) setSelected([]);
				},
				() => {
					handleCloseDeletePopup();
				}
			)
		);
		handleCloseDeletePopup();
	};

	return (
		<>
			<>
				{isDashboard && (
					<Grid container xs={12}>
						<Grid container direction="column" item xs={6}>
							<Grid item>
								<Typography variant="h6" color="secondary">
									{t("invoice.total_amoutn_to_verify")} :
									<Query type={`${INVOICE_TOTAL_AMOUNT}_VERIFIED`}>
										{(dataParam) => ` ${currencyFormat(get(dataParam, "data"))}`}
									</Query>{" "}
									€
								</Typography>
							</Grid>
							<Grid item>
								<Typography variant="subtitle2" color="textSecondary">
									{t("countInvoices")} {t("tobeprocessed")} :
									<Query type={LIST_FETCH_INVOICE}>
										{({ data: param }) => ` ${get(param, "totalElements")}`}
									</Query>
								</Typography>
							</Grid>
						</Grid>
						<Grid container direction="row-reverse" alignContent="flex-end" xs={6}>
							{isDashboard && (
								<Button
									align="right"
									color="secondary"
									className={classes.underlineText}
									onClick={() => onClickShowAll()}
								>
									{t("Show all")}
								</Button>
							)}
						</Grid>
					</Grid>
				)}
				{loading ? (
					<Grid item container xs={12} justify="center">
						<CircularProgress />
					</Grid>
				) : (
					<InvoiceTable
						data={data}
						settings={settings}
						isDashboard={isDashboard}
						filterInvoices={filterInvoices}
						setSelectedId={setSelectedId}
						setOpenDeletePopup={setConfirmObject}
						OpenDeletePopup={confirmObject}
						setSelected={setSelected}
						selected={selected}
					/>
				)}

				<ConfirmDialog
					message={confirmObject.message}
					open={confirmObject.openConfirm}
					onConfirm={handleDeleteInvoice}
					onCancel={handleCloseDeletePopup}
					isLoading={confirmObject.isLoading}
				/>
			</>

			{!isDashboard && (
				<>
					<TablePagination
						rowsPerPageOptions={[5, 10, 25, 50, 100]}
						component="div"
						count={data?.totalElements ? data?.totalElements : 0}
						rowsPerPage={filterInvoices.pageSize || defaultPageSize}
						page={filterContext?.pageNo || filterInvoices.pageNo}
						onChangePage={handleChangePage}
						onChangeRowsPerPage={handleChangeRowsPerPage}
						labelRowsPerPage={t("pagination.rows.per.page")}
					/>
				</>
			)}
		</>
	);
}

export default InvoicesTable;
