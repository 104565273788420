import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import debounce from "lodash/debounce";
import isEmpty from "lodash/isEmpty";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { SearchFilter, useQuery } from "../../../../../components/Filter";
import { fetchListStatusLitige } from "../../../../../redux/actions/invoices";
import useStyles from "../../style";

const columns = ["createdAt", "invoiceNo"];
const INVALID_STATUS = ["TO_PAY", "PAYED", "CANCELED", "PROCESSING"];

function FilterLitige(props) {
	const dispatch = useDispatch();

	const classes = useStyles();
	const { t } = useTranslation();
	const query = useQuery();
	const [searchField] = React.useState("");
	const [status, setStatus] = React.useState([]);

	const [statusList, setStatusList] = React.useState([]);
	const [sortBy, setSortBy] = React.useState("");
	const [checkedAll] = React.useState(false);
	const [checkedindeterminate] = React.useState(false);
	const [openDeletePopup, setOpenDeletePopup] = React.useState(false);

	const onFilterChange = (newFilter) => {
		if (props.setFilterLitigeData && props.filterLitigeData) {
			const filter = { ...props.filterLitigeData, ...newFilter, pageNo: 0 };
			props.setFilterLitigeData(filter);
		}
	};

	const handleChangeStatus = (event) => {
		const list = statusList.filter((s) => event.target.value.includes(s.label));
		setStatus(event.target.value);
		onFilterChange({ status: list });
	};

	const getDataFromSettings = (data, code) => data.find((e) => e.code === code);

	const handleChangeSortBy = (event) => {
		setSortBy(event.target.value);
		onFilterChange({ sortBy: event.target.value });
	};

	useEffect(() => {
		dispatch(fetchListStatusLitige()).then((result) => {
			setStatusList(result.data);
		});
	}, [props.invoicesLitige]);

	const handleCloseDeletePopup = () => {
		setOpenDeletePopup(!openDeletePopup);
	};

	const toDelete = () => {};

	const debouncer = useCallback(
		debounce((nextValue) => onFilterChange({ invoiceNoOrSupplierCode: nextValue }), 500),
		[]
	);

	const handleFilterValueChange = () => {
		debouncer(query.get("q"));
	};

	useEffect(() => {
		handleFilterValueChange();
	}, [query.get("q")]);

	return (
		<>
			<Dialog
				open={openDeletePopup}
				onClose={handleCloseDeletePopup}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{t("deleteMessage")}</DialogTitle>

				<DialogActions>
					<Button onClick={handleCloseDeletePopup} color="secondary" autoFocus>
						{t("cancel")}
					</Button>
					<Button onClick={toDelete} type="submit" color="secondary">
						{t("confirm")}
					</Button>
				</DialogActions>
			</Dialog>
			<Grid container>
				<Grid container justify="center" spacing={1}>
					<Grid item xs="auto">
						<SearchFilter
							callback={handleFilterValueChange}
							placeHolder={t("SearchInvoice")}
							style={classes.searchIcon}
							value={query.get("q") ? query.get("q") : searchField}
						/>
					</Grid>

					<Grid item xs justify="center">
						<FormControl fullWidth>
							<InputLabel id="status">{t("Status")}</InputLabel>
							<Select
								labelId="status"
								id="statusId"
								multiple
								value={status}
								onChange={handleChangeStatus}
								input={<Input />}
								renderValue={(selected) => selected.map((s) => t(s)).join(", ")}
							>
								{statusList
									?.filter(({ code }) => !INVALID_STATUS.includes(code))
									.map((row) => (
										<MenuItem value={row.label}>
											<Checkbox checked={status.indexOf(row.label) > -1} />
											<ListItemText primary={t(row.label)} />
										</MenuItem>
									))}
							</Select>
						</FormControl>
					</Grid>

					<Grid item xs justify="center">
						<FormControl fullWidth>
							<InputLabel id="sortBy">{t("sortBy")}</InputLabel>
							<Select
								labelId="sortBy"
								id="sortById"
								value={sortBy}
								input={<Input />}
								onChange={handleChangeSortBy}
								renderValue={(selected) => t(selected)}
							>
								<MenuItem value="">
									<ListItemText primary="" />
								</MenuItem>
								{columns.map((row, i) => (
									<MenuItem key={i} value={row}>
										<ListItemText primary={t(row)} />
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>

					<Grid style={{ marginTop: 15 }}>
						<Grid container style={{ justifyContent: "space-between" }}>
							<Grid item>
								{props.settings &&
									(getDataFromSettings(props.settings, "INVOICE_DELETE_ACTION")?.value === "1" ||
										getDataFromSettings(props.settings, "INVOICE_DELETE_All_ACTION")?.value ===
											"1") &&
									!isEmpty(props.selected) && (
										<Tooltip placement="top" title={t("tooltip.action.delete")}>
											<IconButton
												aria-label="delete"
												// disabled={isEmpty(props.selected)}
												disabled
												onClick={() => {
													setOpenDeletePopup(true);
												}}
											>
												<DeleteIcon />
											</IconButton>
										</Tooltip>
									)}
							</Grid>

							<Grid item style={{ marginTop: 5 }}>
								<Checkbox
									checked={checkedAll}
									indeterminate={checkedindeterminate}
									onChange={(e) => {
										props.handleSelectedAll(e.target.checked);
									}}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
}

export default FilterLitige;
