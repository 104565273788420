import { Tooltip } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { useTheme } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import ConfirmDialog from "components/ConfirmDialog";

import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { isSupplierOnly, toast } from "utils";
import clsx from "clsx";
import PDFViewer from "pages/Invoices/Detail/Components/PDFViewer";
import PDFJSBackend from "pages/Invoices/Detail/Components/pdfjs";
import CircularLoader from "../../../components/Loaders/CircularLoader";
import {
	createInvoiceReintegration,
	fetchDocumentInvoice,
	fetchInvoice,
	fetchInvoiceLitige,
} from "../../../redux/actions/invoices";
import { TREATED } from "../../../redux/constants";
import useStyles from "../../Invoices/Detail/style";
import HistoryLitige from "./Components/HistoryLitige";
import DialogReject from "./Components/DialogReject";

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`scrollable-auto-tabpanel-${index}`}
			aria-labelledby={`scrollable-auto-tab-${index}`}
			{...other} // eslint-disable-line
		>
			{value === index && (
				<Grid container xs={12} style={{ margin: 0 }}>
					{children}
				</Grid>
			)}
		</div>
	);
}
TabPanel.propTypes = {
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};
function a11yProps(index) {
	return {
		id: `scrollable-auto-tab-${index}`,
		"aria-controls": `scrollable-auto-tabpanel-${index}`,
	};
}

function InvoiceDetailElitige(props) {
	const classes = useStyles();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const [invoice, setInvoice] = React.useState({});

	const [billingLines, setBillingLines] = React.useState([]);
	const [value, setValue] = React.useState(0);
	const [invoicePersisted, setInvoicePersisted] = React.useState({});

	const [scValue, setScValue] = React.useState(0);

	const [openRejectPopup, setOpenRejectPopup] = React.useState(false);

	const [document, setDocument] = React.useState(null);

	const [previewFile, setPreviewFile] = React.useState(null);
	const [mimeType, setMimeType] = React.useState(null);
	const [confirmObject, setConfirmObject] = React.useState({
		message: t("confirmation.reintegrate"),
		openConfirm: false,
		cancel: false,
		isLoading: false,
	});

	// const invoiceLitige = useSelector(({ requests }) =>
	// 	get(requests, "queries.LIST_FETCH_INVOICE_LITIGE.data")
	// );

	const currentUser = useSelector(({ requests }) =>
		get(requests, "queries.FETCH_CURRENT_USER.data")
	);

	const invoiceLitige = useSelector(({ requests }) =>
		get(requests, "queries.FETCH_INVOICE_LITIGE.data")
	);

	// const document = useSelector(({ requests }) =>

	//  get(requests, "dataQueris.FETCH_DOCUMENT_INVOICE.data")
	// );
	const isSupplier = isSupplierOnly(get(currentUser, "roles", []));

	useEffect(() => {
		if (document) {
			const blob = new Blob([document], { type: mimeType });
			const fileURL = URL.createObjectURL(blob);
			setPreviewFile(fileURL);
		}
	}, [document]);

	useEffect(() => {
		dispatch(fetchInvoiceLitige(props.match.params.id ? parseFloat(props.match.params.id) : 0));
	}, []);

	useEffect(() => {
		if (invoiceLitige) {
			dispatch(
				fetchInvoice(invoiceLitige.invoice.id ? parseFloat(invoiceLitige.invoice.id) : 0)
			).then((result) => {
				setInvoicePersisted(result.data);
				setInvoice(result.data);
				if (result.data) {
					setBillingLines(result.data.billingLinesDtos);
				}
			});

			dispatch(
				fetchDocumentInvoice(
					invoiceLitige.invoice.id ? parseFloat(invoiceLitige.invoice.id) : 0,
					"inv",
					null,
					setMimeType
				)
			).then((response) => {
				setDocument(response.data);
			});
		}
	}, [invoiceLitige]);

	const theme = useTheme();

	// temp fix

	useEffect(
		() => () => {
			window.location.reload(false);
		},
		[]
	);

	const handleChangeFirstTab = (event, newValue) => {
		setValue(newValue);
	};
	const handleChangeSecondTab = (event, newScValue) => {
		setScValue(newScValue);
	};

	const onCloseDialogReject = (error) => {
		if (error) {
			toast.error(t("invoice.detail.reject.failed"));
		} else {
			toast.success(t("invoice.detail.reject.success"));
			dispatch(fetchInvoiceLitige(props.match.params.id ? parseFloat(props.match.params.id) : 0));
		}
	};

	const minimizeValueDesignation = (v) => (v && v.length > 45 ? `${v.substr(0, 44)}...` : v);
	const minimizeValueCode = (v) => (v && v.length > 9 ? `${v.substr(0, 8)}...` : v);

	const status = get(invoicePersisted, "status");

	const statusCode = get(status, "code");

	const closeReintegration = () => {
		setConfirmObject({ ...confirmObject, openConfirm: false, isLoading: false });
	};

	const reintegreInvoice = (invoiceLitigeId) => {
		setConfirmObject({ ...confirmObject, isLoading: true });
		dispatch(createInvoiceReintegration(invoiceLitigeId)).then(
			() => {
				toast.success(t("invoice.detail.reintegrated.success"));
				dispatch(fetchInvoiceLitige(props.match.params.id ? parseFloat(props.match.params.id) : 0));
				closeReintegration();
			},
			() => {
				toast.success(t("invoice.detail.reintegrated.failed"));
				closeReintegration();
			}
		);
	};

	const onConfirmReintegrate = () => {
		reintegreInvoice(invoiceLitige && invoiceLitige.id);
	};

	return (
		<Grid container xs={12} style={{ padding: "10px 0" }}>
			<Grid container item xs={12} className={classes.detailsContent} justify="space-evenly">
				<Grid container item xs={12} justify="space-evenly">
					<Grid container item xs={5}>
						<Grid
							container
							item
							xs
							direction="row"
							alignItems="flex-start"
							className={classes.detailHeader}
						>
							<Grid container item xs={3}>
								<Grid container item xs={12} justify="flex-start">
									<Button
										variant="contained"
										fullWidth
										color="secondary"
										className={classes.backBtn}
										onClick={() => history.goBack()}
									>
										{t("return")}
									</Button>
								</Grid>
							</Grid>
							<Grid container item xs={9}>
								<Grid container xs={12} item justify="flex-end">
									<Grid>
										{invoiceLitige && invoiceLitige.statusLitige ? (
											<Chip
												align="left"
												label={t(invoiceLitige.statusLitige.label)}
												color="secondary"
											/>
										) : (
											<CircularLoader />
										)}
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					{!isSupplier && (
						<Grid container item xs={5}>
							&nbsp;
						</Grid>
					)}
				</Grid>
				{(!isSupplier ||
					!isEmpty(get(invoice, "purchaseOrder.deliveryItems", [])) ||
					TREATED.includes(statusCode)) && (
					<Grid container item xs={5} className={classes.invoiceOrderTabs}>
						<Grid item xs={12}>
							<AppBar position="static" color="white" elevation={0} className={classes.rootAppbar}>
								<Tabs
									value={value}
									onChange={handleChangeFirstTab}
									indicatorColor="secondary"
									textColor="secondary"
									variant="fullWidth"
									aria-label="tab facture"
								>
									{(!isSupplier || TREATED.includes(statusCode)) && (
										<Tab label={t("Invoice Details")} {...a11yProps(0)} />
									)}
								</Tabs>
							</AppBar>

							{(!isSupplier || TREATED.includes(statusCode)) && (
								<TabPanel
									section={1}
									value={value}
									index={0}
									dir={theme.direction}
									className={classes.tabInvoiceContainer}
								>
									{invoice ? (
										<Grid container item xs={12} style={{ height: "100%" }}>
											<Grid container item xs={12} className={classes.tabInvoice} spacing={2}>
												<Grid
													container
													item
													xs={12}
													justify="center"
													alignItems="flex-end"
													spacing={1}
													className={classes.noPaddingY}
												>
													<Grid item xs={6}>
														<Typography variant="subtitle2" align="left" color="primary">
															{t("supplier")}
															{"	"}
															<br />

															<Typography
																variant="body2"
																align="left"
																className={classes.blackColor}
															>
																{get(invoice, "supplier.label")}
																<Divider />
															</Typography>
														</Typography>
													</Grid>
													<Grid item xs={6}>
														<Typography variant="subtitle2" align="left" color="primary">
															{t("invoice Number")}
															{"	"}

															<br />

															<Typography
																variant="body2"
																align="left"
																className={classes.blackColor}
															>
																{invoicePersisted && invoicePersisted.invoiceNo}

																<Divider />
															</Typography>
														</Typography>
													</Grid>
												</Grid>

												<Grid
													container
													item
													xs={12}
													justify="center"
													alignItems="flex-end"
													spacing={2}
												>
													<Grid item xs={6}>
														<Typography variant="subtitle2" align="left" color="primary">
															{t("VAT Number")}
															{"	"}
															<br />
															<Typography
																variant="body2"
																align="left"
																className={classes.blackColor}
															>
																{get(invoice, "supplier.vatNumber")}
																<Divider />
															</Typography>
														</Typography>
													</Grid>
													<Grid item xs={6}>
														<Typography variant="subtitle2" align="left" color="primary">
															{t("invoice.detail.company_siret")}
															{"	"}

															<br />

															<Typography
																variant="body2"
																align="left"
																className={classes.blackColor}
															>
																{get(invoice, "supplier.companySiret")}
																<Divider />
															</Typography>
														</Typography>
													</Grid>
												</Grid>

												<Grid
													container
													item
													xs={12}
													justify="center"
													alignItems="flex-end"
													spacing={1}
												>
													<Grid item xs={6}>
														<Typography variant="subtitle2" align="left" color="primary">
															{t("issueDate")}
															{"	"}

															<br />

															<Typography
																variant="body2"
																align="left"
																className={classes.blackColor}
															>
																{get(invoice, "issueDate")}

																<Divider />
															</Typography>
														</Typography>
													</Grid>

													<Grid item xs={6}>
														<Typography variant="subtitle2" align="left" color="primary">
															{t("dueDate")}
															{"	"}

															<br />

															<Typography
																variant="body2"
																align="left"
																className={classes.blackColor}
															>
																{get(invoice, "dueDate")}

																<Divider />
															</Typography>
														</Typography>
													</Grid>
												</Grid>

												<Grid container item xs={12} alignItems="flex-end" spacing={1}>
													<Grid item xs={6}>
														<Typography variant="subtitle2" align="left" color="primary">
															{t("orderNo")}
															{"	"}

															<Typography
																variant="body2"
																align="left"
																className={classes.blackColor}
															>
																{get(invoice, "purchaseOrderNo")}
																<Divider />
															</Typography>
														</Typography>
													</Grid>

													<Grid item xs={6}>
														<Typography variant="subtitle2" align="left" color="primary">
															{t("DeliverySlip")}
															{"	"}
															<br />
															<Typography
																variant="body2"
																align="left"
																className={classes.blackColor}
															>
																{get(invoice, "deliverySlip")}
																<Divider />
															</Typography>
														</Typography>
													</Grid>
												</Grid>

												<Grid
													container
													item
													xs={12}
													justify="center"
													alignItems="flex-end"
													spacing={1}
												>
													<Grid item xs={6}>
														<Typography variant="subtitle2" align="left" color="primary">
															{t("Delivery address")}
															{"	"}
															<br />

															<Typography
																variant="body2"
																align="left"
																className={classes.blackColor}
															>
																{get(invoice, "deliveryAddress")}
																<Divider />
															</Typography>
														</Typography>
													</Grid>
													<Grid item xs={6}>
														<Typography variant="subtitle2" align="left" color="primary">
															{t("Billing address")}
															{"	"}
															<Typography
																variant="body2"
																align="left"
																className={classes.blackColor}
															>
																<Divider />
															</Typography>
														</Typography>
													</Grid>
												</Grid>
												<Grid container item xs={12} alignItems="flex-end" spacing={1}>
													<Grid item xs={6}>
														<Typography variant="subtitle2" align="left" color="primary">
															{t("country")}
															{"	"}

															<br />

															<Typography
																variant="body2"
																align="left"
																className={classes.blackColor}
															>
																{get(invoice, "country")}
																<Divider />
															</Typography>
														</Typography>
													</Grid>
													<Grid item xs={6}>
														<Typography variant="subtitle2" align="left" color="primary">
															{t("currency")}
															{"	"}

															<br />

															<Typography
																variant="body2"
																align="left"
																className={classes.blackColor}
															>
																{get(invoice, "currency")}
																<Divider />
															</Typography>
														</Typography>
													</Grid>
												</Grid>
											</Grid>

											{(!isSupplier || TREATED.includes(statusCode)) && (
												<Grid
													id="billing-line-container"
													container
													xs={12}
													direction="row"
													justify="space-between"
													className={classes.cardBilling}
												>
													<Grid
														container
														item
														alignItems="center"
														xs={10}
														className={classes.headerBillingCard}
													>
														<Grid item alignContent="center">
															<Typography variant="subtitle2" align="left" color="primary">
																{t("Commands")}
																{"	"}
															</Typography>
														</Grid>
													</Grid>

													<Grid container item alignItems="center" justify="center" xs={12}>
														<TableContainer
															component={Paper}
															elevation={0}
															className={classes.tableContainer}
														>
															<Table aria-label="spanning table">
																<TableHead>
																	<TableRow>
																		<TableCell>
																			<Typography
																				className={classes.tableCell1}
																				variant="subtitle2"
																				align="left"
																			>
																				{t("itemCode")}
																			</Typography>
																		</TableCell>
																		<TableCell>
																			<Typography
																				className={classes.tableCell3}
																				variant="subtitle2"
																				align="left"
																			>
																				{t("designation")}
																			</Typography>
																		</TableCell>
																		<TableCell>
																			<Typography
																				className={classes.tableCell2}
																				variant="subtitle2"
																				align="left"
																			>
																				{t("quantity")}
																			</Typography>
																		</TableCell>
																		<TableCell>
																			<Typography
																				className={classes.tableCell1}
																				variant="subtitle2"
																				align="left"
																			>
																				{t("unitPrice")}
																			</Typography>
																		</TableCell>
																		<TableCell>
																			<Typography
																				className={classes.tableCell2}
																				variant="subtitle2"
																				align="left"
																			>
																				{t("vat")}
																			</Typography>
																		</TableCell>
																		<TableCell>
																			<Typography
																				className={classes.tableCell4}
																				variant="subtitle2"
																				align="left"
																			>
																				{t("totalHt")}
																			</Typography>
																		</TableCell>
																	</TableRow>
																</TableHead>
																<TableBody>
																	<>
																		{billingLines &&
																			!isEmpty(billingLines) &&
																			billingLines.map((row) => (
																				<TableRow key={row.id}>
																					<>
																						{" "}
																						<Tooltip title={row.codeArticle} arrow>
																							<TableCell>
																								<Typography
																									className={classes.tableCell1}
																									variant="subtitle"
																									align="left"
																								>
																									{minimizeValueCode(row.codeArticle)}
																								</Typography>
																							</TableCell>
																						</Tooltip>
																						<Tooltip title={row.designation} arrow>
																							<TableCell>
																								<Typography
																									className={classes.tableCell3}
																									variant="subtitle"
																									align="left"
																								>
																									{minimizeValueDesignation(row.designation)}
																								</Typography>
																							</TableCell>
																						</Tooltip>
																						<TableCell>
																							<Typography
																								className={classes.tableCell2}
																								variant="subtitle"
																								align="left"
																							>
																								{row.quantity}
																							</Typography>
																						</TableCell>
																						<TableCell>
																							<Typography
																								className={classes.tableCell1}
																								variant="subtitle"
																								align="left"
																							>
																								{row.price}
																							</Typography>
																						</TableCell>
																						<TableCell>
																							<Typography
																								className={classes.tableCell2}
																								variant="subtitle"
																								align="left"
																							>
																								{row.vat}
																							</Typography>
																						</TableCell>
																						<TableCell>
																							<Typography
																								className={classes.tableCell1}
																								variant="subtitle"
																								align="left"
																							>
																								{row.totalHt}
																							</Typography>
																						</TableCell>
																					</>
																				</TableRow>
																			))}
																	</>
																	<TableRow>
																		<TableCell rowSpan={100} />
																		<TableCell rowSpan={100} />
																		<TableCell rowSpan={100} />
																		<TableCell rowSpan={100} />
																	</TableRow>
																	<>
																		{invoice &&
																			invoice.additionalAmountsHT &&
																			invoice.additionalAmountsHT.map((row) => (
																				<TableRow>
																					<TableCell align="right">{t("Total HT")}</TableCell>

																					<TableCell align="right">
																						{get(row, "amount")} {get(invoice, "currencySymbol")}
																					</TableCell>
																				</TableRow>
																			))}
																		{invoice &&
																			invoice.additionalAmountsTVA &&
																			invoice.additionalAmountsTVA.map((row) => (
																				<TableRow>
																					<TableCell align="right"> {t("VAT amount")}</TableCell>

																					<TableCell align="right">
																						{get(row, "amount")} {get(invoice, "currencySymbol")}
																					</TableCell>
																				</TableRow>
																			))}

																		<TableRow>
																			<TableCell align="right"> {t("Discount")}</TableCell>

																			<TableCell align="right">
																				{get(invoice, "discount")} {get(invoice, "currencySymbol")}
																			</TableCell>
																		</TableRow>
																		{invoice &&
																			invoice.additionalAmountsCharge &&
																			invoice.additionalAmountsCharge.map((row) => (
																				<TableRow>
																					<TableCell align="right">
																						{" "}
																						{t("invoice_charge")}
																					</TableCell>

																					<TableCell align="right">
																						{get(row, "amount")} {get(invoice, "currencySymbol")}
																					</TableCell>
																				</TableRow>
																			))}
																		<TableRow>
																			<TableCell align="right">{t("Total amount")}</TableCell>
																			<TableCell align="right">
																				{get(invoice, "total")} {invoice && invoice.currencySymbol}
																			</TableCell>
																		</TableRow>
																	</>
																</TableBody>
															</Table>
															<>
																{get(invoice, "status.code") !== "TO_VALIDATE_EXTERNAL" && (
																	<Grid container xs={12} className={classes.approveContainer}>
																		<>
																			{get(invoice, "approvedBy") && (
																				<Grid item xs={6}>
																					<Typography
																						variant="subtitle2"
																						align="left"
																						color="primary"
																					>
																						{get(invoice, "isApproved") === false
																							? t("rejectedBy")
																							: t("approvedBy")}

																						<Typography variant="body2" align="left">
																							{get(invoice, "approvedBy")}
																						</Typography>
																					</Typography>
																				</Grid>
																			)}
																			{get(invoice, "approvedComment") && (
																				<Grid item xs={6}>
																					<Typography
																						variant="subtitle2"
																						align="left"
																						color="primary"
																					>
																						{t("comment")}

																						<Typography
																							variant="body2"
																							align="left"
																							className={classes.blackColor}
																						>
																							{get(invoice, "approvedComment")}
																							<Divider />
																						</Typography>
																					</Typography>
																				</Grid>
																			)}
																		</>
																	</Grid>
																)}
																<Divider />
															</>
														</TableContainer>
													</Grid>
												</Grid>
											)}
										</Grid>
									) : (
										<CircularLoader />
									)}
								</TabPanel>
							)}
						</Grid>
					</Grid>
				)}

				<Grid container item xs={5}>
					<Grid item xs={12}>
						<>
							<AppBar
								position="relative"
								color="default"
								elevation={0}
								className={classes.rootAppbar}
							>
								<Tabs
									className={classes.root}
									value={scValue}
									onChange={handleChangeSecondTab}
									indicatorColor="secondary"
									textColor="secondary"
									variant="fullWidth"
									aria-label="full width tabs example2"
								>
									<Tab label={t("Invoice overview")} {...a11yProps(0)} />

									{!isSupplier && <Tab label={t("History")} {...a11yProps(1)} />}
								</Tabs>
							</AppBar>
							<TabPanel
								style={{
									minHeight: "805px",
								}}
								section={2}
								value={scValue}
								index={0}
								dir={theme.direction}
							>
								{previewFile ? (
									mimeType === "application/pdf" ? (
										<>
											<PDFViewer
												backend={PDFJSBackend}
												src={previewFile}
												style={{
													height: "800px",
												}}
											/>
										</>
									) : (
										<img src={previewFile} className={classes.previewImg} alt="preview" />
									)
								) : (
									<CircularLoader />
								)}
							</TabPanel>
							{!isSupplier && invoiceLitige && (
								<TabPanel
									section={2}
									value={scValue}
									index={1}
									dir={theme.direction}
									style={{
										minHeight: "805px",
									}}
								>
									<HistoryLitige
										idInvoiceLitige={invoiceLitige.id}
										{...props} //eslint-disable-line
									/>
								</TabPanel>
							)}
						</>
					</Grid>
				</Grid>
				{invoiceLitige && invoiceLitige.statusLitige.code === "OPEN" && (
					<Grid container item xs={12} justify="space-evenly" style={{ marginTop: "15px" }}>
						<Grid container item xs={5}>
							<Grid container item xs={12} className={classes.rectifyHeader} spacing={1}>
								<Grid item xs={4}>
									<Button
										disableRipple
										variant="contained"
										fullWidth
										className={clsx(classes.button, classes.rejectButton)}
										onClick={() => {
											setOpenRejectPopup(true);
										}}
									>
										{t("Reject Invoice")}
									</Button>
									<DialogReject
										open={openRejectPopup}
										setOpen={setOpenRejectPopup}
										onClose={onCloseDialogReject}
										invoicePersistedId={invoicePersisted && invoicePersisted.id}
										invoiceLitigeId={invoiceLitige && invoiceLitige.id}
										{...props} // eslint-disable-line
									/>
								</Grid>

								<Grid item xs={4}>
									<Button
										disableRipple
										variant="contained"
										fullWidth
										className={clsx(classes.button, classes.reintegreButton)}
										onClick={() => {
											setConfirmObject({ ...confirmObject, openConfirm: true, isLoading: false });
										}}
									>
										{t("invoice.transition.reintegrate")}
									</Button>
								</Grid>
							</Grid>
						</Grid>
						<Grid container item xs={5}>
							&nbsp;
						</Grid>
					</Grid>
				)}
			</Grid>
			<ConfirmDialog
				message={confirmObject.message}
				open={confirmObject.openConfirm}
				isLoading={confirmObject.isLoading}
				onConfirm={onConfirmReintegrate}
				onCancel={closeReintegration}
			/>
		</Grid>
	);
}
export default InvoiceDetailElitige;
