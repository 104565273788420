export const permanentInvoiceStatus = [
	"TO_VERIFY",
	"INTEGRATION",
	"EXCEPTION",
	"TO_VALIDATE_EXTERNAL",
	"REJECTED",
	"EXPORTED",
	"INTEGRATION-TO_VERIFY",
	"INTEGRATION-EXCEPTION",
];

export const disableAutomaticTransitionStatus = ["TO_VERIFY"];

export const initialTransitions = (t) => [
	{
		id: "INTEGRATION",
		type: "status",
		sourcePosition: "right",
		targetPosition: "left",
		data: {
			label: t("invoice.status.integration"),
			rules: [],
			rectify: false,
			to_delete: false,
		},
		position: { x: 20, y: 200 },
	},
	{
		id: "TO_VERIFY",
		type: "status",
		sourcePosition: "right",
		targetPosition: "left",
		data: {
			label: t("invoice.status.toverify"),
			rules: [],
			rectify: false,
			to_delete: false,
		},
		position: { x: 280, y: 350 },
	},
	{
		id: "EXCEPTION",
		type: "status",
		sourcePosition: "right",
		targetPosition: "left",
		data: {
			label: t("invoice.status.exception"),
			rules: [],
			rectify: false,
			to_delete: false,
		},
		position: { x: 280, y: 50 },
	},
	{
		id: "TO_VALIDATE_EXTERNAL",
		type: "status",
		sourcePosition: "right",
		targetPosition: "left",
		data: {
			label: t("invoice.status.external_validation"),
			rules: [],
			rectify: false,
			to_delete: false,
		},
		position: { x: 50, y: 50 },
	},
	{
		id: "REJECTED",
		type: "status",
		sourcePosition: "right",
		targetPosition: "left",
		data: {
			label: t("invoice.status.rejected"),
			rules: [],
			rectify: false,
			to_delete: false,
		},
		position: { x: 50, y: 50 },
	},
	{
		id: "EXPORTED",
		type: "status",
		sourcePosition: "right",
		targetPosition: "left",
		data: {
			label: t("invoice.status.exported"),
			rules: [],
			rectify: false,
			to_delete: false,
		},
		position: { x: 50, y: 50 },
	},
	{
		id: "INTEGRATION-TO_VERIFY",
		description: "Initialize",
		source: "INTEGRATION",
		target: "TO_VERIFY",
		label: t("TO_VERIFY"),
		data: {
			label: t("TO_VERIFY"),
			buttonLabel: t("TO_VERIFY"),
			rules: [],
			automatic: false,
			to_delete: false,
		},
		type: "smoothstep",
		arrowHeadType: "arrow",
		animated: false,
	},
	{
		id: "INTEGRATION-EXCEPTION",
		description: "Initialize",
		source: "INTEGRATION",
		target: "EXCEPTION",
		label: t("EXCEPTION"),
		data: {
			label: t("EXCEPTION"),
			buttonLabel: t("EXCEPTION"),
			rules: [],
			automatic: false,
			to_delete: false,
		},
		type: "smoothstep",
		arrowHeadType: "arrow",
		animated: false,
	},
];
