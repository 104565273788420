import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from "@material-ui/icons/Delete";
import GetAppIcon from "@material-ui/icons/GetApp";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import CardActions from "@material-ui/core/CardActions";
import Tooltip from "@material-ui/core/Tooltip";
import Checkbox from "@material-ui/core/Checkbox";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import get from "lodash/get";
import { getStatusChipColor, isSupplierOnly, toast } from "utils";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DescriptionIcon from "@material-ui/icons/Description";
import { getApi } from "redux/store";
import LoadingButton from "components/LoadingButton";
import {
	deleteInvoice,
	downloadInvoice,
	invoicesTotalAmounts,
	downloadInvoiceExcel,
	fetchCountInvoices,
} from "../../../../redux/actions/invoices/index";
import UserProfile from "../../../../App/user-profile.png";
import useStyles from "./style";
import { fetchSupplierInvoices } from "../../../../redux/actions/suppliers";

function InvoicesTable({
	isDashboard,
	setSelected,
	selected,
	supplierInvoices,
	onClickInvoice,
	downloadXMLInvoice,
	idSupplier,
	isSupplierDetails,
}) {
	const classes = useStyles();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [openDeletePopup, setOpenDeletePopup] = React.useState(false);
	const [isLoading, setIsLoading] = React.useState({
		excel: { id: [] },
		xml: { id: [] },
		pdf: { id: [] },
		edi: false,
		idoc: false,
		ec: false,
	});
	const [selectedId, setSelectedId] = React.useState(0);
	const currentUser = useSelector(({ requests }) =>
		get(requests, "queries.FETCH_CURRENT_USER.data")
	);

	const onClickRow = (id) => {
		onClickInvoice(id);
	};

	const handleExportXml = (id, row) => {
		const ids = [...isLoading?.xml?.id, row.id];
		dispatch(
			downloadXMLInvoice(
				id,
				() => toast.error(t("invoice.export.error")),
				() => {
					const i = ids.indexOf(row.id);
					if (i > -1) {
						ids.splice(i, 1); // 2nd parameter means remove one item only
						setIsLoading({
							...isLoading,
							xml: { id: [...ids], turning: false },
						});
					}
				}
			)
		);
	};

	const handleExport = (id, row) => {
		const ids = [...isLoading?.pdf?.id, row.id];
		dispatch(
			downloadInvoice(
				id,
				"inv",
				() => toast.error(t("invoice.export.error")),
				() => {
					const i = ids.indexOf(row.id);
					if (i > -1) {
						ids.splice(i, 1); // 2nd parameter means remove one item only
						setIsLoading({
							...isLoading,
							pdf: { id: [...ids], turning: false },
						});
					}
				}
			)
		);
	};

	const ConfirmationDialog = ({ open, setOpen, id }) => {
		const handleCloseDeletePopup = () => {
			setOpen(false);
		};

		const toDelete = () => {
			dispatch(
				deleteInvoice(id, () => {
					dispatch(fetchSupplierInvoices(idSupplier));
					dispatch(invoicesTotalAmounts(isDashboard ? ["TO_VERIFY"] : []));
					dispatch(fetchCountInvoices());
				})
			);
			handleCloseDeletePopup();
		};

		return (
			<Dialog
				open={open}
				onClose={handleCloseDeletePopup}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{t("individualDeleteMessage")}</DialogTitle>

				<DialogActions>
					<Button onClick={handleCloseDeletePopup} color="secondary" autoFocus>
						{t("cancel")}
					</Button>
					<Button onClick={() => toDelete(id)} type="submit" color="secondary">
						{t("confirm")}
					</Button>
				</DialogActions>
			</Dialog>
		);
	};
	return (
		<>
			{supplierInvoices &&
				supplierInvoices.map((row, i) => (
					<Card key={i} className={classes.root} elevation={1}>
						<CardContent className={classes.content}>
							<ButtonBase focusRipple onClick={() => onClickRow(row.id)}>
								<Grid container spacing={8} xs={12}>
									<Grid container item xs={4}>
										<Grid container item xs={4} direction="column">
											<Grid item className={classes.avatar}>
												<Avatar
													alt="Remy Sharp"
													src={
														row && row.supplier && row.supplier.logo
															? `${getApi()}/invoice${row.supplier.logo}`
															: UserProfile
													}
												/>
											</Grid>
											<Grid item>
												<Typography variant="subtitle2" align="center">
													{row.supplier && row.supplier.label}
												</Typography>
											</Grid>
										</Grid>
										<Grid container item xs={8} direction="column">
											<Grid item>
												<Typography variant="body1">{row.invoiceNo}</Typography>
											</Grid>
											<Grid item>
												<Typography variant="caption" color="textSecondary">
													{row.issueDate}
												</Typography>
											</Grid>
											<Grid item>
												<Chip
													label={t(
														row.status.displayName ? t(row.status.displayName) : t(row.status.label)
													)}
													size="small"
													style={{
														backgroundColor: getStatusChipColor(row.status.code),
														color: "white",
													}}
												/>
											</Grid>
										</Grid>
									</Grid>
									<Grid container item xs={4}>
										<Grid container item xs={6} direction="column">
											<Grid item>
												<Typography variant="subtitle2" color="textSecondary">
													{t("orderNo")}
												</Typography>
											</Grid>
											<Grid item>
												<Typography variant="caption" color="textSecondary">
													{row.purchaseOrderNo}
												</Typography>
											</Grid>
										</Grid>

										<Grid container item xs={6} direction="column">
											<Grid item>
												<Typography variant="subtitle2" color="textSecondary">
													{t("createdAt")}
												</Typography>
											</Grid>
											<Grid item>
												<Typography variant="caption" color="textSecondary">
													{row.createdAt}
												</Typography>
											</Grid>
										</Grid>
									</Grid>

									<Grid container item xs={4}>
										<Grid container item direction="column" xs={6}>
											<Grid item>
												<Typography variant="subtitle2" color="textSecondary" align="right">
													{t("Pre-Tax-Amount")}
												</Typography>
											</Grid>
											<Grid item>
												<Typography variant="subtitle2" color="textSecondary" align="right">
													{t("VAT amount")}
												</Typography>
											</Grid>
											<Grid item>
												<Typography variant="body1" color="primary" align="right">
													{t("Total")}
												</Typography>
											</Grid>
										</Grid>
										<Grid container item direction="column" xs={6}>
											<Grid item>
												<Typography variant="body2" color="textSecondary" align="right">
													{row.additionalAmountsHT &&
														row.additionalAmountsHT.filter((amount) => amount.principal)[0] &&
														row.additionalAmountsHT.filter((amount) => amount.principal)[0]
															.amount}{" "}
													€
												</Typography>
											</Grid>
											<Grid item>
												<Typography variant="body2" color="textSecondary" align="right">
													{row.additionalAmountsTVA &&
														row.additionalAmountsTVA.filter((amount) => amount.principal)[0] &&
														row.additionalAmountsTVA.filter((amount) => amount.principal)[0]
															.amount}{" "}
													€
												</Typography>
											</Grid>
											<Grid item>
												<Typography variant="body1" color="primary" align="right">
													{row.total} €
												</Typography>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</ButtonBase>
						</CardContent>
						<CardActions>
							<Grid container xs={12} justify="flex-end">
								{!isSupplierOnly(get(currentUser, "roles", [])) && (
									<Grid item>
										<IconButton
											aria-label="exportXML"
											onClick={() => {
												setIsLoading({
													...isLoading,
													xml: { id: [...isLoading?.xml?.id, row.id], turning: true },
												});
												handleExportXml(row.id, row);
											}}
										>
											<LoadingButton isLoading={isLoading?.xml?.id.includes(row.id)}>
												<ImportExportIcon />
											</LoadingButton>
										</IconButton>
									</Grid>
								)}
								<Grid item>
									<IconButton
										aria-label="download"
										onClick={() => {
											setIsLoading({
												...isLoading,
												pdf: { id: [...isLoading?.pdf?.id, row.id], turning: true },
											});
											handleExport(row.id, row);
										}}
									>
										<LoadingButton isLoading={isLoading?.pdf?.id.includes(row.id)}>
											<GetAppIcon />
										</LoadingButton>
									</IconButton>
								</Grid>
								{!isSupplierOnly(get(currentUser, "roles", [])) && !isSupplierDetails && (
									<Grid item>
										<IconButton
											aria-label="delete"
											disabled={!(row.status && row.status.code === "TO_VERIFY")}
											onClick={() => {
												setOpenDeletePopup(true);
												setSelectedId(row.id);
											}}
										>
											<DeleteIcon />
										</IconButton>
									</Grid>
								)}
								{isSupplierOnly(get(currentUser, "roles", [])) && (
									<Grid item>
										<Tooltip placement="top" title={t("tooltip.action.export_excel")}>
											<IconButton
												aria-label="download"
												onClick={() => {
													setIsLoading({
														...isLoading,
														excel: { id: [...isLoading?.excel?.id, row.id] },
													});
													const ids = [...isLoading?.excel?.id, row.id];
													dispatch(
														downloadInvoiceExcel(
															row.id,
															() => {},
															() => {
																const index = ids.indexOf(row.id);
																if (i > -1) {
																	ids.splice(index, 1); // 2nd parameter means remove one item only
																	setIsLoading({
																		...isLoading,
																		excel: { id: [...ids] },
																	});
																}
															}
														)
													);
												}}
											>
												<LoadingButton isLoading={isLoading?.excel?.id.includes(row.id)}>
													<DescriptionIcon />
												</LoadingButton>
											</IconButton>
										</Tooltip>
									</Grid>
								)}
								{!isDashboard && (
									<Grid item>
										<Checkbox
											value={parseInt(row.id, 10)}
											checked={selected.includes(parseInt(row.id, 10))}
											onChange={(e) => {
												const list = [...selected];
												// eslint-disable-next-line
												e.target.checked
													? list.push(parseInt(e.target.value, 10))
													: list.splice(list.indexOf(parseInt(e.target.value, 10)), 1);
												setSelected(list);
											}}
										/>
									</Grid>
								)}
							</Grid>
						</CardActions>
					</Card>
				))}
			<ConfirmationDialog open={openDeletePopup} setOpen={setOpenDeletePopup} id={selectedId} />
		</>
	);
}
export default InvoicesTable;
