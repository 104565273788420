/* eslint-disable */
import React, { Fragment, useState } from "react";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import { Link, useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ChevronRight from "@material-ui/icons/ChevronRightOutlined";
import ExpandMore from "@material-ui/icons/ExpandMoreOutlined";
import { useTranslation } from "react-i18next";

import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import get from "lodash/get";
import logo from "../../logo.png";
import { setIsUserModif, toggleEnv } from "../../appSlice";

import useStyles from "./style";
import { REGEX } from "utils";

export default ({ menu }) => {
	const classes = useStyles();
	const history = useHistory();
	const menuExpanded = useSelector(({ appState }) => appState.menuExpanded);
	const headerExpanded = useSelector(({ appState }) => appState.headerExpanded);
	const isUserModify = useSelector(({ appState }) => appState.isUserModify);
	const isOrderEnv = useSelector(({ appState }) => appState.isOrderEnv);
	const env = isOrderEnv ? "orders" : "invoices";
	const changePassPage = history.location.pathname.match(REGEX.CHANGE_PASSWORD);
	const [openSubMenu, setOpenSubMenu] = useState(1);
	const { pathname } = useLocation();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const checkEnv = () => {
		if (
			(new URL(window.location).pathname.startsWith("/home") ||
				new URL(window.location).pathname.startsWith("/client") ||
				new URL(window.location).pathname.startsWith("/orders") ||
				new URL(window.location).pathname.startsWith("/contact")) &&
			env === "invoices"
		)
			dispatch(toggleEnv());
	};

	const onListClick = (items, index) => {
		if (isUserModify) dispatch(setIsUserModif());
		if (items) {
			setOpenSubMenu(openSubMenu === index ? -1 : index);
		}
	};

	const currentUser = useSelector(({ requests }) =>
		get(requests, "queries.FETCH_CURRENT_USER.data")
	);

	const useHasAccess = (hasAccess) => {
		if (Array.isArray(hasAccess) && get(currentUser, "roles"))
			return hasAccess.some((r) => currentUser.roles.includes(r));
		else if (get(currentUser, "roles")) return currentUser.roles.indexOf(hasAccess);
		return false;
	};

	React.useEffect(() => {
		checkEnv();
	});

	const checkPath = (path) => {
		return (
			path === pathname ||
			(path?.startsWith("/administration") && pathname?.startsWith("/administration")) ||
			(pathname.startsWith(path) && path !== "/")
		);
	};

	return (
		<Drawer
			variant="permanent"
			classes={{
				paper: clsx(
					classes.menuLeft,
					!menuExpanded && classes.menuLeftClose,
					headerExpanded && classes.menuLeftExpanded
				),
			}}
			open={menuExpanded}
		>
			<div className={classes.logo}>{!headerExpanded && <img src={logo} alt="Logo" />}</div>
			{!changePassPage && (
				<List>
					{menu[env].map(({ name, icon, path = "", items, hasAccess }, index) => (
						<>
							{useHasAccess(hasAccess) && (
								<Fragment key={index}>
									<ListItem
										id={`left-menu-item-${index}`}
										button
										component={Link}
										to={path}
										onClick={() => onListClick(items, index)}
										className={clsx(checkPath(path) && classes.selected)}
									>
										<ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
										<ListItemText primary={t(name)} />
										{items && (openSubMenu !== index ? <ChevronRight /> : <ExpandMore />)}
									</ListItem>
									{items && (
										<Collapse in={openSubMenu === index} timeout="auto" unmountOnExit>
											<List component="div" disablePadding className={classes.nested}>
												{/* eslint-disable-next-line */}
												{items.map(({ name, icon, path }) => (
													<ListItem
														id={`left-menu-item-collapse-${index}`}
														key={path}
														button
														component={Link}
														to={path}
														className={clsx(checkPath(path) && classes.selected)}
													>
														<ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
														<ListItemText primary={t(name)} />
													</ListItem>
												))}
											</List>
										</Collapse>
									)}
								</Fragment>
							)}
						</>
					))}
				</List>
			)}
		</Drawer>
	);
};
