import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import { Button, Divider } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import Badge from "@material-ui/core/Badge";
import get from "lodash/get";
import { useHistory } from "react-router";
import isEmpty from "lodash/isEmpty";
import DropzoneArea from "components/DropZoneArea";
import { toast, currencyFormat, isSupplierOnly } from "utils";
import { EXTENTIONS_INVOICES } from "utils/constants";
import { getApi } from "redux/store";
import useStyles from "./style";
import {
	fetchSupplier,
	fetchSupplierInvoices,
	invoicesTotalAmountsBySupplier,
	invoicesVATAmountsBySupplier,
} from "../../redux/actions/suppliers";
import {
	downloadXMLInvoice,
	fetchCountInvoices,
	fetchCountSuppliers,
	invoicesTotalAmounts,
	fetchInvoices,
	invoicesTotalAmountsOverDueBySupplier,
	createInvoice,
} from "../../redux/actions/invoices";
import PaidInvoice from "../Home/components/PaidInvoicesNew";
import UnpaidInvoice from "../Home/components/UnpaidInvoicesNew";
import InvoicesTable from "../Home/components/InvoicesTable";
import UserProfile from "../../App/user-profile.png";
import SubPageContainer from "../../components/SubPageContainer";
import SubPagePanel from "../../components/SubPagePanel";
import OrderTable from "./Component/OrderTable";
import { fetchOrdersBySupplier } from "../../redux/actions/orders";
import { fetchDeliveryItemsBySupplier } from "../../redux/actions/delivery";
import { DetailsLegalInformation } from "./Component/DetailsSupplier/DetailsLegalInformation";
import { DetailsSupplierContact } from "./Component/DetailsSupplier/DetailsSupplierContact";
import { DetailsFinancialInformations } from "./Component/DetailsSupplier/DetailsFinancialInformations";
import DeliveryTable from "./Component/DeliveryTable";
import CircularLoader from "../../components/Loaders/CircularLoader";
import Messages from "../Messages";

function a11yProps(index) {
	return {
		id: `supplier-detail-tab-${index}`,
		"aria-controls": `supplier-detail-tabpanel-${index}`,
	};
}

function SupplierDetails(props) {
	const classes = useStyles();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const [value, setValue] = React.useState(0);
	const [valueI, setValueI] = React.useState(0);
	const [expanded, setExpanded] = React.useState(false);
	const supplier = useSelector(({ requests }) => get(requests.queries, "FETCH_SUPPLIER.data"));

	const supplierInvoices = useSelector(({ requests }) =>
		get(requests.queries, "FETCH_SUPPLIER_INVOICES.data")
	);

	const totalInvoicesAmount = useSelector(({ requests }) =>
		get(requests.queries, "INVOICE_TOTAL_AMOUNT_BY_SUPPLIER.data")
	);
	const totalInvoicesAmountPayed = useSelector(({ requests }) =>
		get(requests.queries, "INVOICE_TOTAL_AMOUNT_BY_SUPPLIER_EXPORTED.data")
	);

	const totalInvoicesAmountUnpaid = useSelector(({ requests }) =>
		get(requests.queries, "INVOICE_TOTAL_AMOUNT_BY_SUPPLIER_UNPAID.data")
	);
	const totalVatAmount = useSelector(({ requests }) =>
		get(requests.queries, "INVOICE_VAT_AMOUNT_BY_SUPPLIER_EXPORTED.data")
	);
	const currentUser = useSelector(({ requests }) =>
		get(requests, "queries.FETCH_CURRENT_USER.data")
	);

	const orders = useSelector(({ requests }) =>
		get(requests.queries, "FETCH_ORDER_BY_SUPPLIER.data")
	);
	const deliveries = useSelector(({ requests }) =>
		get(requests.queries, "FETCH_DELIVERY_ITEMS_BY_SUPPLIER.data")
	);
	const totalAmountsOverDueBySupplier = useSelector(
		({ requests }) =>
			requests.queries.INVOICE_TOTAL_OVERDUE_AMOUNT_BY_SUPPLIER &&
			requests.queries.INVOICE_TOTAL_OVERDUE_AMOUNT_BY_SUPPLIER.data
	);
	const [files, setFiles] = React.useState([]);
	const [filesNotPersisted, setFilesNotPersisted] = React.useState([]);
	const onloadFiles = (filesParam) => {
		setFiles(filesParam);
	};
	const onDeleteFile = (file) => {
		files.splice(files.indexOf(file), 1);
		toast.info(t("fileRemovedMsg", { filename: file.name }), { autoClose: 3000 });
	};

	// eslint-disable-next-line no-shadow
	const filterInvoices = useSelector(({ filterInvoices }) => filterInvoices);

	const onclickUploadInvoices = () => {
		files.forEach((element) => {
			dispatch(createInvoice(element)).then((response) => {
				if (response.status === 201) {
					toast.success(t("invoice_uploaded_success"));
				}
				dispatch(fetchInvoices(filterInvoices));
				dispatch(invoicesTotalAmounts([]));
				if (response.data && !isEmpty(response.data.nameFile)) {
					setFilesNotPersisted([...filesNotPersisted, response.data.nameFile]);
				}
			});
		});
	};

	useEffect(() => {
		// dispatch(invoicesTotalAmounts());
		dispatch(fetchCountSuppliers());
		dispatch(fetchCountInvoices());
		dispatch(invoicesTotalAmountsOverDueBySupplier(props.match.params.idSupplier));
		dispatch(fetchSupplier(props.match.params.idSupplier));
		dispatch(fetchSupplierInvoices(props.match.params.idSupplier));
		dispatch(fetchOrdersBySupplier(props.match.params.idSupplier));
		dispatch(invoicesTotalAmountsBySupplier(props.match.params.idSupplier));
		dispatch(invoicesTotalAmountsBySupplier(props.match.params.idSupplier, "EXPORTED"));
		dispatch(invoicesTotalAmountsBySupplier(props.match.params.idSupplier, "UNPAID"));
		dispatch(invoicesVATAmountsBySupplier(props.match.params.idSupplier, "EXPORTED"));
		dispatch(fetchDeliveryItemsBySupplier(props.match.params.idSupplier));
	}, [dispatch, props.match.params.idSupplier]);

	const onClickRow = (id) => {
		history.push(`invoices/details/${id}`);
	};

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	const handleChangeInformtionTab = (event, newValue) => {
		setValueI(newValue);
	};

	const onDrop = () => {
		// toast.success(t("fileAddedMsg"), { autoClose: 3000 });
	};

	return (
		<>
			{supplier ? (
				<Grid container xs={12} className={classes.rootContainer}>
					<Grid container item xs={8}>
						<Card style={{ width: "100%" }}>
							<Grid item container xs={12}>
								<Grid
									container
									item
									className={classes.supplierItem}
									direction="column"
									spacing={4}
									justify="center"
									alignItems="center"
									xs={3}
								>
									<Grid item container justify="center" alignItems="center">
										<Badge
											overlap="circle"
											anchorOrigin={{
												vertical: "bottom",
												horizontal: "right",
											}}
											classes={{ badge: classes.customBadge }}
										>
											<Avatar
												alt="Remy Sharp"
												src={
													supplier && supplier.logo
														? `${getApi()}/invoice${supplier.logo}`
														: UserProfile
												}
												className={classes.large}
											/>
										</Badge>
									</Grid>

									<Grid item>
										<Typography variant="h6" color="primary">
											{get(supplier, "label")}
										</Typography>
									</Grid>
								</Grid>
								<Grid container item xs={1}>
									<Divider orientation="vertical" flexItem />
								</Grid>
								<Grid container item xs={8}>
									<Paper position="static" className={classes.appBar} style={{ width: "100%" }}>
										<Tabs
											value={valueI}
											onChange={handleChangeInformtionTab}
											aria-label="Assets"
											className={classes.tabs}
										>
											<Tab
												label={t("details")}
												{...a11yProps(0)} // eslint-disable-line
												classes={{ selected: classes.selectedTab }}
											/>
											<Tab
												label={t("associatedContact")}
												{...a11yProps(1)} // eslint-disable-line
												classes={{ selected: classes.selectedTab }}
											/>
											<Tab
												label={t("financialInformation")}
												{...a11yProps(2)} // eslint-disable-line
												classes={{ selected: classes.selectedTab }}
											/>
										</Tabs>
									</Paper>
									<SubPagePanel value={valueI} index={0} className={classes.fullwidth}>
										<DetailsLegalInformation supplier={supplier} />
									</SubPagePanel>
									<SubPagePanel value={valueI} index={1} className={classes.fullwidth}>
										<DetailsSupplierContact
											contact={supplier && supplier.contacts && supplier.contacts[0]}
										/>
									</SubPagePanel>
									<SubPagePanel value={valueI} index={2} className={classes.fullwidth}>
										<DetailsFinancialInformations supplier={supplier} />
									</SubPagePanel>
								</Grid>
							</Grid>
						</Card>
					</Grid>

					<Grid continer item direction="column" xs={4}>
						<Grid item xs={12}>
							<PaidInvoice
								elevation={26}
								square
								totalVatAmount={totalVatAmount}
								totalPaidAmount={totalInvoicesAmountPayed}
								style={{ background: "#f8f9fa" }}
								currentUser={currentUser}
							/>
						</Grid>
						<Grid item xs={12}>
							<UnpaidInvoice
								elevation={26}
								square
								unpaidIncoicesAmount={totalInvoicesAmountUnpaid}
								style={{ background: "#f8f9fa" }}
								overdue={totalAmountsOverDueBySupplier}
								currentUser={currentUser}
							/>
						</Grid>
					</Grid>
				</Grid>
			) : (
				<CircularLoader />
			)}
			<Grid container xs={12} className={classes.invoicesDetails}>
				<Grid container direction="column" item xs={6}>
					<Grid item>
						<Typography variant="h6" color="secondary">
							{t("Total amount of invoices")} :
							{totalInvoicesAmount >= 0 ? (
								` ${currencyFormat(totalInvoicesAmount)} €`
							) : (
								<CircularLoader />
							)}
						</Typography>
					</Grid>
					<Grid item>
						<Typography variant="subtitle2" color="textSecondary">
							{t("countInvoices")} :{" "}
							{supplierInvoices ? get(supplierInvoices, "length") : <CircularLoader />}
						</Typography>
					</Grid>
				</Grid>
			</Grid>

			<Grid container spacing={4} className={classes.invoicesTable}>
				<Grid item xs={12} sm={12}>
					<SubPageContainer>
						<Grid
							spacing={2}
							container
							item
							xs={12}
							className={classes.headerZone}
							justify="center"
							alignItems="stretch"
						>
							<Grid item xs={6}>
								<DropzoneArea
									expanded={expanded}
									showFileNames
									useChipsForPreview
									dropzoneClass={classes.dropzone}
									dropzoneParagraphClass={classes.text}
									onChange={onloadFiles}
									onDelete={onDeleteFile}
									onDrop={onDrop}
									zoneMessage="Add your Invoice"
									acceptedFiles={EXTENTIONS_INVOICES}
								/>
							</Grid>
							<Grid container item xs={2}>
								{expanded && (
									<Button
										fullWidth
										variant="contained"
										color="primary"
										className={classes.expand}
										onClick={(e) => {
											onclickUploadInvoices(e);
											setExpanded(!expanded);
										}}
									>
										{t("Upload")}
									</Button>
								)}
							</Grid>
						</Grid>
						{isSupplierOnly(get(currentUser, "roles", [])) && (
							<Grid container item xs={12} justify="flex-end">
								{!expanded && (
									<Button
										variant="contained"
										color="primary"
										className={classes.expand}
										onClick={() => {
											setExpanded(!expanded);
										}}
									>
										{t("Add an invoice")}
									</Button>
								)}
							</Grid>
						)}

						<Paper position="static" className={classes.appBar}>
							<Tabs
								value={value}
								onChange={handleChange}
								aria-label="Assets"
								className={classes.tabs}
							>
								<Tab
									label={t("Invoices")}
									{...a11yProps(0)} // eslint-disable-line
									classes={{ selected: classes.selectedTab }}
								/>
								<Tab
									label={t("Orders")}
									{...a11yProps(1)} // eslint-disable-line
									classes={{ selected: classes.selectedTab }}
								/>
								<Tab
									label={t("delivery")}
									{...a11yProps(2)} // eslint-disable-line
									classes={{ selected: classes.selectedTab }}
								/>
								<Tab
									label={t("contact")}
									{...a11yProps(3)} // eslint-disable-line
									classes={{ selected: classes.selectedTab }}
								/>
							</Tabs>
						</Paper>
						<SubPagePanel value={value} index={0}>
							{get(supplierInvoices, "length") >= 0 ? (
								<InvoicesTable
									isDashboard
									isSupplierDetails
									supplierInvoices={supplierInvoices}
									totalInvoicesAmount={totalInvoicesAmount}
									onClickInvoice={onClickRow}
									downloadXMLInvoice={downloadXMLInvoice}
									idSupplier={props.match.params.idSupplier}
								/>
							) : (
								<CircularLoader />
							)}
						</SubPagePanel>
						<SubPagePanel value={value} index={1}>
							{get(orders, "length") >= 0 ? <OrderTable orders={orders} /> : <CircularLoader />}
						</SubPagePanel>
						<SubPagePanel value={value} index={2}>
							{get(orders, "length") >= 0 ? (
								<DeliveryTable deliveries={deliveries} />
							) : (
								<CircularLoader />
							)}
						</SubPagePanel>
						<SubPagePanel value={value} index={3}>
							<Messages />
						</SubPagePanel>
					</SubPageContainer>
				</Grid>
			</Grid>
		</>
	);
}

export default SupplierDetails;
