export const rectColor = "rgba(204,204,204,0.25)";
// export const rectColor = "#000";
export const highlightColor = "rgba(13, 168, 173,0.5)";

export const adaptData = (data) => {
	let rects = [];
	// eslint-disable-next-line
	data.objects = data.objects.map((e) => {
		e.type = "rect";
		e.fill = rectColor;

		// eslint-disable-next-line
		switch (e.type) {
			case "text":
				rects = [
					...rects,
					{
						type: "rect",
						text: e.text,
						top: e.top,
						left: e.left,
						width: e.width,
						height: e.height,
						fill: rectColor,
						rx: 5,
						ry: 5,
					},
				];
				return { ...e, hoverCursor: "pointer", selectable: false, fill: "" };
			case "textbox":
				return { ...e, hoverCursor: "pointer" };
			case "image":
				return { ...e, hoverCursor: "auto" };
			case "line":
				return { ...e, hoverCursor: "auto" };
			case "rect":
				return { ...e, hoverCursor: "pointer" };
		}
	});
	data.objects = [...rects, ...data.objects];
	data.background = "";
	return data;
};

export const getBase64 = (file) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);

		reader.onload = () => resolve(reader.result.split(",")[1]);
		reader.onerror = (error) => reject(error);
	});

export const reducedData = (scaleX, scaleY, pagesDataDto) => {
	const result = {};
	const _objects = pagesDataDto.recognitionTextDto.map((elem) => {
		const object = {
			top: elem.location.top * scaleY,
			left: elem.location.left * scaleX,
			width: (elem.location.right - elem.location.left) * scaleX,
			height: (elem.location.bottom - elem.location.top) * scaleY,
			text: elem,
		};
		return object;
	});
	result.objects = _objects;
	return result;
};
