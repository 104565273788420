import React, { Fragment } from "react";
import { useDispatch } from "react-redux";

import { useTranslation } from "react-i18next";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import clsx from "clsx";
import Input from "@material-ui/core/Input";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import useStyles from "../../style";
import { updateFormulaire } from "../../../../../redux/formulaireReducer";

function AmountsComponent({ formulaire, rectify, setLocation }) {
	const classes = useStyles();
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const updateSelectedItem = (value) => {
		dispatch(updateFormulaire({ ...formulaire, selectedItem: value }));
	};
	const isEmptyOrNull = (value) => value === null || value === "";

	return (
		<>
			<TableRow>
				<TableCell rowSpan={100} />
				<TableCell rowSpan={100} />
				<TableCell rowSpan={100} />
				<TableCell rowSpan={100} />
			</TableRow>
			{formulaire.orderDto.additionalAmountsHT.map((row, index) => (
				<TableRow>
					<TableCell align="right" className={clsx(rectify && classes.rectifyHover)}>
						{t("Total HT ")}
						{index !== 0 ? index + 1 : ""}
					</TableCell>
					<TableCell align="right">
						<Input
							className={clsx(
								"classes.resize",
								isEmptyOrNull(formulaire.orderDto.additionalAmountsHT[index].amount.value) &&
									classes.emptyInput
							)}
							fullWidth
							onChange={(e) => {
								const _formulaire = JSON.parse(JSON.stringify(formulaire));
								_formulaire.orderDto.additionalAmountsHT[index].amount.value = e.target.value;
								if (isEmptyOrNull(e.target.value)) {
									_formulaire.orderDto.additionalAmountsHT[index].amount.location = {
										top: null,
										right: null,
										left: null,
										bottom: null,
									};
								}
								dispatch(updateFormulaire(_formulaire));
							}}
							onClick={() => {
								updateSelectedItem(`additionalAmountsht.${index}.amount`);
								setLocation(formulaire.orderDto.additionalAmountsHT[index].amount.location);
							}}
							value={formulaire.orderDto.additionalAmountsHT[index].amount.value}
						/>
					</TableCell>
					{index === 0 ? (
						<IconButton
							aria-label="add"
							onClick={() => {
								if (rectify) {
									const _formulaire = JSON.parse(JSON.stringify(formulaire));
									_formulaire.orderDto.additionalAmountsHT.push({
										amount: {},
										type: "HT",
										principal: false,
									});
									dispatch(updateFormulaire(_formulaire));
								}
							}}
						>
							<AddIcon />
						</IconButton>
					) : (
						<IconButton
							aria-label="add"
							onClick={() => {
								const _formulaire = JSON.parse(JSON.stringify(formulaire));
								_formulaire.orderDto.additionalAmountsHT.splice(index, 1);
								dispatch(updateFormulaire(_formulaire));
							}}
						>
							<DeleteOutline />
						</IconButton>
					)}
				</TableRow>
			))}

			{formulaire.orderDto.additionalAmountsTVA.map((row, index) => (
				<TableRow>
					<TableCell align="right" className={clsx(rectify && classes.rectifyHover)}>
						{t("VAT amount")}
						{index !== 0 ? index + 1 : ""}
					</TableCell>
					<TableCell align="right">
						<Input
							className={clsx(
								"classes.resize",
								isEmptyOrNull(formulaire.orderDto.additionalAmountsTVA[index].amount.value) &&
									classes.emptyInput
							)}
							fullWidth
							onChange={(e) => {
								const _formulaire = JSON.parse(JSON.stringify(formulaire));
								_formulaire.orderDto.additionalAmountsTVA[index].amount.value = e.target.value;
								if (isEmptyOrNull(e.target.value)) {
									_formulaire.orderDto.additionalAmountsTVA[index].amount.location = {
										top: null,
										right: null,
										left: null,
										bottom: null,
									};
								}
								dispatch(updateFormulaire(_formulaire));
							}}
							onClick={() => {
								updateSelectedItem(`additionalAmountstva.${index}.amount`);
								setLocation(formulaire.orderDto.additionalAmountsTVA[index].amount.location);
							}}
							value={formulaire.orderDto.additionalAmountsTVA[index].amount.value}
						/>
					</TableCell>
					{index === 0 ? (
						<IconButton
							aria-label="add"
							onClick={() => {
								if (rectify) {
									const _formulaire = JSON.parse(JSON.stringify(formulaire));
									_formulaire.orderDto.additionalAmountsTVA.push({
										amount: {},
										type: "VAT",
										principal: false,
									});
									dispatch(updateFormulaire(_formulaire));
								}
							}}
						>
							<AddIcon />
						</IconButton>
					) : (
						<IconButton
							aria-label="add"
							onClick={() => {
								const _formulaire = JSON.parse(JSON.stringify(formulaire));
								_formulaire.orderDto.additionalAmountsTVA.splice(index, 1);
								dispatch(updateFormulaire(_formulaire));
							}}
						>
							<DeleteOutline />
						</IconButton>
					)}
				</TableRow>
			))}
			<TableRow>
				<TableCell align="right" className={clsx(rectify && classes.rectifyHover)}>
					{" "}
					{t("Discount")}
				</TableCell>
				<TableCell align="right">
					<Input
						className={clsx(
							"classes.resize",
							rectify && isEmptyOrNull(formulaire.orderDto.discount.value) && classes.emptyInput
						)}
						fullWidth
						onChange={(e) => {
							const _formulaire = JSON.parse(JSON.stringify(formulaire));
							_formulaire.orderDto.discount.value = e.target.value;
							if (isEmptyOrNull(e.target.value)) {
								_formulaire.orderDto.discount.location = {
									top: null,
									right: null,
									left: null,
									bottom: null,
								};
							}
							dispatch(updateFormulaire(_formulaire));
						}}
						onClick={() => {
							updateSelectedItem("discount");
							setLocation(formulaire.orderDto.discount.location);
						}}
						value={formulaire.orderDto.discount.value}
					/>
				</TableCell>
			</TableRow>
			{formulaire.orderDto.additionalAmountsCharge.map((row, index) => (
				<TableRow>
					<TableCell align="right" className={clsx(rectify && classes.rectifyHover)}>
						{t("invoice_charge")}
						{index !== 0 ? index + 1 : ""}
					</TableCell>
					<TableCell align="right">
						<Input
							className={clsx(
								"classes.resize",
								isEmptyOrNull(formulaire.orderDto.additionalAmountsCharge[index].amount.value) &&
									classes.emptyInput
							)}
							fullWidth
							onChange={(e) => {
								const _formulaire = JSON.parse(JSON.stringify(formulaire));
								_formulaire.orderDto.additionalAmountsCharge[index].amount.value = e.target.value;
								if (isEmptyOrNull(e.target.value)) {
									_formulaire.orderDto.additionalAmountsCharge[index].amount.location = {
										top: null,
										right: null,
										left: null,
										bottom: null,
									};
								}
								dispatch(updateFormulaire(_formulaire));
							}}
							onClick={() => {
								updateSelectedItem(`additionalAmountscharge.${index}.amount`);
								setLocation(formulaire.orderDto.additionalAmountsCharge[index].amount.location);
							}}
							value={formulaire.orderDto.additionalAmountsCharge[index].amount.value}
						/>
					</TableCell>
					{index === 0 ? (
						<IconButton
							aria-label="add"
							onClick={() => {
								if (rectify) {
									const _formulaire = JSON.parse(JSON.stringify(formulaire));
									_formulaire.orderDto.additionalAmountsCharge.push({
										amount: {},
										type: "CHARGE",
										principal: false,
									});
									dispatch(updateFormulaire(_formulaire));
								}
							}}
						>
							<AddIcon />
						</IconButton>
					) : (
						<IconButton
							aria-label="add"
							onClick={() => {
								const _formulaire = JSON.parse(JSON.stringify(formulaire));
								_formulaire.orderDto.additionalAmountsCharge.splice(index, 1);
								dispatch(updateFormulaire(_formulaire));
							}}
						>
							<DeleteOutline />
						</IconButton>
					)}
				</TableRow>
			))}
			<TableRow>
				<TableCell align="right" className={clsx(rectify && classes.rectifyHover)}>
					{t("Total amount")}
				</TableCell>
				<TableCell align="right">
					<Input
						className={clsx(
							"classes.resize",
							rectify && isEmptyOrNull(formulaire.orderDto.total.value) && classes.emptyInput
						)}
						fullWidth
						onChange={(e) => {
							const _formulaire = JSON.parse(JSON.stringify(formulaire));
							_formulaire.orderDto.total.value = e.target.value;
							if (isEmptyOrNull(e.target.value)) {
								_formulaire.orderDto.total.location = {
									top: null,
									right: null,
									left: null,
									bottom: null,
								};
							}
							dispatch(updateFormulaire(_formulaire));
						}}
						onClick={() => {
							updateSelectedItem("total");
							setLocation(formulaire.orderDto.total.location);
						}}
						value={formulaire.orderDto.total.value}
					/>
				</TableCell>
			</TableRow>
		</>
	);
}

export default AmountsComponent;
