import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import get from "lodash/get";
import TableCardItem from "../../../../components/TableCardItem";

function DeliveryTable({ deliveries }) {
  const { t } = useTranslation();

  const onClickRow = () => {};
  return (
    <Grid container>
      <Grid item xs={12}>
        <>
          {deliveries &&
            deliveries.map((delivery, i) => {
              const infoItems = [
                {
                  label: t("deliveryNo"),
                  value: get(delivery, "delivery.deliveryNumber"),
                },
                {
                  label: t("orderNo"),
                  value: get(delivery, "purchaseOrder.orderNo"),
                },
                {
                  label: t("codeArticle"),
                  value: get(delivery, "codeArticle"),
                },
                {
                  label: t("deliveredQuantity"),
                  value: get(delivery, "deliveredQuantity"),
                },
              ];
              return (
                <TableCardItem
                  index={i}
                  row={delivery}
                  infoItems={infoItems}
                  onClickRow={() => {
                    onClickRow(delivery.orderNo);
                  }}
                  onDeleteItem={() => {}}
                  withLogo={false}
                />
              );
            })}
        </>
      </Grid>
    </Grid>
  );
}

export default DeliveryTable;
